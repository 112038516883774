function x(t) {
  const n = new Set(t);
  return (e) => n.has(e);
}
function A(t, ...n) {
  try {
    return t == null ? void 0 : t(...n);
  } catch (e) {
    console.error(e);
  }
}
const f = (t) => {
  Promise.resolve().then(t);
}, P = queueMicrotask ?? f, o = (t) => (n) => typeof n === t, a = o("object"), d = o("bigint"), u = o("boolean"), s = o("function"), p = o("number"), w = o("string"), M = o("symbol"), g = o("undefined"), c = (t) => t === null, R = (t) => c(t) || g(t), { isArray: N } = Array, i = (t) => a(t) && !c(t) && !N(t), q = (t) => Number.isInteger(t), k = (t) => d(t) || u(t) || p(t) || w(t) || M(t) || g(t) || c(t), B = (t) => i(t) && s(t.then), F = (t) => i(t) && s(t.then) && s(t.catch) && s(t.finally), L = (t) => i(t) && s(t.next), O = (t) => i(t) && s(t.next) && s(t.throw) && s(t.return), S = (t) => t != null, I = "useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict";
let j = (t, n, e) => {
  let h = (2 << Math.log(t.length - 1) / Math.LN2) - 1, m = -~(1.6 * h * n / t.length);
  return (l = n) => {
    let r = "";
    for (; ; ) {
      let b = e(m), y = m;
      for (; y--; )
        if (r += t[b[y] & h] || "", r.length === l)
          return r;
    }
  };
};
const T = j(I, 21, (t) => {
  let n = globalThis.crypto;
  return n === void 0 && (n = require("node:crypto").webcrypto), n.getRandomValues(new Uint8Array(t));
}), U = (t, n) => (e) => Math.min(Math.max(e, t), n);
export {
  x as arrayHas,
  P as asap,
  U as createInRange,
  N as isArray,
  d as isBigint,
  u as isBoolean,
  s as isFunction,
  O as isGenerator,
  q as isInteger,
  L as isIterator,
  R as isNill,
  c as isNull,
  p as isNumber,
  i as isObject,
  a as isObjectRaw,
  k as isPrimitive,
  F as isPromise,
  B as isPromiseLike,
  w as isString,
  M as isSymbol,
  g as isUndefined,
  T as nanoid,
  S as nonNullable,
  A as safeCallback
};
