/*!
 * @dineug/erd-editor
 * @version 3.2.6 | Sat Nov 09 2024
 * @author SeungHwan-Lee <dineug2@gmail.com>
 * @license MIT
 */
var hf = typeof global == "object" && global && global.Object === Object && global, Jy = typeof self == "object" && self && self.Object === Object && self, yt = hf || Jy || Function("return this")(), Qe = yt.Symbol, pf = Object.prototype, Xy = pf.hasOwnProperty, eg = pf.toString, sr = Qe ? Qe.toStringTag : void 0;
function tg(e) {
  var t = Xy.call(e, sr), n = e[sr];
  try {
    e[sr] = void 0;
    var o = !0;
  } catch {
  }
  var r = eg.call(e);
  return o && (t ? e[sr] = n : delete e[sr]), r;
}
var ng = Object.prototype, og = ng.toString;
function rg(e) {
  return og.call(e);
}
var sg = "[object Null]", ig = "[object Undefined]", gu = Qe ? Qe.toStringTag : void 0;
function Un(e) {
  return e == null ? e === void 0 ? ig : sg : gu && gu in Object(e) ? tg(e) : rg(e);
}
function Et(e) {
  return e != null && typeof e == "object";
}
var ag = "[object Symbol]";
function na(e) {
  return typeof e == "symbol" || Et(e) && Un(e) == ag;
}
function Fl(e, t) {
  for (var n = -1, o = e == null ? 0 : e.length, r = Array(o); ++n < o; )
    r[n] = t(e[n], n, e);
  return r;
}
var Be = Array.isArray, lg = 1 / 0, vu = Qe ? Qe.prototype : void 0, bu = vu ? vu.toString : void 0;
function yf(e) {
  if (typeof e == "string")
    return e;
  if (Be(e))
    return Fl(e, yf) + "";
  if (na(e))
    return bu ? bu.call(e) : "";
  var t = e + "";
  return t == "0" && 1 / e == -lg ? "-0" : t;
}
var cg = /\s/;
function ug(e) {
  for (var t = e.length; t-- && cg.test(e.charAt(t)); )
    ;
  return t;
}
var dg = /^\s+/;
function fg(e) {
  return e && e.slice(0, ug(e) + 1).replace(dg, "");
}
function dt(e) {
  var t = typeof e;
  return e != null && (t == "object" || t == "function");
}
var Iu = NaN, mg = /^[-+]0x[0-9a-f]+$/i, hg = /^0b[01]+$/i, pg = /^0o[0-7]+$/i, yg = parseInt;
function gf(e) {
  if (typeof e == "number")
    return e;
  if (na(e))
    return Iu;
  if (dt(e)) {
    var t = typeof e.valueOf == "function" ? e.valueOf() : e;
    e = dt(t) ? t + "" : t;
  }
  if (typeof e != "string")
    return e === 0 ? e : +e;
  e = fg(e);
  var n = hg.test(e);
  return n || pg.test(e) ? yg(e.slice(2), n ? 2 : 8) : mg.test(e) ? Iu : +e;
}
var Tu = 1 / 0, gg = 17976931348623157e292;
function Zs(e) {
  if (!e)
    return e === 0 ? e : 0;
  if (e = gf(e), e === Tu || e === -Tu) {
    var t = e < 0 ? -1 : 1;
    return t * gg;
  }
  return e === e ? e : 0;
}
function vg(e) {
  var t = Zs(e), n = t % 1;
  return t === t ? n ? t - n : t : 0;
}
function ql(e) {
  return e;
}
var bg = "[object AsyncFunction]", Ig = "[object Function]", Tg = "[object GeneratorFunction]", wg = "[object Proxy]";
function vf(e) {
  if (!dt(e))
    return !1;
  var t = Un(e);
  return t == Ig || t == Tg || t == bg || t == wg;
}
var _a = yt["__core-js_shared__"], wu = function() {
  var e = /[^.]+$/.exec(_a && _a.keys && _a.keys.IE_PROTO || "");
  return e ? "Symbol(src)_1." + e : "";
}();
function Cg(e) {
  return !!wu && wu in e;
}
var Og = Function.prototype, Eg = Og.toString;
function jn(e) {
  if (e != null) {
    try {
      return Eg.call(e);
    } catch {
    }
    try {
      return e + "";
    } catch {
    }
  }
  return "";
}
var Sg = /[\\^$.*+?()[\]{}|]/g, xg = /^\[object .+?Constructor\]$/, Ag = Function.prototype, Mg = Object.prototype, Ng = Ag.toString, $g = Mg.hasOwnProperty, kg = RegExp(
  "^" + Ng.call($g).replace(Sg, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function Dg(e) {
  if (!dt(e) || Cg(e))
    return !1;
  var t = vf(e) ? kg : xg;
  return t.test(jn(e));
}
function Rg(e, t) {
  return e == null ? void 0 : e[t];
}
function Fn(e, t) {
  var n = Rg(e, t);
  return Dg(n) ? n : void 0;
}
var il = Fn(yt, "WeakMap"), Cu = Object.create, Lg = /* @__PURE__ */ function() {
  function e() {
  }
  return function(t) {
    if (!dt(t))
      return {};
    if (Cu)
      return Cu(t);
    e.prototype = t;
    var n = new e();
    return e.prototype = void 0, n;
  };
}();
function Pg(e, t, n) {
  switch (n.length) {
    case 0:
      return e.call(t);
    case 1:
      return e.call(t, n[0]);
    case 2:
      return e.call(t, n[0], n[1]);
    case 3:
      return e.call(t, n[0], n[1], n[2]);
  }
  return e.apply(t, n);
}
function _g() {
}
function Bg(e, t) {
  var n = -1, o = e.length;
  for (t || (t = Array(o)); ++n < o; )
    t[n] = e[n];
  return t;
}
var Wg = 800, Ug = 16, jg = Date.now;
function Fg(e) {
  var t = 0, n = 0;
  return function() {
    var o = jg(), r = Ug - (o - n);
    if (n = o, r > 0) {
      if (++t >= Wg)
        return arguments[0];
    } else
      t = 0;
    return e.apply(void 0, arguments);
  };
}
function qg(e) {
  return function() {
    return e;
  };
}
var $i = function() {
  try {
    var e = Fn(Object, "defineProperty");
    return e({}, "", {}), e;
  } catch {
  }
}(), Hg = $i ? function(e, t) {
  return $i(e, "toString", {
    configurable: !0,
    enumerable: !1,
    value: qg(t),
    writable: !0
  });
} : ql;
const Zg = Hg;
var bf = Fg(Zg);
function Vg(e, t) {
  for (var n = -1, o = e == null ? 0 : e.length; ++n < o && t(e[n], n, e) !== !1; )
    ;
  return e;
}
function zg(e, t, n, o) {
  for (var r = e.length, s = n + (o ? 1 : -1); o ? s-- : ++s < r; )
    if (t(e[s], s, e))
      return s;
  return -1;
}
function Gg(e) {
  return e !== e;
}
function Yg(e, t, n) {
  for (var o = n - 1, r = e.length; ++o < r; )
    if (e[o] === t)
      return o;
  return -1;
}
function Kg(e, t, n) {
  return t === t ? Yg(e, t, n) : zg(e, Gg, n);
}
function If(e, t) {
  var n = e == null ? 0 : e.length;
  return !!n && Kg(e, t, 0) > -1;
}
var Qg = 9007199254740991, Jg = /^(?:0|[1-9]\d*)$/;
function oa(e, t) {
  var n = typeof e;
  return t = t ?? Qg, !!t && (n == "number" || n != "symbol" && Jg.test(e)) && e > -1 && e % 1 == 0 && e < t;
}
function Hl(e, t, n) {
  t == "__proto__" && $i ? $i(e, t, {
    configurable: !0,
    enumerable: !0,
    value: n,
    writable: !0
  }) : e[t] = n;
}
function ra(e, t) {
  return e === t || e !== e && t !== t;
}
var Xg = Object.prototype, ev = Xg.hasOwnProperty;
function Zl(e, t, n) {
  var o = e[t];
  (!(ev.call(e, t) && ra(o, n)) || n === void 0 && !(t in e)) && Hl(e, t, n);
}
function os(e, t, n, o) {
  var r = !n;
  n || (n = {});
  for (var s = -1, i = t.length; ++s < i; ) {
    var a = t[s], l = o ? o(n[a], e[a], a, n, e) : void 0;
    l === void 0 && (l = e[a]), r ? Hl(n, a, l) : Zl(n, a, l);
  }
  return n;
}
var Ou = Math.max;
function Tf(e, t, n) {
  return t = Ou(t === void 0 ? e.length - 1 : t, 0), function() {
    for (var o = arguments, r = -1, s = Ou(o.length - t, 0), i = Array(s); ++r < s; )
      i[r] = o[t + r];
    r = -1;
    for (var a = Array(t + 1); ++r < t; )
      a[r] = o[r];
    return a[t] = n(i), Pg(e, this, a);
  };
}
function tv(e, t) {
  return bf(Tf(e, t, ql), e + "");
}
var nv = 9007199254740991;
function Vl(e) {
  return typeof e == "number" && e > -1 && e % 1 == 0 && e <= nv;
}
function Wo(e) {
  return e != null && Vl(e.length) && !vf(e);
}
function ov(e, t, n) {
  if (!dt(n))
    return !1;
  var o = typeof t;
  return (o == "number" ? Wo(n) && oa(t, n.length) : o == "string" && t in n) ? ra(n[t], e) : !1;
}
var rv = Object.prototype;
function sa(e) {
  var t = e && e.constructor, n = typeof t == "function" && t.prototype || rv;
  return e === n;
}
function sv(e, t) {
  for (var n = -1, o = Array(e); ++n < e; )
    o[n] = t(n);
  return o;
}
var iv = "[object Arguments]";
function Eu(e) {
  return Et(e) && Un(e) == iv;
}
var wf = Object.prototype, av = wf.hasOwnProperty, lv = wf.propertyIsEnumerable, cv = Eu(/* @__PURE__ */ function() {
  return arguments;
}()) ? Eu : function(e) {
  return Et(e) && av.call(e, "callee") && !lv.call(e, "callee");
};
const ia = cv;
function uv() {
  return !1;
}
var Cf = typeof exports == "object" && exports && !exports.nodeType && exports, Su = Cf && typeof module == "object" && module && !module.nodeType && module, dv = Su && Su.exports === Cf, xu = dv ? yt.Buffer : void 0, fv = xu ? xu.isBuffer : void 0, mv = fv || uv;
const zr = mv;
var hv = "[object Arguments]", pv = "[object Array]", yv = "[object Boolean]", gv = "[object Date]", vv = "[object Error]", bv = "[object Function]", Iv = "[object Map]", Tv = "[object Number]", wv = "[object Object]", Cv = "[object RegExp]", Ov = "[object Set]", Ev = "[object String]", Sv = "[object WeakMap]", xv = "[object ArrayBuffer]", Av = "[object DataView]", Mv = "[object Float32Array]", Nv = "[object Float64Array]", $v = "[object Int8Array]", kv = "[object Int16Array]", Dv = "[object Int32Array]", Rv = "[object Uint8Array]", Lv = "[object Uint8ClampedArray]", Pv = "[object Uint16Array]", _v = "[object Uint32Array]", ee = {};
ee[Mv] = ee[Nv] = ee[$v] = ee[kv] = ee[Dv] = ee[Rv] = ee[Lv] = ee[Pv] = ee[_v] = !0;
ee[hv] = ee[pv] = ee[xv] = ee[yv] = ee[Av] = ee[gv] = ee[vv] = ee[bv] = ee[Iv] = ee[Tv] = ee[wv] = ee[Cv] = ee[Ov] = ee[Ev] = ee[Sv] = !1;
function Bv(e) {
  return Et(e) && Vl(e.length) && !!ee[Un(e)];
}
function aa(e) {
  return function(t) {
    return e(t);
  };
}
var Of = typeof exports == "object" && exports && !exports.nodeType && exports, Wr = Of && typeof module == "object" && module && !module.nodeType && module, Wv = Wr && Wr.exports === Of, Ba = Wv && hf.process, Mo = function() {
  try {
    var e = Wr && Wr.require && Wr.require("util").types;
    return e || Ba && Ba.binding && Ba.binding("util");
  } catch {
  }
}(), Au = Mo && Mo.isTypedArray, Uv = Au ? aa(Au) : Bv;
const zl = Uv;
var jv = Object.prototype, Fv = jv.hasOwnProperty;
function Ef(e, t) {
  var n = Be(e), o = !n && ia(e), r = !n && !o && zr(e), s = !n && !o && !r && zl(e), i = n || o || r || s, a = i ? sv(e.length, String) : [], l = a.length;
  for (var c in e)
    (t || Fv.call(e, c)) && !(i && // Safari 9 has enumerable `arguments.length` in strict mode.
    (c == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
    r && (c == "offset" || c == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
    s && (c == "buffer" || c == "byteLength" || c == "byteOffset") || // Skip index properties.
    oa(c, l))) && a.push(c);
  return a;
}
function Sf(e, t) {
  return function(n) {
    return e(t(n));
  };
}
var qv = Sf(Object.keys, Object), Hv = Object.prototype, Zv = Hv.hasOwnProperty;
function xf(e) {
  if (!sa(e))
    return qv(e);
  var t = [];
  for (var n in Object(e))
    Zv.call(e, n) && n != "constructor" && t.push(n);
  return t;
}
function rs(e) {
  return Wo(e) ? Ef(e) : xf(e);
}
function Vv(e) {
  var t = [];
  if (e != null)
    for (var n in Object(e))
      t.push(n);
  return t;
}
var zv = Object.prototype, Gv = zv.hasOwnProperty;
function Yv(e) {
  if (!dt(e))
    return Vv(e);
  var t = sa(e), n = [];
  for (var o in e)
    o == "constructor" && (t || !Gv.call(e, o)) || n.push(o);
  return n;
}
function Gl(e) {
  return Wo(e) ? Ef(e, !0) : Yv(e);
}
var Kv = /\.|\[(?:[^[\]]*|(["'])(?:(?!\1)[^\\]|\\.)*?\1)\]/, Qv = /^\w*$/;
function Yl(e, t) {
  if (Be(e))
    return !1;
  var n = typeof e;
  return n == "number" || n == "symbol" || n == "boolean" || e == null || na(e) ? !0 : Qv.test(e) || !Kv.test(e) || t != null && e in Object(t);
}
var Gr = Fn(Object, "create");
function Jv() {
  this.__data__ = Gr ? Gr(null) : {}, this.size = 0;
}
function Xv(e) {
  var t = this.has(e) && delete this.__data__[e];
  return this.size -= t ? 1 : 0, t;
}
var eb = "__lodash_hash_undefined__", tb = Object.prototype, nb = tb.hasOwnProperty;
function ob(e) {
  var t = this.__data__;
  if (Gr) {
    var n = t[e];
    return n === eb ? void 0 : n;
  }
  return nb.call(t, e) ? t[e] : void 0;
}
var rb = Object.prototype, sb = rb.hasOwnProperty;
function ib(e) {
  var t = this.__data__;
  return Gr ? t[e] !== void 0 : sb.call(t, e);
}
var ab = "__lodash_hash_undefined__";
function lb(e, t) {
  var n = this.__data__;
  return this.size += this.has(e) ? 0 : 1, n[e] = Gr && t === void 0 ? ab : t, this;
}
function Pn(e) {
  var t = -1, n = e == null ? 0 : e.length;
  for (this.clear(); ++t < n; ) {
    var o = e[t];
    this.set(o[0], o[1]);
  }
}
Pn.prototype.clear = Jv;
Pn.prototype.delete = Xv;
Pn.prototype.get = ob;
Pn.prototype.has = ib;
Pn.prototype.set = lb;
function cb() {
  this.__data__ = [], this.size = 0;
}
function la(e, t) {
  for (var n = e.length; n--; )
    if (ra(e[n][0], t))
      return n;
  return -1;
}
var ub = Array.prototype, db = ub.splice;
function fb(e) {
  var t = this.__data__, n = la(t, e);
  if (n < 0)
    return !1;
  var o = t.length - 1;
  return n == o ? t.pop() : db.call(t, n, 1), --this.size, !0;
}
function mb(e) {
  var t = this.__data__, n = la(t, e);
  return n < 0 ? void 0 : t[n][1];
}
function hb(e) {
  return la(this.__data__, e) > -1;
}
function pb(e, t) {
  var n = this.__data__, o = la(n, e);
  return o < 0 ? (++this.size, n.push([e, t])) : n[o][1] = t, this;
}
function qt(e) {
  var t = -1, n = e == null ? 0 : e.length;
  for (this.clear(); ++t < n; ) {
    var o = e[t];
    this.set(o[0], o[1]);
  }
}
qt.prototype.clear = cb;
qt.prototype.delete = fb;
qt.prototype.get = mb;
qt.prototype.has = hb;
qt.prototype.set = pb;
var Yr = Fn(yt, "Map");
function yb() {
  this.size = 0, this.__data__ = {
    hash: new Pn(),
    map: new (Yr || qt)(),
    string: new Pn()
  };
}
function gb(e) {
  var t = typeof e;
  return t == "string" || t == "number" || t == "symbol" || t == "boolean" ? e !== "__proto__" : e === null;
}
function ca(e, t) {
  var n = e.__data__;
  return gb(t) ? n[typeof t == "string" ? "string" : "hash"] : n.map;
}
function vb(e) {
  var t = ca(this, e).delete(e);
  return this.size -= t ? 1 : 0, t;
}
function bb(e) {
  return ca(this, e).get(e);
}
function Ib(e) {
  return ca(this, e).has(e);
}
function Tb(e, t) {
  var n = ca(this, e), o = n.size;
  return n.set(e, t), this.size += n.size == o ? 0 : 1, this;
}
function Ht(e) {
  var t = -1, n = e == null ? 0 : e.length;
  for (this.clear(); ++t < n; ) {
    var o = e[t];
    this.set(o[0], o[1]);
  }
}
Ht.prototype.clear = yb;
Ht.prototype.delete = vb;
Ht.prototype.get = bb;
Ht.prototype.has = Ib;
Ht.prototype.set = Tb;
var wb = "Expected a function";
function Kl(e, t) {
  if (typeof e != "function" || t != null && typeof t != "function")
    throw new TypeError(wb);
  var n = function() {
    var o = arguments, r = t ? t.apply(this, o) : o[0], s = n.cache;
    if (s.has(r))
      return s.get(r);
    var i = e.apply(this, o);
    return n.cache = s.set(r, i) || s, i;
  };
  return n.cache = new (Kl.Cache || Ht)(), n;
}
Kl.Cache = Ht;
var Cb = 500;
function Ob(e) {
  var t = Kl(e, function(o) {
    return n.size === Cb && n.clear(), o;
  }), n = t.cache;
  return t;
}
var Eb = /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|$))/g, Sb = /\\(\\)?/g, xb = Ob(function(e) {
  var t = [];
  return e.charCodeAt(0) === 46 && t.push(""), e.replace(Eb, function(n, o, r, s) {
    t.push(r ? s.replace(Sb, "$1") : o || n);
  }), t;
});
function _n(e) {
  return e == null ? "" : yf(e);
}
function Uo(e, t) {
  return Be(e) ? e : Yl(e, t) ? [e] : xb(_n(e));
}
var Ab = 1 / 0;
function jo(e) {
  if (typeof e == "string" || na(e))
    return e;
  var t = e + "";
  return t == "0" && 1 / e == -Ab ? "-0" : t;
}
function ua(e, t) {
  t = Uo(t, e);
  for (var n = 0, o = t.length; e != null && n < o; )
    e = e[jo(t[n++])];
  return n && n == o ? e : void 0;
}
function Mb(e, t, n) {
  var o = e == null ? void 0 : ua(e, t);
  return o === void 0 ? n : o;
}
function Ql(e, t) {
  for (var n = -1, o = t.length, r = e.length; ++n < o; )
    e[r + n] = t[n];
  return e;
}
var Mu = Qe ? Qe.isConcatSpreadable : void 0;
function Nb(e) {
  return Be(e) || ia(e) || !!(Mu && e && e[Mu]);
}
function Jl(e, t, n, o, r) {
  var s = -1, i = e.length;
  for (n || (n = Nb), r || (r = []); ++s < i; ) {
    var a = e[s];
    t > 0 && n(a) ? t > 1 ? Jl(a, t - 1, n, o, r) : Ql(r, a) : o || (r[r.length] = a);
  }
  return r;
}
function $b(e) {
  var t = e == null ? 0 : e.length;
  return t ? Jl(e, 1) : [];
}
function Af(e) {
  return bf(Tf(e, void 0, $b), e + "");
}
var kb = Sf(Object.getPrototypeOf, Object);
const Xl = kb;
var Db = "[object Object]", Rb = Function.prototype, Lb = Object.prototype, Mf = Rb.toString, Pb = Lb.hasOwnProperty, _b = Mf.call(Object);
function Bb(e) {
  if (!Et(e) || Un(e) != Db)
    return !1;
  var t = Xl(e);
  if (t === null)
    return !0;
  var n = Pb.call(t, "constructor") && t.constructor;
  return typeof n == "function" && n instanceof n && Mf.call(n) == _b;
}
function Nf(e, t, n) {
  var o = -1, r = e.length;
  t < 0 && (t = -t > r ? 0 : r + t), n = n > r ? r : n, n < 0 && (n += r), r = t > n ? 0 : n - t >>> 0, t >>>= 0;
  for (var s = Array(r); ++o < r; )
    s[o] = e[o + t];
  return s;
}
function Wb(e, t, n) {
  var o = e.length;
  return n = n === void 0 ? o : n, !t && n >= o ? e : Nf(e, t, n);
}
var Ub = "\\ud800-\\udfff", jb = "\\u0300-\\u036f", Fb = "\\ufe20-\\ufe2f", qb = "\\u20d0-\\u20ff", Hb = jb + Fb + qb, Zb = "\\ufe0e\\ufe0f", Vb = "\\u200d", zb = RegExp("[" + Vb + Ub + Hb + Zb + "]");
function $f(e) {
  return zb.test(e);
}
function Gb(e) {
  return e.split("");
}
var kf = "\\ud800-\\udfff", Yb = "\\u0300-\\u036f", Kb = "\\ufe20-\\ufe2f", Qb = "\\u20d0-\\u20ff", Jb = Yb + Kb + Qb, Xb = "\\ufe0e\\ufe0f", eI = "[" + kf + "]", al = "[" + Jb + "]", ll = "\\ud83c[\\udffb-\\udfff]", tI = "(?:" + al + "|" + ll + ")", Df = "[^" + kf + "]", Rf = "(?:\\ud83c[\\udde6-\\uddff]){2}", Lf = "[\\ud800-\\udbff][\\udc00-\\udfff]", nI = "\\u200d", Pf = tI + "?", _f = "[" + Xb + "]?", oI = "(?:" + nI + "(?:" + [Df, Rf, Lf].join("|") + ")" + _f + Pf + ")*", rI = _f + Pf + oI, sI = "(?:" + [Df + al + "?", al, Rf, Lf, eI].join("|") + ")", iI = RegExp(ll + "(?=" + ll + ")|" + sI + rI, "g");
function aI(e) {
  return e.match(iI) || [];
}
function lI(e) {
  return $f(e) ? aI(e) : Gb(e);
}
function cI(e) {
  return function(t) {
    t = _n(t);
    var n = $f(t) ? lI(t) : void 0, o = n ? n[0] : t.charAt(0), r = n ? Wb(n, 1).join("") : t.slice(1);
    return o[e]() + r;
  };
}
var Bf = cI("toUpperCase");
function uI(e) {
  return Bf(_n(e).toLowerCase());
}
function dI(e, t, n, o) {
  var r = -1, s = e == null ? 0 : e.length;
  for (o && s && (n = e[++r]); ++r < s; )
    n = t(n, e[r], r, e);
  return n;
}
function fI(e) {
  return function(t) {
    return e == null ? void 0 : e[t];
  };
}
var mI = {
  // Latin-1 Supplement block.
  À: "A",
  Á: "A",
  Â: "A",
  Ã: "A",
  Ä: "A",
  Å: "A",
  à: "a",
  á: "a",
  â: "a",
  ã: "a",
  ä: "a",
  å: "a",
  Ç: "C",
  ç: "c",
  Ð: "D",
  ð: "d",
  È: "E",
  É: "E",
  Ê: "E",
  Ë: "E",
  è: "e",
  é: "e",
  ê: "e",
  ë: "e",
  Ì: "I",
  Í: "I",
  Î: "I",
  Ï: "I",
  ì: "i",
  í: "i",
  î: "i",
  ï: "i",
  Ñ: "N",
  ñ: "n",
  Ò: "O",
  Ó: "O",
  Ô: "O",
  Õ: "O",
  Ö: "O",
  Ø: "O",
  ò: "o",
  ó: "o",
  ô: "o",
  õ: "o",
  ö: "o",
  ø: "o",
  Ù: "U",
  Ú: "U",
  Û: "U",
  Ü: "U",
  ù: "u",
  ú: "u",
  û: "u",
  ü: "u",
  Ý: "Y",
  ý: "y",
  ÿ: "y",
  Æ: "Ae",
  æ: "ae",
  Þ: "Th",
  þ: "th",
  ß: "ss",
  // Latin Extended-A block.
  Ā: "A",
  Ă: "A",
  Ą: "A",
  ā: "a",
  ă: "a",
  ą: "a",
  Ć: "C",
  Ĉ: "C",
  Ċ: "C",
  Č: "C",
  ć: "c",
  ĉ: "c",
  ċ: "c",
  č: "c",
  Ď: "D",
  Đ: "D",
  ď: "d",
  đ: "d",
  Ē: "E",
  Ĕ: "E",
  Ė: "E",
  Ę: "E",
  Ě: "E",
  ē: "e",
  ĕ: "e",
  ė: "e",
  ę: "e",
  ě: "e",
  Ĝ: "G",
  Ğ: "G",
  Ġ: "G",
  Ģ: "G",
  ĝ: "g",
  ğ: "g",
  ġ: "g",
  ģ: "g",
  Ĥ: "H",
  Ħ: "H",
  ĥ: "h",
  ħ: "h",
  Ĩ: "I",
  Ī: "I",
  Ĭ: "I",
  Į: "I",
  İ: "I",
  ĩ: "i",
  ī: "i",
  ĭ: "i",
  į: "i",
  ı: "i",
  Ĵ: "J",
  ĵ: "j",
  Ķ: "K",
  ķ: "k",
  ĸ: "k",
  Ĺ: "L",
  Ļ: "L",
  Ľ: "L",
  Ŀ: "L",
  Ł: "L",
  ĺ: "l",
  ļ: "l",
  ľ: "l",
  ŀ: "l",
  ł: "l",
  Ń: "N",
  Ņ: "N",
  Ň: "N",
  Ŋ: "N",
  ń: "n",
  ņ: "n",
  ň: "n",
  ŋ: "n",
  Ō: "O",
  Ŏ: "O",
  Ő: "O",
  ō: "o",
  ŏ: "o",
  ő: "o",
  Ŕ: "R",
  Ŗ: "R",
  Ř: "R",
  ŕ: "r",
  ŗ: "r",
  ř: "r",
  Ś: "S",
  Ŝ: "S",
  Ş: "S",
  Š: "S",
  ś: "s",
  ŝ: "s",
  ş: "s",
  š: "s",
  Ţ: "T",
  Ť: "T",
  Ŧ: "T",
  ţ: "t",
  ť: "t",
  ŧ: "t",
  Ũ: "U",
  Ū: "U",
  Ŭ: "U",
  Ů: "U",
  Ű: "U",
  Ų: "U",
  ũ: "u",
  ū: "u",
  ŭ: "u",
  ů: "u",
  ű: "u",
  ų: "u",
  Ŵ: "W",
  ŵ: "w",
  Ŷ: "Y",
  ŷ: "y",
  Ÿ: "Y",
  Ź: "Z",
  Ż: "Z",
  Ž: "Z",
  ź: "z",
  ż: "z",
  ž: "z",
  Ĳ: "IJ",
  ĳ: "ij",
  Œ: "Oe",
  œ: "oe",
  ŉ: "'n",
  ſ: "s"
}, hI = fI(mI);
const pI = hI;
var yI = /[\xc0-\xd6\xd8-\xf6\xf8-\xff\u0100-\u017f]/g, gI = "\\u0300-\\u036f", vI = "\\ufe20-\\ufe2f", bI = "\\u20d0-\\u20ff", II = gI + vI + bI, TI = "[" + II + "]", wI = RegExp(TI, "g");
function CI(e) {
  return e = _n(e), e && e.replace(yI, pI).replace(wI, "");
}
var OI = /[^\x00-\x2f\x3a-\x40\x5b-\x60\x7b-\x7f]+/g;
function EI(e) {
  return e.match(OI) || [];
}
var SI = /[a-z][A-Z]|[A-Z]{2}[a-z]|[0-9][a-zA-Z]|[a-zA-Z][0-9]|[^a-zA-Z0-9 ]/;
function xI(e) {
  return SI.test(e);
}
var Wf = "\\ud800-\\udfff", AI = "\\u0300-\\u036f", MI = "\\ufe20-\\ufe2f", NI = "\\u20d0-\\u20ff", $I = AI + MI + NI, Uf = "\\u2700-\\u27bf", jf = "a-z\\xdf-\\xf6\\xf8-\\xff", kI = "\\xac\\xb1\\xd7\\xf7", DI = "\\x00-\\x2f\\x3a-\\x40\\x5b-\\x60\\x7b-\\xbf", RI = "\\u2000-\\u206f", LI = " \\t\\x0b\\f\\xa0\\ufeff\\n\\r\\u2028\\u2029\\u1680\\u180e\\u2000\\u2001\\u2002\\u2003\\u2004\\u2005\\u2006\\u2007\\u2008\\u2009\\u200a\\u202f\\u205f\\u3000", Ff = "A-Z\\xc0-\\xd6\\xd8-\\xde", PI = "\\ufe0e\\ufe0f", qf = kI + DI + RI + LI, Hf = "['’]", Nu = "[" + qf + "]", _I = "[" + $I + "]", Zf = "\\d+", BI = "[" + Uf + "]", Vf = "[" + jf + "]", zf = "[^" + Wf + qf + Zf + Uf + jf + Ff + "]", WI = "\\ud83c[\\udffb-\\udfff]", UI = "(?:" + _I + "|" + WI + ")", jI = "[^" + Wf + "]", Gf = "(?:\\ud83c[\\udde6-\\uddff]){2}", Yf = "[\\ud800-\\udbff][\\udc00-\\udfff]", to = "[" + Ff + "]", FI = "\\u200d", $u = "(?:" + Vf + "|" + zf + ")", qI = "(?:" + to + "|" + zf + ")", ku = "(?:" + Hf + "(?:d|ll|m|re|s|t|ve))?", Du = "(?:" + Hf + "(?:D|LL|M|RE|S|T|VE))?", Kf = UI + "?", Qf = "[" + PI + "]?", HI = "(?:" + FI + "(?:" + [jI, Gf, Yf].join("|") + ")" + Qf + Kf + ")*", ZI = "\\d*(?:1st|2nd|3rd|(?![123])\\dth)(?=\\b|[A-Z_])", VI = "\\d*(?:1ST|2ND|3RD|(?![123])\\dTH)(?=\\b|[a-z_])", zI = Qf + Kf + HI, GI = "(?:" + [BI, Gf, Yf].join("|") + ")" + zI, YI = RegExp([
  to + "?" + Vf + "+" + ku + "(?=" + [Nu, to, "$"].join("|") + ")",
  qI + "+" + Du + "(?=" + [Nu, to + $u, "$"].join("|") + ")",
  to + "?" + $u + "+" + ku,
  to + "+" + Du,
  VI,
  ZI,
  Zf,
  GI
].join("|"), "g");
function KI(e) {
  return e.match(YI) || [];
}
function QI(e, t, n) {
  return e = _n(e), t = n ? void 0 : t, t === void 0 ? xI(e) ? KI(e) : EI(e) : e.match(t) || [];
}
var JI = "['’]", XI = RegExp(JI, "g");
function e0(e) {
  return function(t) {
    return dI(QI(CI(t).replace(XI, "")), e, "");
  };
}
var t0 = e0(function(e, t, n) {
  return t = t.toLowerCase(), e + (n ? uI(t) : t);
}), n0 = yt.isFinite, o0 = Math.min;
function r0(e) {
  var t = Math[e];
  return function(n, o) {
    if (n = gf(n), o = o == null ? 0 : o0(vg(o), 292), o && n0(n)) {
      var r = (_n(n) + "e").split("e"), s = t(r[0] + "e" + (+r[1] + o));
      return r = (_n(s) + "e").split("e"), +(r[0] + "e" + (+r[1] - o));
    }
    return t(n);
  };
}
function s0() {
  this.__data__ = new qt(), this.size = 0;
}
function i0(e) {
  var t = this.__data__, n = t.delete(e);
  return this.size = t.size, n;
}
function a0(e) {
  return this.__data__.get(e);
}
function l0(e) {
  return this.__data__.has(e);
}
var c0 = 200;
function u0(e, t) {
  var n = this.__data__;
  if (n instanceof qt) {
    var o = n.__data__;
    if (!Yr || o.length < c0 - 1)
      return o.push([e, t]), this.size = ++n.size, this;
    n = this.__data__ = new Ht(o);
  }
  return n.set(e, t), this.size = n.size, this;
}
function Ot(e) {
  var t = this.__data__ = new qt(e);
  this.size = t.size;
}
Ot.prototype.clear = s0;
Ot.prototype.delete = i0;
Ot.prototype.get = a0;
Ot.prototype.has = l0;
Ot.prototype.set = u0;
function d0(e, t) {
  return e && os(t, rs(t), e);
}
function f0(e, t) {
  return e && os(t, Gl(t), e);
}
var Jf = typeof exports == "object" && exports && !exports.nodeType && exports, Ru = Jf && typeof module == "object" && module && !module.nodeType && module, m0 = Ru && Ru.exports === Jf, Lu = m0 ? yt.Buffer : void 0, Pu = Lu ? Lu.allocUnsafe : void 0;
function h0(e, t) {
  if (t)
    return e.slice();
  var n = e.length, o = Pu ? Pu(n) : new e.constructor(n);
  return e.copy(o), o;
}
function p0(e, t) {
  for (var n = -1, o = e == null ? 0 : e.length, r = 0, s = []; ++n < o; ) {
    var i = e[n];
    t(i, n, e) && (s[r++] = i);
  }
  return s;
}
function Xf() {
  return [];
}
var y0 = Object.prototype, g0 = y0.propertyIsEnumerable, _u = Object.getOwnPropertySymbols, ec = _u ? function(e) {
  return e == null ? [] : (e = Object(e), p0(_u(e), function(t) {
    return g0.call(e, t);
  }));
} : Xf;
function v0(e, t) {
  return os(e, ec(e), t);
}
var b0 = Object.getOwnPropertySymbols, em = b0 ? function(e) {
  for (var t = []; e; )
    Ql(t, ec(e)), e = Xl(e);
  return t;
} : Xf;
function I0(e, t) {
  return os(e, em(e), t);
}
function tm(e, t, n) {
  var o = t(e);
  return Be(e) ? o : Ql(o, n(e));
}
function cl(e) {
  return tm(e, rs, ec);
}
function nm(e) {
  return tm(e, Gl, em);
}
var ul = Fn(yt, "DataView"), dl = Fn(yt, "Promise"), go = Fn(yt, "Set"), Bu = "[object Map]", T0 = "[object Object]", Wu = "[object Promise]", Uu = "[object Set]", ju = "[object WeakMap]", Fu = "[object DataView]", w0 = jn(ul), C0 = jn(Yr), O0 = jn(dl), E0 = jn(go), S0 = jn(il), vn = Un;
(ul && vn(new ul(new ArrayBuffer(1))) != Fu || Yr && vn(new Yr()) != Bu || dl && vn(dl.resolve()) != Wu || go && vn(new go()) != Uu || il && vn(new il()) != ju) && (vn = function(e) {
  var t = Un(e), n = t == T0 ? e.constructor : void 0, o = n ? jn(n) : "";
  if (o)
    switch (o) {
      case w0:
        return Fu;
      case C0:
        return Bu;
      case O0:
        return Wu;
      case E0:
        return Uu;
      case S0:
        return ju;
    }
  return t;
});
const No = vn;
var x0 = Object.prototype, A0 = x0.hasOwnProperty;
function M0(e) {
  var t = e.length, n = new e.constructor(t);
  return t && typeof e[0] == "string" && A0.call(e, "index") && (n.index = e.index, n.input = e.input), n;
}
var ki = yt.Uint8Array;
function tc(e) {
  var t = new e.constructor(e.byteLength);
  return new ki(t).set(new ki(e)), t;
}
function N0(e, t) {
  var n = t ? tc(e.buffer) : e.buffer;
  return new e.constructor(n, e.byteOffset, e.byteLength);
}
var $0 = /\w*$/;
function k0(e) {
  var t = new e.constructor(e.source, $0.exec(e));
  return t.lastIndex = e.lastIndex, t;
}
var qu = Qe ? Qe.prototype : void 0, Hu = qu ? qu.valueOf : void 0;
function D0(e) {
  return Hu ? Object(Hu.call(e)) : {};
}
function R0(e, t) {
  var n = t ? tc(e.buffer) : e.buffer;
  return new e.constructor(n, e.byteOffset, e.length);
}
var L0 = "[object Boolean]", P0 = "[object Date]", _0 = "[object Map]", B0 = "[object Number]", W0 = "[object RegExp]", U0 = "[object Set]", j0 = "[object String]", F0 = "[object Symbol]", q0 = "[object ArrayBuffer]", H0 = "[object DataView]", Z0 = "[object Float32Array]", V0 = "[object Float64Array]", z0 = "[object Int8Array]", G0 = "[object Int16Array]", Y0 = "[object Int32Array]", K0 = "[object Uint8Array]", Q0 = "[object Uint8ClampedArray]", J0 = "[object Uint16Array]", X0 = "[object Uint32Array]";
function eT(e, t, n) {
  var o = e.constructor;
  switch (t) {
    case q0:
      return tc(e);
    case L0:
    case P0:
      return new o(+e);
    case H0:
      return N0(e, n);
    case Z0:
    case V0:
    case z0:
    case G0:
    case Y0:
    case K0:
    case Q0:
    case J0:
    case X0:
      return R0(e, n);
    case _0:
      return new o();
    case B0:
    case j0:
      return new o(e);
    case W0:
      return k0(e);
    case U0:
      return new o();
    case F0:
      return D0(e);
  }
}
function tT(e) {
  return typeof e.constructor == "function" && !sa(e) ? Lg(Xl(e)) : {};
}
var nT = "[object Map]";
function oT(e) {
  return Et(e) && No(e) == nT;
}
var Zu = Mo && Mo.isMap, rT = Zu ? aa(Zu) : oT, sT = "[object Set]";
function iT(e) {
  return Et(e) && No(e) == sT;
}
var Vu = Mo && Mo.isSet, aT = Vu ? aa(Vu) : iT, lT = 1, cT = 2, uT = 4, om = "[object Arguments]", dT = "[object Array]", fT = "[object Boolean]", mT = "[object Date]", hT = "[object Error]", rm = "[object Function]", pT = "[object GeneratorFunction]", yT = "[object Map]", gT = "[object Number]", sm = "[object Object]", vT = "[object RegExp]", bT = "[object Set]", IT = "[object String]", TT = "[object Symbol]", wT = "[object WeakMap]", CT = "[object ArrayBuffer]", OT = "[object DataView]", ET = "[object Float32Array]", ST = "[object Float64Array]", xT = "[object Int8Array]", AT = "[object Int16Array]", MT = "[object Int32Array]", NT = "[object Uint8Array]", $T = "[object Uint8ClampedArray]", kT = "[object Uint16Array]", DT = "[object Uint32Array]", K = {};
K[om] = K[dT] = K[CT] = K[OT] = K[fT] = K[mT] = K[ET] = K[ST] = K[xT] = K[AT] = K[MT] = K[yT] = K[gT] = K[sm] = K[vT] = K[bT] = K[IT] = K[TT] = K[NT] = K[$T] = K[kT] = K[DT] = !0;
K[hT] = K[rm] = K[wT] = !1;
function Ur(e, t, n, o, r, s) {
  var i, a = t & lT, l = t & cT, c = t & uT;
  if (n && (i = r ? n(e, o, r, s) : n(e)), i !== void 0)
    return i;
  if (!dt(e))
    return e;
  var u = Be(e);
  if (u) {
    if (i = M0(e), !a)
      return Bg(e, i);
  } else {
    var d = No(e), f = d == rm || d == pT;
    if (zr(e))
      return h0(e, a);
    if (d == sm || d == om || f && !r) {
      if (i = l || f ? {} : tT(e), !a)
        return l ? I0(e, f0(i, e)) : v0(e, d0(i, e));
    } else {
      if (!K[d])
        return r ? e : {};
      i = eT(e, d, a);
    }
  }
  s || (s = new Ot());
  var h = s.get(e);
  if (h)
    return h;
  s.set(e, i), aT(e) ? e.forEach(function(v) {
    i.add(Ur(v, t, n, v, e, s));
  }) : rT(e) && e.forEach(function(v, T) {
    i.set(T, Ur(v, t, n, T, e, s));
  });
  var p = c ? l ? nm : cl : l ? Gl : rs, g = u ? void 0 : p(e);
  return Vg(g || e, function(v, T) {
    g && (T = v, v = e[T]), Zl(i, T, Ur(v, t, n, T, e, s));
  }), i;
}
var RT = 1, LT = 4;
function da(e) {
  return Ur(e, RT | LT);
}
var PT = "__lodash_hash_undefined__";
function _T(e) {
  return this.__data__.set(e, PT), this;
}
function BT(e) {
  return this.__data__.has(e);
}
function $o(e) {
  var t = -1, n = e == null ? 0 : e.length;
  for (this.__data__ = new Ht(); ++t < n; )
    this.add(e[t]);
}
$o.prototype.add = $o.prototype.push = _T;
$o.prototype.has = BT;
function WT(e, t) {
  for (var n = -1, o = e == null ? 0 : e.length; ++n < o; )
    if (t(e[n], n, e))
      return !0;
  return !1;
}
function nc(e, t) {
  return e.has(t);
}
var UT = 1, jT = 2;
function im(e, t, n, o, r, s) {
  var i = n & UT, a = e.length, l = t.length;
  if (a != l && !(i && l > a))
    return !1;
  var c = s.get(e), u = s.get(t);
  if (c && u)
    return c == t && u == e;
  var d = -1, f = !0, h = n & jT ? new $o() : void 0;
  for (s.set(e, t), s.set(t, e); ++d < a; ) {
    var p = e[d], g = t[d];
    if (o)
      var v = i ? o(g, p, d, t, e, s) : o(p, g, d, e, t, s);
    if (v !== void 0) {
      if (v)
        continue;
      f = !1;
      break;
    }
    if (h) {
      if (!WT(t, function(T, O) {
        if (!nc(h, O) && (p === T || r(p, T, n, o, s)))
          return h.push(O);
      })) {
        f = !1;
        break;
      }
    } else if (!(p === g || r(p, g, n, o, s))) {
      f = !1;
      break;
    }
  }
  return s.delete(e), s.delete(t), f;
}
function FT(e) {
  var t = -1, n = Array(e.size);
  return e.forEach(function(o, r) {
    n[++t] = [r, o];
  }), n;
}
function oc(e) {
  var t = -1, n = Array(e.size);
  return e.forEach(function(o) {
    n[++t] = o;
  }), n;
}
var qT = 1, HT = 2, ZT = "[object Boolean]", VT = "[object Date]", zT = "[object Error]", GT = "[object Map]", YT = "[object Number]", KT = "[object RegExp]", QT = "[object Set]", JT = "[object String]", XT = "[object Symbol]", ew = "[object ArrayBuffer]", tw = "[object DataView]", zu = Qe ? Qe.prototype : void 0, Wa = zu ? zu.valueOf : void 0;
function nw(e, t, n, o, r, s, i) {
  switch (n) {
    case tw:
      if (e.byteLength != t.byteLength || e.byteOffset != t.byteOffset)
        return !1;
      e = e.buffer, t = t.buffer;
    case ew:
      return !(e.byteLength != t.byteLength || !s(new ki(e), new ki(t)));
    case ZT:
    case VT:
    case YT:
      return ra(+e, +t);
    case zT:
      return e.name == t.name && e.message == t.message;
    case KT:
    case JT:
      return e == t + "";
    case GT:
      var a = FT;
    case QT:
      var l = o & qT;
      if (a || (a = oc), e.size != t.size && !l)
        return !1;
      var c = i.get(e);
      if (c)
        return c == t;
      o |= HT, i.set(e, t);
      var u = im(a(e), a(t), o, r, s, i);
      return i.delete(e), u;
    case XT:
      if (Wa)
        return Wa.call(e) == Wa.call(t);
  }
  return !1;
}
var ow = 1, rw = Object.prototype, sw = rw.hasOwnProperty;
function iw(e, t, n, o, r, s) {
  var i = n & ow, a = cl(e), l = a.length, c = cl(t), u = c.length;
  if (l != u && !i)
    return !1;
  for (var d = l; d--; ) {
    var f = a[d];
    if (!(i ? f in t : sw.call(t, f)))
      return !1;
  }
  var h = s.get(e), p = s.get(t);
  if (h && p)
    return h == t && p == e;
  var g = !0;
  s.set(e, t), s.set(t, e);
  for (var v = i; ++d < l; ) {
    f = a[d];
    var T = e[f], O = t[f];
    if (o)
      var k = i ? o(O, T, f, t, e, s) : o(T, O, f, e, t, s);
    if (!(k === void 0 ? T === O || r(T, O, n, o, s) : k)) {
      g = !1;
      break;
    }
    v || (v = f == "constructor");
  }
  if (g && !v) {
    var R = e.constructor, ae = t.constructor;
    R != ae && "constructor" in e && "constructor" in t && !(typeof R == "function" && R instanceof R && typeof ae == "function" && ae instanceof ae) && (g = !1);
  }
  return s.delete(e), s.delete(t), g;
}
var aw = 1, Gu = "[object Arguments]", Yu = "[object Array]", $s = "[object Object]", lw = Object.prototype, Ku = lw.hasOwnProperty;
function cw(e, t, n, o, r, s) {
  var i = Be(e), a = Be(t), l = i ? Yu : No(e), c = a ? Yu : No(t);
  l = l == Gu ? $s : l, c = c == Gu ? $s : c;
  var u = l == $s, d = c == $s, f = l == c;
  if (f && zr(e)) {
    if (!zr(t))
      return !1;
    i = !0, u = !1;
  }
  if (f && !u)
    return s || (s = new Ot()), i || zl(e) ? im(e, t, n, o, r, s) : nw(e, t, l, n, o, r, s);
  if (!(n & aw)) {
    var h = u && Ku.call(e, "__wrapped__"), p = d && Ku.call(t, "__wrapped__");
    if (h || p) {
      var g = h ? e.value() : e, v = p ? t.value() : t;
      return s || (s = new Ot()), r(g, v, n, o, s);
    }
  }
  return f ? (s || (s = new Ot()), iw(e, t, n, o, r, s)) : !1;
}
function rc(e, t, n, o, r) {
  return e === t ? !0 : e == null || t == null || !Et(e) && !Et(t) ? e !== e && t !== t : cw(e, t, n, o, rc, r);
}
var uw = 1, dw = 2;
function fw(e, t, n, o) {
  var r = n.length, s = r, i = !o;
  if (e == null)
    return !s;
  for (e = Object(e); r--; ) {
    var a = n[r];
    if (i && a[2] ? a[1] !== e[a[0]] : !(a[0] in e))
      return !1;
  }
  for (; ++r < s; ) {
    a = n[r];
    var l = a[0], c = e[l], u = a[1];
    if (i && a[2]) {
      if (c === void 0 && !(l in e))
        return !1;
    } else {
      var d = new Ot();
      if (o)
        var f = o(c, u, l, e, t, d);
      if (!(f === void 0 ? rc(u, c, uw | dw, o, d) : f))
        return !1;
    }
  }
  return !0;
}
function am(e) {
  return e === e && !dt(e);
}
function mw(e) {
  for (var t = rs(e), n = t.length; n--; ) {
    var o = t[n], r = e[o];
    t[n] = [o, r, am(r)];
  }
  return t;
}
function lm(e, t) {
  return function(n) {
    return n == null ? !1 : n[e] === t && (t !== void 0 || e in Object(n));
  };
}
function hw(e) {
  var t = mw(e);
  return t.length == 1 && t[0][2] ? lm(t[0][0], t[0][1]) : function(n) {
    return n === e || fw(n, e, t);
  };
}
function pw(e, t) {
  return e != null && t in Object(e);
}
function yw(e, t, n) {
  t = Uo(t, e);
  for (var o = -1, r = t.length, s = !1; ++o < r; ) {
    var i = jo(t[o]);
    if (!(s = e != null && n(e, i)))
      break;
    e = e[i];
  }
  return s || ++o != r ? s : (r = e == null ? 0 : e.length, !!r && Vl(r) && oa(i, r) && (Be(e) || ia(e)));
}
function cm(e, t) {
  return e != null && yw(e, t, pw);
}
var gw = 1, vw = 2;
function bw(e, t) {
  return Yl(e) && am(t) ? lm(jo(e), t) : function(n) {
    var o = Mb(n, e);
    return o === void 0 && o === t ? cm(n, e) : rc(t, o, gw | vw);
  };
}
function Iw(e) {
  return function(t) {
    return t == null ? void 0 : t[e];
  };
}
function Tw(e) {
  return function(t) {
    return ua(t, e);
  };
}
function ww(e) {
  return Yl(e) ? Iw(jo(e)) : Tw(e);
}
function Cw(e) {
  return typeof e == "function" ? e : e == null ? ql : typeof e == "object" ? Be(e) ? bw(e[0], e[1]) : hw(e) : ww(e);
}
function Ow(e, t, n, o) {
  for (var r = -1, s = e == null ? 0 : e.length; ++r < s; ) {
    var i = e[r];
    t(o, i, n(i), e);
  }
  return o;
}
function Ew(e) {
  return function(t, n, o) {
    for (var r = -1, s = Object(t), i = o(t), a = i.length; a--; ) {
      var l = i[e ? a : ++r];
      if (n(s[l], l, s) === !1)
        break;
    }
    return t;
  };
}
var Sw = Ew();
const xw = Sw;
function Aw(e, t) {
  return e && xw(e, t, rs);
}
function Mw(e, t) {
  return function(n, o) {
    if (n == null)
      return n;
    if (!Wo(n))
      return e(n, o);
    for (var r = n.length, s = t ? r : -1, i = Object(n); (t ? s-- : ++s < r) && o(i[s], s, i) !== !1; )
      ;
    return n;
  };
}
var Nw = Mw(Aw);
function $w(e, t, n, o) {
  return Nw(e, function(r, s, i) {
    t(o, r, n(r), i);
  }), o;
}
function kw(e, t) {
  return function(n, o) {
    var r = Be(n) ? Ow : $w, s = t ? t() : {};
    return r(n, e, Cw(o), s);
  };
}
function Qu(e) {
  return Et(e) && Wo(e);
}
function um(e, t, n) {
  for (var o = -1, r = e == null ? 0 : e.length; ++o < r; )
    if (n(t, e[o]))
      return !0;
  return !1;
}
var Dw = 200;
function Rw(e, t, n, o) {
  var r = -1, s = If, i = !0, a = e.length, l = [], c = t.length;
  if (!a)
    return l;
  n && (t = Fl(t, aa(n))), o ? (s = um, i = !1) : t.length >= Dw && (s = nc, i = !1, t = new $o(t));
  e:
    for (; ++r < a; ) {
      var u = e[r], d = n == null ? u : n(u);
      if (u = o || u !== 0 ? u : 0, i && d === d) {
        for (var f = c; f--; )
          if (t[f] === d)
            continue e;
        l.push(u);
      } else
        s(t, d, o) || l.push(u);
    }
  return l;
}
var sc = tv(function(e, t) {
  return Qu(e) ? Rw(e, Jl(t, 1, Qu, !0)) : [];
});
function Fo(e) {
  var t = e == null ? 0 : e.length;
  return t ? e[t - 1] : void 0;
}
function ss(e) {
  return e && e.length ? e[0] : void 0;
}
var Lw = Object.prototype, Pw = Lw.hasOwnProperty, is = kw(function(e, t, n) {
  Pw.call(e, n) ? e[n].push(t) : Hl(e, n, [t]);
});
function _w(e, t) {
  return t.length < 2 ? e : ua(e, Nf(t, 0, -1));
}
var Bw = "[object Map]", Ww = "[object Set]", Uw = Object.prototype, jw = Uw.hasOwnProperty;
function dm(e) {
  if (e == null)
    return !0;
  if (Wo(e) && (Be(e) || typeof e == "string" || typeof e.splice == "function" || zr(e) || zl(e) || ia(e)))
    return !e.length;
  var t = No(e);
  if (t == Bw || t == Ww)
    return !e.size;
  if (sa(e))
    return !xf(e).length;
  for (var n in e)
    if (jw.call(e, n))
      return !1;
  return !0;
}
function Fw(e, t) {
  return t = Uo(t, e), e = _w(e, t), e == null || delete e[jo(Fo(t))];
}
function qw(e) {
  return Bb(e) ? void 0 : e;
}
var Hw = 1, Zw = 2, Vw = 4, zw = Af(function(e, t) {
  var n = {};
  if (e == null)
    return n;
  var o = !1;
  t = Fl(t, function(s) {
    return s = Uo(s, e), o || (o = s.length > 1), s;
  }), os(e, nm(e), n), o && (n = Ur(n, Hw | Zw | Vw, qw));
  for (var r = t.length; r--; )
    Fw(n, t[r]);
  return n;
});
function Gw(e, t, n, o) {
  if (!dt(e))
    return e;
  t = Uo(t, e);
  for (var r = -1, s = t.length, i = s - 1, a = e; a != null && ++r < s; ) {
    var l = jo(t[r]), c = n;
    if (l === "__proto__" || l === "constructor" || l === "prototype")
      return e;
    if (r != i) {
      var u = a[l];
      c = o ? o(u, l, a) : void 0, c === void 0 && (c = dt(u) ? u : oa(t[r + 1]) ? [] : {});
    }
    Zl(a, l, c), a = a[l];
  }
  return e;
}
function Yw(e, t, n) {
  for (var o = -1, r = t.length, s = {}; ++o < r; ) {
    var i = t[o], a = ua(e, i);
    n(a, i) && Gw(s, Uo(i, e), a);
  }
  return s;
}
function Kw(e, t) {
  return Yw(e, t, function(n, o) {
    return cm(e, o);
  });
}
var Kr = Af(function(e, t) {
  return e == null ? {} : Kw(e, t);
}), Qw = Math.ceil, Jw = Math.max;
function Xw(e, t, n, o) {
  for (var r = -1, s = Jw(Qw((t - e) / (n || 1)), 0), i = Array(s); s--; )
    i[o ? s : ++r] = e, e += n;
  return i;
}
function e1(e) {
  return function(t, n, o) {
    return o && typeof o != "number" && ov(t, n, o) && (n = o = void 0), t = Zs(t), n === void 0 ? (n = t, t = 0) : n = Zs(n), o = o === void 0 ? t < n ? 1 : -1 : Zs(o), Xw(t, n, o, e);
  };
}
var Ju = e1(), ft = r0("round"), t1 = 1 / 0, n1 = go && 1 / oc(new go([, -0]))[1] == t1 ? function(e) {
  return new go(e);
} : _g, o1 = 200;
function r1(e, t, n) {
  var o = -1, r = If, s = e.length, i = !0, a = [], l = a;
  if (n)
    i = !1, r = um;
  else if (s >= o1) {
    var c = t ? null : n1(e);
    if (c)
      return oc(c);
    i = !1, r = nc, l = new $o();
  } else
    l = t ? [] : a;
  e:
    for (; ++o < s; ) {
      var u = e[o], d = t ? t(u) : u;
      if (u = n || u !== 0 ? u : 0, i && d === d) {
        for (var f = l.length; f--; )
          if (l[f] === d)
            continue e;
        t && l.push(d), a.push(u);
      } else
        r(l, d, n) || (l !== a && l.push(d), a.push(u));
    }
  return a;
}
function Di(e) {
  return e && e.length ? r1(e) : [];
}
function $(e) {
  const t = new Set(e);
  return (n) => t.has(n);
}
function s1(e, ...t) {
  try {
    return e == null ? void 0 : e(...t);
  } catch (n) {
    console.error(n);
  }
}
const i1 = (e) => {
  Promise.resolve().then(e);
}, zR = queueMicrotask ?? i1, qo = (e) => (t) => typeof t === e, a1 = qo("object"), He = qo("boolean"), GR = qo("function"), ne = qo("number"), q = qo("string"), l1 = qo("undefined"), fm = (e) => e === null, pe = (e) => fm(e) || l1(e), { isArray: we } = Array, mt = (e) => a1(e) && !fm(e) && !we(e), c1 = (e) => Number.isInteger(e), u1 = "useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict";
let d1 = (e, t, n) => {
  let o = (2 << Math.log(e.length - 1) / Math.LN2) - 1, r = -~(1.6 * o * t / e.length);
  return (s = t) => {
    let i = "";
    for (; ; ) {
      let a = n(r), l = r;
      for (; l--; )
        if (i += e[a[l] & o] || "", i.length === s)
          return i;
    }
  };
};
const fe = d1(u1, 21, (e) => {
  let t = globalThis.crypto;
  return t === void 0 && (t = require("node:crypto").webcrypto), t.getRandomValues(new Uint8Array(e));
}), Je = (e, t) => (n) => Math.min(Math.max(n, e), t);
/*!
 * @dineug/erd-editor-schema
 * @version 0.1.0 | Sat Nov 09 2024
 * @author SeungHwan-Lee <dineug2@gmail.com>
 * @license MIT
 */
function w(e, t, n) {
  return (o) => {
    if (!n)
      return;
    const r = n[o];
    e(r) && (t[o] = r);
  };
}
function je(e) {
  return (t) => q(t) && e.includes(t);
}
function Tt(e) {
  return (t) => ne(t) && e.includes(t);
}
function Le(e, t, n) {
  return Reflect.get(e, t) ?? n;
}
function Ho() {
  const e = Date.now();
  return {
    updateAt: e,
    createAt: e
  };
}
function Zo(e, t) {
  const n = w(ne, e, t);
  n("updateAt"), n("createAt");
}
const f1 = () => ({
  tableIds: [],
  relationshipIds: [],
  indexIds: [],
  memoIds: []
});
function m1(e) {
  const t = f1();
  if (!mt(e) || pe(e))
    return t;
  const n = w(we, t, e);
  return n("tableIds"), n("relationshipIds"), n("indexIds"), n("memoIds"), t;
}
const mm = () => ({
  id: "",
  name: "",
  tableId: "",
  indexColumnIds: [],
  seqIndexColumnIds: [],
  unique: !1,
  meta: Ho()
});
function h1(e) {
  const t = {};
  if (!mt(e) || pe(e))
    return t;
  for (const n of Object.values(e)) {
    if (!n)
      continue;
    const o = mm(), r = w(q, o, n), s = w(He, o, n), i = w(we, o, n);
    r("id"), r("name"), r("tableId"), s("unique"), i("indexColumnIds"), i("seqIndexColumnIds"), Zo(o.meta, n.meta), o.id && (t[o.id] = o);
  }
  return t;
}
const ic = {
  ASC: (
    /*  */
    1
  ),
  DESC: (
    /* */
    2
  )
}, hm = Object.values(ic), pm = () => ({
  id: "",
  indexId: "",
  columnId: "",
  orderType: ic.ASC,
  meta: Ho()
});
function p1(e) {
  const t = {};
  if (!mt(e) || pe(e))
    return t;
  for (const n of Object.values(e)) {
    if (!n)
      continue;
    const o = pm(), r = w(q, o, n);
    r("id"), r("indexId"), r("columnId"), w(Tt(hm), o, n)("orderType"), Zo(o.meta, n.meta), o.id && (t[o.id] = o);
  }
  return t;
}
const y1 = () => ({});
function g1(e) {
  const t = y1();
  if (!mt(e) || pe(e))
    return t;
  for (const [n, o] of Object.entries(e)) {
    if (!we(o) || o.length !== 4)
      continue;
    const [r, s, i, a] = o;
    if (q(r) && ne(s) && ne(i) && mt(a)) {
      const l = {}, c = w(ne, l, a);
      Object.keys(a).forEach(c), t[n] = [r, s, i, l];
    }
  }
  return t;
}
const ym = () => ({
  id: "",
  value: "",
  ui: {
    x: 200,
    y: 100,
    zIndex: 2,
    width: 116,
    height: 100,
    color: ""
  },
  meta: Ho()
});
function v1(e) {
  const t = {};
  if (!mt(e) || pe(e))
    return t;
  for (const n of Object.values(e)) {
    if (!n)
      continue;
    const o = ym(), r = w(q, o, n), s = w(ne, o.ui, n.ui), i = w(q, o.ui, n.ui);
    r("id"), r("value"), i("color"), s("x"), s("y"), s("zIndex"), s("width"), s("height"), Zo(o.meta, n.meta), o.id && (t[o.id] = o);
  }
  return t;
}
const ac = {
  // ZeroOneN: /* */ 0b0000000000000000000000000000001,
  ZeroOne: (
    /*  */
    2
  ),
  ZeroN: (
    /*    */
    4
  ),
  OneOnly: (
    /*  */
    8
  ),
  OneN: (
    /*     */
    16
  )
  // One: /*      */ 0b0000000000000000000000000100000,
  // N: /*        */ 0b0000000000000000000000001000000,
}, gm = Object.values(ac), lc = {
  ring: (
    /* */
    1
  ),
  dash: (
    /* */
    2
  )
}, vm = Object.values(lc), Ri = {
  left: (
    /*   */
    1
  ),
  right: (
    /*  */
    2
  ),
  top: (
    /*    */
    4
  ),
  bottom: (
    /* */
    8
  )
}, fl = Object.values(Ri), bm = () => ({
  id: "",
  identification: !1,
  relationshipType: ac.ZeroN,
  startRelationshipType: lc.dash,
  start: {
    tableId: "",
    columnIds: [],
    x: 0,
    y: 0,
    direction: Ri.bottom
  },
  end: {
    tableId: "",
    columnIds: [],
    x: 0,
    y: 0,
    direction: Ri.bottom
  },
  meta: Ho()
});
function b1(e) {
  const t = {};
  if (!mt(e) || pe(e))
    return t;
  for (const n of Object.values(e)) {
    if (!n)
      continue;
    const o = bm(), r = w(q, o, n), s = w(He, o, n), i = w(ne, o.start, n.start), a = w(q, o.start, n.start), l = w(ne, o.end, n.end), c = w(q, o.end, n.end);
    r("id"), s("identification"), w(Tt(gm), o, n)("relationshipType"), w(Tt(vm), o, n)("startRelationshipType"), a("tableId"), i("x"), i("y"), w(Tt(fl), o.start, n.start)("direction"), w(we, o.start, n.start)("columnIds"), c("tableId"), l("x"), l("y"), w(Tt(fl), o.end, n.end)("direction"), w(we, o.end, n.end)("columnIds"), Zo(o.meta, n.meta), o.id && (t[o.id] = o);
  }
  return t;
}
const cc = {
  ERD: "ERD",
  visualization: "@dineug/erd-editor/builtin-visualization",
  schemaSQL: "@dineug/erd-editor/builtin-schema-sql",
  generatorCode: "@dineug/erd-editor/builtin-generator-code",
  settings: "settings"
}, I1 = Object.values(cc), Kt = {
  tableComment: (
    /*        */
    1
  ),
  columnComment: (
    /*       */
    2
  ),
  columnDataType: (
    /*      */
    4
  ),
  columnDefault: (
    /*       */
    8
  ),
  columnAutoIncrement: (
    /* */
    16
  ),
  columnPrimaryKey: (
    /*    */
    32
  ),
  columnUnique: (
    /*        */
    64
  ),
  columnNotNull: (
    /*       */
    128
  ),
  relationship: (
    /*        */
    256
  )
}, $t = {
  columnName: (
    /*          */
    1
  ),
  columnDataType: (
    /*      */
    2
  ),
  columnNotNull: (
    /*       */
    4
  ),
  columnUnique: (
    /*        */
    8
  ),
  columnAutoIncrement: (
    /* */
    16
  ),
  columnDefault: (
    /*       */
    32
  ),
  columnComment: (
    /*       */
    64
  )
}, ml = Object.values($t), uc = {
  MariaDB: (
    /*    */
    1
  ),
  MSSQL: (
    /*      */
    2
  ),
  MySQL: (
    /*      */
    4
  ),
  Oracle: (
    /*     */
    8
  ),
  PostgreSQL: (
    /* */
    16
  ),
  SQLite: (
    /*     */
    32
  )
}, Im = Object.values(uc), dc = {
  GraphQL: (
    /*    */
    1
  ),
  csharp: (
    /*     */
    2
  ),
  Java: (
    /*       */
    4
  ),
  Kotlin: (
    /*     */
    8
  ),
  TypeScript: (
    /* */
    16
  ),
  JPA: (
    /*        */
    32
  ),
  Scala: (
    /*      */
    64
  )
}, Tm = Object.values(dc), Li = {
  none: (
    /*       */
    1
  ),
  camelCase: (
    /*  */
    2
  ),
  pascalCase: (
    /* */
    4
  ),
  snakeCase: (
    /*  */
    8
  )
}, hl = Object.values(Li), fc = {
  none: (
    /*        */
    1
  ),
  doubleQuote: (
    /* */
    2
  ),
  singleQuote: (
    /* */
    4
  ),
  backtick: (
    /*    */
    8
  )
}, wm = Object.values(fc), T1 = {
  scroll: (
    /*    */
    1
  ),
  zoomLevel: (
    /* */
    2
  )
}, Cm = 0.1, Om = 1, Em = 2e3, Sm = 2e4, w1 = Kt.tableComment | Kt.columnComment | Kt.columnDataType | Kt.columnDefault | Kt.columnPrimaryKey | Kt.columnNotNull | Kt.relationship, C1 = () => ({
  width: 2e3,
  height: 2e3,
  scrollTop: 0,
  scrollLeft: 0,
  zoomLevel: 1,
  show: w1,
  database: uc.MySQL,
  databaseName: "",
  canvasType: cc.ERD,
  language: dc.GraphQL,
  tableNameCase: Li.pascalCase,
  columnNameCase: Li.camelCase,
  bracketType: fc.none,
  relationshipDataTypeSync: !0,
  relationshipOptimization: !1,
  columnOrder: [
    $t.columnName,
    $t.columnDataType,
    $t.columnNotNull,
    $t.columnUnique,
    $t.columnAutoIncrement,
    $t.columnDefault,
    $t.columnComment
  ],
  maxWidthComment: -1,
  ignoreSaveSettings: 0
}), Xu = Je(Em, Sm), O1 = Je(Cm, Om), E1 = Je(60, 200);
function S1(e) {
  const t = C1();
  if (!mt(e) || pe(e))
    return t;
  const n = w(ne, t, e), o = w(q, t, e), r = w(He, t, e);
  return ne(e.width) && (t.width = Xu(e.width)), ne(e.height) && (t.height = Xu(e.height)), ne(e.zoomLevel) && (t.zoomLevel = O1(e.zoomLevel)), ne(e.maxWidthComment) && e.maxWidthComment !== -1 && (t.maxWidthComment = E1(e.maxWidthComment)), n("scrollTop"), n("scrollLeft"), n("show"), n("ignoreSaveSettings"), o("databaseName"), o("canvasType"), r("relationshipDataTypeSync"), r("relationshipOptimization"), w(Tt(Im), t, e)("database"), w(Tt(Tm), t, e)("language"), w(Tt(hl), t, e)("tableNameCase"), w(Tt(hl), t, e)("columnNameCase"), w(Tt(wm), t, e)("bracketType"), we(e.columnOrder) && ml.length === e.columnOrder.length && sc(ml, e.columnOrder).length === 0 && (t.columnOrder = e.columnOrder), t;
}
const xm = () => ({
  id: "",
  name: "",
  comment: "",
  columnIds: [],
  seqColumnIds: [],
  ui: {
    x: 200,
    y: 100,
    zIndex: 2,
    widthName: 60,
    widthComment: 60,
    color: ""
  },
  meta: Ho()
});
function x1(e) {
  const t = {};
  if (!mt(e) || pe(e))
    return t;
  for (const n of Object.values(e)) {
    if (!n)
      continue;
    const o = xm(), r = w(q, o, n), s = w(we, o, n), i = w(ne, o.ui, n.ui), a = w(q, o.ui, n.ui);
    r("id"), r("name"), r("comment"), s("columnIds"), s("seqColumnIds"), a("color"), i("x"), i("y"), i("zIndex"), i("widthName"), i("widthComment"), Zo(o.meta, n.meta), o.id && (t[o.id] = o);
  }
  return t;
}
const Am = () => ({
  id: "",
  tableId: "",
  name: "",
  comment: "",
  dataType: "",
  default: "",
  options: 0,
  ui: {
    keys: 0,
    widthName: 60,
    widthComment: 60,
    widthDataType: 60,
    widthDefault: 60
  },
  meta: Ho()
});
function A1(e) {
  const t = {};
  if (!mt(e) || pe(e))
    return t;
  for (const n of Object.values(e)) {
    if (!n)
      continue;
    const o = Am(), r = w(q, o, n), s = w(ne, o, n), i = w(ne, o.ui, n.ui);
    r("id"), r("tableId"), r("name"), r("comment"), r("dataType"), r("default"), s("options"), i("keys"), i("widthName"), i("widthComment"), i("widthDataType"), i("widthDefault"), Zo(o.meta, n.meta), o.id && (t[o.id] = o);
  }
  return t;
}
function qn(e) {
  var t, n, o, r, s, i;
  const a = e, l = S1(a.settings), c = m1(a.doc), u = g1(a.lww), d = x1((t = a.collections) == null ? void 0 : t.tableEntities), f = A1((n = a.collections) == null ? void 0 : n.tableColumnEntities), h = b1((o = a.collections) == null ? void 0 : o.relationshipEntities), p = h1((r = a.collections) == null ? void 0 : r.indexEntities), g = p1((s = a.collections) == null ? void 0 : s.indexColumnEntities), v = v1((i = a.collections) == null ? void 0 : i.memoEntities);
  return {
    $schema: "https://raw.githubusercontent.com/dineug/erd-editor/main/json-schema/schema.json",
    version: "3.0.0",
    settings: l,
    doc: c,
    collections: {
      tableEntities: d,
      tableColumnEntities: f,
      relationshipEntities: h,
      indexEntities: p,
      indexColumnEntities: g,
      memoEntities: v
    },
    lww: u
  };
}
const M1 = {
  autoIncrement: (
    /* */
    1
  ),
  primaryKey: (
    /*    */
    2
  ),
  unique: (
    /*        */
    4
  ),
  notNull: (
    /*       */
    8
  )
}, N1 = {
  primaryKey: (
    /* */
    1
  ),
  foreignKey: (
    /* */
    2
  )
}, E = {
  CanvasType: cc,
  CanvasTypeList: I1,
  Show: Kt,
  ColumnType: $t,
  ColumnTypeList: ml,
  Database: uc,
  DatabaseList: Im,
  Language: dc,
  LanguageList: Tm,
  NameCase: Li,
  NameCaseList: hl,
  BracketType: fc,
  BracketTypeList: wm,
  RelationshipType: ac,
  RelationshipTypeList: gm,
  StartRelationshipType: lc,
  StartRelationshipTypeList: vm,
  Direction: Ri,
  DirectionList: fl,
  ColumnOption: M1,
  ColumnUIKey: N1,
  OrderType: ic,
  OrderTypeList: hm,
  SaveSettingType: T1,
  CANVAS_SIZE_MAX: Sm,
  CANVAS_SIZE_MIN: Em,
  CANVAS_ZOOM_MAX: Om,
  CANVAS_ZOOM_MIN: Cm
};
function $1(e) {
  const t = qn({});
  return k1(t.settings, e.canvas), D1(t, e.table), R1(t, e.memo), L1(t, e.relationship), t;
}
function k1(e, t) {
  e.width = t.width, e.height = t.height, e.scrollTop = t.scrollTop, e.scrollLeft = t.scrollLeft, e.zoomLevel = t.zoomLevel, e.databaseName = t.databaseName, e.canvasType = E.CanvasType.ERD, e.show = Object.keys(t.show).reduce((o, r) => {
    if (Le(t.show, r, !1)) {
      const s = Le(E.Show, r, 0);
      return o | s;
    }
    return o;
  }, 0), e.database = Reflect.get(E.Database, t.database) ?? E.Database.MySQL, e.language = t.language === "C#" ? E.Language.csharp : Le(E.Language, t.language, E.Language.GraphQL), e.tableNameCase = Le(E.NameCase, t.tableCase, E.NameCase.pascalCase), e.columnNameCase = Le(E.NameCase, t.columnCase, E.NameCase.camelCase), e.bracketType = Le(E.BracketType, t.bracketType, E.BracketType.none), e.relationshipDataTypeSync = t.setting.relationshipDataTypeSync, e.relationshipOptimization = t.setting.relationshipOptimization;
  const n = t.setting.columnOrder.map((o) => E.ColumnType[o]);
  sc(E.ColumnTypeList, n).length === 0 && (e.columnOrder = n);
}
function D1(e, t) {
  const n = t.tables.filter(({ id: r }) => !!r), o = t.indexes.filter(({ id: r }) => !!r);
  e.doc.tableIds = n.map(({ id: r }) => r), e.doc.indexIds = o.map(({ id: r }) => r);
  for (const r of n) {
    const s = xm();
    s.id = r.id, s.name = r.name, s.comment = r.comment, s.columnIds = r.columns.map(({ id: i }) => i), s.seqColumnIds = [...s.columnIds], s.ui.y = r.ui.top, s.ui.x = r.ui.left, s.ui.zIndex = r.ui.zIndex, s.ui.widthName = r.ui.widthName, s.ui.widthComment = r.ui.widthComment, s.ui.color = r.ui.color ?? "";
    for (const i of r.columns) {
      const a = Am();
      a.id = i.id, a.tableId = r.id, a.name = i.name, a.comment = i.comment, a.dataType = i.dataType, a.default = i.default, a.options = Object.keys(i.option).reduce((l, c) => {
        if (Le(i.option, c, !1)) {
          const u = Le(E.ColumnOption, c, 0);
          return l | u;
        }
        return l;
      }, 0), a.ui.widthName = i.ui.widthName, a.ui.widthComment = i.ui.widthComment, a.ui.widthDataType = i.ui.widthDataType, a.ui.widthDefault = i.ui.widthDefault, i.ui.pfk ? a.ui.keys = E.ColumnUIKey.primaryKey | E.ColumnUIKey.foreignKey : i.ui.pk ? a.ui.keys = E.ColumnUIKey.primaryKey : i.ui.fk && (a.ui.keys = E.ColumnUIKey.foreignKey), e.collections.tableColumnEntities[a.id] = a;
    }
    e.collections.tableEntities[s.id] = s;
  }
  for (const r of o) {
    const s = mm();
    s.id = r.id, s.name = r.name, s.tableId = r.tableId, s.unique = r.unique;
    for (const i of r.columns) {
      const a = fe(), l = pm();
      s.indexColumnIds.push(a), s.seqIndexColumnIds.push(a), l.id = a, l.indexId = r.id, l.columnId = i.id, l.orderType = Le(E.OrderType, i.orderType, E.OrderType.ASC), e.collections.indexColumnEntities[l.id] = l;
    }
    e.collections.indexEntities[s.id] = s;
  }
}
function R1(e, t) {
  const n = t.memos.filter(({ id: o }) => !!o);
  e.doc.memoIds = n.map(({ id: o }) => o);
  for (const o of n) {
    const r = ym();
    r.id = o.id, r.value = o.value, r.ui.y = o.ui.top, r.ui.x = o.ui.left, r.ui.width = o.ui.width, r.ui.height = o.ui.height, r.ui.zIndex = o.ui.zIndex, r.ui.color = o.ui.color ?? "", e.collections.memoEntities[r.id] = r;
  }
}
function L1(e, t) {
  const n = t.relationships.filter(({ id: o }) => !!o);
  e.doc.relationshipIds = n.map(({ id: o }) => o);
  for (const o of n) {
    const r = bm();
    r.id = o.id, r.identification = o.identification, r.relationshipType = Le(E.RelationshipType, o.relationshipType, E.RelationshipType.ZeroN), r.startRelationshipType = Le(E.StartRelationshipType, o.startRelationshipType === "Ring" ? "ring" : "dash", E.StartRelationshipType.dash), r.start.tableId = o.start.tableId, r.start.columnIds = o.start.columnIds, r.start.x = o.start.x, r.start.y = o.start.y, r.start.direction = Le(E.Direction, o.start.direction, E.Direction.bottom), r.end.tableId = o.end.tableId, r.end.columnIds = o.end.columnIds, r.end.x = o.end.x, r.end.y = o.end.y, r.end.direction = Le(E.Direction, o.end.direction, E.Direction.bottom), e.collections.relationshipEntities[r.id] = r;
  }
}
function P1(e) {
  return [e, -1, -1, {}];
}
function mc(e, t, n) {
  return e[t] || (e[t] = P1(n), e[t]);
}
function _1(e, t, n, o, r) {
  const s = mc(e, n, o), i = s[1], a = s[2];
  i < t && (s[1] = t), a < t && r();
}
function B1(e, t, n, o, r) {
  const s = mc(e, n, o), i = s[2], a = s[1];
  i < t && (s[2] = t), a <= t && r();
}
function Mm(e, t, n, o, r, s) {
  const i = mc(e, n, o);
  (i[3][r] ?? -1) <= t && (i[3][r] = t, s());
}
let W1 = class {
  constructor(t) {
    Object.defineProperty(this, "collections", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: t
    });
  }
  collection(t) {
    return new U1(this.collections[t], t);
  }
}, U1 = class {
  constructor(t, n) {
    Object.defineProperty(this, "collection", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: t
    }), Object.defineProperty(this, "collectionKey", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: n
    });
  }
  selectById(t) {
    return this.collection[t];
  }
  selectByIds(t) {
    return t.length, t.map((n) => this.selectById(n)).filter(Boolean);
  }
  selectEntities() {
    return this.collection;
  }
  selectAll() {
    return Object.values(this.collection);
  }
  setOne(t) {
    return this.collection[t.id] = t, this;
  }
  setMany(t) {
    return t.forEach((n) => this.setOne(n)), this;
  }
  setAll(t) {
    return this.removeAll(), this.setMany(t), this;
  }
  addOne(t) {
    return this.selectById(t.id) ? this : (this.setOne(t), this);
  }
  addMany(t) {
    return t.forEach((n) => this.addOne(n)), this;
  }
  removeOne(t) {
    return this.selectById(t) && Reflect.deleteProperty(this.collection, t), this;
  }
  removeMany(t) {
    return t.forEach((n) => this.removeOne(n)), this;
  }
  removeAll() {
    return this.collection = {}, this;
  }
  updateOne(t, n) {
    const o = this.selectById(t);
    return o && (n(o), o.meta.updateAt = Date.now()), this;
  }
  updateMany(t, n) {
    return t.forEach((o) => this.updateOne(o, n)), this;
  }
  getOrCreate(t, n) {
    const o = this.selectById(t);
    if (o)
      return o;
    const r = n(t);
    return this.setOne(r), this.selectById(t);
  }
  addOperator(t, n, o, r) {
    return _1(t, n, o, this.collectionKey, r), this;
  }
  removeOperator(t, n, o, r) {
    return B1(t, n, o, this.collectionKey, r), this;
  }
  replaceOperator(t, n, o, r, s) {
    return Mm(t, n, o, this.collectionKey, r, s), this;
  }
};
const y = (e) => new W1(e);
function ed(e, t) {
  return (e & t) === t;
}
const j1 = {
  columnUnique: "columnUnique",
  columnAutoIncrement: "columnAutoIncrement",
  columnName: "columnName",
  columnDataType: "columnDataType",
  columnNotNull: "columnNotNull",
  columnDefault: "columnDefault",
  columnComment: "columnComment"
}, td = Object.values(j1), F1 = {
  ERD: "ERD",
  "@vuerd/builtin-sql-ddl": "@vuerd/builtin-sql-ddl",
  "@vuerd/builtin-grid": "@vuerd/builtin-grid",
  "@vuerd/builtin-generator-code": "@vuerd/builtin-generator-code",
  "@vuerd/builtin-visualization": "@vuerd/builtin-visualization"
}, q1 = Object.values(F1), H1 = {
  MariaDB: "MariaDB",
  MSSQL: "MSSQL",
  MySQL: "MySQL",
  Oracle: "Oracle",
  PostgreSQL: "PostgreSQL",
  SQLite: "SQLite"
}, Z1 = Object.values(H1), V1 = {
  GraphQL: "GraphQL",
  "C#": "C#",
  Java: "Java",
  Kotlin: "Kotlin",
  TypeScript: "TypeScript",
  JPA: "JPA",
  Scala: "Scala"
}, z1 = Object.values(V1), G1 = {
  none: "none",
  camelCase: "camelCase",
  pascalCase: "pascalCase",
  snakeCase: "snakeCase"
}, nd = Object.values(G1), Y1 = {
  AtomOneDark: "AtomOneDark",
  AtomOneLight: "AtomOneLight",
  MonokaiSublime: "MonokaiSublime",
  GithubGist: "GithubGist",
  VS2015: "VS2015"
}, K1 = Object.values(Y1), Q1 = {
  none: "none",
  doubleQuote: "doubleQuote",
  singleQuote: "singleQuote",
  backtick: "backtick"
}, J1 = Object.values(Q1), X1 = 0.1, eC = 1, tC = 2e3, nC = 2e4, oC = () => ({
  version: "2.2.11",
  width: 2e3,
  height: 2e3,
  scrollTop: 0,
  scrollLeft: 0,
  zoomLevel: 1,
  show: {
    tableComment: !0,
    columnComment: !0,
    columnDataType: !0,
    columnDefault: !0,
    columnAutoIncrement: !1,
    columnPrimaryKey: !0,
    columnUnique: !1,
    columnNotNull: !0,
    relationship: !0
  },
  database: "MySQL",
  databaseName: "",
  canvasType: "ERD",
  language: "GraphQL",
  tableCase: "pascalCase",
  columnCase: "camelCase",
  highlightTheme: "VS2015",
  bracketType: "none",
  setting: {
    relationshipDataTypeSync: !0,
    relationshipOptimization: !1,
    columnOrder: [
      "columnName",
      "columnDataType",
      "columnNotNull",
      "columnUnique",
      "columnAutoIncrement",
      "columnDefault",
      "columnComment"
    ]
  },
  pluginSerializationMap: {}
}), od = Je(tC, nC), rC = Je(X1, eC);
function sC(e) {
  var t;
  const n = oC();
  if (pe(e))
    return n;
  const o = w(ne, n, e), r = w(q, n, e), s = w(He, n.show, e.show), i = w(He, n.setting, e.setting);
  if (ne(e.width) && (n.width = od(e.width)), ne(e.height) && (n.height = od(e.height)), ne(e.zoomLevel) && (n.zoomLevel = rC(e.zoomLevel)), r("version"), r("databaseName"), o("scrollTop"), o("scrollLeft"), w(je(Z1), n, e)("database"), w(je(q1), n, e)("canvasType"), w(je(z1), n, e)("language"), w(je(nd), n, e)("tableCase"), w(je(nd), n, e)("columnCase"), w(je(K1), n, e)("highlightTheme"), w(je(J1), n, e)("bracketType"), s("tableComment"), s("columnComment"), s("columnDataType"), s("columnDefault"), s("columnAutoIncrement"), s("columnPrimaryKey"), s("columnUnique"), s("columnNotNull"), s("relationship"), i("relationshipDataTypeSync"), i("relationshipOptimization"), (t = e.setting) != null && t.columnOrder && td.length === e.setting.columnOrder.length && sc(td, e.setting.columnOrder).length === 0 && (n.setting.columnOrder = e.setting.columnOrder), mt(e.pluginSerializationMap)) {
    const a = e.pluginSerializationMap;
    for (const l of Object.keys(a)) {
      const c = a[l];
      q(c) && (n.pluginSerializationMap[l] = c);
    }
  }
  return n;
}
const iC = () => ({
  memos: []
}), aC = () => ({
  id: "",
  value: "",
  ui: {
    active: !1,
    left: 200,
    top: 200,
    zIndex: 2,
    width: 127,
    height: 127
  }
});
function lC(e) {
  const t = iC();
  if (pe(e) || !we(e.memos))
    return t;
  for (const n of e.memos) {
    const o = aC(), r = w(q, o, n), s = w(ne, o.ui, n.ui), i = w(He, o.ui, n.ui), a = w(q, o.ui, n.ui);
    r("id"), r("value"), i("active"), a("color"), s("left"), s("top"), s("zIndex"), s("width"), s("height"), t.memos.push(o);
  }
  return t;
}
const cC = ["ZeroOneN", "One", "N"], uC = {
  ZeroOneN: "ZeroN",
  One: "OneOnly",
  N: "OneN"
}, dC = (e) => cC.includes(e) ? uC[e] : e, hr = {
  ZeroOneN: "ZeroOneN",
  ZeroOne: "ZeroOne",
  ZeroN: "ZeroN",
  OneOnly: "OneOnly",
  OneN: "OneN",
  One: "One",
  N: "N"
}, fC = Object.values(hr), mC = {
  Ring: "Ring",
  Dash: "Dash"
}, hC = Object.values(mC), pr = {
  left: "left",
  right: "right",
  top: "top",
  bottom: "bottom"
}, rd = Object.values(pr), pC = () => ({
  relationships: []
}), yC = () => ({
  id: "",
  identification: !1,
  relationshipType: "ZeroN",
  startRelationshipType: "Dash",
  start: {
    tableId: "",
    columnIds: [],
    x: 0,
    y: 0,
    direction: "bottom"
  },
  end: {
    tableId: "",
    columnIds: [],
    x: 0,
    y: 0,
    direction: "bottom"
  },
  constraintName: "",
  visible: !0
});
function gC(e) {
  var t, n, o, r;
  const s = pC();
  if (pe(e) || !we(e.relationships))
    return s;
  for (const i of e.relationships) {
    const a = yC(), l = w(q, a, i), c = w(He, a, i), u = w(ne, a.start, i.start), d = w(q, a.start, i.start), f = w(ne, a.end, i.end), h = w(q, a.end, i.end);
    l("id"), l("constraintName"), c("identification"), c("visible"), w(je(fC), a, i)("relationshipType"), a.relationshipType = dC(a.relationshipType), w(je(hC), a, i)("startRelationshipType"), d("tableId"), u("x"), u("y"), w(je(rd), a.start, i.start)("direction"), we((t = i.start) == null ? void 0 : t.columnIds) && (a.start.columnIds = (n = i.start) == null ? void 0 : n.columnIds.filter(q)), h("tableId"), f("x"), f("y"), w(je(rd), a.end, i.end)("direction"), we((o = i.end) == null ? void 0 : o.columnIds) && (a.end.columnIds = (r = i.end) == null ? void 0 : r.columnIds.filter(q)), s.relationships.push(a);
  }
  return s;
}
const Nm = {
  ASC: "ASC",
  DESC: "DESC"
}, vC = Object.values(Nm), bC = () => ({
  tables: [],
  indexes: []
}), IC = () => ({
  id: "",
  name: "",
  comment: "",
  columns: [],
  ui: {
    active: !1,
    left: 200,
    top: 100,
    zIndex: 2,
    widthName: 60,
    widthComment: 60
  },
  visible: !0
}), TC = () => ({
  id: "",
  name: "",
  comment: "",
  dataType: "",
  default: "",
  option: {
    autoIncrement: !1,
    primaryKey: !1,
    unique: !1,
    notNull: !1
  },
  ui: {
    active: !1,
    pk: !1,
    fk: !1,
    pfk: !1,
    widthName: 60,
    widthComment: 60,
    widthDataType: 60,
    widthDefault: 60
  }
}), wC = () => ({
  id: "",
  name: "",
  tableId: "",
  columns: [],
  unique: !1
}), CC = () => ({
  id: "",
  orderType: Nm.ASC
});
function OC(e) {
  const t = bC();
  if (pe(e))
    return t;
  if (we(e.tables))
    for (const n of e.tables) {
      const o = IC(), r = w(q, o, n), s = w(He, o, n), i = w(ne, o.ui, n.ui), a = w(He, o.ui, n.ui), l = w(q, o.ui, n.ui);
      if (r("id"), r("name"), r("comment"), s("visible"), a("active"), l("color"), i("left"), i("top"), i("zIndex"), i("widthName"), i("widthComment"), we(n.columns))
        for (const c of n.columns) {
          const u = TC(), d = w(q, u, c), f = w(ne, u.ui, c.ui), h = w(He, u.ui, c.ui), p = w(He, u.option, c.option);
          d("id"), d("name"), d("comment"), d("dataType"), d("default"), p("autoIncrement"), p("primaryKey"), p("unique"), p("notNull"), h("active"), h("pk"), h("fk"), h("pfk"), f("widthName"), f("widthComment"), f("widthDataType"), f("widthDefault"), o.columns.push(u);
        }
      t.tables.push(o);
    }
  if (we(e.indexes))
    for (const n of e.indexes) {
      const o = wC(), r = w(q, o, n), s = w(He, o, n);
      if (r("id"), r("name"), r("tableId"), s("unique"), we(n.columns))
        for (const i of n.columns) {
          const a = CC();
          w(q, a, i)("id"), w(je(vC), a, i)("orderType"), o.columns.push(a);
        }
      t.indexes.push(o);
    }
  return t;
}
function EC(e) {
  const t = e, n = sC(t.canvas), o = OC(t.table), r = gC(t.relationship), s = lC(t.memo);
  return { canvas: n, table: o, relationship: r, memo: s };
}
E.RelationshipType.ZeroOne + "", hr.ZeroOne, E.RelationshipType.ZeroN + "", hr.ZeroN, E.RelationshipType.OneOnly + "", hr.OneOnly, E.RelationshipType.OneN + "", hr.OneN;
E.Direction.left + "", pr.left, E.Direction.right + "", pr.right, E.Direction.top + "", pr.top, E.Direction.bottom + "", pr.bottom;
function $m(e) {
  const t = JSON.parse(e);
  return Reflect.get(t, "version") === "3.0.0" ? qn(t) : $1(EC(t));
}
function hc(e) {
  const t = Kr(e, [
    "$schema",
    "version",
    "settings",
    "doc",
    "collections",
    "lww"
  ]);
  return ed(t.settings.ignoreSaveSettings, E.SaveSettingType.scroll) && (t.settings.scrollTop = 0, t.settings.scrollLeft = 0), ed(t.settings.ignoreSaveSettings, E.SaveSettingType.zoomLevel) && (t.settings.zoomLevel = 1), JSON.stringify(t, null, 2);
}
/*!
 * @dineug/r-html
 * @version 0.1.3 | Sat Nov 09 2024
 * @author SeungHwan-Lee <dineug2@gmail.com>
 * @license MIT
 */
function QR(e, t) {
  return Object.freeze({ key: t ?? Symbol(), value: e });
}
const km = {
  subscribe: "@@r-html/context-subscribe",
  unsubscribe: "@@r-html/context-unsubscribe"
};
function Dm(e, t) {
  function n(o, r) {
    return new CustomEvent(e, {
      detail: o,
      ...t,
      ...r
    });
  }
  return n.toString = () => `${e}`, n.type = e, n;
}
const vo = Dm(km.subscribe, { bubbles: !0, composed: !0 }), bo = Dm(km.unsubscribe, { bubbles: !0, composed: !0 });
function SC(e, t) {
  const n = (r) => {
    const s = r;
    t.dispatchEvent(vo(s.detail));
  }, o = (r) => {
    const s = r;
    t.dispatchEvent(bo(s.detail));
  };
  return e.addEventListener(vo.type, n), e.addEventListener(bo.type, o), () => {
    e.removeEventListener(vo.type, n), e.removeEventListener(bo.type, o);
  };
}
function fa(e, ...t) {
  try {
    return e == null ? void 0 : e(...t);
  } catch (n) {
    console.error(n);
  }
}
const xC = (e) => {
  Promise.resolve().then(e);
}, Rm = queueMicrotask ?? xC, cn = (e) => (t) => typeof t === e, Lm = cn("object"), AC = cn("bigint"), Pm = cn("boolean"), rn = cn("function"), MC = cn("number"), _m = cn("string"), NC = cn("symbol"), ht = cn("undefined"), jt = (e) => e === null, { isArray: he } = Array, Oe = (e) => Lm(e) && !jt(e) && !he(e), as = (e) => AC(e) || Pm(e) || MC(e) || _m(e) || NC(e) || ht(e) || jt(e);
function Qr() {
  const e = /* @__PURE__ */ new Set(), t = (n) => (e.has(n) || e.add(n), () => {
    e.delete(n);
  });
  return {
    subscribe: t,
    next: (n) => {
      e.forEach((o) => o(n));
    },
    asReadonly: () => ({ subscribe: t })
  };
}
const $C = 1, ma = [], pl = /* @__PURE__ */ new Map(), ks = /* @__PURE__ */ new Map();
let yl = !0, pc = 0;
function kC(e, t, n) {
  const o = Jr.get(n);
  if (!o)
    return !1;
  const r = o.get(e);
  return r ? r.has(t) : !1;
}
const Bm = (e) => (t) => {
  const n = pl.get(t);
  let o = () => {
  };
  const r = n != null && n.promise ? n.promise : new Promise((s) => {
    o = s;
  });
  if (!n) {
    const s = {
      type: e,
      tickCount: pc,
      promise: r,
      fn: t,
      resolve: () => {
        o();
      }
    };
    ma.push(s), pl.set(t, s);
  }
  return yl && (Rm(_C), yl = !1), r;
}, DC = Bm("observer"), RC = Bm("nextTick"), sd = (e, t) => {
  var n;
  return (n = _i.get(e)) == null ? void 0 : n.forEach((o) => kC(e, t, o) && DC(o));
};
function Wm() {
  const e = ma.shift();
  if (e) {
    if (pl.delete(e.fn), e.type === "observer")
      Um(e.fn), bc(e.fn), e.resolve();
    else if (e.type === "nextTick") {
      const t = fa(e.fn);
      t instanceof Promise ? t.finally(e.resolve) : e.resolve();
    }
    LC() && Wm();
  }
}
function LC() {
  const e = ma[0];
  return e ? $C <= pc - e.tickCount : !1;
}
function PC() {
  for (; ma.length; )
    Wm();
  yl = !0, pc = 0;
}
function _C() {
  PC();
}
function id(e, t) {
  const n = zs.get(e);
  if (!n)
    return;
  const o = vl.get(n);
  if (!o)
    return;
  const r = ks.get(n);
  r ? r.has(t) || r.add(t) : (ks.set(n, /* @__PURE__ */ new Set([t])), RC(() => {
    const s = ks.get(n);
    s && (ks.delete(n), s.forEach((i) => o.next(i)));
  }));
}
function m(e, t, n, o) {
  if (n === "a" && !o)
    throw new TypeError("Private accessor was defined without a getter");
  if (typeof t == "function" ? e !== t || !o : !t.has(e))
    throw new TypeError("Cannot read private member from an object whose class did not declare it");
  return n === "m" ? o : n === "a" ? o.call(e) : o ? o.value : t.get(e);
}
function b(e, t, n, o, r) {
  if (o === "m")
    throw new TypeError("Private method is not writable");
  if (o === "a" && !r)
    throw new TypeError("Private accessor was defined without a setter");
  if (typeof t == "function" ? e !== t || !r : !t.has(e))
    throw new TypeError("Cannot write private member to an object whose class did not declare it");
  return o === "a" ? r.call(e, n) : r ? r.value = n : t.set(e, n), n;
}
const BC = "on", WC = "@", UC = ".", jC = "?", FC = "...", qC = "@@r-html", HC = Math.random().toString().substring(2, 8), sn = `${qC}-${HC}`, ZC = `${FC}${sn}`, gl = new RegExp(`${sn}_(\\d+)_`, "g"), VC = new RegExp(`^${sn}_\\d+_$`), zC = /^\n/;
var J;
(function(e) {
  e.attribute = "attribute", e.boolean = "boolean", e.event = "event", e.property = "property", e.spread = "spread", e.directive = "directive";
})(J || (J = {}));
const ha = Symbol.for("https://github.com/dineug/r-html#beforeMount"), pa = Symbol.for("https://github.com/dineug/r-html#mounted"), ya = Symbol.for("https://github.com/dineug/r-html#unmounted"), yc = Symbol.for("https://github.com/dineug/r-html#beforeFirstUpdate"), ga = Symbol.for("https://github.com/dineug/r-html#beforeUpdate"), gc = Symbol.for("https://github.com/dineug/r-html#firstUpdated"), va = Symbol.for("https://github.com/dineug/r-html#updated"), GC = [
  ha,
  pa,
  ya,
  yc,
  ga,
  gc,
  va
], vc = Symbol.for("https://github.com/dineug/r-html#Directive"), Nn = Symbol.for("https://github.com/dineug/r-html#TemplateLiterals");
let Vs = null;
function Pi(e) {
  Vs = e;
}
const ls = (e) => (t) => {
  Vs && (Vs[e] ?? (Vs[e] = [])).push(t);
}, YC = ls(ha), JR = ls(pa), KC = ls(ya), XR = ls(ga), eL = ls(va);
function Fe(e, t) {
  const n = Reflect.get(e, t, e);
  he(n) && n.forEach(fa);
}
function QC(e) {
  GC.forEach((t) => Reflect.set(e, t, null, e));
}
const JC = Qr(), XC = (e) => e, eO = (e) => {
  var t, n, o;
  return o = class extends e {
    constructor(r, s, i) {
      super(r, s, i), t.set(this, []), n.set(this, null), this.hmr();
    }
    commit(r) {
      const s = XC(r);
      super.commit(s), b(this, t, r, "f");
    }
    hmr() {
      b(this, n, JC.subscribe((r) => m(this, t, "f").includes(r) && this.commit(m(this, t, "f"))), "f");
    }
    destroy() {
      var r, s;
      (r = m(this, n, "f")) == null || r.call(this), (s = super.destroy) == null || s.call(this);
    }
  }, t = /* @__PURE__ */ new WeakMap(), n = /* @__PURE__ */ new WeakMap(), o;
}, zs = /* @__PURE__ */ new WeakMap(), _i = /* @__PURE__ */ new WeakMap(), ad = /* @__PURE__ */ new WeakMap(), vl = /* @__PURE__ */ new WeakMap(), Jr = /* @__PURE__ */ new WeakMap(), tO = { shallow: !1 };
let Dt = null;
function bc(e) {
  return Dt = e, fa(e), Dt = null, () => Um(e);
}
function Um(e) {
  const t = Jr.get(e);
  if (t)
    for (const [n] of t.entries()) {
      const o = _i.get(n);
      o == null || o.delete(e);
    }
  t && Jr.delete(e);
}
function nO(e) {
  if (!Dt)
    return;
  const t = _i.get(e);
  t ? t.has(Dt) || t.add(Dt) : _i.set(e, /* @__PURE__ */ new Set([Dt]));
}
function oO(e, t) {
  if (!Dt)
    return;
  const n = Jr.get(Dt);
  if (n) {
    const o = n.get(e);
    o ? o.has(t) || o.add(t) : n.set(e, /* @__PURE__ */ new Set([t]));
  } else
    Jr.set(Dt, /* @__PURE__ */ new Map([[e, /* @__PURE__ */ new Set([t])]]));
}
const rO = (e) => e instanceof Node || e instanceof Map || e instanceof Set || e instanceof WeakMap || e instanceof WeakSet || e instanceof RegExp || e instanceof Date || e instanceof Promise || (he(e) || Oe(e)) && Object.isFrozen(e);
function cs(e, t = {}) {
  const { shallow: n } = Object.assign({}, tO, t), o = new Proxy(e, {
    get(r, s, i) {
      const a = Reflect.get(r, s, i);
      return rO(a) ? a : (nO(e), oO(e, s), !n && (Oe(a) || he(a)) && !ad.has(a) ? zs.has(a) ? zs.get(a) : cs(a, t) : a);
    },
    set(r, s, i, a) {
      const l = Reflect.get(r, s, a), c = Reflect.set(r, s, i, a);
      return (!he(r) && l !== i || s === "length") && (sd(r, s), id(r, s)), c;
    },
    deleteProperty(r, s) {
      const i = Reflect.deleteProperty(r, s);
      return sd(r, s), id(r, s), i;
    }
  });
  return zs.set(e, o), ad.set(o, e), o;
}
function tL(e) {
  return (vl.get(e) ?? vl.set(e, Qr()).get(e)).asReadonly();
}
function nL(e, t) {
  const n = cs({ value: t.value }, { shallow: !0 }), o = (i) => {
    n.value = i;
  }, r = () => e instanceof HTMLElement ? e : e.parentElement ?? e.host, s = () => {
    r().dispatchEvent(vo({
      context: t,
      observer: o
    }));
  };
  return s(), YC(s), KC(() => {
    r().dispatchEvent(bo({
      context: t,
      observer: o
    }));
  }), n;
}
function oL(e, t, n) {
  const o = e instanceof HTMLElement ? e : e.parentElement ?? e.host, r = Qr(), s = /* @__PURE__ */ new Map(), i = (l) => {
    var c, u;
    const d = l;
    ((u = (c = d.detail) == null ? void 0 : c.context) == null ? void 0 : u.key) === t.key && (d.stopPropagation(), d.detail.observer(n), s.set(d.detail.observer, r.subscribe(d.detail.observer)));
  }, a = (l) => {
    var c, u;
    const d = l;
    if (((u = (c = d.detail) == null ? void 0 : c.context) == null ? void 0 : u.key) === t.key) {
      d.stopPropagation();
      const f = s.get(d.detail.observer);
      f == null || f(), s.delete(d.detail.observer);
    }
  };
  return o.addEventListener(vo.type, i), o.addEventListener(bo.type, a), {
    set: (l) => {
      r.next(l);
    },
    destroy: () => {
      o.removeEventListener(vo.type, i), o.removeEventListener(bo.type, a);
      for (const l of s.values())
        l();
      s.clear();
    }
  };
}
var Ce;
(function(e) {
  e.element = "element", e.text = "text", e.comment = "comment";
})(Ce || (Ce = {}));
class Sn {
  constructor(t = {}) {
    Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: Ce.comment
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: ""
    }), Object.defineProperty(this, "attrs", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "parent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: null
    }), Object.defineProperty(this, "children", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.assign(this, t);
  }
  *iterParent() {
    yield this, this.parent && (yield* this.parent.iterParent());
  }
  *[Symbol.iterator]() {
    if (yield this, !!this.children)
      for (const t of this.children)
        yield* t;
  }
}
const sO = (e, t = !1) => t ? document.createElementNS("http://www.w3.org/2000/svg", e) : document.createElement(e), iO = ({ type: e, value: t }, n = !1) => e === Ce.element ? sO(t, n) : e === Ce.text ? document.createTextNode(t) : document.createComment(t), Ic = (e) => !!e && e !== "false";
function aO(e, { type: t, name: n, value: o }) {
  switch (t) {
    case J.attribute:
      e.setAttribute(n, o ?? "");
      break;
    case J.boolean:
      Ic(o) && e.setAttribute(n, "");
      break;
    case J.property:
      Reflect.set(e, n, o, e);
      break;
  }
}
function lO(e, { type: t, name: n, value: o }) {
  switch (t) {
    case J.attribute:
    case J.property:
      Reflect.set(e, n, o);
      break;
    case J.boolean:
      Reflect.set(e, n, Ic(o));
      break;
  }
}
const Bn = (e, t) => e.length === t.length && e.every((n, o) => n === t[o]);
function bl(e, t) {
  if (e === t)
    return !0;
  const n = Oe(e) ? e : {}, o = Oe(t) ? t : {}, r = Object.keys(n), s = Object.keys(o);
  return r.length === s.length && s.every((i) => Reflect.get(n, i) === Reflect.get(o, i));
}
const cO = (e) => he(e) && rn(e[0]) && (ht(e[1]) || Pm(e[1]) || Oe(e[1]));
function Xe(e, t) {
  const n = t.parentNode;
  n && n.insertBefore(e, t);
}
function et(e, t) {
  const n = t.parentNode;
  n && (t.nextSibling ? n.insertBefore(e, t.nextSibling) : n.appendChild(e));
}
const $e = (e) => e.parentNode && e.parentNode.removeChild(e), uO = (e) => e instanceof Node;
function ke(e, t) {
  const n = [];
  let o = e.nextSibling;
  for (; o && o !== t; )
    n.push(o), o = o.nextSibling;
  return n;
}
const Il = () => {
}, ld = (e) => e instanceof HTMLElement, cd = (e) => e instanceof SVGElement, dO = (e) => e instanceof Promise, fO = (e) => {
  var t;
  return ((t = e.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)) == null ? void 0 : t.join("-").toLowerCase()) ?? "";
}, ir = (e) => e.replace(/^([A-Z])|[\s-_](\w)/g, (t, n, o) => o ? o.toUpperCase() : n.toLowerCase());
var St;
(function(e) {
  e.html = "html", e.svg = "svg", e.css = "css";
})(St || (St = {}));
const mO = new Set(Object.values(St)), Ua = /* @__PURE__ */ new WeakMap(), ja = /* @__PURE__ */ new WeakMap(), hO = /* @__PURE__ */ new Set([St.svg]), jm = (e) => `${sn}_${e}_`, pO = (e) => he(e) && he(e.raw), ba = (e) => Oe(e) && pO(e.strings) && he(e.values) && mO.has(Reflect.get(e, Nn) ?? ""), Ia = (e) => ba(e) && e[Nn] === St.css, Vo = (e, t = !0, n = !1) => (o) => t ? !!(o != null && o.trimStart().startsWith(e)) : n ? !!(o != null && o.trimEnd().endsWith(e)) : new RegExp(e).test(o ?? ""), Fm = Vo(ZC), qm = Vo(UC), Hm = Vo(jC), Zm = Vo(WC), Vm = Vo(BC), ko = Vo(sn, !1), us = (e) => ko(e) && VC.test((e == null ? void 0 : e.trim()) ?? ""), ud = ({ type: e, value: t }) => e === J.spread || e === J.directive || ko(t), yO = (e) => hO.has(e), dd = (e) => us(e) ? J.directive : Fm(e) ? J.spread : qm(e) ? J.property : Zm(e) || Vm(e) ? J.event : Hm(e) ? J.boolean : J.attribute, Ds = (e) => Fm(e) ? e.substring(3) : us(e) ? e : qm(e) || Zm(e) || Hm(e) ? e.substring(1) : Vm(e) ? e.substring(2) : e;
function Ie(e) {
  const t = [];
  let n = gl.exec(e);
  for (; n; ) {
    const o = Number(n[1]);
    t.push([n[0], Number.isInteger(o) ? o : -1]), n = gl.exec(e);
  }
  return t;
}
const fd = "0123456789abcdefghijklmnopqrstuvwxyz-_";
function gO(e, t) {
  return e = Math.ceil(e), t = Math.floor(t), Math.floor(Math.random() * (t - e)) + e;
}
function vO(e = 21) {
  let t = "_";
  for (let n = 0; n < e; n++)
    t += fd.charAt(gO(0, fd.length));
  return t;
}
var xe, no, Gs, yr, Qt, gr, vr;
class bO {
  constructor(t, { name: n, value: o }) {
    xe.set(this, void 0), no.set(this, void 0), Gs.set(this, void 0), yr.set(this, []), Qt.set(this, []), gr.set(this, null), vr.set(this, null), b(this, xe, t, "f"), b(this, no, n, "f"), b(this, Gs, o, "f"), b(this, yr, Ie(o ?? ""), "f");
  }
  commit(t) {
    const n = m(this, yr, "f").map(([r, s]) => t[s]);
    if (Bn(m(this, Qt, "f"), n))
      return;
    const o = n[n.length - 1];
    if (m(this, no, "f") === "class")
      this.classCommit(o);
    else if (m(this, no, "f") === "style")
      this.styleCommit(o);
    else {
      const r = n.reduce((s, i, a) => s.replace(new RegExp(m(this, yr, "f")[a][0]), Ys(i)), m(this, Gs, "f") ?? "");
      m(this, xe, "f").setAttribute(m(this, no, "f"), r.trim());
    }
    b(this, Qt, n, "f");
  }
  classCommit(t) {
    if (!ld(m(this, xe, "f")) && !cd(m(this, xe, "f")) || !Oe(t) && !he(t))
      return;
    const n = m(this, Qt, "f")[m(this, Qt, "f").length - 1];
    if (n === t || he(n) && he(t) && Bn(n, t) || Oe(n) && Oe(t) && bl(n, t))
      return;
    const o = [...m(this, xe, "f").classList], r = zm(t);
    if (jt(m(this, vr, "f")))
      b(this, vr, o, "f");
    else {
      const s = m(this, vr, "f"), i = o.filter((a) => !s.includes(a) && !r.includes(a));
      m(this, xe, "f").classList.remove(...i);
    }
    m(this, xe, "f").classList.add(...r);
  }
  styleCommit(t) {
    if (!ld(m(this, xe, "f")) && !cd(m(this, xe, "f")))
      return;
    const n = m(this, Qt, "f")[m(this, Qt, "f").length - 1];
    if (bl(n, t))
      return;
    const o = IO(m(this, xe, "f")), r = Oe(t) ? t : {};
    if (jt(m(this, gr, "f")))
      b(this, gr, o, "f");
    else {
      const s = m(this, gr, "f");
      Object.keys(o).filter((i) => !s[i] && !r[i]).forEach((i) => m(this, xe, "f").style.removeProperty(i));
    }
    for (const s of Object.keys(r))
      m(this, xe, "f").style.setProperty(s, r[s]);
  }
}
xe = /* @__PURE__ */ new WeakMap(), no = /* @__PURE__ */ new WeakMap(), Gs = /* @__PURE__ */ new WeakMap(), yr = /* @__PURE__ */ new WeakMap(), Qt = /* @__PURE__ */ new WeakMap(), gr = /* @__PURE__ */ new WeakMap(), vr = /* @__PURE__ */ new WeakMap();
function Ys(e) {
  return as(e) && !jt(e) && !ht(e) || Ia(e) ? String(e) : "";
}
function IO(e) {
  const t = {};
  for (let n = 0; n < e.style.length; n++) {
    const o = e.style.item(n);
    t[o] = e.style.getPropertyValue(o);
  }
  return t;
}
function zm(e, t = []) {
  if (Ia(e))
    return t.push(Ys(e)), t;
  if (as(e))
    t.push(Ys(e));
  else if (Lm(e))
    if (he(e))
      for (let n = 0; n < e.length; n++)
        e[n] && zm(e[n], t);
    else
      for (let n in e)
        e[n] && t.push(Ys(n));
  return t;
}
var br, Ir, Ks, Qs;
class TO {
  constructor(t, { name: n, value: o }) {
    br.set(this, void 0), Ir.set(this, void 0), Ks.set(this, []), Qs.set(this, []), b(this, br, t, "f"), b(this, Ir, n, "f"), b(this, Ks, Ie(o ?? ""), "f");
  }
  commit(t) {
    const n = m(this, Ks, "f").map(([r, s]) => t[s]);
    if (Bn(m(this, Qs, "f"), n))
      return;
    const o = n[n.length - 1];
    Ic(o) ? m(this, br, "f").setAttribute(m(this, Ir, "f"), "") : m(this, br, "f").removeAttribute(m(this, Ir, "f")), b(this, Qs, n, "f");
  }
}
br = /* @__PURE__ */ new WeakMap(), Ir = /* @__PURE__ */ new WeakMap(), Ks = /* @__PURE__ */ new WeakMap(), Qs = /* @__PURE__ */ new WeakMap();
var Do;
(function(e) {
  e.node = "node", e.attribute = "attribute";
})(Do || (Do = {}));
function Gm(e, t) {
  return Reflect.set(t, vc, e), t;
}
var Js, Xs, ei, Tr, Jt;
const wO = (e) => he(e) && Reflect.get(e, vc) === Do.attribute;
let Ym = class {
  constructor(e, { name: t }) {
    Js.set(this, void 0), Xs.set(this, void 0), ei.set(this, null), Tr.set(this, null), Jt.set(this, null), b(this, Js, e, "f"), b(this, Xs, Ie(t)[0], "f");
  }
  commit(e) {
    var t, n, o, r;
    const [, s] = m(this, Xs, "f"), i = e[s];
    if (!wO(i))
      return;
    const [a, l] = i;
    if (m(this, ei, "f") !== l)
      (t = m(this, Jt, "f")) == null || t.call(this), b(this, Tr, l({ node: m(this, Js, "f") }), "f"), b(this, ei, l, "f"), b(this, Jt, (n = m(this, Tr, "f")) == null ? void 0 : n.call(this, a), "f");
    else {
      const c = (o = m(this, Tr, "f")) == null ? void 0 : o.call(this, a);
      m(this, Jt, "f") !== c && ((r = m(this, Jt, "f")) == null || r.call(this), b(this, Jt, c, "f"));
    }
  }
  destroy() {
    var e;
    (e = m(this, Jt, "f")) == null || e.call(this);
  }
};
Js = /* @__PURE__ */ new WeakMap(), Xs = /* @__PURE__ */ new WeakMap(), ei = /* @__PURE__ */ new WeakMap(), Tr = /* @__PURE__ */ new WeakMap(), Jt = /* @__PURE__ */ new WeakMap();
var bn, In, ti, wr;
class Km {
  constructor(t, { name: n, value: o }) {
    bn.set(this, void 0), In.set(this, void 0), ti.set(this, []), wr.set(this, []), b(this, bn, t, "f"), b(this, In, n, "f"), b(this, ti, Ie(o ?? ""), "f");
  }
  commit(t) {
    const n = m(this, ti, "f").map(([o, r]) => t[r]).filter((o) => rn(o) || cO(o));
    Bn(m(this, wr, "f"), n) || (this.clear(), n.forEach((o) => rn(o) ? m(this, bn, "f").addEventListener(m(this, In, "f"), o) : m(this, bn, "f").addEventListener(m(this, In, "f"), o[0], o[1])), b(this, wr, n, "f"));
  }
  clear() {
    m(this, wr, "f").forEach((t) => rn(t) ? m(this, bn, "f").removeEventListener(m(this, In, "f"), t) : m(this, bn, "f").removeEventListener(m(this, In, "f"), t[0], t[1]));
  }
  destroy() {
    this.clear();
  }
}
bn = /* @__PURE__ */ new WeakMap(), In = /* @__PURE__ */ new WeakMap(), ti = /* @__PURE__ */ new WeakMap(), wr = /* @__PURE__ */ new WeakMap();
var Cr, ni, oi, ri;
class CO {
  constructor(t, { name: n, value: o }) {
    Cr.set(this, void 0), ni.set(this, void 0), oi.set(this, []), ri.set(this, []), b(this, Cr, t, "f"), b(this, ni, n, "f"), b(this, oi, Ie(o ?? ""), "f");
  }
  commit(t) {
    const n = m(this, oi, "f").map(([r, s]) => t[s]);
    if (Bn(m(this, ri, "f"), n))
      return;
    const o = n[n.length - 1];
    Reflect.set(m(this, Cr, "f"), m(this, ni, "f"), o, m(this, Cr, "f")), b(this, ri, n, "f");
  }
}
Cr = /* @__PURE__ */ new WeakMap(), ni = /* @__PURE__ */ new WeakMap(), oi = /* @__PURE__ */ new WeakMap(), ri = /* @__PURE__ */ new WeakMap();
var Or, si, ii;
class Qm {
  constructor(t, { name: n }) {
    Or.set(this, void 0), si.set(this, void 0), ii.set(this, null), b(this, Or, t, "f"), b(this, si, Ie(n)[0], "f");
  }
  commit(t) {
    const [, n] = m(this, si, "f"), o = t[n];
    !Oe(o) || bl(m(this, ii, "f"), o) || (Object.keys(o).forEach((r) => Reflect.set(m(this, Or, "f"), r, o[r], m(this, Or, "f"))), b(this, ii, o, "f"));
  }
}
Or = /* @__PURE__ */ new WeakMap(), si = /* @__PURE__ */ new WeakMap(), ii = /* @__PURE__ */ new WeakMap();
const OO = (e, t) => t.type === J.attribute ? new bO(e, t) : t.type === J.boolean ? new TO(e, t) : t.type === J.event ? new Km(e, t) : t.type === J.property ? new CO(e, t) : t.type === J.spread ? new Qm(e, t) : new Ym(e, t);
var ai, li, Er, Sr;
class EO {
  constructor(t, { value: n }) {
    ai.set(this, void 0), li.set(this, void 0), Er.set(this, []), Sr.set(this, []), b(this, ai, t, "f"), b(this, li, n, "f"), _m(n) && b(this, Er, Ie(n), "f");
  }
  commit(t) {
    const n = m(this, Er, "f").map(([r, s]) => t[s]);
    if (Bn(m(this, Sr, "f"), n))
      return;
    b(this, Sr, n, "f");
    const o = m(this, Sr, "f").reduce((r, s, i) => r.replace(new RegExp(m(this, Er, "f")[i][0]), as(s) && !jt(s) && !ht(s) ? String(s) : ""), m(this, li, "f") ?? "");
    m(this, ai, "f").data = o;
  }
}
ai = /* @__PURE__ */ new WeakMap(), li = /* @__PURE__ */ new WeakMap(), Er = /* @__PURE__ */ new WeakMap(), Sr = /* @__PURE__ */ new WeakMap();
const Tl = Symbol("https://github.com/dineug/r-html.git#hostBridge");
function SO(e, t) {
  if (t instanceof ShadowRoot) {
    const n = t.host;
    Reflect.set(e, Tl, n);
  }
  return () => {
    Reflect.deleteProperty(e, Tl);
  };
}
function xO(e) {
  return Reflect.get(e, Tl) ?? null;
}
var ci, ui, di, fi;
class AO {
  constructor(t, { name: n, value: o }) {
    ci.set(this, void 0), ui.set(this, void 0), di.set(this, []), fi.set(this, []), b(this, ci, t, "f"), b(this, ui, n, "f"), b(this, di, Ie(o ?? ""), "f");
  }
  commit(t) {
    const n = m(this, di, "f").map(([r, s]) => t[s]);
    if (Bn(m(this, fi, "f"), n))
      return;
    const o = n[n.length - 1];
    Reflect.set(m(this, ci, "f"), m(this, ui, "f"), o), b(this, fi, n, "f");
  }
}
ci = /* @__PURE__ */ new WeakMap(), ui = /* @__PURE__ */ new WeakMap(), di = /* @__PURE__ */ new WeakMap(), fi = /* @__PURE__ */ new WeakMap();
var Rt;
(function(e) {
  e.create = "create", e.move = "move";
})(Rt || (Rt = {}));
function MO(e) {
  const t = [], n = /* @__PURE__ */ new Map();
  return e.forEach(({ type: o, value: r }, s) => {
    const i = {
      type: o,
      key: o === te.templateLiterals ? r.strings : r
    };
    t.push(i), n.set(i, s);
  }), {
    items: t,
    itemToIndex: n
  };
}
function NO(e) {
  const t = [], n = /* @__PURE__ */ new Map();
  return e.forEach((o, r) => {
    const s = un(o), i = {
      type: s,
      key: s === te.templateLiterals ? o.strings : o
    };
    t.push(i), n.set(i, r);
  }), {
    items: t,
    itemToIndex: n
  };
}
function Jm(e, t, n) {
  const o = !!(n != null && n.strict), r = {
    update: [],
    delete: []
  }, s = /* @__PURE__ */ new Set(), i = [], a = e.items, l = t.items;
  return a.forEach((c, u) => {
    const d = l.findIndex((f, h) => c.type === f.type && c.key === f.key && !s.has(h));
    d === -1 ? o ? r.delete.push({ from: u }) : i.push(c) : (s.add(d), r.update.push({ action: Rt.move, from: u, to: d }));
  }), i.forEach((c) => {
    const u = e.itemToIndex.get(c), d = l.find((f, h) => c.type === f.type && !s.has(h));
    if (d) {
      const f = t.itemToIndex.get(d);
      s.add(f), r.update.push({ action: Rt.move, from: u, to: f });
    } else
      r.delete.push({ from: u });
  }), l.forEach((c, u) => {
    s.has(u) || r.update.push({
      action: Rt.create,
      from: -1,
      to: t.itemToIndex.get(c)
    });
  }), r.update.sort((c, u) => c.to - u.to), r;
}
var xr, mi, Re, Ar;
class Xm {
  constructor(t, n) {
    xr.set(this, void 0), mi.set(this, void 0), Re.set(this, []), b(this, xr, t, "f"), b(this, mi, n, "f");
  }
  commit(t) {
    const n = Jm(MO(m(this, Re, "f")), NO(t)), o = { length: t.length };
    n.update.forEach(({ action: r, from: s, to: i }) => {
      switch (r) {
        case Rt.create:
          const a = document.createComment("");
          i === 0 ? et(a, m(this, xr, "f")) : m(this, Re, "f").length ? et(a, o[i - 1] ? o[i - 1].endNode : m(this, Re, "f")[i - 1].endNode) : Xe(a, m(this, mi, "f")), o[i] = new $O(a, t[i]);
          break;
        case Rt.move:
          if (o[i] = m(this, Re, "f")[s], i === s)
            return;
          i === 0 ? m(this, Re, "f")[s].insert("after", m(this, xr, "f")) : m(this, Re, "f")[s].insert("after", o[i - 1] ? o[i - 1].endNode : m(this, Re, "f")[i - 1].endNode);
          break;
      }
    }), n.delete.forEach(({ from: r }) => m(this, Re, "f")[r].destroy()), b(this, Re, Array.from(o), "f"), m(this, Re, "f").forEach((r, s) => r.commit(t[s]));
  }
  destroy() {
    m(this, Re, "f").forEach((t) => t.destroy());
  }
}
xr = /* @__PURE__ */ new WeakMap(), mi = /* @__PURE__ */ new WeakMap(), Re = /* @__PURE__ */ new WeakMap();
let $O = class {
  constructor(e, t) {
    Ar.set(this, void 0), Object.defineProperty(this, "startNode", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: document.createComment("")
    }), Object.defineProperty(this, "endNode", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: document.createComment("")
    }), Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Xe(this.startNode, e), et(this.endNode, e), $e(e), this.value = t, this.type = un(t), b(this, Ar, zo(this.type, this.startNode, this.endNode), "f");
  }
  commit(e) {
    m(this, Ar, "f").commit(e), this.value = e;
  }
  insert(e, t) {
    const n = [
      this.startNode,
      ...ke(this.startNode, this.endNode),
      this.endNode
    ];
    e === "before" ? n.forEach((o) => Xe(o, t)) : n.reverse().forEach((o) => et(o, t));
  }
  destroy() {
    var e, t;
    (t = (e = m(this, Ar, "f")).destroy) == null || t.call(e), ke(this.startNode, this.endNode).forEach($e), this.startNode.remove(), this.endNode.remove();
  }
};
Ar = /* @__PURE__ */ new WeakMap();
var Mr, Nr, hi, $r, oo;
class eh {
  constructor(t, n) {
    Mr.set(this, void 0), Nr.set(this, void 0), hi.set(this, null), $r.set(this, null), oo.set(this, void 0), b(this, Mr, t, "f"), b(this, Nr, n, "f");
  }
  commit(t) {
    var n, o;
    if (!ih(t))
      return;
    const [r, s] = t;
    if (m(this, hi, "f") !== s)
      this.clear(), b(this, $r, s({
        startNode: m(this, Mr, "f"),
        endNode: m(this, Nr, "f")
      }), "f"), b(this, hi, s, "f"), b(this, oo, (n = m(this, $r, "f")) == null ? void 0 : n.call(this, r), "f");
    else {
      const i = (o = m(this, $r, "f")) == null ? void 0 : o.call(this, r);
      m(this, oo, "f") !== i && (this.clear(), b(this, oo, i, "f"));
    }
  }
  clear() {
    var t;
    (t = m(this, oo, "f")) == null || t.call(this), ke(m(this, Mr, "f"), m(this, Nr, "f")).forEach($e);
  }
  destroy() {
    this.clear();
  }
}
Mr = /* @__PURE__ */ new WeakMap(), Nr = /* @__PURE__ */ new WeakMap(), hi = /* @__PURE__ */ new WeakMap(), $r = /* @__PURE__ */ new WeakMap(), oo = /* @__PURE__ */ new WeakMap();
class th {
  constructor(t, n) {
  }
  commit(t) {
  }
}
var pi, ro;
class nh {
  constructor(t, n) {
    pi.set(this, void 0), ro.set(this, null), b(this, pi, n, "f");
  }
  commit(t) {
    m(this, ro, "f") !== t && (m(this, ro, "f") && $e(m(this, ro, "f")), Xe(t, m(this, pi, "f")), b(this, ro, t, "f"));
  }
}
pi = /* @__PURE__ */ new WeakMap(), ro = /* @__PURE__ */ new WeakMap();
var so, io, yi, ao, kr;
class oh {
  constructor(t, n) {
    so.set(this, void 0), io.set(this, void 0), yi.set(this, null), ao.set(this, null), kr.set(this, Il), b(this, so, t, "f"), b(this, io, n, "f");
  }
  commit(t) {
    m(this, yi, "f") !== t && (this.clear(), dO(t) && this.promiseCommit(t));
  }
  promiseCommit(t) {
    const [n, o] = kO(t);
    b(this, kr, o, "f"), n.then((r) => {
      var s;
      const i = un(r);
      b(this, ao, zo(i, m(this, so, "f"), m(this, io, "f")), "f"), (s = m(this, ao, "f")) == null || s.commit(r);
    }), b(this, yi, t, "f");
  }
  partClear() {
    var t, n;
    (n = (t = m(this, ao, "f")) == null ? void 0 : t.destroy) == null || n.call(t), ke(m(this, so, "f"), m(this, io, "f")).forEach($e);
  }
  clear() {
    var t, n;
    m(this, kr, "f").call(this), (n = (t = m(this, ao, "f")) == null ? void 0 : t.destroy) == null || n.call(t), ke(m(this, so, "f"), m(this, io, "f")).forEach($e), b(this, kr, Il, "f");
  }
  destroy() {
    this.clear();
  }
}
so = /* @__PURE__ */ new WeakMap(), io = /* @__PURE__ */ new WeakMap(), yi = /* @__PURE__ */ new WeakMap(), ao = /* @__PURE__ */ new WeakMap(), kr = /* @__PURE__ */ new WeakMap();
function kO(e) {
  let t = Il;
  const n = new Promise((r, s) => t = s), o = () => t();
  return [Promise.race([n, e]), o];
}
var gi, vi;
class rh {
  constructor(t, n) {
    gi.set(this, document.createTextNode("")), vi.set(this, null), et(m(this, gi, "f"), t);
  }
  commit(t) {
    m(this, vi, "f") !== t && (m(this, gi, "f").data = jt(t) || ht(t) ? "" : String(t), b(this, vi, t, "f"));
  }
}
gi = /* @__PURE__ */ new WeakMap(), vi = /* @__PURE__ */ new WeakMap();
var bi, Dr, lt;
class sh {
  constructor(t, n) {
    bi.set(this, void 0), Dr.set(this, void 0), lt.set(this, null), b(this, bi, t, "f"), b(this, Dr, n, "f");
  }
  commit(t) {
    const { strings: n, values: o } = t;
    m(this, lt, "f") && !m(this, lt, "f").equalStrings(n) && (m(this, lt, "f").destroy(), b(this, lt, null, "f")), m(this, lt, "f") || (b(this, lt, new ch(t, m(this, bi, "f"), m(this, Dr, "f")), "f"), m(this, lt, "f").insert("before", m(this, Dr, "f"))), m(this, lt, "f").commit(o);
  }
  destroy() {
    var t;
    (t = m(this, lt, "f")) == null || t.destroy();
  }
}
bi = /* @__PURE__ */ new WeakMap(), Dr = /* @__PURE__ */ new WeakMap(), lt = /* @__PURE__ */ new WeakMap();
var te;
(function(e) {
  e.primitive = "primitive", e.templateLiterals = "templateLiterals", e.array = "array", e.node = "node", e.object = "object", e.function = "function", e.directive = "directive";
})(te || (te = {}));
const ih = (e) => he(e) && Reflect.get(e, vc) === Do.node, Hn = (e) => (t) => t instanceof e, DO = Hn(rh), RO = Hn(sh), LO = Hn(Xm), PO = Hn(nh), _O = Hn(oh), BO = Hn(th), WO = Hn(eh), un = (e) => as(e) ? te.primitive : ba(e) ? te.templateLiterals : ih(e) ? te.directive : he(e) ? te.array : uO(e) ? te.node : rn(e) ? te.function : te.object, UO = {
  [te.primitive]: DO,
  [te.templateLiterals]: RO,
  [te.array]: LO,
  [te.node]: PO,
  [te.function]: BO,
  [te.object]: _O,
  [te.directive]: WO
}, jO = {
  [te.primitive]: rh,
  [te.templateLiterals]: sh,
  [te.array]: Xm,
  [te.node]: nh,
  [te.function]: th,
  [te.object]: oh,
  [te.directive]: eh
}, ah = (e, t) => UO[e](t), zo = (e, t, n) => new jO[e](t, n);
var Xt, lo, Ii, wl, Rr, Tn, co, uo, Ti, Lr, wi;
class FO {
  constructor(t, n, o, r) {
    var s;
    Xt.set(this, document.createComment("")), lo.set(this, document.createComment("")), Ii.set(this, void 0), wl.set(this, void 0), Rr.set(this, []), Tn.set(this, []), co.set(this, null), uo.set(this, cs({}, { shallow: !0 })), Ti.set(this, null), Lr.set(this, null), wi.set(this, document.createElement("div")), b(this, Xt, t, "f"), b(this, lo, n, "f"), b(this, wl, o, "f"), b(this, Ii, Ie(o.value)[0], "f"), o.staticAttrs && o.staticAttrs.forEach((i) => lO(m(this, uo, "f"), i)), (s = o.attrs) == null || s.forEach((i) => {
      i.type === J.directive ? m(this, Rr, "f").push(i) : i.type === J.spread ? r.push(new Qm(m(this, uo, "f"), i)) : i.type === J.event ? r.push(new Km(m(this, wi, "f"), i)) : r.push(new AO(m(this, uo, "f"), i));
    });
  }
  createContext() {
    const t = m(this, Xt, "f"), n = {
      host: document.body,
      get parentElement() {
        return t.parentElement;
      },
      dispatchEvent: (r) => m(this, wi, "f").dispatchEvent(r)
    }, o = m(this, Xt, "f").getRootNode();
    if (o instanceof ShadowRoot) {
      const r = o.host;
      n.host = r;
    } else if (o instanceof DocumentFragment) {
      const r = xO(o);
      r && (n.host = r);
    }
    return n;
  }
  commit(t) {
    const [, n] = m(this, Ii, "f"), o = t[n];
    if (!rn(o) || m(this, Ti, "f") === o) {
      m(this, Tn, "f").forEach((a) => a.commit(t));
      return;
    }
    const r = this.createContext();
    this.clear(), Pi(this);
    const s = o.call(r, m(this, uo, "f"), r);
    Pi(null), m(this, Rr, "f").length && m(this, Tn, "f").push(...m(this, Rr, "f").map((a) => new Ym(r, a))), Fe(this, ha);
    let i = !1;
    b(this, Lr, bc(() => {
      var a;
      const l = s(), c = un(l);
      ah(c, m(this, co, "f")) || (this.partClear(), b(this, co, zo(c, m(this, Xt, "f"), m(this, lo, "f")), "f")), Fe(this, i ? ga : yc), (a = m(this, co, "f")) == null || a.commit(l), i ? Fe(this, va) : (Fe(this, gc), i = !0);
    }), "f"), m(this, Tn, "f").forEach((a) => a.commit(t)), Fe(this, pa), b(this, Ti, o, "f");
  }
  partClear() {
    var t, n;
    (n = (t = m(this, co, "f")) == null ? void 0 : t.destroy) == null || n.call(t), ke(m(this, Xt, "f"), m(this, lo, "f")).forEach($e);
  }
  clear() {
    var t;
    ke(m(this, Xt, "f"), m(this, lo, "f")).forEach($e), Fe(this, ya), m(this, Tn, "f").forEach((n) => {
      var o;
      return (o = n.destroy) == null ? void 0 : o.call(n);
    }), (t = m(this, Lr, "f")) == null || t.call(this), b(this, Tn, [], "f"), b(this, Lr, null, "f"), QC(this);
  }
  destroy() {
    this.clear(), this.partClear();
  }
}
Xt = /* @__PURE__ */ new WeakMap(), lo = /* @__PURE__ */ new WeakMap(), Ii = /* @__PURE__ */ new WeakMap(), wl = /* @__PURE__ */ new WeakMap(), Rr = /* @__PURE__ */ new WeakMap(), Tn = /* @__PURE__ */ new WeakMap(), co = /* @__PURE__ */ new WeakMap(), uo = /* @__PURE__ */ new WeakMap(), Ti = /* @__PURE__ */ new WeakMap(), Lr = /* @__PURE__ */ new WeakMap(), wi = /* @__PURE__ */ new WeakMap();
var ar, lr, cr, md;
const qO = (md = class {
  constructor(e, t, n) {
    ar.set(this, document.createComment("")), lr.set(this, document.createComment("")), cr.set(this, void 0), b(this, cr, new FO(m(this, ar, "f"), m(this, lr, "f"), t, n), "f"), Xe(m(this, ar, "f"), e), et(m(this, lr, "f"), e), e.remove();
  }
  commit(e) {
    m(this, cr, "f").commit(e);
  }
  destroy() {
    var e, t;
    (t = (e = m(this, cr, "f")).destroy) == null || t.call(e), m(this, ar, "f").remove(), m(this, lr, "f").remove();
  }
}, ar = /* @__PURE__ */ new WeakMap(), lr = /* @__PURE__ */ new WeakMap(), cr = /* @__PURE__ */ new WeakMap(), md), HO = eO(qO);
var fo, mo, Ci, Oi, wn;
class ZO {
  constructor(t, { value: n }) {
    fo.set(this, document.createComment("")), mo.set(this, document.createComment("")), Ci.set(this, void 0), Oi.set(this, null), wn.set(this, null), b(this, Ci, Ie(n)[0], "f"), Xe(m(this, fo, "f"), t), et(m(this, mo, "f"), t), t.remove();
  }
  commit(t) {
    var n;
    const [, o] = m(this, Ci, "f"), r = t[o];
    if (m(this, Oi, "f") === r)
      return;
    const s = un(r);
    ah(s, m(this, wn, "f")) || (jt(m(this, wn, "f")) || this.clear(), b(this, wn, zo(s, m(this, fo, "f"), m(this, mo, "f")), "f")), (n = m(this, wn, "f")) == null || n.commit(r), b(this, Oi, r, "f");
  }
  clear() {
    var t, n;
    (n = (t = m(this, wn, "f")) == null ? void 0 : t.destroy) == null || n.call(t), ke(m(this, fo, "f"), m(this, mo, "f")).forEach($e);
  }
  destroy() {
    this.clear(), m(this, fo, "f").remove(), m(this, mo, "f").remove();
  }
}
fo = /* @__PURE__ */ new WeakMap(), mo = /* @__PURE__ */ new WeakMap(), Ci = /* @__PURE__ */ new WeakMap(), Oi = /* @__PURE__ */ new WeakMap(), wn = /* @__PURE__ */ new WeakMap();
function lh(e = [], t, n = !1, o = []) {
  return e.forEach((r) => {
    if (r.isComponent) {
      const i = document.createComment("");
      t.appendChild(i), o.push(new HO(i, r, o));
      return;
    }
    const s = iO(r, r.isSvg || n);
    t.appendChild(s), s instanceof Comment && r.isMarker && o.push(new EO(s, r)), s instanceof Text && r.isMarkerOnly && o.push(new ZO(s, r)), s instanceof Element && (r.staticAttrs && r.staticAttrs.forEach((i) => aO(s, i)), r.attrs && o.push(...r.attrs.map((i) => OO(s, i))), r.children && lh(r.children, s, r.isSvg || n, o));
  }), o;
}
function VO(e, t = !1) {
  const n = document.createDocumentFragment(), o = lh(e.children, n, t);
  return [n, o];
}
var ho, po, en, Pr, Ei, Si;
class ch {
  constructor(t, n, o) {
    if (ho.set(this, document.createComment("")), po.set(this, document.createComment("")), en.set(this, null), Pr.set(this, []), Ei.set(this, void 0), Si.set(this, !1), b(this, Ei, t.strings, "f"), t[Nn] !== St.html && t[Nn] !== St.svg)
      return;
    const [r, s] = VO(t.template.node, yO(t[Nn]));
    b(this, en, r, "f"), b(this, Pr, s, "f"), n && o ? (b(this, ho, n, "f"), b(this, po, o, "f"), b(this, Si, !0, "f")) : (r.prepend(m(this, ho, "f")), r.append(m(this, po, "f")));
  }
  equalStrings(t) {
    return m(this, Ei, "f") === t;
  }
  commit(t) {
    m(this, Pr, "f").forEach((n) => n.commit(t));
  }
  insert(t, n) {
    m(this, en, "f") && (t === "before" ? Xe(m(this, en, "f"), n) : t === "after" ? et(m(this, en, "f"), n) : n.appendChild(m(this, en, "f")), b(this, en, null, "f"));
  }
  destroy() {
    m(this, Pr, "f").forEach((t) => {
      var n;
      return (n = t.destroy) == null ? void 0 : n.call(t);
    }), ke(m(this, ho, "f"), m(this, po, "f")).forEach($e), m(this, Si, "f") || (m(this, ho, "f").remove(), m(this, po, "f").remove());
  }
}
ho = /* @__PURE__ */ new WeakMap(), po = /* @__PURE__ */ new WeakMap(), en = /* @__PURE__ */ new WeakMap(), Pr = /* @__PURE__ */ new WeakMap(), Ei = /* @__PURE__ */ new WeakMap(), Si = /* @__PURE__ */ new WeakMap();
const ur = /* @__PURE__ */ new WeakMap();
function zO(e, t) {
  var n;
  if (!ba(t)) {
    ur.has(e) && ((n = ur.get(e)) == null || n.destroy(), ur.delete(e));
    return;
  }
  const { strings: o, values: r } = t, s = ur.get(e);
  if (s != null && s.equalStrings(o))
    s == null || s.commit(r);
  else {
    const i = new ch(t);
    s == null || s.destroy(), ur.set(e, i), i.insert("children", e), i.commit(r);
  }
}
function GO(e, t) {
  return (...n) => Gm(Do.attribute, [e(...n), t]);
}
const rL = (e) => ({ value: e }), sL = GO((e) => e, ({ node: e }) => {
  let t = null;
  const n = () => {
    t && (t.value = null);
  };
  return (o) => (t === o || (o.value = e, t = o), n);
});
function Tc(e, t) {
  return (...n) => Gm(Do.node, [
    e(...n),
    t
  ]);
}
const iL = Tc((e) => e, ({ startNode: e, endNode: t }) => {
  const n = e.getRootNode();
  let o = /* @__PURE__ */ new Map(), r = null;
  const s = (u) => ba(u) ? u.strings : u, i = (u) => {
    const d = s(u);
    return o.has(d) ? o.get(d) : null;
  }, a = (u, d) => {
    o.set(s(u), d);
  }, l = (u) => {
    const d = un(u), f = zo(d, e, t), h = document.createDocumentFragment(), p = SC(h, n), g = SO(h, n);
    return {
      part: f,
      fragment: h,
      destroy: () => {
        var v;
        p(), g(), (v = f.destroy) == null || v.call(f);
      }
    };
  }, c = () => {
    o.forEach(({ destroy: u }) => u()), o = /* @__PURE__ */ new Map(), ke(e, t).forEach($e);
  };
  return (u) => {
    const d = i(r), f = i(u);
    if (d && s(r) !== s(u) && ke(e, t).forEach((h) => d.fragment.appendChild(h)), f)
      s(r) !== s(u) && Xe(f.fragment, t), f.part.commit(u);
    else {
      const h = l(u);
      a(u, h), h.part.commit(u);
    }
    return r = u, c;
  };
}), aL = Tc((e) => e, ({ startNode: e, endNode: t }) => {
  let n = null;
  const o = () => {
    ke(e, t).forEach($e);
  };
  return (r) => {
    if (n === r)
      return o;
    o();
    const s = document.createElement("template");
    return s.innerHTML = r, Xe(s.content, t), n = r, o;
  };
});
var _r;
const lL = Tc((e, t, n) => (e.length, [e, t, n]), ({ startNode: e, endNode: t }) => {
  let n = [];
  const o = () => {
    n.forEach((r) => r.destroy());
  };
  return ([r, s, i]) => {
    const a = QO(r, s, i), l = a.values, c = Jm(KO(n), a, {
      strict: !0
    }), u = { length: l.length };
    return c.update.forEach(({ action: d, from: f, to: h }) => {
      switch (d) {
        case Rt.create:
          const p = document.createComment("");
          h === 0 ? et(p, e) : n.length ? et(p, u[h - 1] ? u[h - 1].endNode : n[h - 1].endNode) : Xe(p, t), u[h] = new YO(p, l[h].value, l[h].key);
          break;
        case Rt.move:
          if (u[h] = n[f], h === f)
            return;
          h === 0 ? n[f].insert("after", e) : n[f].insert("after", u[h - 1] ? u[h - 1].endNode : n[h - 1].endNode);
          break;
      }
    }), c.delete.forEach(({ from: d }) => n[d].destroy()), n = Array.from(u), n.forEach((d, f) => d.commit(l[f].value)), o;
  };
});
class YO {
  constructor(t, n, o) {
    _r.set(this, void 0), Object.defineProperty(this, "startNode", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: document.createComment("")
    }), Object.defineProperty(this, "endNode", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: document.createComment("")
    }), Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "key", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Xe(this.startNode, t), et(this.endNode, t), $e(t), this.key = o, this.type = un(n), b(this, _r, zo(this.type, this.startNode, this.endNode), "f");
  }
  commit(t) {
    m(this, _r, "f").commit(t);
  }
  insert(t, n) {
    const o = [
      this.startNode,
      ...ke(this.startNode, this.endNode),
      this.endNode
    ];
    t === "before" ? o.forEach((r) => Xe(r, n)) : o.reverse().forEach((r) => et(r, n));
  }
  destroy() {
    var t, n;
    (n = (t = m(this, _r, "f")).destroy) == null || n.call(t), ke(this.startNode, this.endNode).forEach($e), this.startNode.remove(), this.endNode.remove();
  }
}
_r = /* @__PURE__ */ new WeakMap();
function KO(e) {
  const t = [], n = /* @__PURE__ */ new Map();
  return e.forEach(({ type: o, key: r }, s) => {
    const i = { type: o, key: r };
    t.push(i), n.set(i, s);
  }), {
    items: t,
    itemToIndex: n
  };
}
function QO(e, t, n) {
  const o = [], r = /* @__PURE__ */ new Map(), s = [];
  return e.forEach((i, a, l) => {
    const c = {
      key: t(i),
      value: n(i, a, l)
    }, u = { type: un(c.value), key: c.key };
    s.push(c), o.push(u), r.set(u, a);
  }), {
    items: o,
    itemToIndex: r,
    values: s
  };
}
const JO = (e) => he(e) ? e : ht(e) ? [] : Object.keys(e), XO = (e) => ht(e) || he(e) ? [] : Object.keys(e).filter((t) => {
  const n = e[t];
  return n === Number || n === String || n === Boolean || Oe(n) && !ht(n.default);
}).map((t) => {
  const n = e[t];
  return Oe(n) ? [t, Reflect.get(n, "default")] : n === Number ? [t, 0] : n === Boolean ? [t, !1] : [t, ""];
}), eE = (e) => ht(e) || he(e) ? [] : Object.keys(e).filter((t) => {
  const n = e[t];
  return rn(n) || Oe(n) && !ht(n.type);
}).map((t) => {
  const n = e[t];
  return Oe(n) ? [t, Reflect.get(n, "type")] : [t, n];
});
var A;
(function(e) {
  e.string = "string", e.whiteSpace = "whiteSpace", e.nextLine = "nextLine", e.equal = "equal", e.tilde = "tilde", e.plus = "plus", e.sharp = "sharp", e.asterisk = "asterisk", e.commercialAt = "commercialAt", e.ampersand = "ampersand", e.period = "period", e.comma = "comma", e.colon = "colon", e.semicolon = "semicolon", e.gt = "gt", e.leftBrace = "leftBrace", e.rightBrace = "rightBrace", e.leftBracket = "leftBracket", e.rightBracket = "rightBracket", e.leftParent = "leftParent", e.rightParent = "rightParent";
})(A || (A = {}));
var kt;
(function(e) {
  e.style = "style", e.multiComment = "multiComment", e.singleComment = "singleComment";
})(kt || (kt = {}));
const X = {
  doubleQuote: '"',
  singleQuote: "'",
  whiteSpace: /\s/,
  string: /\S/,
  breakString: /:|;|{|&|#|@|,|>|~|\(|\)|\[|\.|\+/,
  nextLine: `
`,
  slash: "/",
  equal: "=",
  tilde: "~",
  plus: "+",
  sharp: "#",
  asterisk: "*",
  commercialAt: "@",
  ampersand: "&",
  period: ".",
  comma: ",",
  colon: ":",
  semicolon: ";",
  gt: ">",
  leftBrace: "{",
  rightBrace: "}",
  leftBracket: "[",
  rightBracket: "]",
  leftParent: "(",
  rightParent: ")"
}, se = (e) => (t) => e === t, Fa = (e) => (t) => e.test(t), B = {
  doubleQuote: se(X.doubleQuote),
  singleQuote: se(X.singleQuote),
  whiteSpace: Fa(X.whiteSpace),
  string: Fa(X.string),
  breakString: Fa(X.breakString),
  nextLine: se(X.nextLine),
  slash: se(X.slash),
  equal: se(X.equal),
  tilde: se(X.tilde),
  plus: se(X.plus),
  sharp: se(X.sharp),
  asterisk: se(X.asterisk),
  commercialAt: se(X.commercialAt),
  ampersand: se(X.ampersand),
  period: se(X.period),
  comma: se(X.comma),
  colon: se(X.colon),
  semicolon: se(X.semicolon),
  gt: se(X.gt),
  leftBrace: se(X.leftBrace),
  rightBrace: se(X.rightBrace),
  leftBracket: se(X.leftBracket),
  rightBracket: se(X.rightBracket),
  leftParent: se(X.leftParent),
  rightParent: se(X.rightParent)
}, tE = (e) => (t) => B.slash(e[t]) && B.asterisk(e[t + 1]), nE = (e) => (t) => B.asterisk(e[t]) && B.slash(e[t + 1]), oE = (e) => (t) => B.slash(e[t]) && B.slash(e[t + 1]);
function rE(e) {
  const t = [];
  let n = 0;
  const o = () => n < e.length, r = tE(e), s = nE(e), i = oE(e), a = (l = kt.style) => {
    for (; o(); ) {
      let c = e[n];
      if (B.nextLine(c)) {
        if (t.push({ type: A.nextLine, value: c }), n++, l === kt.singleComment)
          break;
        continue;
      }
      if (B.whiteSpace(c)) {
        let u = "";
        for (; o() && B.whiteSpace(c) && !B.nextLine(c); )
          u += c, c = e[++n];
        t.push({ type: A.whiteSpace, value: u });
        continue;
      }
      if (l === kt.style) {
        if (r(n)) {
          a(kt.multiComment);
          continue;
        }
        if (i(n)) {
          a(kt.singleComment);
          continue;
        }
        if (B.colon(c)) {
          t.push({ type: A.colon, value: c });
          let u = "";
          if (c = e[++n], B.whiteSpace(c)) {
            for (u = ""; o() && B.whiteSpace(c); )
              u += c, c = e[++n];
            t.push({ type: A.whiteSpace, value: u }), u = "";
          } else
            continue;
          for (; o() && !B.semicolon(c); )
            u += c, c = e[++n];
          t.push({ type: A.string, value: u });
          continue;
        }
        if (B.leftBracket(c)) {
          t.push({ type: A.leftBracket, value: c });
          let u = "";
          for (c = e[++n]; o() && !B.equal(c) && !B.rightBracket(c); )
            u += c, c = e[++n];
          if (t.push({ type: A.string, value: u }), B.equal(c) && (t.push({ type: A.equal, value: c }), c = e[++n]), B.doubleQuote(c)) {
            let d = "";
            for (c = e[++n]; o() && !B.doubleQuote(c); )
              d += c, c = e[++n];
            t.push({ type: A.string, value: d }), c = e[++n];
          }
          if (B.singleQuote(c)) {
            let d = "";
            for (c = e[++n]; o() && !B.singleQuote(c); )
              d += c, c = e[++n];
            t.push({ type: A.string, value: d }), c = e[++n];
          }
          B.rightBracket(c) && (t.push({ type: A.rightBracket, value: c }), n++);
          continue;
        }
        if (B.commercialAt(c)) {
          t.push({ type: A.commercialAt, value: c }), n++;
          continue;
        }
        if (B.gt(c)) {
          t.push({ type: A.gt, value: c }), n++;
          continue;
        }
        if (B.tilde(c)) {
          t.push({ type: A.tilde, value: c }), n++;
          continue;
        }
        if (B.plus(c)) {
          t.push({ type: A.plus, value: c }), n++;
          continue;
        }
        if (B.sharp(c)) {
          t.push({ type: A.sharp, value: c }), n++;
          continue;
        }
        if (B.asterisk(c)) {
          t.push({ type: A.asterisk, value: c }), n++;
          continue;
        }
        if (B.ampersand(c)) {
          t.push({ type: A.ampersand, value: c }), n++;
          continue;
        }
        if (B.period(c)) {
          t.push({ type: A.period, value: c }), n++;
          continue;
        }
        if (B.comma(c)) {
          t.push({ type: A.comma, value: c }), n++;
          continue;
        }
        if (B.semicolon(c)) {
          t.push({ type: A.semicolon, value: c }), n++;
          continue;
        }
        if (B.leftBrace(c)) {
          t.push({ type: A.leftBrace, value: c }), n++;
          continue;
        }
        if (B.rightBrace(c)) {
          t.push({ type: A.rightBrace, value: c }), n++;
          continue;
        }
        if (B.leftParent(c)) {
          let u = "";
          for (; o() && !B.rightParent(c); )
            u += c, c = e[++n];
          u += c, t.push({ type: A.string, value: u }), n++;
          continue;
        }
      } else if (l === kt.multiComment) {
        if (s(n)) {
          t.push({ type: A.string, value: "*/" }), n += 2;
          break;
        }
      } else if (l === kt.singleComment && B.nextLine(c)) {
        t.push({ type: A.nextLine, value: c }), n++;
        break;
      }
      if (B.string(c)) {
        let u = "";
        for (; o() && B.string(c) && !B.breakString(c); )
          u += c, c = e[++n];
        t.push({ type: A.string, value: u });
        continue;
      }
      n++;
    }
  };
  return a(), t;
}
const uh = new RegExp(`^${sn.replace("@@", "")}_\\d+_$`), dh = (e) => (t) => (n) => (o) => n[o] ? n[o][e] === t : !1, ce = dh("type"), wc = dh("value"), dn = ce(A.string), sE = ce(A.whiteSpace), fh = ce(A.nextLine);
ce(A.equal);
ce(A.tilde);
ce(A.plus);
const iE = ce(A.sharp), aE = ce(A.asterisk), Cc = ce(A.commercialAt), lE = ce(A.ampersand), cE = ce(A.period);
ce(A.comma);
const mh = ce(A.colon), Oc = ce(A.semicolon);
ce(A.gt);
const uE = ce(A.leftBrace), dE = ce(A.rightBrace);
ce(A.leftBracket);
ce(A.rightBracket);
ce(A.leftParent);
ce(A.rightParent);
const fE = wc("/*"), mE = wc("*/"), hE = wc("//"), pE = fh, yE = (e) => {
  const t = dn(e), n = mh(e), o = sE(e), r = Oc(e);
  return (s) => t(s) && n(s + 1) && o(s + 2) && t(s + 3) && r(s + 4);
}, gE = (e) => {
  const t = Cc(e), n = dn(e), o = Oc(e), r = fh(e);
  return (s) => t(s) && t(s + 1) && n(s + 2) && uh.test(e[s + 2].value) && (r(s + 3) || o(s + 3));
}, hh = (e) => {
  const t = Cc(e), n = dn(e);
  return (o) => t(o) && n(o + 1);
}, vE = (e) => {
  const t = mh(e), n = dn(e);
  return (o) => t(o) && n(o + 1);
}, bE = dn, IE = aE, TE = (e) => {
  const t = Cc(e), n = dn(e);
  return (o) => t(o) && t(o + 1) && n(o + 2) && uh.test(e[o + 2].value);
}, wE = (e) => {
  const t = bE(e), n = IE(e), o = lE(e), r = TE(e);
  return (s) => t(s) || n(s) || o(s) || r(s);
}, CE = (e) => {
  const t = cE(e), n = dn(e);
  return (o) => t(o) && n(o + 1);
}, OE = (e) => {
  const t = iE(e), n = dn(e);
  return (o) => t(o) && n(o + 1);
}, EE = (e) => {
  const t = wE(e), n = CE(e), o = OE(e), r = hh(e), s = vE(e);
  return (i) => t(i) || n(i) || o(i) || r(i) || s(i);
}, ph = (e) => (t) => (n) => {
  var o;
  n && (t[e] ? (o = t[e]) == null || o.push(n) : t[e] = [n]);
}, hd = ph("properties"), dr = ph("children");
var Ne;
(function(e) {
  e.style = "style", e.comment = "comment", e.atRule = "atRule";
})(Ne || (Ne = {}));
let Rs = class {
  constructor(t = {}) {
    Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: Ne.comment
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "properties", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "parent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: null
    }), Object.defineProperty(this, "children", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.assign(this, t);
  }
  *iterParent() {
    yield this, this.parent && (yield* this.parent.iterParent());
  }
  *[Symbol.iterator]() {
    if (yield this, !!this.children)
      for (const t of this.children)
        yield* t;
  }
};
function SE(e) {
  let t = 0;
  const n = () => t < e.length, o = yE(e), r = gE(e), s = EE(e), i = uE(e), a = dE(e), l = fE(e), c = mE(e), u = hE(e), d = pE(e), f = hh(e), h = Oc(e), p = (g, v, T = Ne.style) => {
    const O = new Rs({
      type: T,
      parent: g,
      value: v
    });
    for (; n(); ) {
      let k = e[t];
      if (a(t)) {
        t++;
        break;
      }
      if (o(t)) {
        const R = e[t + 3];
        hd(O)({
          name: k.value,
          value: R.value
        }), t += 5;
        continue;
      } else if (r(t)) {
        const R = `@@${e[t + 2].value}`;
        hd(O)({
          name: R,
          value: R
        }), t += 4;
        continue;
      } else if (l(t)) {
        let R = k.value;
        for (k = e[++t]; n() && !c(t); )
          R += k.value, k = e[++t];
        R += k.value, dr(O)(new Rs({
          type: Ne.comment,
          parent: O,
          value: R
        })), t++;
        continue;
      } else if (u(t)) {
        let R = k.value;
        for (k = e[++t]; n() && !d(t); )
          R += k.value, k = e[++t];
        dr(O)(new Rs({
          type: Ne.comment,
          parent: O,
          value: R
        })), t++;
        continue;
      } else if (f(t)) {
        let R = k.value;
        for (k = e[++t]; n() && !i(t) && !h(t); )
          R += k.value, k = e[++t];
        if (h(t)) {
          R += ";", dr(O)(new Rs({
            type: Ne.atRule,
            parent: O,
            value: R
          })), t++;
          continue;
        }
        dr(O)(p(O, R.trim(), Ne.atRule));
        continue;
      } else if (s(t)) {
        let R = k.value;
        for (k = e[++t]; n() && !i(t); )
          R += k.value, k = e[++t];
        dr(O)(p(O, R.trim()));
        continue;
      }
      t++;
    }
    return O;
  };
  return p(null);
}
const xE = (e) => SE(rE(e));
var ye;
(function(e) {
  e.string = "string", e.whiteSpace = "whiteSpace", e.lt = "lt", e.gt = "gt", e.slash = "slash", e.equal = "equal";
})(ye || (ye = {}));
var tn;
(function(e) {
  e.element = "element", e.text = "text", e.comment = "comment";
})(tn || (tn = {}));
const rt = {
  doubleQuote: '"',
  singleQuote: "'",
  whiteSpace: /\s/,
  string: /\S/,
  breakString: /<|>|=/,
  lt: "<",
  gt: ">",
  slash: "/",
  equal: "=",
  hyphen: "-",
  exclamationPoint: "!"
}, Vt = (e) => (t) => e === t, qa = (e) => (t) => e.test(t), ue = {
  doubleQuote: Vt(rt.doubleQuote),
  singleQuote: Vt(rt.singleQuote),
  whiteSpace: qa(rt.whiteSpace),
  string: qa(rt.string),
  breakString: qa(rt.breakString),
  lt: Vt(rt.lt),
  gt: Vt(rt.gt),
  slash: Vt(rt.slash),
  equal: Vt(rt.equal),
  hyphen: Vt(rt.hyphen),
  exclamationPoint: Vt(rt.exclamationPoint)
}, AE = (e) => (t) => ue.lt(e[t]) && ue.exclamationPoint(e[t + 1]) && ue.hyphen(e[t + 2]) && ue.hyphen(e[t + 3]);
function ME(e) {
  const t = [];
  let n = 0;
  const o = () => n < e.length, r = AE(e), s = (i = tn.text) => {
    for (; o(); ) {
      let a = e[n];
      if (ue.whiteSpace(a)) {
        let l = "";
        for (; o() && ue.whiteSpace(a); )
          l += a, a = e[++n];
        i !== tn.element && t.push({ type: ye.whiteSpace, value: l });
        continue;
      }
      if (i === tn.element) {
        if (ue.lt(a)) {
          t.push({ type: ye.lt, value: a }), n++;
          continue;
        }
        if (ue.gt(a)) {
          t.push({ type: ye.gt, value: a }), n++;
          break;
        }
        if (ue.slash(a)) {
          t.push({ type: ye.slash, value: a }), n++;
          continue;
        }
        if (ue.equal(a)) {
          t.push({ type: ye.equal, value: a }), n++;
          continue;
        }
        if (ue.doubleQuote(a)) {
          let l = "";
          for (a = e[++n]; o() && !ue.doubleQuote(a); )
            l += a, a = e[++n];
          t.push({ type: ye.string, value: l }), n++;
          continue;
        }
        if (ue.singleQuote(a)) {
          let l = "";
          for (a = e[++n]; o() && !ue.singleQuote(a); )
            l += a, a = e[++n];
          t.push({ type: ye.string, value: l }), n++;
          continue;
        }
      } else if (i === tn.comment) {
        if (ue.lt(a)) {
          t.push({ type: ye.lt, value: a }), n++;
          continue;
        }
        if (ue.gt(a)) {
          t.push({ type: ye.gt, value: a }), n++;
          break;
        }
      } else if (ue.lt(a)) {
        s(r(n) ? tn.comment : tn.element);
        continue;
      }
      if (ue.string(a)) {
        let l = "";
        for (; o() && ue.string(a) && !ue.breakString(a); )
          l += a, a = e[++n];
        t.push({ type: ye.string, value: l });
        continue;
      }
      n++;
    }
  };
  return s(), t;
}
const NE = /^(area|base|br|col|embed|hr|img|input|keygen|link|meta|param|source|track|wbr)$/i, yh = (e) => (t) => (n) => (o) => n[o] ? n[o][e] === t : !1, Go = yh("type"), gh = yh("value"), ds = Go(ye.lt), fs = Go(ye.gt), Ec = Go(ye.slash), $E = Go(ye.equal), ms = Go(ye.string), kE = Go(ye.whiteSpace), DE = gh("!--"), RE = gh("--"), LE = (e) => {
  const t = ds(e), n = ms(e);
  return (o) => t(o) && n(o + 1);
}, PE = (e) => {
  const t = ds(e), n = Ec(e), o = ms(e), r = fs(e);
  return (s) => t(s) && n(s + 1) && o(s + 2) && r(s + 3);
}, _E = (e) => {
  const t = ds(e), n = Ec(e), o = fs(e);
  return (r) => t(r) && n(r + 1) && n(r + 2) && o(r + 3);
}, BE = (e) => {
  const t = Ec(e), n = fs(e);
  return (o) => t(o) && n(o + 1);
}, WE = (e) => NE.test(e), UE = (e) => {
  const t = ds(e), n = ms(e), o = DE(e);
  return (r) => t(r) && n(r + 1) && o(r + 1);
}, jE = (e) => {
  const t = ms(e), n = RE(e), o = fs(e);
  return (r) => t(r) && n(r) && o(r + 1);
}, vh = (e) => (t) => (n) => {
  var o;
  n && (t[e] ? (o = t[e]) == null || o.push(n) : t[e] = [n]);
}, FE = vh("attrs"), pd = vh("children");
function qE(e) {
  const t = new Sn({
    type: Ce.element,
    value: "template",
    children: []
  });
  let n = 0;
  const o = () => n < e.length, r = LE(e), s = PE(e), i = _E(e), a = BE(e), l = UE(e), c = jE(e), u = ds(e), d = fs(e), f = $E(e), h = kE(e), p = ms(e), g = () => {
    let T = e[n];
    const O = { name: T.value };
    return T = e[++n], f(n) && (T = e[++n], p(n) && (O.value = T.value, n++)), O;
  }, v = (T) => {
    let O = e[n];
    if (h(n) || p(n)) {
      let k = O.value;
      for (O = e[++n]; o() && !u(n); )
        k += O.value, O = e[++n];
      return k = k.trim(), k.length ? new Sn({ parent: T, type: Ce.text, value: k }) : null;
    }
    if (l(n)) {
      let k = "";
      for (n += 2, O = e[n]; o() && !c(n); )
        k += O.value, O = e[++n];
      return c(n) && (n += 2), new Sn({ parent: T, type: Ce.comment, value: k });
    }
    if (r(n)) {
      O = e[++n];
      const k = WE(O.value), R = new Sn({
        parent: T,
        type: Ce.element,
        value: O.value.toLowerCase()
      });
      for (n++; o() && !d(n) && !a(n); ) {
        if (p(n)) {
          FE(R)(g());
          continue;
        }
        n++;
      }
      if (a(n))
        return n += 2, R;
      for (O = e[++n]; o() && !s(n) && !i(n) && !k; )
        pd(R)(v(R));
      return (s(n) || i(n)) && (n += 4), R;
    }
    return n++, null;
  };
  for (; o(); )
    pd(t)(v(t));
  return t;
}
const HE = (e) => qE(ME(e)), ZE = (e, t) => e.reduce((n, o) => {
  const r = n[t(o)];
  return r ? r.push(o) : n[t(o)] = [o], n;
}, {});
class Io {
  get isMarker() {
    return ko(this.value);
  }
  get isMarkerOnly() {
    return us(this.value);
  }
  get isSvg() {
    return this.type === Ce.element && /^svg$/i.test(this.value);
  }
  get isComponent() {
    return this.type === Ce.element && this.isMarkerOnly;
  }
  constructor(t, n = null) {
    if (Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: Ce.comment
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: ""
    }), Object.defineProperty(this, "staticAttrs", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "attrs", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "parent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: null
    }), Object.defineProperty(this, "children", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), this.type = t.type, this.value = t.value, this.parent = n, t.attrs) {
      const [o, r] = GE(t.attrs);
      o.length && (this.staticAttrs = o), r.length && (this.attrs = r);
    }
    t.children && (this.children = t.children.map((o) => new Io(o, this)));
  }
  insert(t, n, o) {
    if (this.children) {
      const r = t === "before" ? 0 : 1;
      this.children.includes(o) && this.children.splice(this.children.indexOf(o) + r, 0, n);
    } else
      this.children = [n];
  }
  *iterParent() {
    yield this, this.parent && (yield* this.parent.iterParent());
  }
  *[Symbol.iterator]() {
    if (yield this, !!this.children)
      for (const t of this.children)
        yield* t;
  }
}
function VE(e) {
  const t = new Io(e);
  for (const n of t)
    n.type === Ce.text && !us(n.value) && zE(n);
  return t;
}
function zE(e) {
  const t = Ie(e.value);
  e.value.replace(gl, sn).split(sn).reduce((n, o, r) => (r < t.length ? n.push(new Io(new Sn({ type: Ce.text, value: o }), e.parent), new Io(new Sn({ type: Ce.text, value: t[r][0] }), e.parent)) : n.push(new Io(new Sn({ type: Ce.text, value: o }), e.parent)), n), []).filter((n) => n.value !== "" && !(!n.value.trim() && zC.test(n.value))).reverse().forEach((n, o, { length: r }) => o === r - 1 ? e.value = n.value : e.parent && e.parent.insert("after", n, e));
}
function GE(e = []) {
  const t = ZE(e, (n) => Ds(n.name));
  return Object.keys(t).map((n) => t[n]).reduce((n, o) => {
    const [r, s] = n, i = o[o.length - 1], a = dd(i.name);
    if (a === J.event)
      s.push(...o.filter((l) => !!l.value).map((l) => ({
        type: dd(l.name),
        name: Ds(l.name),
        value: l.value
      })));
    else if (a === J.attribute) {
      const l = o.filter((u) => !!u.value).map((u) => u.value).join(" "), c = { type: a, name: Ds(i.name) };
      l && (c.value = l), ud(c) ? s.push(c) : r.push(c);
    } else {
      const l = { type: a, name: Ds(i.name) };
      i.value && (l.value = i.value), ud(l) ? s.push(l) : r.push(l);
    }
    return n;
  }, [[], []]);
}
const bh = (e) => (t, ...n) => {
  const o = {
    strings: t,
    values: n,
    [Nn]: e
  };
  if (Ua.has(t)) {
    const i = Ua.get(t);
    return o.template = i, o;
  }
  const r = t.reduce((i, a, l) => (l < n.length ? i.push(a, jm(l)) : i.push(a), i), []).join(""), s = VE(HE(r));
  return o.template = Object.freeze({ node: s }), Ua.set(t, o.template), o;
}, YE = bh(St.html), uL = bh(St.svg), Ih = (e) => as(e) && !jt(e) && !ht(e) ? String(e) : Ia(e) ? `.${String(e)}` : "", Th = (e) => (t, [n, o]) => t.replace(new RegExp(n), Ih(e[o]));
class Sc {
  get selector() {
    return [...this.iterParent()].reverse().map((t) => t.toString(!0)).join(" ");
  }
  get isAtRule() {
    return this.type === Ne.atRule;
  }
  constructor(t, n = null, o) {
    var r;
    if (Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: Ne.comment
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "style", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: ""
    }), Object.defineProperty(this, "parent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: null
    }), Object.defineProperty(this, "children", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "skipParent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: !1
    }), this.type = t.type, this.value = t.value, this.parent = n, t.value && t.isMarker) {
      const a = Th(o), l = Ie(t.value).reduce(a, t.value);
      this.value = l;
    }
    this.value && t.isThis && this.parent && (this.value = this.value.replace(/\&/, (r = this.parent) == null ? void 0 : r.toString(!0)), this.skipParent = !0), this.value && t.isAtRule && this.parent && (this.skipParent = !0);
    const [s, i] = wh(t, o);
    if (t.children && i.push(...t.children.map((a) => [a, o])), this.style = s, this.children = i.filter(([a]) => a.type === Ne.style || a.type === Ne.atRule).map(([a, l]) => new Sc(a, this, l)), this.value && t.isAtRule && this.parent && t.children) {
      let a = "";
      for (const l of this.children)
        l.value && (a += `${l.value} {
${l.style}}
`);
      this.style = a;
    }
  }
  toString(t = !1) {
    return this.value ? this.value : t ? `.${yd(this.style)}` : yd(this.style);
  }
  *iterParent() {
    if (yield this, !!this.parent)
      if (this.skipParent) {
        if (!this.parent.parent)
          return;
        yield* this.parent.parent.iterParent();
      } else
        yield* this.parent.iterParent();
  }
  *[Symbol.iterator]() {
    if (yield this, !!this.children)
      for (const t of this.children)
        t.isAtRule ? yield t : yield* t;
  }
}
const Ha = /* @__PURE__ */ new Map();
function yd(e) {
  if (Ha.has(e))
    return Ha.get(e);
  const t = vO();
  return Ha.set(e, t), t;
}
const Ls = (e, t) => `${e}: ${t};
`;
function wh(e, t, n = ["", []]) {
  var o;
  const r = Th(t);
  return (o = e.properties) == null || o.forEach((s) => {
    if (s.isDynamic) {
      const i = Ie(s.name), a = t[i[0][1]];
      Ia(a) ? (a.template.node.children && n[1].push(...a.template.node.children.map((l) => [
        l,
        a.values
      ])), wh(a.template.node, a.values, n)) : n[0] += Ih(a);
    } else if (s.isMarkerName && s.isMarkerValue) {
      const i = Ie(s.name), a = Ie(s.value), l = i.reduce(r, s.name), c = a.reduce(r, s.value);
      n[0] += Ls(l, c);
    } else if (s.isMarkerName) {
      const i = Ie(s.name).reduce(r, s.name);
      n[0] += Ls(i, s.value);
    } else if (s.isMarkerValue) {
      const i = Ie(s.value).reduce(r, s.value);
      n[0] += Ls(s.name, i);
    } else
      n[0] += Ls(s.name, s.value);
  }), n;
}
const KE = {
  vCSSStyleSheetMap: /* @__PURE__ */ new Map(),
  hostContextMap: /* @__PURE__ */ new Map()
};
let Bi = globalThis.ShadowRoot && "adoptedStyleSheets" in Document.prototype && "replace" in CSSStyleSheet.prototype;
function hs() {
  return KE;
}
function gd(e, t) {
  const n = hs(), o = new Sc(e, null, t);
  return [...o].forEach((r) => {
    const s = r.selector;
    if (n.vCSSStyleSheetMap.has(s))
      return;
    const i = Bi ? new CSSStyleSheet() : null, a = Bi ? null : document.createElement("style"), l = r.isAtRule && !r.style ? `${s}` : `${s} {
${r.style}}`;
    i ? i.replaceSync(l) : a && (a.textContent = l), n.vCSSStyleSheetMap.set(s, {
      selector: s,
      cssText: l,
      style: r.style,
      sheet: i,
      styleElement: a
    }), Ch();
  }), String(o);
}
function Ch() {
  Bi ? QE() : JE();
}
function QE() {
  const e = hs(), t = Array.from(e.vCSSStyleSheetMap).map(([, { sheet: n }]) => n).filter(Boolean);
  Array.from(e.hostContextMap).forEach(([n]) => {
    n.adoptedStyleSheets = t;
  });
}
function JE() {
  const e = hs();
  Array.from(e.hostContextMap).forEach(([t, { vSheets: n, styleElements: o }]) => {
    Array.from(e.vCSSStyleSheetMap).filter(([, r]) => !n.has(r)).map(([, r]) => (n.add(r), r.styleElement ? document.importNode(r.styleElement, !0) : null)).filter(Boolean).forEach((r) => {
      t.appendChild(r), o.add(r);
    });
  });
}
function XE(e) {
  const t = hs();
  t.hostContextMap.has(e) || (t.hostContextMap.set(e, {
    vSheets: /* @__PURE__ */ new Set(),
    styleElements: /* @__PURE__ */ new Set()
  }), Ch());
}
function eS(e) {
  const t = hs(), n = t.hostContextMap.get(e);
  n && (Bi ? e.adoptedStyleSheets = [] : n.styleElements.forEach((o) => e.removeChild(o)), t.hostContextMap.delete(e));
}
const pn = Symbol.for("https://github.com/dineug/r-html#props");
function dL(e, t) {
  var n, o, r, s, i;
  t.shadow ?? (t.shadow = "open");
  const a = JO(t.observedProps), l = eE(t.observedProps), c = XO(t.observedProps), u = (i = class extends HTMLElement {
    static get observedAttributes() {
      return Array.from(/* @__PURE__ */ new Set([
        ...a,
        ...a.map((d) => fO(d))
      ]));
    }
    constructor() {
      super(), Object.defineProperty(this, s, {
        enumerable: !0,
        configurable: !0,
        writable: !0,
        value: cs({}, { shallow: !0 })
      }), n.set(this, null), o.set(this, this), r.set(this, void 0), Object.defineProperty(this, "host", {
        enumerable: !0,
        configurable: !0,
        writable: !0,
        value: this
      }), c.forEach(([d, f]) => Reflect.set(this[pn], ir(d), f)), t.shadow && b(this, o, this.attachShadow({ mode: t.shadow }), "f"), Pi(this), b(this, r, t.render.call(this, this[pn], this), "f"), Pi(null);
    }
    connectedCallback() {
      const d = this.getRootNode();
      d instanceof ShadowRoot && (this.host = d.host), m(this, o, "f") instanceof ShadowRoot && XE(m(this, o, "f")), Fe(this, ha);
      let f = !1;
      b(this, n, bc(() => {
        Fe(this, f ? ga : yc), zO(m(this, o, "f"), YE`${m(this, r, "f").call(this)}`), f ? Fe(this, va) : (Fe(this, gc), f = !0);
      }), "f"), Fe(this, pa);
    }
    disconnectedCallback() {
      var d;
      (d = m(this, n, "f")) == null || d.call(this), b(this, n, null, "f"), Fe(this, ya), m(this, o, "f") instanceof ShadowRoot && eS(m(this, o, "f"));
    }
    attributeChangedCallback(d, f, h) {
      const p = l.find(([g]) => ir(g) === ir(d));
      p ? Reflect.set(this[pn], ir(d), p[1] === Boolean ? h === "true" || h === "" : p[1](h)) : Reflect.set(this[pn], ir(d), h);
    }
  }, n = /* @__PURE__ */ new WeakMap(), o = /* @__PURE__ */ new WeakMap(), r = /* @__PURE__ */ new WeakMap(), s = pn, i);
  a.forEach((d) => {
    Object.defineProperty(u.prototype, d, {
      get() {
        return Reflect.get(this[pn], d);
      },
      set(f) {
        Reflect.set(this[pn], d, f);
      }
    });
  }), customElements.define(e, u);
}
function I(e) {
  function t(n) {
    return {
      type: e,
      payload: n,
      timestamp: Date.now()
    };
  }
  return t.toString = () => `${e}`, t.type = e, t;
}
function* Cl(e, t, n) {
  for (const o of n)
    o != null && o[Symbol.iterator] ? yield* Cl(e, t, o) : rn(o) ? yield* Cl(e, t, o(e, t)) : yield o;
}
const Oh = (e, t, n) => [...Cl(e, t, n)], Za = (...e) => (t) => e.reduce((n, o) => o(n), t);
function* tS(e) {
  yield e;
}
const Va = function* (e) {
  for (const t of e)
    t.length && (yield t);
};
function nS({ context: e, state: t, reducers: n, enableObservable: o = !0 }) {
  const r = o ? cs(t) : t, s = Qr(), i = Qr();
  let a = Za(Va);
  const l = (g) => {
    const v = Reflect.get(n, g.type, n);
    fa(v, r, g, e);
  }, c = (...g) => {
    const v = Oh(r, e, g);
    s.next(v);
  }, u = (...g) => {
    Rm(() => c(...g));
  }, d = s.subscribe((g) => {
    const v = a(tS(g));
    for (const T of v)
      i.next(T);
  }), f = i.subscribe((g) => g.forEach(l)), h = (...g) => (a = Za(...g, Va), () => {
    a = Za(Va);
  }), p = () => {
    d(), f();
  };
  return {
    context: e,
    state: r,
    dispatch: u,
    dispatchSync: c,
    subscribe: i.subscribe,
    pipe: h,
    destroy: p
  };
}
class oS {
  get isMarkerName() {
    return ko(this.name);
  }
  get isMarkerValue() {
    return ko(this.value);
  }
  get isDynamic() {
    return us(this.name) && this.name === this.value;
  }
  constructor(t) {
    Object.defineProperty(this, "name", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), this.name = t.name, this.value = t.value;
  }
}
class xc {
  get isMarker() {
    return ko(this.value);
  }
  get isThis() {
    var t;
    return !!((t = this.value) != null && t.startsWith("&"));
  }
  get isAtRule() {
    return this.type === Ne.atRule;
  }
  constructor(t, n = null) {
    Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: Ne.comment
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "properties", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "parent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: null
    }), Object.defineProperty(this, "children", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), this.type = t.type, this.value = t.value, this.parent = n, t.properties && (this.properties = t.properties.map((o) => new oS(o))), t.children && (this.children = t.children.map((o) => new xc(o, this)));
  }
  *iterParent() {
    yield this, this.parent && (yield* this.parent.iterParent());
  }
  *[Symbol.iterator]() {
    if (yield this, !!this.children)
      for (const t of this.children)
        yield* t;
  }
}
function rS(e) {
  return new xc(e);
}
const fL = (e, ...t) => {
  const n = {
    strings: e,
    values: t,
    [Nn]: St.css
  };
  if (ja.has(e)) {
    const i = ja.get(e), a = gd(i.node, t);
    return n.template = i, n.toString = () => a, n;
  }
  const o = e.raw.reduce((i, a, l) => (l < t.length ? i.push(a, jm(l)) : i.push(a), i), []).join(""), r = rS(xE(o)), s = gd(r, t);
  return n.template = Object.freeze({ node: r }), n.toString = () => s, ja.set(e, n.template), n;
};
var Ol = function(e, t) {
  return Ol = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(n, o) {
    n.__proto__ = o;
  } || function(n, o) {
    for (var r in o)
      Object.prototype.hasOwnProperty.call(o, r) && (n[r] = o[r]);
  }, Ol(e, t);
};
function fn(e, t) {
  if (typeof t != "function" && t !== null)
    throw new TypeError("Class extends value " + String(t) + " is not a constructor or null");
  Ol(e, t);
  function n() {
    this.constructor = e;
  }
  e.prototype = t === null ? Object.create(t) : (n.prototype = t.prototype, new n());
}
function sS(e, t, n, o) {
  function r(s) {
    return s instanceof n ? s : new n(function(i) {
      i(s);
    });
  }
  return new (n || (n = Promise))(function(s, i) {
    function a(u) {
      try {
        c(o.next(u));
      } catch (d) {
        i(d);
      }
    }
    function l(u) {
      try {
        c(o.throw(u));
      } catch (d) {
        i(d);
      }
    }
    function c(u) {
      u.done ? s(u.value) : r(u.value).then(a, l);
    }
    c((o = o.apply(e, t || [])).next());
  });
}
function Eh(e, t) {
  var n = { label: 0, sent: function() {
    if (s[0] & 1)
      throw s[1];
    return s[1];
  }, trys: [], ops: [] }, o, r, s, i;
  return i = { next: a(0), throw: a(1), return: a(2) }, typeof Symbol == "function" && (i[Symbol.iterator] = function() {
    return this;
  }), i;
  function a(c) {
    return function(u) {
      return l([c, u]);
    };
  }
  function l(c) {
    if (o)
      throw new TypeError("Generator is already executing.");
    for (; i && (i = 0, c[0] && (n = 0)), n; )
      try {
        if (o = 1, r && (s = c[0] & 2 ? r.return : c[0] ? r.throw || ((s = r.return) && s.call(r), 0) : r.next) && !(s = s.call(r, c[1])).done)
          return s;
        switch (r = 0, s && (c = [c[0] & 2, s.value]), c[0]) {
          case 0:
          case 1:
            s = c;
            break;
          case 4:
            return n.label++, { value: c[1], done: !1 };
          case 5:
            n.label++, r = c[1], c = [0];
            continue;
          case 7:
            c = n.ops.pop(), n.trys.pop();
            continue;
          default:
            if (s = n.trys, !(s = s.length > 0 && s[s.length - 1]) && (c[0] === 6 || c[0] === 2)) {
              n = 0;
              continue;
            }
            if (c[0] === 3 && (!s || c[1] > s[0] && c[1] < s[3])) {
              n.label = c[1];
              break;
            }
            if (c[0] === 6 && n.label < s[1]) {
              n.label = s[1], s = c;
              break;
            }
            if (s && n.label < s[2]) {
              n.label = s[2], n.ops.push(c);
              break;
            }
            s[2] && n.ops.pop(), n.trys.pop();
            continue;
        }
        c = t.call(e, n);
      } catch (u) {
        c = [6, u], r = 0;
      } finally {
        o = s = 0;
      }
    if (c[0] & 5)
      throw c[1];
    return { value: c[0] ? c[1] : void 0, done: !0 };
  }
}
function Ro(e) {
  var t = typeof Symbol == "function" && Symbol.iterator, n = t && e[t], o = 0;
  if (n)
    return n.call(e);
  if (e && typeof e.length == "number")
    return {
      next: function() {
        return e && o >= e.length && (e = void 0), { value: e && e[o++], done: !e };
      }
    };
  throw new TypeError(t ? "Object is not iterable." : "Symbol.iterator is not defined.");
}
function Wi(e, t) {
  var n = typeof Symbol == "function" && e[Symbol.iterator];
  if (!n)
    return e;
  var o = n.call(e), r, s = [], i;
  try {
    for (; (t === void 0 || t-- > 0) && !(r = o.next()).done; )
      s.push(r.value);
  } catch (a) {
    i = { error: a };
  } finally {
    try {
      r && !r.done && (n = o.return) && n.call(o);
    } finally {
      if (i)
        throw i.error;
    }
  }
  return s;
}
function Ui(e, t, n) {
  if (n || arguments.length === 2)
    for (var o = 0, r = t.length, s; o < r; o++)
      (s || !(o in t)) && (s || (s = Array.prototype.slice.call(t, 0, o)), s[o] = t[o]);
  return e.concat(s || Array.prototype.slice.call(t));
}
function To(e) {
  return this instanceof To ? (this.v = e, this) : new To(e);
}
function iS(e, t, n) {
  if (!Symbol.asyncIterator)
    throw new TypeError("Symbol.asyncIterator is not defined.");
  var o = n.apply(e, t || []), r, s = [];
  return r = {}, i("next"), i("throw"), i("return"), r[Symbol.asyncIterator] = function() {
    return this;
  }, r;
  function i(f) {
    o[f] && (r[f] = function(h) {
      return new Promise(function(p, g) {
        s.push([f, h, p, g]) > 1 || a(f, h);
      });
    });
  }
  function a(f, h) {
    try {
      l(o[f](h));
    } catch (p) {
      d(s[0][3], p);
    }
  }
  function l(f) {
    f.value instanceof To ? Promise.resolve(f.value.v).then(c, u) : d(s[0][2], f);
  }
  function c(f) {
    a("next", f);
  }
  function u(f) {
    a("throw", f);
  }
  function d(f, h) {
    f(h), s.shift(), s.length && a(s[0][0], s[0][1]);
  }
}
function aS(e) {
  if (!Symbol.asyncIterator)
    throw new TypeError("Symbol.asyncIterator is not defined.");
  var t = e[Symbol.asyncIterator], n;
  return t ? t.call(e) : (e = typeof Ro == "function" ? Ro(e) : e[Symbol.iterator](), n = {}, o("next"), o("throw"), o("return"), n[Symbol.asyncIterator] = function() {
    return this;
  }, n);
  function o(s) {
    n[s] = e[s] && function(i) {
      return new Promise(function(a, l) {
        i = e[s](i), r(a, l, i.done, i.value);
      });
    };
  }
  function r(s, i, a, l) {
    Promise.resolve(l).then(function(c) {
      s({ value: c, done: a });
    }, i);
  }
}
function mL(e, t, n, o) {
  if (n === "a" && !o)
    throw new TypeError("Private accessor was defined without a getter");
  if (typeof t == "function" ? e !== t || !o : !t.has(e))
    throw new TypeError("Cannot read private member from an object whose class did not declare it");
  return n === "m" ? o : n === "a" ? o.call(e) : o ? o.value : t.get(e);
}
function ve(e) {
  return typeof e == "function";
}
function Sh(e) {
  var t = function(o) {
    Error.call(o), o.stack = new Error().stack;
  }, n = e(t);
  return n.prototype = Object.create(Error.prototype), n.prototype.constructor = n, n;
}
var za = Sh(function(e) {
  return function(n) {
    e(this), this.message = n ? n.length + ` errors occurred during unsubscription:
` + n.map(function(o, r) {
      return r + 1 + ") " + o.toString();
    }).join(`
  `) : "", this.name = "UnsubscriptionError", this.errors = n;
  };
});
function ji(e, t) {
  if (e) {
    var n = e.indexOf(t);
    0 <= n && e.splice(n, 1);
  }
}
var ps = function() {
  function e(t) {
    this.initialTeardown = t, this.closed = !1, this._parentage = null, this._finalizers = null;
  }
  return e.prototype.unsubscribe = function() {
    var t, n, o, r, s;
    if (!this.closed) {
      this.closed = !0;
      var i = this._parentage;
      if (i)
        if (this._parentage = null, Array.isArray(i))
          try {
            for (var a = Ro(i), l = a.next(); !l.done; l = a.next()) {
              var c = l.value;
              c.remove(this);
            }
          } catch (g) {
            t = { error: g };
          } finally {
            try {
              l && !l.done && (n = a.return) && n.call(a);
            } finally {
              if (t)
                throw t.error;
            }
          }
        else
          i.remove(this);
      var u = this.initialTeardown;
      if (ve(u))
        try {
          u();
        } catch (g) {
          s = g instanceof za ? g.errors : [g];
        }
      var d = this._finalizers;
      if (d) {
        this._finalizers = null;
        try {
          for (var f = Ro(d), h = f.next(); !h.done; h = f.next()) {
            var p = h.value;
            try {
              vd(p);
            } catch (g) {
              s = s ?? [], g instanceof za ? s = Ui(Ui([], Wi(s)), Wi(g.errors)) : s.push(g);
            }
          }
        } catch (g) {
          o = { error: g };
        } finally {
          try {
            h && !h.done && (r = f.return) && r.call(f);
          } finally {
            if (o)
              throw o.error;
          }
        }
      }
      if (s)
        throw new za(s);
    }
  }, e.prototype.add = function(t) {
    var n;
    if (t && t !== this)
      if (this.closed)
        vd(t);
      else {
        if (t instanceof e) {
          if (t.closed || t._hasParent(this))
            return;
          t._addParent(this);
        }
        (this._finalizers = (n = this._finalizers) !== null && n !== void 0 ? n : []).push(t);
      }
  }, e.prototype._hasParent = function(t) {
    var n = this._parentage;
    return n === t || Array.isArray(n) && n.includes(t);
  }, e.prototype._addParent = function(t) {
    var n = this._parentage;
    this._parentage = Array.isArray(n) ? (n.push(t), n) : n ? [n, t] : t;
  }, e.prototype._removeParent = function(t) {
    var n = this._parentage;
    n === t ? this._parentage = null : Array.isArray(n) && ji(n, t);
  }, e.prototype.remove = function(t) {
    var n = this._finalizers;
    n && ji(n, t), t instanceof e && t._removeParent(this);
  }, e.EMPTY = function() {
    var t = new e();
    return t.closed = !0, t;
  }(), e;
}(), xh = ps.EMPTY;
function Ah(e) {
  return e instanceof ps || e && "closed" in e && ve(e.remove) && ve(e.add) && ve(e.unsubscribe);
}
function vd(e) {
  ve(e) ? e() : e.unsubscribe();
}
var Mh = {
  onUnhandledError: null,
  onStoppedNotification: null,
  Promise: void 0,
  useDeprecatedSynchronousErrorHandling: !1,
  useDeprecatedNextContext: !1
}, Nh = {
  setTimeout: function(e, t) {
    for (var n = [], o = 2; o < arguments.length; o++)
      n[o - 2] = arguments[o];
    return setTimeout.apply(void 0, Ui([e, t], Wi(n)));
  },
  clearTimeout: function(e) {
    var t = Nh.delegate;
    return ((t == null ? void 0 : t.clearTimeout) || clearTimeout)(e);
  },
  delegate: void 0
};
function $h(e) {
  Nh.setTimeout(function() {
    throw e;
  });
}
function El() {
}
function xi(e) {
  e();
}
var Ac = function(e) {
  fn(t, e);
  function t(n) {
    var o = e.call(this) || this;
    return o.isStopped = !1, n ? (o.destination = n, Ah(n) && n.add(o)) : o.destination = dS, o;
  }
  return t.create = function(n, o, r) {
    return new Sl(n, o, r);
  }, t.prototype.next = function(n) {
    this.isStopped || this._next(n);
  }, t.prototype.error = function(n) {
    this.isStopped || (this.isStopped = !0, this._error(n));
  }, t.prototype.complete = function() {
    this.isStopped || (this.isStopped = !0, this._complete());
  }, t.prototype.unsubscribe = function() {
    this.closed || (this.isStopped = !0, e.prototype.unsubscribe.call(this), this.destination = null);
  }, t.prototype._next = function(n) {
    this.destination.next(n);
  }, t.prototype._error = function(n) {
    try {
      this.destination.error(n);
    } finally {
      this.unsubscribe();
    }
  }, t.prototype._complete = function() {
    try {
      this.destination.complete();
    } finally {
      this.unsubscribe();
    }
  }, t;
}(ps), lS = Function.prototype.bind;
function Ga(e, t) {
  return lS.call(e, t);
}
var cS = function() {
  function e(t) {
    this.partialObserver = t;
  }
  return e.prototype.next = function(t) {
    var n = this.partialObserver;
    if (n.next)
      try {
        n.next(t);
      } catch (o) {
        Ps(o);
      }
  }, e.prototype.error = function(t) {
    var n = this.partialObserver;
    if (n.error)
      try {
        n.error(t);
      } catch (o) {
        Ps(o);
      }
    else
      Ps(t);
  }, e.prototype.complete = function() {
    var t = this.partialObserver;
    if (t.complete)
      try {
        t.complete();
      } catch (n) {
        Ps(n);
      }
  }, e;
}(), Sl = function(e) {
  fn(t, e);
  function t(n, o, r) {
    var s = e.call(this) || this, i;
    if (ve(n) || !n)
      i = {
        next: n ?? void 0,
        error: o ?? void 0,
        complete: r ?? void 0
      };
    else {
      var a;
      s && Mh.useDeprecatedNextContext ? (a = Object.create(n), a.unsubscribe = function() {
        return s.unsubscribe();
      }, i = {
        next: n.next && Ga(n.next, a),
        error: n.error && Ga(n.error, a),
        complete: n.complete && Ga(n.complete, a)
      }) : i = n;
    }
    return s.destination = new cS(i), s;
  }
  return t;
}(Ac);
function Ps(e) {
  $h(e);
}
function uS(e) {
  throw e;
}
var dS = {
  closed: !0,
  next: El,
  error: uS,
  complete: El
}, Mc = function() {
  return typeof Symbol == "function" && Symbol.observable || "@@observable";
}();
function fS(e) {
  return e;
}
function mS(e) {
  return e.length === 0 ? fS : e.length === 1 ? e[0] : function(n) {
    return e.reduce(function(o, r) {
      return r(o);
    }, n);
  };
}
var tt = function() {
  function e(t) {
    t && (this._subscribe = t);
  }
  return e.prototype.lift = function(t) {
    var n = new e();
    return n.source = this, n.operator = t, n;
  }, e.prototype.subscribe = function(t, n, o) {
    var r = this, s = pS(t) ? t : new Sl(t, n, o);
    return xi(function() {
      var i = r, a = i.operator, l = i.source;
      s.add(a ? a.call(s, l) : l ? r._subscribe(s) : r._trySubscribe(s));
    }), s;
  }, e.prototype._trySubscribe = function(t) {
    try {
      return this._subscribe(t);
    } catch (n) {
      t.error(n);
    }
  }, e.prototype.forEach = function(t, n) {
    var o = this;
    return n = bd(n), new n(function(r, s) {
      var i = new Sl({
        next: function(a) {
          try {
            t(a);
          } catch (l) {
            s(l), i.unsubscribe();
          }
        },
        error: s,
        complete: r
      });
      o.subscribe(i);
    });
  }, e.prototype._subscribe = function(t) {
    var n;
    return (n = this.source) === null || n === void 0 ? void 0 : n.subscribe(t);
  }, e.prototype[Mc] = function() {
    return this;
  }, e.prototype.pipe = function() {
    for (var t = [], n = 0; n < arguments.length; n++)
      t[n] = arguments[n];
    return mS(t)(this);
  }, e.prototype.toPromise = function(t) {
    var n = this;
    return t = bd(t), new t(function(o, r) {
      var s;
      n.subscribe(function(i) {
        return s = i;
      }, function(i) {
        return r(i);
      }, function() {
        return o(s);
      });
    });
  }, e.create = function(t) {
    return new e(t);
  }, e;
}();
function bd(e) {
  var t;
  return (t = e ?? Mh.Promise) !== null && t !== void 0 ? t : Promise;
}
function hS(e) {
  return e && ve(e.next) && ve(e.error) && ve(e.complete);
}
function pS(e) {
  return e && e instanceof Ac || hS(e) && Ah(e);
}
function yS(e) {
  return ve(e == null ? void 0 : e.lift);
}
function Zn(e) {
  return function(t) {
    if (yS(t))
      return t.lift(function(n) {
        try {
          return e(n, this);
        } catch (o) {
          this.error(o);
        }
      });
    throw new TypeError("Unable to lift unknown Observable type");
  };
}
function xt(e, t, n, o, r) {
  return new kh(e, t, n, o, r);
}
var kh = function(e) {
  fn(t, e);
  function t(n, o, r, s, i, a) {
    var l = e.call(this, n) || this;
    return l.onFinalize = i, l.shouldUnsubscribe = a, l._next = o ? function(c) {
      try {
        o(c);
      } catch (u) {
        n.error(u);
      }
    } : e.prototype._next, l._error = s ? function(c) {
      try {
        s(c);
      } catch (u) {
        n.error(u);
      } finally {
        this.unsubscribe();
      }
    } : e.prototype._error, l._complete = r ? function() {
      try {
        r();
      } catch (c) {
        n.error(c);
      } finally {
        this.unsubscribe();
      }
    } : e.prototype._complete, l;
  }
  return t.prototype.unsubscribe = function() {
    var n;
    if (!this.shouldUnsubscribe || this.shouldUnsubscribe()) {
      var o = this.closed;
      e.prototype.unsubscribe.call(this), !o && ((n = this.onFinalize) === null || n === void 0 || n.call(this));
    }
  }, t;
}(Ac), gS = Sh(function(e) {
  return function() {
    e(this), this.name = "ObjectUnsubscribedError", this.message = "object unsubscribed";
  };
}), Dh = function(e) {
  fn(t, e);
  function t() {
    var n = e.call(this) || this;
    return n.closed = !1, n.currentObservers = null, n.observers = [], n.isStopped = !1, n.hasError = !1, n.thrownError = null, n;
  }
  return t.prototype.lift = function(n) {
    var o = new Id(this, this);
    return o.operator = n, o;
  }, t.prototype._throwIfClosed = function() {
    if (this.closed)
      throw new gS();
  }, t.prototype.next = function(n) {
    var o = this;
    xi(function() {
      var r, s;
      if (o._throwIfClosed(), !o.isStopped) {
        o.currentObservers || (o.currentObservers = Array.from(o.observers));
        try {
          for (var i = Ro(o.currentObservers), a = i.next(); !a.done; a = i.next()) {
            var l = a.value;
            l.next(n);
          }
        } catch (c) {
          r = { error: c };
        } finally {
          try {
            a && !a.done && (s = i.return) && s.call(i);
          } finally {
            if (r)
              throw r.error;
          }
        }
      }
    });
  }, t.prototype.error = function(n) {
    var o = this;
    xi(function() {
      if (o._throwIfClosed(), !o.isStopped) {
        o.hasError = o.isStopped = !0, o.thrownError = n;
        for (var r = o.observers; r.length; )
          r.shift().error(n);
      }
    });
  }, t.prototype.complete = function() {
    var n = this;
    xi(function() {
      if (n._throwIfClosed(), !n.isStopped) {
        n.isStopped = !0;
        for (var o = n.observers; o.length; )
          o.shift().complete();
      }
    });
  }, t.prototype.unsubscribe = function() {
    this.isStopped = this.closed = !0, this.observers = this.currentObservers = null;
  }, Object.defineProperty(t.prototype, "observed", {
    get: function() {
      var n;
      return ((n = this.observers) === null || n === void 0 ? void 0 : n.length) > 0;
    },
    enumerable: !1,
    configurable: !0
  }), t.prototype._trySubscribe = function(n) {
    return this._throwIfClosed(), e.prototype._trySubscribe.call(this, n);
  }, t.prototype._subscribe = function(n) {
    return this._throwIfClosed(), this._checkFinalizedStatuses(n), this._innerSubscribe(n);
  }, t.prototype._innerSubscribe = function(n) {
    var o = this, r = this, s = r.hasError, i = r.isStopped, a = r.observers;
    return s || i ? xh : (this.currentObservers = null, a.push(n), new ps(function() {
      o.currentObservers = null, ji(a, n);
    }));
  }, t.prototype._checkFinalizedStatuses = function(n) {
    var o = this, r = o.hasError, s = o.thrownError, i = o.isStopped;
    r ? n.error(s) : i && n.complete();
  }, t.prototype.asObservable = function() {
    var n = new tt();
    return n.source = this, n;
  }, t.create = function(n, o) {
    return new Id(n, o);
  }, t;
}(tt), Id = function(e) {
  fn(t, e);
  function t(n, o) {
    var r = e.call(this) || this;
    return r.destination = n, r.source = o, r;
  }
  return t.prototype.next = function(n) {
    var o, r;
    (r = (o = this.destination) === null || o === void 0 ? void 0 : o.next) === null || r === void 0 || r.call(o, n);
  }, t.prototype.error = function(n) {
    var o, r;
    (r = (o = this.destination) === null || o === void 0 ? void 0 : o.error) === null || r === void 0 || r.call(o, n);
  }, t.prototype.complete = function() {
    var n, o;
    (o = (n = this.destination) === null || n === void 0 ? void 0 : n.complete) === null || o === void 0 || o.call(n);
  }, t.prototype._subscribe = function(n) {
    var o, r;
    return (r = (o = this.source) === null || o === void 0 ? void 0 : o.subscribe(n)) !== null && r !== void 0 ? r : xh;
  }, t;
}(Dh), vS = {
  now: function() {
    return Date.now();
  },
  delegate: void 0
}, bS = function(e) {
  fn(t, e);
  function t(n, o) {
    return e.call(this) || this;
  }
  return t.prototype.schedule = function(n, o) {
    return this;
  }, t;
}(ps), xl = {
  setInterval: function(e, t) {
    for (var n = [], o = 2; o < arguments.length; o++)
      n[o - 2] = arguments[o];
    return setInterval.apply(void 0, Ui([e, t], Wi(n)));
  },
  clearInterval: function(e) {
    var t = xl.delegate;
    return ((t == null ? void 0 : t.clearInterval) || clearInterval)(e);
  },
  delegate: void 0
}, IS = function(e) {
  fn(t, e);
  function t(n, o) {
    var r = e.call(this, n, o) || this;
    return r.scheduler = n, r.work = o, r.pending = !1, r;
  }
  return t.prototype.schedule = function(n, o) {
    var r;
    if (o === void 0 && (o = 0), this.closed)
      return this;
    this.state = n;
    var s = this.id, i = this.scheduler;
    return s != null && (this.id = this.recycleAsyncId(i, s, o)), this.pending = !0, this.delay = o, this.id = (r = this.id) !== null && r !== void 0 ? r : this.requestAsyncId(i, this.id, o), this;
  }, t.prototype.requestAsyncId = function(n, o, r) {
    return r === void 0 && (r = 0), xl.setInterval(n.flush.bind(n, this), r);
  }, t.prototype.recycleAsyncId = function(n, o, r) {
    if (r === void 0 && (r = 0), r != null && this.delay === r && this.pending === !1)
      return o;
    o != null && xl.clearInterval(o);
  }, t.prototype.execute = function(n, o) {
    if (this.closed)
      return new Error("executing a cancelled action");
    this.pending = !1;
    var r = this._execute(n, o);
    if (r)
      return r;
    this.pending === !1 && this.id != null && (this.id = this.recycleAsyncId(this.scheduler, this.id, null));
  }, t.prototype._execute = function(n, o) {
    var r = !1, s;
    try {
      this.work(n);
    } catch (i) {
      r = !0, s = i || new Error("Scheduled action threw falsy error");
    }
    if (r)
      return this.unsubscribe(), s;
  }, t.prototype.unsubscribe = function() {
    if (!this.closed) {
      var n = this, o = n.id, r = n.scheduler, s = r.actions;
      this.work = this.state = this.scheduler = null, this.pending = !1, ji(s, this), o != null && (this.id = this.recycleAsyncId(r, o, null)), this.delay = null, e.prototype.unsubscribe.call(this);
    }
  }, t;
}(bS), Td = function() {
  function e(t, n) {
    n === void 0 && (n = e.now), this.schedulerActionCtor = t, this.now = n;
  }
  return e.prototype.schedule = function(t, n, o) {
    return n === void 0 && (n = 0), new this.schedulerActionCtor(this, t).schedule(o, n);
  }, e.now = vS.now, e;
}(), TS = function(e) {
  fn(t, e);
  function t(n, o) {
    o === void 0 && (o = Td.now);
    var r = e.call(this, n, o) || this;
    return r.actions = [], r._active = !1, r;
  }
  return t.prototype.flush = function(n) {
    var o = this.actions;
    if (this._active) {
      o.push(n);
      return;
    }
    var r;
    this._active = !0;
    do
      if (r = n.execute(n.state, n.delay))
        break;
    while (n = o.shift());
    if (this._active = !1, r) {
      for (; n = o.shift(); )
        n.unsubscribe();
      throw r;
    }
  }, t;
}(Td), Nc = new TS(IS), wS = Nc;
function CS(e) {
  return e && ve(e.schedule);
}
var OS = function(e) {
  return e && typeof e.length == "number" && typeof e != "function";
};
function ES(e) {
  return ve(e == null ? void 0 : e.then);
}
function SS(e) {
  return ve(e[Mc]);
}
function xS(e) {
  return Symbol.asyncIterator && ve(e == null ? void 0 : e[Symbol.asyncIterator]);
}
function AS(e) {
  return new TypeError("You provided " + (e !== null && typeof e == "object" ? "an invalid object" : "'" + e + "'") + " where a stream was expected. You can provide an Observable, Promise, ReadableStream, Array, AsyncIterable, or Iterable.");
}
function MS() {
  return typeof Symbol != "function" || !Symbol.iterator ? "@@iterator" : Symbol.iterator;
}
var NS = MS();
function $S(e) {
  return ve(e == null ? void 0 : e[NS]);
}
function kS(e) {
  return iS(this, arguments, function() {
    var n, o, r, s;
    return Eh(this, function(i) {
      switch (i.label) {
        case 0:
          n = e.getReader(), i.label = 1;
        case 1:
          i.trys.push([1, , 9, 10]), i.label = 2;
        case 2:
          return [4, To(n.read())];
        case 3:
          return o = i.sent(), r = o.value, s = o.done, s ? [4, To(void 0)] : [3, 5];
        case 4:
          return [2, i.sent()];
        case 5:
          return [4, To(r)];
        case 6:
          return [4, i.sent()];
        case 7:
          return i.sent(), [3, 2];
        case 8:
          return [3, 10];
        case 9:
          return n.releaseLock(), [7];
        case 10:
          return [2];
      }
    });
  });
}
function DS(e) {
  return ve(e == null ? void 0 : e.getReader);
}
function ys(e) {
  if (e instanceof tt)
    return e;
  if (e != null) {
    if (SS(e))
      return RS(e);
    if (OS(e))
      return LS(e);
    if (ES(e))
      return PS(e);
    if (xS(e))
      return Rh(e);
    if ($S(e))
      return _S(e);
    if (DS(e))
      return BS(e);
  }
  throw AS(e);
}
function RS(e) {
  return new tt(function(t) {
    var n = e[Mc]();
    if (ve(n.subscribe))
      return n.subscribe(t);
    throw new TypeError("Provided object does not correctly implement Symbol.observable");
  });
}
function LS(e) {
  return new tt(function(t) {
    for (var n = 0; n < e.length && !t.closed; n++)
      t.next(e[n]);
    t.complete();
  });
}
function PS(e) {
  return new tt(function(t) {
    e.then(function(n) {
      t.closed || (t.next(n), t.complete());
    }, function(n) {
      return t.error(n);
    }).then(null, $h);
  });
}
function _S(e) {
  return new tt(function(t) {
    var n, o;
    try {
      for (var r = Ro(e), s = r.next(); !s.done; s = r.next()) {
        var i = s.value;
        if (t.next(i), t.closed)
          return;
      }
    } catch (a) {
      n = { error: a };
    } finally {
      try {
        s && !s.done && (o = r.return) && o.call(r);
      } finally {
        if (n)
          throw n.error;
      }
    }
    t.complete();
  });
}
function Rh(e) {
  return new tt(function(t) {
    WS(e, t).catch(function(n) {
      return t.error(n);
    });
  });
}
function BS(e) {
  return Rh(kS(e));
}
function WS(e, t) {
  var n, o, r, s;
  return sS(this, void 0, void 0, function() {
    var i, a;
    return Eh(this, function(l) {
      switch (l.label) {
        case 0:
          l.trys.push([0, 5, 6, 11]), n = aS(e), l.label = 1;
        case 1:
          return [4, n.next()];
        case 2:
          if (o = l.sent(), !!o.done)
            return [3, 4];
          if (i = o.value, t.next(i), t.closed)
            return [2];
          l.label = 3;
        case 3:
          return [3, 1];
        case 4:
          return [3, 11];
        case 5:
          return a = l.sent(), r = { error: a }, [3, 11];
        case 6:
          return l.trys.push([6, , 9, 10]), o && !o.done && (s = n.return) ? [4, s.call(n)] : [3, 8];
        case 7:
          l.sent(), l.label = 8;
        case 8:
          return [3, 10];
        case 9:
          if (r)
            throw r.error;
          return [7];
        case 10:
          return [7];
        case 11:
          return t.complete(), [2];
      }
    });
  });
}
function US(e, t, n, o, r) {
  o === void 0 && (o = 0), r === void 0 && (r = !1);
  var s = t.schedule(function() {
    n(), r ? e.add(this.schedule(null, o)) : this.unsubscribe();
  }, o);
  if (e.add(s), !r)
    return s;
}
function jS(e) {
  return e instanceof Date && !isNaN(e);
}
function Fi(e, t) {
  return Zn(function(n, o) {
    var r = 0;
    n.subscribe(xt(o, function(s) {
      o.next(e.call(t, s, r++));
    }));
  });
}
function FS(e, t, n, o, r, s, i, a) {
  var l = [], c = 0, u = 0, d = !1, f = function() {
    d && !l.length && !c && t.complete();
  }, h = function(g) {
    return c < o ? p(g) : l.push(g);
  }, p = function(g) {
    s && t.next(g), c++;
    var v = !1;
    ys(n(g, u++)).subscribe(xt(t, function(T) {
      r == null || r(T), s ? h(T) : t.next(T);
    }, function() {
      v = !0;
    }, void 0, function() {
      if (v)
        try {
          c--;
          for (var T = function() {
            var O = l.shift();
            i ? US(t, i, function() {
              return p(O);
            }) : p(O);
          }; l.length && c < o; )
            T();
          f();
        } catch (O) {
          t.error(O);
        }
    }));
  };
  return e.subscribe(xt(t, h, function() {
    d = !0, f();
  })), function() {
    a == null || a();
  };
}
function Lh(e, t, n) {
  return n === void 0 && (n = 1 / 0), ve(t) ? Lh(function(o, r) {
    return Fi(function(s, i) {
      return t(o, s, r, i);
    })(ys(e(o, r)));
  }, n) : (typeof t == "number" && (n = t), Zn(function(o, r) {
    return FS(o, r, e, n);
  }));
}
function qS(e, t, n) {
  e === void 0 && (e = 0), n === void 0 && (n = wS);
  var o = -1;
  return t != null && (CS(t) ? n = t : o = t), new tt(function(r) {
    var s = jS(e) ? +e - n.now() : e;
    s < 0 && (s = 0);
    var i = 0;
    return n.schedule(function() {
      r.closed || (r.next(i++), 0 <= o ? this.schedule(void 0, o) : r.complete());
    }, s);
  });
}
function HS(e, t) {
  return Zn(function(n, o) {
    var r = 0;
    n.subscribe(xt(o, function(s) {
      return e.call(t, s, r++) && o.next(s);
    }));
  });
}
function ZS(e) {
  return Zn(function(t, n) {
    var o = [];
    return t.subscribe(xt(n, function(r) {
      return o.push(r);
    }, function() {
      n.next(o), n.complete();
    })), ys(e).subscribe(xt(n, function() {
      var r = o;
      o = [], n.next(r);
    }, El)), function() {
      o = null;
    };
  });
}
function VS(e, t) {
  return t === void 0 && (t = Nc), Zn(function(n, o) {
    var r = null, s = null, i = null, a = function() {
      if (r) {
        r.unsubscribe(), r = null;
        var c = s;
        s = null, o.next(c);
      }
    };
    function l() {
      var c = i + e, u = t.now();
      if (u < c) {
        r = this.schedule(void 0, c - u), o.add(r);
        return;
      }
      a();
    }
    n.subscribe(xt(o, function(c) {
      s = c, i = t.now(), r || (r = t.schedule(l, e), o.add(r));
    }, function() {
      a(), o.complete();
    }, void 0, function() {
      s = r = null;
    }));
  });
}
function zS(e, t, n, o) {
  return Zn(function(r, s) {
    var i;
    !t || typeof t == "function" ? i = t : (n = t.duration, i = t.element, o = t.connector);
    var a = /* @__PURE__ */ new Map(), l = function(p) {
      a.forEach(p), p(s);
    }, c = function(p) {
      return l(function(g) {
        return g.error(p);
      });
    }, u = 0, d = !1, f = new kh(s, function(p) {
      try {
        var g = e(p), v = a.get(g);
        if (!v) {
          a.set(g, v = o ? o() : new Dh());
          var T = h(g, v);
          if (s.next(T), n) {
            var O = xt(v, function() {
              v.complete(), O == null || O.unsubscribe();
            }, void 0, void 0, function() {
              return a.delete(g);
            });
            f.add(ys(n(T)).subscribe(O));
          }
        }
        v.next(i ? i(p) : p);
      } catch (k) {
        c(k);
      }
    }, function() {
      return l(function(p) {
        return p.complete();
      });
    }, c, function() {
      return a.clear();
    }, function() {
      return d = !0, u === 0;
    });
    r.subscribe(f);
    function h(p, g) {
      var v = new tt(function(T) {
        u++;
        var O = g.subscribe(T);
        return function() {
          O.unsubscribe(), --u === 0 && d && f.unsubscribe();
        };
      });
      return v.key = p, v;
    }
  });
}
function GS(e, t) {
  return Zn(function(n, o) {
    var r = t ?? {}, s = r.leading, i = s === void 0 ? !0 : s, a = r.trailing, l = a === void 0 ? !1 : a, c = !1, u = null, d = null, f = !1, h = function() {
      d == null || d.unsubscribe(), d = null, l && (v(), f && o.complete());
    }, p = function() {
      d = null, f && o.complete();
    }, g = function(T) {
      return d = ys(e(T)).subscribe(xt(o, h, p));
    }, v = function() {
      if (c) {
        c = !1;
        var T = u;
        u = null, o.next(T), !f && g(T);
      }
    };
    n.subscribe(xt(o, function(T) {
      c = !0, u = T, !(d && !d.closed) && (i ? v() : g(T));
    }, function() {
      f = !0, !(l && c && d && !d.closed) && o.complete();
    }));
  });
}
function YS(e, t, n) {
  t === void 0 && (t = Nc);
  var o = qS(e, t);
  return GS(function() {
    return o;
  }, n);
}
const x = {
  changeHasHistory: "editor.changeHasHistory",
  selectAll: "editor.selectAll",
  unselectAll: "editor.unselectAll",
  select: "editor.select",
  changeViewport: "editor.changeViewport",
  clear: "editor.clear",
  loadJson: "editor.loadJson",
  initialClear: "editor.initialClear",
  initialLoadJson: "editor.initialLoadJson",
  focusTable: "editor.focusTable",
  focusColumn: "editor.focusColumn",
  focusTableEnd: "editor.focusTableEnd",
  focusMoveTable: "editor.focusMoveTable",
  editTable: "editor.editTable",
  editTableEnd: "editor.editTableEnd",
  selectAllColumn: "editor.selectAllColumn",
  drawStartRelationship: "editor.drawStartRelationship",
  drawStartAddRelationship: "editor.drawStartAddRelationship",
  drawEndRelationship: "editor.drawEndRelationship",
  drawRelationship: "editor.drawRelationship",
  hoverColumnMap: "editor.hoverColumnMap",
  hoverRelationshipMap: "editor.hoverRelationshipMap",
  changeOpenMap: "editor.changeOpenMap",
  dragstartColumn: "editor.dragstartColumn",
  dragendColumn: "editor.dragendColumn",
  sharedMouseTracker: "editor.sharedMouseTracker",
  validationIds: "editor.validationIds"
}, Ph = E.CanvasType, KS = E.CanvasTypeList, G = E.Show, P = E.ColumnType, QS = E.ColumnTypeList, Jn = E.Database, JS = E.DatabaseList, hL = E.Language, XS = E.LanguageList, pL = E.NameCase, ex = E.NameCaseList, _s = E.BracketType, tx = E.BracketTypeList, _h = E.RelationshipType, Al = E.StartRelationshipType, Ct = E.Direction, U = E.ColumnOption, Pe = E.ColumnUIKey, wo = E.OrderType, yL = E.SaveSettingType, nx = E.CANVAS_SIZE_MAX, ox = E.CANVAS_SIZE_MIN, rx = E.CANVAS_ZOOM_MAX, sx = E.CANVAS_ZOOM_MIN, gL = {
  [_s.none]: "",
  [_s.backtick]: "`",
  [_s.doubleQuote]: '"',
  [_s.singleQuote]: "'"
}, vL = {
  [P.columnName]: "Name",
  [P.columnDataType]: "DataType",
  [P.columnDefault]: "Default",
  [P.columnComment]: "Comment",
  [P.columnAutoIncrement]: "Auto Increment",
  [P.columnUnique]: "Unique",
  [P.columnNotNull]: "Not Null"
}, Vn = {
  shared: (
    /*     */
    1
  ),
  changeOnly: (
    /* */
    2
  ),
  following: (
    /*  */
    4
  )
};
function ix(e, t) {
  return {
    ...da(t),
    tags: c1(t.tags) ? t.tags | e : e
  };
}
function ax(e, t) {
  return t.map((n) => ix(e, n));
}
const lx = (e) => (...t) => function* (n, o) {
  const r = Oh(n, o, t);
  yield ax(e, r);
}, bL = lx(Vn.changeOnly);
function M(e, t) {
  return (e & t) === t;
}
function wd(e, t) {
  return !(e.x > t.x + t.w || e.x + e.w < t.x || e.y > t.y + t.h || e.y + e.h < t.y);
}
function Ml(e, t, n) {
  const o = { x: 0, y: 0, w: 0, h: 0 };
  return o.w = e * n, o.h = t * n, o.x = (e - o.w) / 2, o.y = (t - o.h) / 2, o;
}
function cx({ x: e, y: t }, n, o, r) {
  const s = Ml(n, o, r), i = e * r, a = t * r, l = s.x + i, c = s.y + a;
  return { x: l, y: c };
}
function Bh(e, t, n, o) {
  const { x: r, y: s } = e, { x: i, y: a } = cx(e, t, n, o), l = (r - i) / o, c = (s - a) / o, u = r + l, d = s + c;
  return { x: u, y: d };
}
const ux = 200, dx = 100, Cd = 50, fx = 16, mx = 9, Wh = 1200, hx = Wh / fx * mx, Uh = 20, $n = 8, jh = 12, px = 4, qi = 1, Hi = 8, yx = 2, gx = 2, vx = Uh + yx * 2, bx = jh + gx + vx, IL = 4, Fh = 12, qh = 12, Ze = 60, Hh = 35, Zh = 22, Vh = 15, Ix = 2, Tx = Uh + Ix * 2, Zi = 1, Vi = 8, zh = jh + px, wx = 100 + zh, Cx = 100, TL = 150, wL = 20, CL = 30, OL = 200, Cn = {
  MariaDB: "MariaDB",
  MSSQL: "MSSQL",
  MySQL: "MySQL",
  Oracle: "Oracle",
  PostgreSQL: "PostgreSQL",
  SQLite: "SQLite"
}, Ox = Object.values(Cn), EL = {
  [Cn.MariaDB]: Jn.MariaDB,
  [Cn.MSSQL]: Jn.MSSQL,
  [Cn.MySQL]: Jn.MySQL,
  [Cn.Oracle]: Jn.Oracle,
  [Cn.PostgreSQL]: Jn.PostgreSQL,
  [Cn.SQLite]: Jn.SQLite
}, Ex = /[^0-9]/g, Gh = (e) => e.replace(Ex, ""), Sx = Je(ox, nx), xx = Je(sx, rx), Yh = Je(Ze, 200);
function Nl(e) {
  const t = q(e) ? Number(Gh(e)) : e;
  return Sx(t);
}
function Ta(e) {
  return ft(xx(e), 2);
}
function SL(e) {
  const t = q(e) ? Number(Gh(e)) : e;
  return `${Yh(t)}px`;
}
function xL(e) {
  return `${(e * 100).toFixed()}%`;
}
const Ax = $(JS), Kh = $(ex), Mx = $(tx), Nx = $(XS), Od = $(QS), AL = $(Ox), Qh = $(KS);
function ge(e) {
  return Math.max(e, Ze);
}
function ML(e) {
  return q(e) ? e.trim() : "";
}
function NL(e) {
  return e <= 0.7;
}
const De = {
  table: "table",
  memo: "memo"
}, W = {
  tableName: "tableName",
  tableComment: "tableComment",
  columnName: "columnName",
  columnDataType: "columnDataType",
  columnNotNull: "columnNotNull",
  columnUnique: "columnUnique",
  columnAutoIncrement: "columnAutoIncrement",
  columnDefault: "columnDefault",
  columnComment: "columnComment"
}, ct = {
  ArrowUp: "ArrowUp",
  ArrowRight: "ArrowRight",
  ArrowDown: "ArrowDown",
  ArrowLeft: "ArrowLeft",
  Tab: "Tab"
}, $L = $(Object.values(ct)), $x = () => ({
  id: fe(),
  selectedMap: {},
  hasUndo: !1,
  hasRedo: !1,
  viewport: {
    width: Wh,
    height: hx
  },
  focusTable: null,
  drawRelationship: null,
  hoverColumnMap: {},
  hoverRelationshipMap: {},
  openMap: {},
  draggableColumn: null,
  draggingColumnMap: {},
  sharedMouseTrackerMap: {}
}), Ed = (e) => e.ui.zIndex, wa = (e, t) => Math.max(1, ...e.map(Ed), ...t.map(Ed)) + 1, kx = (e) => (t) => e.y === t.y && e.x === t.x;
function Jh({ width: e, height: t, zoomLevel: n, scrollLeft: o, scrollTop: r }, s, i) {
  const a = Bh({
    x: ux - o,
    y: dx - r
  }, e, t, n), l = [...s, ...i].map(({ ui: c }) => c);
  for (; l.some(kx(a)); )
    a.x += Cd, a.y += Cd;
  return a;
}
function Yo() {
  const e = Date.now();
  return {
    updateAt: e,
    createAt: e
  };
}
function Dx(e, t) {
  return e < t ? Ju(e, t + 1) : Ju(t, e + 1);
}
function kL(e) {
  return Bf(t0(e));
}
function gs(e, t) {
  return Di([...e, t]);
}
function Xh(e, t, n) {
  if (!t || t === n)
    return [n];
  const o = e.indexOf(t), r = e.indexOf(n);
  return o === -1 ? [n] : Dx(o, r).map((s) => e[s]);
}
function Rx(e, t, n, o) {
  return Di([
    ...t,
    ...Xh(e, n, o)
  ]);
}
const Lx = {
  [P.columnName]: !0,
  [P.columnDataType]: G.columnDataType,
  [P.columnNotNull]: G.columnNotNull,
  [P.columnUnique]: G.columnUnique,
  [P.columnAutoIncrement]: G.columnAutoIncrement,
  [P.columnDefault]: G.columnDefault,
  [P.columnComment]: G.columnComment
}, Px = {
  [P.columnName]: W.columnName,
  [P.columnDataType]: W.columnDataType,
  [P.columnNotNull]: W.columnNotNull,
  [P.columnUnique]: W.columnUnique,
  [P.columnAutoIncrement]: W.columnAutoIncrement,
  [P.columnDefault]: W.columnDefault,
  [P.columnComment]: W.columnComment
}, ep = [
  W.tableName,
  W.tableComment
];
function Ko({ settings: { show: e, columnOrder: t } }) {
  return t.filter((n) => {
    const o = Lx[n];
    return o === !0 ? !0 : M(e, o);
  }).map((n) => Px[n]);
}
function vs({ collections: e, editor: { focusTable: t } }) {
  if (!t)
    return !1;
  const n = y(e).collection("tableEntities").selectById(t.tableId);
  return !!(n != null && n.columnIds.length);
}
function $c(e) {
  const { editor: { focusTable: t } } = e;
  if (!t)
    return !0;
  const n = Ko(e);
  return n.indexOf(t.focusType) === n.length - 1;
}
function tp(e) {
  const { editor: { focusTable: t } } = e;
  return t ? Ko(e).indexOf(t.focusType) === 0 : !0;
}
function kc({ collections: e, editor: { focusTable: t } }) {
  if (!(t != null && t.columnId))
    return !0;
  const n = y(e).collection("tableEntities").selectById(t.tableId);
  return n ? n.columnIds.indexOf(t.columnId) === n.columnIds.length - 1 : !0;
}
function np({ collections: e, editor: { focusTable: t } }) {
  if (!(t != null && t.columnId))
    return !0;
  const n = y(e).collection("tableEntities").selectById(t.tableId);
  return n ? n.columnIds.indexOf(t.columnId) === 0 : !0;
}
function $l(e) {
  const t = Ko(e);
  return t[t.length - 1];
}
function kl(e) {
  return Ko(e)[0];
}
function _x(e) {
  const { editor: { focusTable: t } } = e;
  if (!t)
    return W.columnName;
  const n = Ko(e), o = n.indexOf(t.focusType);
  return $c(e) ? n[0] : n[o + 1];
}
function Bx(e) {
  const { editor: { focusTable: t } } = e;
  if (!t)
    return W.columnName;
  const n = Ko(e), o = n.indexOf(t.focusType);
  return tp(e) ? n[n.length - 1] : n[o - 1];
}
function Wx(e, t) {
  const { collections: n, editor: { focusTable: o } } = e;
  if (!(o != null && o.columnId))
    return null;
  const r = y(n).collection("tableEntities").selectById(o.tableId);
  if (!r)
    return null;
  const s = r.columnIds.indexOf(o.columnId);
  if (s <= 0)
    return null;
  let i = null;
  for (let a = s; a >= 0; a--) {
    const l = r.columnIds[a];
    if (!t.includes(l)) {
      i = l;
      break;
    }
  }
  return i;
}
function Ca({ settings: { show: e } }) {
  return M(e, G.tableComment) ? ep : [W.tableName];
}
function Dc(e) {
  const { editor: { focusTable: t } } = e;
  if (!t)
    return !0;
  const n = Ca(e);
  return n.indexOf(t.focusType) === n.length - 1;
}
function op(e) {
  const { editor: { focusTable: t } } = e;
  return t ? Ca(e).indexOf(t.focusType) === 0 : !0;
}
const Wn = $(ep);
function Sd(e) {
  const { editor: { focusTable: t } } = e;
  if (!t)
    return W.tableName;
  const n = Ca(e), o = n.indexOf(t.focusType);
  return Dc(e) ? n[0] : n[o + 1];
}
function xd(e) {
  const { editor: { focusTable: t } } = e;
  if (!t)
    return W.tableName;
  const n = Ca(e), o = n.indexOf(t.focusType);
  return op(e) ? n[n.length - 1] : n[o - 1];
}
function Ux(e, t) {
  const { collections: n, editor: { focusTable: o } } = e;
  if (!o)
    return;
  const r = y(n).collection("tableEntities").selectById(o.tableId);
  if (r) {
    if (Wn(o.focusType)) {
      if (vs(e)) {
        const s = r.columnIds[r.columnIds.length - 1];
        o.focusType = $l(e), o.columnId = s, o.prevSelectColumnId = s, o.selectColumnIds = [s];
      }
    } else if (np(e))
      o.focusType = W.tableName, o.columnId = null, o.prevSelectColumnId = null, o.selectColumnIds = [];
    else if (o.columnId) {
      const s = r.columnIds.indexOf(o.columnId), i = r.columnIds[s - 1];
      o.columnId = i, o.prevSelectColumnId = i, t.shiftKey && t.moveKey !== ct.Tab ? o.selectColumnIds = gs(o.selectColumnIds, i) : o.selectColumnIds = [i];
    }
  }
}
function jx(e, t) {
  const { collections: n, editor: { focusTable: o } } = e;
  if (!o)
    return;
  const r = y(n).collection("tableEntities").selectById(o.tableId);
  if (r) {
    if (Wn(o.focusType)) {
      if (vs(e)) {
        const s = r.columnIds[0];
        o.focusType = kl(e), o.columnId = s, o.prevSelectColumnId = s, o.selectColumnIds = [s];
      }
    } else if (kc(e))
      o.focusType = W.tableName, o.columnId = null, o.prevSelectColumnId = null, o.selectColumnIds = [];
    else if (o.columnId) {
      const s = r.columnIds.indexOf(o.columnId), i = r.columnIds[s + 1];
      o.columnId = i, o.prevSelectColumnId = i, t.shiftKey && t.moveKey !== ct.Tab ? o.selectColumnIds = gs(o.selectColumnIds, i) : o.selectColumnIds = [i];
    }
  }
}
function Ad(e, t) {
  const { collections: n, editor: { focusTable: o } } = e;
  if (!o)
    return;
  const r = y(n).collection("tableEntities").selectById(o.tableId);
  if (r)
    if (Wn(o.focusType))
      if (op(e))
        if (vs(e)) {
          const s = r.columnIds[r.columnIds.length - 1];
          o.focusType = $l(e), o.columnId = s, o.prevSelectColumnId = s, o.selectColumnIds = [s];
        } else
          o.focusType = xd(e);
      else
        o.focusType = xd(e);
    else if (tp(e)) {
      if (np(e))
        o.focusType = M(e.settings.show, G.tableComment) ? W.tableComment : W.tableName, o.columnId = null, o.prevSelectColumnId = null, o.selectColumnIds = [];
      else if (o.columnId) {
        const s = r.columnIds.indexOf(o.columnId), i = r.columnIds[s - 1];
        o.focusType = $l(e), o.columnId = i, o.prevSelectColumnId = i, t.shiftKey && t.moveKey !== ct.Tab ? o.selectColumnIds = gs(o.selectColumnIds, i) : o.selectColumnIds = [i];
      }
    } else
      o.focusType = Bx(e), !t.shiftKey && o.columnId && (o.prevSelectColumnId = o.columnId, o.selectColumnIds = [o.columnId]);
}
function Md(e, t) {
  const { collections: n, editor: { focusTable: o } } = e;
  if (!o)
    return;
  const r = y(n).collection("tableEntities").selectById(o.tableId);
  if (r)
    if (Wn(o.focusType))
      if (Dc(e))
        if (vs(e)) {
          const s = r.columnIds[0];
          o.focusType = kl(e), o.columnId = s, o.prevSelectColumnId = s, o.selectColumnIds = [s];
        } else
          o.focusType = Sd(e);
      else
        o.focusType = Sd(e);
    else if ($c(e)) {
      if (kc(e))
        o.focusType = W.tableName, o.columnId = null, o.prevSelectColumnId = null, o.selectColumnIds = [];
      else if (o.columnId) {
        const s = r.columnIds.indexOf(o.columnId), i = r.columnIds[s + 1];
        o.focusType = kl(e), o.columnId = i, o.prevSelectColumnId = i, t.shiftKey && t.moveKey !== ct.Tab ? o.selectColumnIds = gs(o.selectColumnIds, i) : o.selectColumnIds = [i];
      }
    } else
      o.focusType = _x(e), !t.shiftKey && o.columnId && (o.prevSelectColumnId = o.columnId, o.selectColumnIds = [o.columnId]);
}
const Nd = 1e3 * 30, Fx = I(x.changeHasHistory), qx = ({ editor: e }, { payload: { hasRedo: t, hasUndo: n } }) => {
  e.hasRedo = t, e.hasUndo = n;
}, Hx = I(x.selectAll), Zx = ({ editor: e, doc: t }) => {
  const n = t.tableIds.reduce((r, s) => (r[s] = De.table, r), {}), o = t.memoIds.reduce((r, s) => (r[s] = De.memo, r), {});
  e.selectedMap = {
    ...o,
    ...n
  };
}, Oa = I(x.unselectAll), Vx = ({ editor: e }) => {
  Object.keys(e.selectedMap).forEach((t) => {
    Reflect.deleteProperty(e.selectedMap, t);
  });
}, Qo = I(x.select), zx = ({ editor: e }, { payload: t }) => {
  Object.assign(e.selectedMap, t);
}, Gx = I(x.changeViewport), Yx = ({ editor: e }, { payload: { width: t, height: n } }) => {
  e.viewport.width = t, e.viewport.height = n;
}, Rc = I(x.clear), Kx = (e) => {
  const { doc: t, collections: n, lww: o } = qn({});
  e.doc = t, e.collections = n, e.lww = o;
}, an = I(x.loadJson), Qx = (e, { payload: { value: t } }) => {
  const { version: n, settings: o, doc: r, collections: s, lww: i } = $m(t);
  Qh(o.canvasType) || (o.canvasType = Ph.ERD), Object.assign(e.settings, o), e.version = n, e.doc = r, e.collections = s, e.lww = i;
}, rp = I(x.initialClear), Jx = (e) => {
  const { doc: t, collections: n, lww: o } = qn({});
  e.doc = t, e.collections = n, e.lww = o;
}, Lo = I(x.initialLoadJson), Xx = (e, { payload: { value: t } }) => {
  const { version: n, settings: o, doc: r, collections: s, lww: i } = $m(t);
  Qh(o.canvasType) || (o.canvasType = Ph.ERD), Object.assign(e.settings, o), e.version = n, e.doc = r, e.collections = s, e.lww = i;
}, Ea = I(x.focusTable), eA = ({ editor: e, collections: t }, { payload: n }) => {
  var r, s;
  const o = y(t).collection("tableEntities");
  if (((r = e.focusTable) == null ? void 0 : r.tableId) === n.tableId && n.focusType)
    e.focusTable.focusType = n.focusType, e.focusTable.columnId = null, e.focusTable.prevSelectColumnId = null, e.focusTable.selectColumnIds = [];
  else if (n.focusType) {
    const i = o.selectById(n.tableId);
    if (!i)
      return;
    e.focusTable = {
      tableId: i.id,
      focusType: n.focusType,
      columnId: null,
      prevSelectColumnId: null,
      selectColumnIds: [],
      edit: !1
    };
  } else if (((s = e.focusTable) == null ? void 0 : s.tableId) !== n.tableId) {
    const i = o.selectById(n.tableId);
    if (!i)
      return;
    e.focusTable = {
      tableId: i.id,
      focusType: W.tableName,
      columnId: null,
      prevSelectColumnId: null,
      selectColumnIds: [],
      edit: !1
    };
  }
}, ln = I(x.focusColumn), tA = ({ editor: e, collections: t }, { payload: n }) => {
  var r;
  const o = y(t).collection("tableEntities");
  if (((r = e.focusTable) == null ? void 0 : r.tableId) === n.tableId) {
    const s = o.selectById(n.tableId);
    if (!s)
      return;
    const i = e.focusTable;
    i.columnId = n.columnId, i.focusType = n.focusType, n.$mod && n.shiftKey ? i.selectColumnIds = Rx(s.columnIds, i.selectColumnIds, i.prevSelectColumnId, i.columnId) : n.shiftKey ? i.selectColumnIds = Xh(s.columnIds, i.prevSelectColumnId, i.columnId) : n.$mod ? i.selectColumnIds = gs(i.selectColumnIds, n.columnId) : i.selectColumnIds = [n.columnId], i.prevSelectColumnId = n.columnId;
  } else {
    const s = o.selectById(n.tableId);
    if (!s)
      return;
    e.focusTable = {
      tableId: s.id,
      focusType: n.focusType,
      columnId: n.columnId,
      prevSelectColumnId: n.columnId,
      selectColumnIds: [n.columnId],
      edit: !1
    };
  }
}, Lc = I(x.focusTableEnd), nA = ({ editor: e }) => {
  e.focusTable = null;
}, sp = I(x.focusMoveTable), oA = (e, { payload: t }) => {
  const { editor: { focusTable: n } } = e;
  if (n)
    switch (n.edit = !1, t.moveKey) {
      case ct.ArrowUp:
        Ux(e, t);
        break;
      case ct.ArrowDown:
        jx(e, t);
        break;
      case ct.ArrowLeft:
        Ad(e, t);
        break;
      case ct.ArrowRight:
        Md(e, t);
        break;
      case ct.Tab:
        t.shiftKey ? Ad(e, t) : Md(e, t);
        break;
    }
}, rA = I(x.editTable), sA = ({ editor: { focusTable: e } }) => {
  e && (e.edit = !0);
}, iA = I(x.editTableEnd), aA = ({ editor: { focusTable: e } }) => {
  e && (e.edit = !1);
}, lA = I(x.selectAllColumn), cA = ({ collections: e, editor: { focusTable: t } }) => {
  if (!t)
    return;
  const n = y(e).collection("tableEntities").selectById(t.tableId);
  n && (t.selectColumnIds = [...n.columnIds]);
}, ip = I(x.drawStartRelationship), uA = ({ editor: e }, { payload: { relationshipType: t } }) => {
  e.drawRelationship = {
    relationshipType: t,
    start: null,
    end: { x: 0, y: 0 }
  };
}, ap = I(x.drawStartAddRelationship), dA = ({ editor: { drawRelationship: e }, collections: t }, { payload: { tableId: n } }) => {
  if (!e)
    return;
  const o = y(t).collection("tableEntities").selectById(n);
  o && (e.start = {
    tableId: n,
    x: o.ui.x,
    y: o.ui.y
  });
}, Pc = I(x.drawEndRelationship), fA = ({ editor: e }) => {
  e.drawRelationship = null;
}, mA = I(x.drawRelationship), hA = ({ editor: { drawRelationship: e }, settings: { scrollLeft: t, scrollTop: n, zoomLevel: o, width: r, height: s } }, { payload: { x: i, y: a } }) => {
  if (!(e != null && e.start))
    return;
  const l = Bh({ x: i - t, y: a - n }, r, s, o);
  e.end.x = l.x, e.end.y = l.y;
}, _c = I(x.hoverColumnMap), pA = ({ editor: e }, { payload: { columnIds: t } }) => {
  Object.keys(e.hoverColumnMap).forEach((n) => {
    Reflect.deleteProperty(e.hoverColumnMap, n);
  });
  for (const n of t)
    e.hoverColumnMap[n] = !0;
}, Bc = I(x.hoverRelationshipMap), yA = ({ editor: e }, { payload: { relationshipIds: t } }) => {
  Object.keys(e.hoverRelationshipMap).forEach((n) => {
    Reflect.deleteProperty(e.hoverRelationshipMap, n);
  });
  for (const n of t)
    e.hoverRelationshipMap[n] = !0;
}, gA = I(x.changeOpenMap), vA = ({ editor: e }, { payload: t }) => {
  Object.assign(e.openMap, t);
}, Wc = I(x.dragstartColumn), bA = ({ editor: e }, { payload: t }) => {
  e.draggableColumn = t, t.columnIds.forEach((n) => {
    e.draggingColumnMap[n] = !0;
  });
}, IA = I(x.dragendColumn), TA = ({ editor: e }) => {
  e.draggableColumn = null, Object.keys(e.draggingColumnMap).forEach((t) => {
    Reflect.deleteProperty(e.draggingColumnMap, t);
  });
}, wA = I(x.sharedMouseTracker), CA = ({ editor: e }, { payload: t, tags: n, meta: o }) => {
  if (pe(n) || !M(n, Vn.shared) || !q(o == null ? void 0 : o.editorId) || e.id === o.editorId)
    return;
  const r = e.sharedMouseTrackerMap[o.editorId], s = !q(o.nickname) || dm(o.nickname.trim()) ? "user" : o.nickname.trim();
  r ? (r.x = t.x, r.y = t.y, r.nickname = s, clearTimeout(r.timeoutId), r.timeoutId = setTimeout(() => {
    Reflect.deleteProperty(e.sharedMouseTrackerMap, o.editorId);
  }, Nd)) : e.sharedMouseTrackerMap[o.editorId] = {
    ...t,
    id: o.editorId,
    nickname: s,
    timeoutId: setTimeout(() => {
      Reflect.deleteProperty(e.sharedMouseTrackerMap, o.editorId);
    }, Nd)
  };
}, OA = I(x.validationIds), EA = ({ doc: e, collections: t }) => {
  const n = y(t).collection("tableEntities"), o = y(t).collection("tableColumnEntities"), r = y(t).collection("indexEntities"), s = y(t).collection("indexColumnEntities"), i = y(t).collection("relationshipEntities"), a = y(t).collection("memoEntities"), l = e.tableIds.filter((f) => !n.selectById(f)), c = e.relationshipIds.filter((f) => !i.selectById(f)), u = e.indexIds.filter((f) => !r.selectById(f)), d = e.memoIds.filter((f) => !a.selectById(f));
  e.tableIds = e.tableIds.filter((f) => !l.includes(f)), e.relationshipIds = e.relationshipIds.filter((f) => !c.includes(f)), e.indexIds = e.indexIds.filter((f) => !u.includes(f)), e.memoIds = e.memoIds.filter((f) => !d.includes(f)), n.selectAll().forEach((f) => {
    const h = f.columnIds.filter((g) => !o.selectById(g)), p = f.seqColumnIds.filter((g) => !o.selectById(g));
    f.columnIds = f.columnIds.filter((g) => !h.includes(g)), f.seqColumnIds = f.seqColumnIds.filter((g) => !p.includes(g));
  }), r.selectAll().forEach((f) => {
    const h = f.indexColumnIds.filter((g) => !s.selectById(g)), p = f.seqIndexColumnIds.filter((g) => !s.selectById(g));
    f.indexColumnIds = f.indexColumnIds.filter((g) => !h.includes(g)), f.seqIndexColumnIds = f.seqIndexColumnIds.filter((g) => !p.includes(g));
  });
}, SA = {
  [x.changeHasHistory]: qx,
  [x.selectAll]: Zx,
  [x.unselectAll]: Vx,
  [x.select]: zx,
  [x.changeViewport]: Yx,
  [x.clear]: Kx,
  [x.loadJson]: Qx,
  [x.initialClear]: Jx,
  [x.initialLoadJson]: Xx,
  [x.focusTable]: eA,
  [x.focusColumn]: tA,
  [x.focusTableEnd]: nA,
  [x.focusMoveTable]: oA,
  [x.editTable]: sA,
  [x.editTableEnd]: aA,
  [x.selectAllColumn]: cA,
  [x.drawStartRelationship]: uA,
  [x.drawStartAddRelationship]: dA,
  [x.drawEndRelationship]: fA,
  [x.drawRelationship]: hA,
  [x.hoverColumnMap]: pA,
  [x.hoverRelationshipMap]: yA,
  [x.changeOpenMap]: vA,
  [x.dragstartColumn]: bA,
  [x.dragendColumn]: TA,
  [x.sharedMouseTracker]: CA,
  [x.validationIds]: EA
}, xA = {
  changeHasHistoryAction: Fx,
  selectAllAction: Hx,
  unselectAllAction: Oa,
  selectAction: Qo,
  changeViewportAction: Gx,
  clearAction: Rc,
  loadJsonAction: an,
  initialClearAction: rp,
  initialLoadJsonAction: Lo,
  focusTableAction: Ea,
  focusColumnAction: ln,
  focusTableEndAction: Lc,
  focusMoveTableAction: sp,
  editTableAction: rA,
  editTableEndAction: iA,
  selectAllColumnAction: lA,
  drawStartRelationshipAction: ip,
  drawStartAddRelationshipAction: ap,
  drawEndRelationshipAction: Pc,
  drawRelationshipAction: mA,
  hoverColumnMapAction: _c,
  hoverRelationshipMapAction: Bc,
  changeOpenMapAction: gA,
  dragstartColumnAction: Wc,
  dragendColumnAction: IA,
  sharedMouseTrackerAction: wA,
  validationIdsAction: OA
}, AA = (e, t, n) => {
  e.push(Rc(), an({ value: hc(n) }));
}, MA = (e, t, n) => {
  e.push(an({ value: hc(n) }));
}, NA = {
  [x.loadJson]: AA,
  [x.clear]: MA
}, Ve = {
  addIndex: "index.add",
  removeIndex: "index.remove",
  changeIndexName: "index.changeName",
  changeIndexUnique: "index.changeUnique"
};
var DL = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function $A(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
var kA = function(t) {
  return DA(t) && !RA(t);
};
function DA(e) {
  return !!e && typeof e == "object";
}
function RA(e) {
  var t = Object.prototype.toString.call(e);
  return t === "[object RegExp]" || t === "[object Date]" || _A(e);
}
var LA = typeof Symbol == "function" && Symbol.for, PA = LA ? Symbol.for("react.element") : 60103;
function _A(e) {
  return e.$$typeof === PA;
}
function BA(e) {
  return Array.isArray(e) ? [] : {};
}
function Xr(e, t) {
  return t.clone !== !1 && t.isMergeableObject(e) ? Po(BA(e), e, t) : e;
}
function WA(e, t, n) {
  return e.concat(t).map(function(o) {
    return Xr(o, n);
  });
}
function UA(e, t) {
  if (!t.customMerge)
    return Po;
  var n = t.customMerge(e);
  return typeof n == "function" ? n : Po;
}
function jA(e) {
  return Object.getOwnPropertySymbols ? Object.getOwnPropertySymbols(e).filter(function(t) {
    return Object.propertyIsEnumerable.call(e, t);
  }) : [];
}
function $d(e) {
  return Object.keys(e).concat(jA(e));
}
function lp(e, t) {
  try {
    return t in e;
  } catch {
    return !1;
  }
}
function FA(e, t) {
  return lp(e, t) && !(Object.hasOwnProperty.call(e, t) && Object.propertyIsEnumerable.call(e, t));
}
function qA(e, t, n) {
  var o = {};
  return n.isMergeableObject(e) && $d(e).forEach(function(r) {
    o[r] = Xr(e[r], n);
  }), $d(t).forEach(function(r) {
    FA(e, r) || (lp(e, r) && n.isMergeableObject(t[r]) ? o[r] = UA(r, n)(e[r], t[r], n) : o[r] = Xr(t[r], n));
  }), o;
}
function Po(e, t, n) {
  n = n || {}, n.arrayMerge = n.arrayMerge || WA, n.isMergeableObject = n.isMergeableObject || kA, n.cloneUnlessOtherwiseSpecified = Xr;
  var o = Array.isArray(t), r = Array.isArray(e), s = o === r;
  return s ? o ? n.arrayMerge(e, t, n) : qA(e, t, n) : Xr(t, n);
}
Po.all = function(t, n) {
  if (!Array.isArray(t))
    throw new Error("first argument should be an array");
  return t.reduce(function(o, r) {
    return Po(o, r, n);
  }, {});
};
var HA = Po, ZA = HA;
const Jo = /* @__PURE__ */ $A(ZA), zn = (e) => Jo({
  id: fe(),
  name: "",
  tableId: "",
  indexColumnIds: [],
  seqIndexColumnIds: [],
  unique: !1,
  meta: Yo()
}, e ?? {}), Uc = I(Ve.addIndex), VA = ({ doc: e, collections: t, lww: n }, { payload: { id: o, tableId: r }, timestamp: s }) => {
  y(t).collection("indexEntities").addOne(zn({ id: o, tableId: r })).addOperator(n, s, o, () => {
    $(e.indexIds)(o) || e.indexIds.push(o);
  });
}, es = I(Ve.removeIndex), zA = ({ doc: e, collections: t, lww: n }, { payload: { id: o }, timestamp: r }) => {
  y(t).collection("indexEntities").removeOperator(n, r, o, () => {
    const s = e.indexIds.indexOf(o);
    s !== -1 && e.indexIds.splice(s, 1);
  });
}, cp = I(Ve.changeIndexName), GA = ({ collections: e, lww: t }, { payload: { id: n, tableId: o, value: r }, timestamp: s }) => {
  const i = y(e).collection("indexEntities");
  i.getOrCreate(n, (a) => zn({ id: a, tableId: o })), i.replaceOperator(t, s, n, "name", () => {
    i.updateOne(n, (a) => {
      a.name = r;
    });
  });
}, jc = I(Ve.changeIndexUnique), YA = ({ collections: e, lww: t }, { payload: { id: n, tableId: o, value: r }, timestamp: s }) => {
  const i = y(e).collection("indexEntities");
  i.getOrCreate(n, (a) => zn({ id: a, tableId: o })), i.replaceOperator(t, s, n, "unique", () => {
    i.updateOne(n, (a) => {
      a.unique = r;
    });
  });
}, KA = {
  [Ve.addIndex]: VA,
  [Ve.removeIndex]: zA,
  [Ve.changeIndexName]: GA,
  [Ve.changeIndexUnique]: YA
}, QA = {
  addIndexAction: Uc,
  removeIndexAction: es,
  changeIndexNameAction: cp,
  changeIndexUniqueAction: jc
}, JA = (e, { payload: { id: t } }) => {
  e.push(es({ id: t }));
}, XA = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("indexEntities").selectById(t);
  o && e.push(Uc({
    id: t,
    tableId: o.tableId
  }));
}, eM = (e, { payload: { id: t, tableId: n } }, { collections: o }) => {
  const r = y(o).collection("indexEntities").selectById(t);
  r && e.push(cp({
    id: t,
    tableId: n,
    value: r.name
  }));
}, tM = (e, { payload: { id: t, tableId: n, value: o } }, { collections: r }) => {
  y(r).collection("indexEntities").selectById(t) && e.push(jc({
    id: t,
    tableId: n,
    value: !o
  }));
}, nM = {
  [Ve.addIndex]: JA,
  [Ve.removeIndex]: XA,
  [Ve.changeIndexName]: eM,
  [Ve.changeIndexUnique]: tM
}, ze = {
  addIndexColumn: "indexColumn.add",
  removeIndexColumn: "indexColumn.remove",
  moveIndexColumn: "indexColumn.move",
  changeIndexColumnOrderType: "indexColumn.changeOrderType"
}, Fc = (e) => Jo({
  id: fe(),
  indexId: "",
  columnId: "",
  orderType: wo.ASC,
  meta: Yo()
}, e ?? {});
function up(e, t, n) {
  if ($(t)(n)) {
    const r = t.reduce((s, i, a) => (s[i] = a, s), {});
    e.push(n), e.sort((s, i) => {
      const a = r[s], l = r[i];
      return a === void 0 ? 1 : l === void 0 ? -1 : a - l;
    });
  } else
    e.push(n), t.push(n);
}
const zi = I(ze.addIndexColumn), oM = ({ collections: e, lww: t }, { payload: { id: n, indexId: o, tableId: r, columnId: s }, timestamp: i }) => {
  const a = y(e).collection("indexEntities"), l = a.getOrCreate(o, (c) => zn({ id: c, tableId: r }));
  y(e).collection("indexColumnEntities").addOne(Fc({ id: n, indexId: o, columnId: s })).addOperator(t, i, n, () => {
    $(l.indexColumnIds)(n) || a.updateOne(o, (c) => {
      up(c.indexColumnIds, c.seqIndexColumnIds, n);
    });
  });
}, qc = I(ze.removeIndexColumn), rM = ({ collections: e, lww: t }, { payload: { id: n, indexId: o, tableId: r }, timestamp: s }) => {
  const i = y(e).collection("indexEntities"), a = i.getOrCreate(o, (l) => zn({ id: l, tableId: r }));
  y(e).collection("indexColumnEntities").removeOperator(t, s, n, () => {
    const l = a.indexColumnIds.indexOf(n);
    l !== -1 && i.updateOne(o, (c) => {
      c.indexColumnIds.splice(l, 1);
    });
  });
}, Hc = I(ze.moveIndexColumn), sM = ({ collections: e }, { payload: { id: t, indexId: n, tableId: o, targetId: r } }) => {
  if (t === r)
    return;
  const s = y(e).collection("indexEntities"), i = s.getOrCreate(n, (c) => zn({ id: c, tableId: o })), a = i.indexColumnIds.indexOf(t);
  if (a === -1)
    return;
  const l = i.indexColumnIds.indexOf(r);
  l !== -1 && s.updateOne(n, (c) => {
    c.indexColumnIds.splice(a, 1), c.indexColumnIds.splice(l, 0, t);
    const u = c.seqIndexColumnIds.indexOf(t), d = c.seqIndexColumnIds.indexOf(r);
    u !== -1 && d !== -1 && (c.seqIndexColumnIds.splice(u, 1), c.seqIndexColumnIds.splice(d, 0, t));
  });
}, Zc = I(ze.changeIndexColumnOrderType), iM = ({ collections: e, lww: t }, { payload: { id: n, indexId: o, columnId: r, value: s }, timestamp: i }) => {
  const a = y(e).collection("indexColumnEntities");
  a.getOrCreate(n, (l) => Fc({ id: l, indexId: o, columnId: r })), a.replaceOperator(t, i, n, "orderType", () => {
    a.updateOne(n, (l) => {
      l.orderType = s;
    });
  });
}, aM = {
  [ze.addIndexColumn]: oM,
  [ze.removeIndexColumn]: rM,
  [ze.moveIndexColumn]: sM,
  [ze.changeIndexColumnOrderType]: iM
}, lM = {
  addIndexColumnAction: zi,
  removeIndexColumnAction: qc,
  moveIndexColumnAction: Hc,
  changeIndexColumnOrderTypeAction: Zc
}, cM = (e, { payload: { id: t, indexId: n, tableId: o } }) => {
  e.push(qc({ id: t, indexId: n, tableId: o }));
}, uM = (e, { payload: { id: t, indexId: n, tableId: o } }, { collections: r }) => {
  const s = y(r).collection("indexColumnEntities").selectById(t);
  s && e.push(zi({
    id: t,
    indexId: n,
    tableId: o,
    columnId: s.columnId
  }));
}, dM = (e, { payload: { id: t, indexId: n, tableId: o, targetId: r } }, { collections: s }) => {
  const i = y(s).collection("indexEntities").selectById(n);
  if (!i)
    return;
  const a = i.indexColumnIds.indexOf(t);
  if (a === -1)
    return;
  const l = i.indexColumnIds.indexOf(r);
  if (l === -1)
    return;
  const c = a < l ? a + 1 : a - 1, u = i.indexColumnIds[c];
  e.push(Hc({
    indexId: n,
    tableId: o,
    id: t,
    targetId: u
  }));
}, fM = (e, { payload: { id: t, indexId: n, columnId: o } }, { collections: r }) => {
  const s = y(r).collection("indexColumnEntities").selectById(t);
  s && e.push(Zc({
    id: t,
    indexId: n,
    columnId: o,
    value: s.orderType
  }));
}, mM = {
  [ze.addIndexColumn]: cM,
  [ze.removeIndexColumn]: uM,
  [ze.moveIndexColumn]: dM,
  [ze.changeIndexColumnOrderType]: fM
}, oe = {
  addMemo: "memo.add",
  moveMemo: "memo.move",
  moveToMemo: "memo.moveTo",
  removeMemo: "memo.remove",
  changeMemoValue: "memo.changeValue",
  changeMemoColor: "memo.changeColor",
  resizeMemo: "memo.resize",
  changeZIndex: "memo.changeZIndex"
}, Gn = (e) => Jo({
  id: fe(),
  value: "",
  ui: {
    x: 200,
    y: 100,
    zIndex: 2,
    width: wx,
    height: Cx,
    color: ""
  },
  meta: Yo()
}, e ?? {}), Vc = I(oe.addMemo), hM = ({ doc: e, collections: t, lww: n }, { payload: { id: o, ui: r }, timestamp: s }) => {
  y(t).collection("memoEntities").addOne(Gn({ id: o, ui: r })).addOperator(n, s, o, () => {
    $(e.memoIds)(o) || e.memoIds.push(o);
  });
}, Co = I(oe.moveMemo), pM = ({ collections: e }, { payload: { ids: t, movementX: n, movementY: o } }) => {
  const r = y(e).collection("memoEntities");
  for (const s of t)
    r.getOrCreate(s, (i) => Gn({ id: i }));
  r.updateMany(t, (s) => {
    s.ui.x = ft(s.ui.x + n, 4), s.ui.y = ft(s.ui.y + o, 4);
  });
}, dp = I(oe.moveToMemo), yM = ({ collections: e }, { payload: { id: t, x: n, y: o } }) => {
  const r = y(e).collection("memoEntities");
  r.getOrCreate(t, (s) => Gn({ id: s })), r.updateOne(t, (s) => {
    s.ui.x = n, s.ui.y = o;
  });
}, Gi = I(oe.removeMemo), gM = ({ doc: e, collections: t, lww: n }, { payload: { id: o }, timestamp: r }) => {
  y(t).collection("memoEntities").removeOperator(n, r, o, () => {
    const s = e.memoIds.indexOf(o);
    s !== -1 && e.memoIds.splice(s, 1);
  });
}, fp = I(oe.changeMemoValue), vM = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }) => {
  const s = y(e).collection("memoEntities");
  s.getOrCreate(n, (i) => Gn({ id: i })), s.replaceOperator(t, r, n, "value", () => {
    s.updateOne(n, (i) => {
      i.value = o;
    });
  });
}, jr = I(oe.changeMemoColor), bM = ({ collections: e, lww: t }, { payload: { id: n, color: o }, timestamp: r }) => {
  const s = y(e).collection("memoEntities");
  s.getOrCreate(n, (i) => Gn({ id: i })), s.replaceOperator(t, r, n, "ui.color", () => {
    s.updateOne(n, (i) => {
      i.ui.color = o;
    });
  });
}, mp = I(oe.resizeMemo), IM = ({ collections: e }, { payload: { id: t, x: n, y: o, width: r, height: s } }) => {
  const i = y(e).collection("memoEntities");
  i.getOrCreate(t, (a) => Gn({ id: a })), i.updateOne(t, (a) => {
    a.ui.x = n, a.ui.y = o, a.ui.width = r, a.ui.height = s;
  });
}, hp = I(oe.changeZIndex), TM = ({ collections: e }, { payload: { id: t, zIndex: n } }) => {
  const o = y(e).collection("memoEntities");
  o.getOrCreate(t, (r) => Gn({ id: r })), o.updateOne(t, (r) => {
    r.ui.zIndex = n;
  });
}, wM = {
  [oe.addMemo]: hM,
  [oe.moveMemo]: pM,
  [oe.moveToMemo]: yM,
  [oe.removeMemo]: gM,
  [oe.changeMemoValue]: vM,
  [oe.changeMemoColor]: bM,
  [oe.resizeMemo]: IM,
  [oe.changeZIndex]: TM
}, CM = {
  addMemoAction: Vc,
  moveMemoAction: Co,
  moveToMemoAction: dp,
  removeMemoAction: Gi,
  changeMemoValueAction: fp,
  changeMemoColorAction: jr,
  resizeMemoAction: mp,
  changeZIndexAction: hp
}, OM = 20, EM = (e, { payload: { id: t } }) => {
  e.push(Gi({ id: t }));
}, SM = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("memoEntities").selectById(t);
  o && e.push(Vc({ id: o.id, ui: Kr(o.ui, ["x", "y", "zIndex"]) }));
}, xM = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("memoEntities").selectById(t);
  o && e.push(fp({ id: t, value: o.value }));
}, AM = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("memoEntities").selectById(t);
  o && e.push(dp({
    id: t,
    x: o.ui.x,
    y: o.ui.y
  }));
}, MM = {
  [oe.addMemo]: EM,
  [oe.removeMemo]: SM,
  [oe.changeMemoValue]: xM,
  [oe.moveToMemo]: AM
}, NM = (e, t, n) => {
  const o = n.filter((s) => s.type === Co.type);
  if (!o.length)
    return;
  const r = is(o, (s) => s.payload.ids.join(","));
  for (const [, s] of Object.entries(r)) {
    const { payload: { ids: i } } = ss(s), { x: a, y: l } = s.reduce((c, { payload: { movementX: u, movementY: d } }) => (c.x += u, c.y += d, c), { x: 0, y: 0 });
    Math.abs(a) + Math.abs(l) < OM || (e.push(Co({
      ids: i,
      movementX: -1 * a,
      movementY: -1 * l
    })), t.push(Co({
      ids: i,
      movementX: a,
      movementY: l
    })));
  }
}, $M = (e, t, n) => {
  const o = n.filter(({ type: s }) => s === jr.type);
  if (!o.length)
    return;
  const r = is(o, (s) => s.payload.id);
  for (const [s, i] of Object.entries(r)) {
    const a = ss(i), l = Fo(i);
    e.push(jr({
      id: s,
      color: a.payload.prevColor,
      prevColor: l.payload.color
    })), t.push(jr({
      id: s,
      color: l.payload.color,
      prevColor: a.payload.prevColor
    }));
  }
}, kM = (e, t, n) => {
  const o = n.filter((s) => s.type === mp.type);
  if (!o.length)
    return;
  const r = is(o, (s) => s.payload.id);
  for (const [, s] of Object.entries(r)) {
    if (s.length < 2)
      continue;
    const i = ss(s), a = Fo(s);
    e.push(i), t.push(a);
  }
}, DM = {
  [oe.moveMemo]: NM,
  [oe.changeMemoColor]: $M,
  [oe.resizeMemo]: kM
}, Lt = {
  addRelationship: "relationship.add",
  removeRelationship: "relationship.remove",
  changeRelationshipType: "relationship.changeType"
}, pp = (e) => Jo({
  id: fe(),
  identification: !1,
  relationshipType: _h.ZeroN,
  startRelationshipType: Al.dash,
  start: {
    tableId: "",
    columnIds: [],
    x: 0,
    y: 0,
    direction: Ct.bottom
  },
  end: {
    tableId: "",
    columnIds: [],
    x: 0,
    y: 0,
    direction: Ct.bottom
  },
  meta: Yo()
}, e ?? {}), bs = I(Lt.addRelationship), RM = ({ doc: e, collections: t, lww: n }, { payload: { id: o, relationshipType: r, start: s, end: i }, timestamp: a }) => {
  y(t).collection("relationshipEntities").addOne(pp({
    id: o,
    relationshipType: r,
    start: {
      tableId: s.tableId,
      columnIds: s.columnIds
    },
    end: {
      tableId: i.tableId,
      columnIds: i.columnIds
    }
  })).addOperator(n, a, o, () => {
    $(e.relationshipIds)(o) || e.relationshipIds.push(o);
  });
}, _o = I(Lt.removeRelationship), LM = ({ doc: e, collections: t, lww: n }, { payload: { id: o }, timestamp: r }) => {
  y(t).collection("relationshipEntities").removeOperator(n, r, o, () => {
    const s = e.relationshipIds.indexOf(o);
    s !== -1 && e.relationshipIds.splice(s, 1);
  });
}, yp = I(Lt.changeRelationshipType), PM = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }) => {
  const s = y(e).collection("relationshipEntities");
  s.replaceOperator(t, r, n, "relationshipType", () => {
    s.updateOne(n, (i) => {
      i.relationshipType = o;
    });
  });
}, _M = {
  [Lt.addRelationship]: RM,
  [Lt.removeRelationship]: LM,
  [Lt.changeRelationshipType]: PM
}, BM = {
  addRelationshipAction: bs,
  removeRelationshipAction: _o,
  changeRelationshipTypeAction: yp
}, WM = (e, { payload: { id: t } }) => {
  e.push(_o({ id: t }));
}, UM = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("relationshipEntities").selectById(t);
  o && e.push(bs({
    id: o.id,
    relationshipType: o.relationshipType,
    start: Kr(o.start, ["tableId", "columnIds"]),
    end: Kr(o.end, ["tableId", "columnIds"])
  }));
}, jM = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("relationshipEntities").selectById(t);
  o && e.push(yp({
    id: t,
    value: o.relationshipType
  }));
}, FM = {
  [Lt.addRelationship]: WM,
  [Lt.removeRelationship]: UM,
  [Lt.changeRelationshipType]: jM
}, L = {
  changeDatabaseName: "settings.changeDatabaseName",
  resize: "settings.resize",
  changeZoomLevel: "settings.changeZoomLevel",
  streamZoomLevel: "settings.streamZoomLevel",
  scrollTo: "settings.scrollTo",
  streamScrollTo: "settings.streamScrollTo",
  changeShow: "settings.changeShow",
  changeDatabase: "settings.changeDatabase",
  changeCanvasType: "settings.changeCanvasType",
  changeLanguage: "settings.changeLanguage",
  changeTableNameCase: "settings.changeTableNameCase",
  changeColumnNameCase: "settings.changeColumnNameCase",
  changeBracketType: "settings.changeBracketType",
  changeRelationshipDataTypeSync: "settings.changeRelationshipDataTypeSync",
  changeRelationshipOptimization: "settings.changeRelationshipOptimization",
  changeColumnOrder: "settings.changeColumnOrder",
  changeMaxWidthComment: "settings.changeMaxWidthComment",
  changeIgnoreSaveSettings: "settings.changeIgnoreSaveSettings"
}, qM = I(L.changeDatabaseName), HM = ({ settings: e, lww: t }, { payload: { value: n }, timestamp: o }) => {
  Mm(t, o, "settings.databaseName", "settings", "databaseName", () => {
    e.databaseName = n;
  });
}, gp = I(L.resize), ZM = ({ settings: e }, { payload: { width: t, height: n } }) => {
  e.width = Nl(t), e.height = Nl(n);
}, zc = I(L.changeZoomLevel), VM = ({ settings: e }, { payload: { value: t }, tags: n }) => {
  !pe(n) && M(n, Vn.following) || (e.zoomLevel = Ta(t));
}, Fr = I(L.streamZoomLevel), zM = ({ settings: e }, { payload: { value: t }, tags: n }) => {
  !pe(n) && M(n, Vn.following) || (e.zoomLevel = Ta(e.zoomLevel + t));
}, Gc = I(L.scrollTo), GM = ({ settings: e, editor: { viewport: t } }, { payload: { scrollTop: n, scrollLeft: o }, tags: r }) => {
  if (!pe(r) && M(r, Vn.following))
    return;
  const s = Je(t.height - e.height, 0), i = Je(t.width - e.width, 0);
  e.scrollTop = ft(s(n), 4), e.scrollLeft = ft(i(o), 4);
}, qr = I(L.streamScrollTo), YM = ({ settings: e, editor: { viewport: t } }, { payload: { movementX: n, movementY: o }, tags: r }) => {
  if (!pe(r) && M(r, Vn.following))
    return;
  const s = Je(t.height - e.height, 0), i = Je(t.width - e.width, 0);
  e.scrollTop = ft(s(e.scrollTop + o), 4), e.scrollLeft = ft(i(e.scrollLeft + n), 4);
}, Yc = I(L.changeShow), KM = ({ settings: e }, { payload: { show: t, value: n } }) => {
  e.show = n ? e.show | t : e.show & ~t;
}, QM = I(L.changeDatabase), JM = ({ settings: e }, { payload: { value: t } }) => {
  Ax(t) && (e.database = t);
}, XM = I(L.changeCanvasType), eN = ({ settings: e }, { payload: { value: t }, tags: n }) => {
  !pe(n) && M(n, Vn.following) || (e.canvasType = t);
}, tN = I(L.changeLanguage), nN = ({ settings: e }, { payload: { value: t } }) => {
  Nx(t) && (e.language = t);
}, oN = I(L.changeTableNameCase), rN = ({ settings: e }, { payload: { value: t } }) => {
  Kh(t) && (e.tableNameCase = t);
}, sN = I(L.changeColumnNameCase), iN = ({ settings: e }, { payload: { value: t } }) => {
  Kh(t) && (e.columnNameCase = t);
}, aN = I(L.changeBracketType), lN = ({ settings: e }, { payload: { value: t } }) => {
  Mx(t) && (e.bracketType = t);
}, cN = I(L.changeRelationshipDataTypeSync), uN = ({ settings: e }, { payload: { value: t } }) => {
  e.relationshipDataTypeSync = t;
}, dN = I(L.changeRelationshipOptimization), fN = ({ settings: e }, { payload: { value: t } }) => {
  e.relationshipOptimization = t;
}, mN = I(L.changeColumnOrder), hN = ({ settings: e }, { payload: { value: t, target: n } }) => {
  if (t === n || !Od(t) || !Od(n))
    return;
  const o = e.columnOrder.indexOf(t), r = e.columnOrder.indexOf(n);
  o === -1 || r === -1 || (e.columnOrder.splice(o, 1), e.columnOrder.splice(r, 0, t));
}, vp = I(L.changeMaxWidthComment), pN = ({ settings: e }, { payload: { value: t } }) => {
  e.maxWidthComment = t === -1 ? t : Yh(t);
}, yN = I(L.changeIgnoreSaveSettings), gN = ({ settings: e }, { payload: { saveSettingType: t, value: n } }) => {
  e.ignoreSaveSettings = n ? e.ignoreSaveSettings | t : e.ignoreSaveSettings & ~t;
}, vN = {
  [L.changeDatabaseName]: HM,
  [L.resize]: ZM,
  [L.changeZoomLevel]: VM,
  [L.streamZoomLevel]: zM,
  [L.scrollTo]: GM,
  [L.streamScrollTo]: YM,
  [L.changeShow]: KM,
  [L.changeDatabase]: JM,
  [L.changeCanvasType]: eN,
  [L.changeLanguage]: nN,
  [L.changeTableNameCase]: rN,
  [L.changeColumnNameCase]: iN,
  [L.changeBracketType]: lN,
  [L.changeRelationshipDataTypeSync]: uN,
  [L.changeRelationshipOptimization]: fN,
  [L.changeColumnOrder]: hN,
  [L.changeMaxWidthComment]: pN,
  [L.changeIgnoreSaveSettings]: gN
}, bN = {
  changeDatabaseNameAction: qM,
  resizeAction: gp,
  changeZoomLevelAction: zc,
  streamZoomLevelAction: Fr,
  scrollToAction: Gc,
  streamScrollToAction: qr,
  changeShowAction: Yc,
  changeDatabaseAction: QM,
  changeCanvasTypeAction: XM,
  changeLanguageAction: tN,
  changeTableNameCaseAction: oN,
  changeColumnNameCaseAction: sN,
  changeBracketTypeAction: aN,
  changeRelationshipDataTypeSyncAction: cN,
  changeRelationshipOptimizationAction: dN,
  changeColumnOrderAction: mN,
  changeMaxWidthCommentAction: vp,
  changeIgnoreSaveSettingsAction: yN
}, IN = 20, TN = (e, t, { settings: n }) => {
  e.push(gp({ width: n.width, height: n.height }));
}, wN = (e, t, { settings: n }) => {
  e.push(Gc({
    scrollLeft: n.scrollLeft,
    scrollTop: n.scrollTop
  }));
}, CN = (e, { payload: { show: t, value: n } }) => {
  e.push(Yc({ show: t, value: !n }));
}, ON = (e, t, { settings: n }) => {
  e.push(zc({ value: n.zoomLevel }));
}, EN = {
  [L.resize]: TN,
  [L.scrollTo]: wN,
  [L.changeShow]: CN,
  [L.changeZoomLevel]: ON
}, SN = (e, t, n) => {
  const o = n.filter((i) => i.type === qr.type);
  if (!o.length)
    return;
  const { x: r, y: s } = o.reduce((i, { payload: { movementX: a, movementY: l } }) => (i.x += a, i.y += l, i), { x: 0, y: 0 });
  Math.abs(r) + Math.abs(s) < IN || (e.push(qr({
    movementX: -1 * r,
    movementY: -1 * s
  })), t.push(qr({
    movementX: r,
    movementY: s
  })));
}, xN = (e, t, n) => {
  const o = n.filter((s) => s.type === Fr.type);
  if (!o.length)
    return;
  const r = o.reduce((s, { payload: { value: i } }) => s + i, 0);
  e.push(Fr({ value: -1 * r })), t.push(Fr({ value: r }));
}, AN = {
  [L.streamScrollTo]: SN,
  [L.streamZoomLevel]: xN
}, Q = {
  addTable: "table.add",
  moveTable: "table.move",
  moveToTable: "table.moveTo",
  removeTable: "table.remove",
  changeTableName: "table.changeName",
  changeTableComment: "table.changeComment",
  changeTableColor: "table.changeColor",
  changeZIndex: "table.changeZIndex",
  sortTable: "table.sort"
};
function Kc(e, { settings: { show: t, maxWidthComment: n }, collections: o }) {
  let r = e.ui.widthName + $n;
  if (M(t, G.tableComment)) {
    const l = n === -1 ? e.ui.widthComment : n < e.ui.widthComment ? n : e.ui.widthComment;
    r += l + $n;
  }
  const s = NN(t);
  r < s && (r = s);
  const i = y(o).collection("tableColumnEntities").selectByIds(e.columnIds), a = $N(i, t, n);
  return r < a.width && (r = a.width), {
    ...a,
    width: qi + Hi + r + Hi + qi
  };
}
const MN = [
  {
    key: G.columnComment,
    width: Ze
  },
  {
    key: G.columnDataType,
    width: Ze
  },
  {
    key: G.columnDefault,
    width: Ze
  },
  {
    key: G.columnNotNull,
    width: Hh
  },
  {
    key: G.columnAutoIncrement,
    width: Vh
  },
  {
    key: G.columnUnique,
    width: Zh
  }
];
function NN(e) {
  return MN.reduce((t, { key: n, width: o }) => M(e, n) ? t + o + $n : t, qh + $n + Ze + $n + Fh);
}
function $N(e, t, n) {
  const o = {
    width: 0,
    name: 0,
    comment: 0,
    dataType: 0,
    default: 0,
    notNull: 0,
    autoIncrement: 0,
    unique: 0
  };
  for (const r of e) {
    if (o.name < r.ui.widthName && (o.name = r.ui.widthName), M(t, G.columnComment) && o.comment < r.ui.widthComment) {
      const s = n === -1 ? r.ui.widthComment : n < r.ui.widthComment ? n : r.ui.widthComment;
      o.comment = s;
    }
    M(t, G.columnDataType) && o.dataType < r.ui.widthDataType && (o.dataType = r.ui.widthDataType), M(t, G.columnDefault) && o.default < r.ui.widthDefault && (o.default = r.ui.widthDefault);
  }
  return M(t, G.columnNotNull) && (o.notNull = Hh), M(t, G.columnAutoIncrement) && (o.autoIncrement = Vh), M(t, G.columnUnique) && (o.unique = Zh), o.width = Object.entries(o).reduce((r, [s, i]) => s === "width" || i === 0 ? r : r + i + $n, qh + $n + Fh), o;
}
function Qc(e) {
  return qi + Hi + bx + e.columnIds.length * Tx + Hi + qi;
}
function kN({ doc: { tableIds: e }, collections: t }, { toWidth: n }) {
  const o = y(t).collection("tableEntities").selectByIds(e), r = y(t).collection("tableColumnEntities");
  for (const s of o) {
    s.ui.widthName = ge(n(s.name)), s.ui.widthComment = ge(n(s.comment));
    const i = r.selectByIds(s.columnIds);
    for (const a of i)
      a.ui.widthName = ge(n(a.name)), a.ui.widthDataType = ge(n(a.dataType)), a.ui.widthDefault = ge(n(a.default)), a.ui.widthComment = ge(n(a.comment));
  }
}
const gt = (e) => Jo({
  id: fe(),
  name: "",
  comment: "",
  columnIds: [],
  seqColumnIds: [],
  ui: {
    x: 200,
    y: 100,
    zIndex: 2,
    widthName: Ze,
    widthComment: Ze,
    color: ""
  },
  meta: Yo()
}, e ?? {}), Jc = I(Q.addTable), DN = ({ doc: e, collections: t, lww: n }, { payload: { id: o, ui: r }, timestamp: s }) => {
  y(t).collection("tableEntities").addOne(gt({ id: o, ui: r })).addOperator(n, s, o, () => {
    $(e.tableIds)(o) || e.tableIds.push(o);
  });
}, Oo = I(Q.moveTable), RN = ({ collections: e }, { payload: { ids: t, movementX: n, movementY: o } }) => {
  const r = y(e).collection("tableEntities");
  for (const s of t)
    r.getOrCreate(s, (i) => gt({ id: i }));
  r.updateMany(t, (s) => {
    s.ui.x = ft(s.ui.x + n, 4), s.ui.y = ft(s.ui.y + o, 4);
  });
}, Xc = I(Q.moveToTable), LN = ({ collections: e }, { payload: { id: t, x: n, y: o } }) => {
  const r = y(e).collection("tableEntities");
  r.getOrCreate(t, (s) => gt({ id: s })), r.updateOne(t, (s) => {
    s.ui.x = n, s.ui.y = o;
  });
}, Yi = I(Q.removeTable), PN = ({ doc: e, collections: t, lww: n }, { payload: { id: o }, timestamp: r }) => {
  y(t).collection("tableEntities").removeOperator(n, r, o, () => {
    const s = e.tableIds.indexOf(o);
    s !== -1 && e.tableIds.splice(s, 1);
  });
}, eu = I(Q.changeTableName), _N = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }, { toWidth: s }) => {
  const i = y(e).collection("tableEntities");
  i.getOrCreate(n, (a) => gt({ id: a })), i.replaceOperator(t, r, n, "name", () => {
    i.updateOne(n, (a) => {
      a.name = o, a.ui.widthName = ge(s(o));
    });
  });
}, tu = I(Q.changeTableComment), BN = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }, { toWidth: s }) => {
  const i = y(e).collection("tableEntities");
  i.getOrCreate(n, (a) => gt({ id: a })), i.replaceOperator(t, r, n, "comment", () => {
    i.updateOne(n, (a) => {
      a.comment = o, a.ui.widthComment = ge(s(o));
    });
  });
}, Hr = I(Q.changeTableColor), WN = ({ collections: e, lww: t }, { payload: { id: n, color: o }, timestamp: r }) => {
  const s = y(e).collection("tableEntities");
  s.getOrCreate(n, (i) => gt({ id: i })), s.replaceOperator(t, r, n, "ui.color", () => {
    s.updateOne(n, (i) => {
      i.ui.color = o;
    });
  });
}, bp = I(Q.changeZIndex), UN = ({ collections: e }, { payload: { id: t, zIndex: n } }) => {
  const o = y(e).collection("tableEntities");
  o.getOrCreate(t, (r) => gt({ id: r })), o.updateOne(t, (r) => {
    r.ui.zIndex = n;
  });
}, nu = I(Q.sortTable), jN = (e) => {
  const { doc: t, settings: n, collections: o } = e, r = n.width, s = y(o).collection("tableEntities").selectByIds(t.tableIds), i = 80;
  s.sort((u, d) => u.columnIds.length - d.columnIds.length);
  let a = 50, l = 50, c = 50;
  s.forEach((u) => {
    const d = Kc(u, e).width + i, f = Qc(u) + i;
    a + d > r && (l += c, c = 0, a = 50), c < f && (c = f), u.ui.y = l, u.ui.x = a, a += d;
  });
}, FN = {
  [Q.addTable]: DN,
  [Q.moveTable]: RN,
  [Q.moveToTable]: LN,
  [Q.removeTable]: PN,
  [Q.changeTableName]: _N,
  [Q.changeTableComment]: BN,
  [Q.changeTableColor]: WN,
  [Q.changeZIndex]: UN,
  [Q.sortTable]: jN
}, qN = {
  addTableAction: Jc,
  moveTableAction: Oo,
  moveToTableAction: Xc,
  removeTableAction: Yi,
  changeTableNameAction: eu,
  changeTableCommentAction: tu,
  changeTableColorAction: Hr,
  changeZIndexAction: bp,
  sortTableAction: nu
}, HN = 20, ZN = (e, { payload: { id: t } }) => {
  e.push(Yi({ id: t }));
}, VN = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("tableEntities").selectById(t);
  o && e.push(Jc({ id: o.id, ui: Kr(o.ui, ["x", "y", "zIndex"]) }));
}, zN = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("tableEntities").selectById(t);
  o && e.push(eu({ id: t, value: o.name }));
}, GN = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("tableEntities").selectById(t);
  o && e.push(tu({ id: t, value: o.comment }));
}, YN = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("tableEntities").selectById(t);
  o && e.push(Xc({
    id: t,
    x: o.ui.x,
    y: o.ui.y
  }));
}, KN = () => {
}, QN = {
  [Q.addTable]: ZN,
  [Q.removeTable]: VN,
  [Q.changeTableName]: zN,
  [Q.changeTableComment]: GN,
  [Q.moveToTable]: YN,
  [Q.sortTable]: KN
}, JN = (e, t, n) => {
  const o = n.filter((s) => s.type === Oo.type);
  if (!o.length)
    return;
  const r = is(o, (s) => s.payload.ids.join(","));
  for (const [, s] of Object.entries(r)) {
    const { payload: { ids: i } } = ss(s), { x: a, y: l } = s.reduce((c, { payload: { movementX: u, movementY: d } }) => (c.x += u, c.y += d, c), { x: 0, y: 0 });
    Math.abs(a) + Math.abs(l) < HN || (e.push(Oo({
      ids: i,
      movementX: -1 * a,
      movementY: -1 * l
    })), t.push(Oo({
      ids: i,
      movementX: a,
      movementY: l
    })));
  }
}, XN = (e, t, n) => {
  const o = n.filter(({ type: s }) => s === Hr.type);
  if (!o.length)
    return;
  const r = is(o, (s) => s.payload.id);
  for (const [s, i] of Object.entries(r)) {
    const a = ss(i), l = Fo(i);
    e.push(Hr({
      id: s,
      color: a.payload.prevColor,
      prevColor: l.payload.color
    })), t.push(Hr({
      id: s,
      color: l.payload.color,
      prevColor: a.payload.prevColor
    }));
  }
}, e$ = {
  [Q.moveTable]: JN,
  [Q.changeTableColor]: XN
}, F = {
  addColumn: "column.add",
  removeColumn: "column.remove",
  changeColumnName: "column.changeName",
  changeColumnComment: "column.changeComment",
  changeColumnDataType: "column.changeDataType",
  changeColumnDefault: "column.changeDefault",
  changeColumnAutoIncrement: "column.changeAutoIncrement",
  changeColumnPrimaryKey: "column.changePrimaryKey",
  changeColumnUnique: "column.changeUnique",
  changeColumnNotNull: "column.changeNotNull",
  moveColumn: "column.move"
}, Mt = (e) => Jo({
  id: fe(),
  tableId: "",
  name: "",
  comment: "",
  dataType: "",
  default: "",
  options: 0,
  ui: {
    keys: 0,
    widthName: Ze,
    widthComment: Ze,
    widthDataType: Ze,
    widthDefault: Ze
  },
  meta: Yo()
}, e ?? {}), At = I(F.addColumn), t$ = ({ collections: e, lww: t }, { payload: { id: n, tableId: o }, timestamp: r }) => {
  const s = y(e).collection("tableEntities"), i = s.getOrCreate(o, (a) => gt({ id: a }));
  y(e).collection("tableColumnEntities").addOne(Mt({ id: n, tableId: o })).addOperator(t, r, n, () => {
    $(i.columnIds)(n) || s.updateOne(o, (a) => {
      up(a.columnIds, a.seqColumnIds, n);
    });
  });
}, Eo = I(F.removeColumn), n$ = ({ collections: e, lww: t }, { payload: { id: n, tableId: o }, timestamp: r }) => {
  const s = y(e).collection("tableEntities"), i = s.getOrCreate(o, (a) => gt({ id: a }));
  y(e).collection("tableColumnEntities").removeOperator(t, r, n, () => {
    const a = i.columnIds.indexOf(n);
    a !== -1 && s.updateOne(o, (l) => {
      l.columnIds.splice(a, 1);
    });
  });
}, Pt = I(F.changeColumnName), o$ = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }, { toWidth: s }) => {
  const i = y(e).collection("tableColumnEntities");
  i.getOrCreate(n, (a) => Mt({ id: a })), i.replaceOperator(t, r, n, "name", () => {
    i.updateOne(n, (a) => {
      a.name = o, a.ui.widthName = ge(s(o));
    });
  });
}, _t = I(F.changeColumnComment), r$ = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }, { toWidth: s }) => {
  const i = y(e).collection("tableColumnEntities");
  i.getOrCreate(n, (a) => Mt({ id: a })), i.replaceOperator(t, r, n, "comment", () => {
    i.updateOne(n, (a) => {
      a.comment = o, a.ui.widthComment = ge(s(o));
    });
  });
}, Bt = I(F.changeColumnDataType), s$ = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }, { toWidth: s }) => {
  const i = y(e).collection("tableColumnEntities");
  i.getOrCreate(n, (a) => Mt({ id: a })), i.replaceOperator(t, r, n, "dataType", () => {
    i.updateOne(n, (a) => {
      a.dataType = o, a.ui.widthDataType = ge(s(o));
    });
  });
}, Wt = I(F.changeColumnDefault), i$ = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }, { toWidth: s }) => {
  const i = y(e).collection("tableColumnEntities");
  i.getOrCreate(n, (a) => Mt({ id: a })), i.replaceOperator(t, r, n, "default", () => {
    i.updateOne(n, (a) => {
      a.default = o, a.ui.widthDefault = ge(s(o));
    });
  });
}, kn = I(F.changeColumnAutoIncrement), a$ = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }) => {
  const s = y(e).collection("tableColumnEntities");
  s.getOrCreate(n, (i) => Mt({ id: i })), s.replaceOperator(t, r, n, "options(autoIncrement)", () => {
    s.updateOne(n, (i) => {
      i.options = o ? i.options | U.autoIncrement : i.options & ~U.autoIncrement;
    });
  });
}, Yn = I(F.changeColumnPrimaryKey), l$ = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }) => {
  const s = y(e).collection("tableColumnEntities");
  s.getOrCreate(n, (i) => Mt({ id: i })), s.replaceOperator(t, r, n, "options(primaryKey)", () => {
    s.updateOne(n, (i) => {
      i.options = o ? i.options | U.primaryKey : i.options & ~U.primaryKey, i.ui.keys = o ? i.ui.keys | Pe.primaryKey : i.ui.keys & ~Pe.primaryKey;
    });
  });
}, Dn = I(F.changeColumnUnique), c$ = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }) => {
  const s = y(e).collection("tableColumnEntities");
  s.getOrCreate(n, (i) => Mt({ id: i })), s.replaceOperator(t, r, n, "options(unique)", () => {
    s.updateOne(n, (i) => {
      i.options = o ? i.options | U.unique : i.options & ~U.unique;
    });
  });
}, Ut = I(F.changeColumnNotNull), u$ = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }) => {
  const s = y(e).collection("tableColumnEntities");
  s.getOrCreate(n, (i) => Mt({ id: i })), s.replaceOperator(t, r, n, "options(notNull)", () => {
    s.updateOne(n, (i) => {
      i.options = o ? i.options | U.notNull : i.options & ~U.notNull;
    });
  });
}, Ki = I(F.moveColumn), d$ = ({ collections: e }, { payload: { id: t, tableId: n, targetId: o } }) => {
  if (t === o)
    return;
  const r = y(e).collection("tableEntities"), s = r.getOrCreate(n, (l) => gt({ id: l })), i = s.columnIds.indexOf(t);
  if (i === -1)
    return;
  const a = s.columnIds.indexOf(o);
  a !== -1 && r.updateOne(n, (l) => {
    l.columnIds.splice(i, 1), l.columnIds.splice(a, 0, t);
    const c = l.seqColumnIds.indexOf(t), u = l.seqColumnIds.indexOf(o);
    c !== -1 && u !== -1 && (l.seqColumnIds.splice(c, 1), l.seqColumnIds.splice(u, 0, t));
  });
}, f$ = {
  [F.addColumn]: t$,
  [F.removeColumn]: n$,
  [F.changeColumnName]: o$,
  [F.changeColumnComment]: r$,
  [F.changeColumnDataType]: s$,
  [F.changeColumnDefault]: i$,
  [F.changeColumnAutoIncrement]: a$,
  [F.changeColumnPrimaryKey]: l$,
  [F.changeColumnUnique]: c$,
  [F.changeColumnNotNull]: u$,
  [F.moveColumn]: d$
}, m$ = {
  addColumnAction: At,
  removeColumnAction: Eo,
  changeColumnNameAction: Pt,
  changeColumnCommentAction: _t,
  changeColumnDataTypeAction: Bt,
  changeColumnDefaultAction: Wt,
  changeColumnAutoIncrementAction: kn,
  changeColumnPrimaryKeyAction: Yn,
  changeColumnUniqueAction: Dn,
  changeColumnNotNullAction: Ut,
  moveColumnAction: Ki
}, h$ = (e, { payload: { id: t, tableId: n } }) => {
  e.push(Eo({ id: t, tableId: n }));
}, p$ = (e, { payload: { id: t, tableId: n } }) => {
  e.push(At({ id: t, tableId: n }));
}, y$ = (e, { payload: { id: t, tableId: n } }, { collections: o }) => {
  const r = y(o).collection("tableColumnEntities").selectById(t);
  r && e.push(Pt({ id: t, tableId: n, value: r.name }));
}, g$ = (e, { payload: { id: t, tableId: n } }, { collections: o }) => {
  const r = y(o).collection("tableColumnEntities").selectById(t);
  r && e.push(Bt({ id: t, tableId: n, value: r.dataType }));
}, v$ = (e, { payload: { id: t, tableId: n } }, { collections: o }) => {
  const r = y(o).collection("tableColumnEntities").selectById(t);
  r && e.push(Wt({ id: t, tableId: n, value: r.default }));
}, b$ = (e, { payload: { id: t, tableId: n } }, { collections: o }) => {
  const r = y(o).collection("tableColumnEntities").selectById(t);
  r && e.push(_t({ id: t, tableId: n, value: r.comment }));
}, I$ = (e, { payload: { id: t, tableId: n, value: o } }, { collections: r }) => {
  y(r).collection("tableColumnEntities").selectById(t) && e.push(kn({
    id: t,
    tableId: n,
    value: !o
  }));
}, T$ = (e, { payload: { id: t, tableId: n, value: o } }, { collections: r }) => {
  y(r).collection("tableColumnEntities").selectById(t) && e.push(Ut({
    id: t,
    tableId: n,
    value: !o
  }));
}, w$ = (e, { payload: { id: t, tableId: n, value: o } }, { collections: r }) => {
  y(r).collection("tableColumnEntities").selectById(t) && e.push(Yn({
    id: t,
    tableId: n,
    value: !o
  }));
}, C$ = (e, { payload: { id: t, tableId: n, value: o } }, { collections: r }) => {
  y(r).collection("tableColumnEntities").selectById(t) && e.push(Dn({
    id: t,
    tableId: n,
    value: !o
  }));
}, O$ = (e, { payload: { id: t, tableId: n, targetId: o } }, { collections: r }) => {
  const s = y(r).collection("tableEntities").selectById(n);
  if (!s)
    return;
  const i = s.columnIds.indexOf(t);
  if (i === -1)
    return;
  const a = s.columnIds.indexOf(o);
  if (a === -1)
    return;
  const l = i < a ? i + 1 : i - 1, c = s.columnIds[l];
  e.push(Ki({
    id: t,
    tableId: n,
    targetId: c
  }));
}, E$ = {
  [F.addColumn]: h$,
  [F.removeColumn]: p$,
  [F.changeColumnName]: y$,
  [F.changeColumnDataType]: g$,
  [F.changeColumnDefault]: v$,
  [F.changeColumnComment]: b$,
  [F.changeColumnAutoIncrement]: I$,
  [F.changeColumnNotNull]: T$,
  [F.changeColumnPrimaryKey]: w$,
  [F.changeColumnUnique]: C$,
  [F.moveColumn]: O$
}, Ip = {
  ...QN,
  ...E$,
  ...FM,
  ...MM,
  ...EN,
  ...NA,
  ...nM,
  ...mM
}, ts = {
  ...e$,
  ...DM,
  ...AN
};
function S$(e, t, n) {
  const o = [], r = [];
  for (const s of n) {
    const i = Ip[s.type];
    i && (i(o, s, e.state), r.push(da(s)));
  }
  for (const s of Object.keys(ts))
    ts[s](o, r, n);
  !o.length || !r.length || t.push({
    undo: (s) => {
      const i = Date.now();
      s(o.map(kd(i)));
    },
    redo: (s) => {
      const i = Date.now();
      s(r.map(kd(i)));
    }
  });
}
function kd(e) {
  return (t) => ({
    ...da(t),
    timestamp: e
  });
}
const RL = (e, t) => (n) => {
  s1(S$, e, t, n);
}, x$ = () => function* ({ settings: e, doc: { tableIds: t, memoIds: n }, collections: o }) {
  const r = y(o).collection("tableEntities").selectByIds(t), s = y(o).collection("memoEntities").selectByIds(n), i = Jh(e, r, s), a = fe();
  yield Aa(), yield Qo({ [a]: De.memo }), yield Vc({
    id: a,
    ui: {
      ...i,
      zIndex: wa(r, s)
    }
  });
}, Tp = (e) => function* ({ editor: { selectedMap: t } }) {
  if (e) {
    yield Gi({ id: e });
    return;
  }
  const n = Object.entries(t).filter(([, o]) => o === De.memo);
  for (const [o] of n)
    yield Gi({ id: o });
}, A$ = (e, t) => function* ({ doc: { tableIds: n, memoIds: o }, collections: r }) {
  const s = y(r).collection("tableEntities").selectByIds(n), i = y(r).collection("memoEntities").selectByIds(o);
  t || (yield Aa()), yield Qo({ [e]: De.memo }), yield hp({ id: e, zIndex: wa(s, i) });
}, M$ = {
  addMemoAction$: x$,
  removeMemoAction$: Tp,
  selectMemoAction$: A$
}, N$ = {
  columnName: "columnName",
  columnDataType: "columnDataType",
  columnNotNull: "columnNotNull",
  columnUnique: "columnUnique",
  columnAutoIncrement: "columnAutoIncrement",
  columnDefault: "columnDefault",
  columnComment: "columnComment"
}, LL = $(Object.values(N$));
function $$(e, t) {
  return t.filter((n) => {
    switch (n) {
      case P.columnName:
        return !0;
      case P.columnDataType:
        return M(e, G.columnDataType);
      case P.columnDefault:
        return M(e, G.columnDefault);
      case P.columnComment:
        return M(e, G.columnComment);
      case P.columnAutoIncrement:
        return M(e, G.columnAutoIncrement);
      case P.columnUnique:
        return M(e, G.columnUnique);
      case P.columnNotNull:
        return M(e, G.columnNotNull);
      default:
        return !1;
    }
  });
}
const k$ = () => function* ({ settings: e, doc: { tableIds: t, memoIds: n }, collections: o }) {
  const r = y(o).collection("tableEntities").selectByIds(t), s = y(o).collection("memoEntities").selectByIds(n), i = Jh(e, r, s), a = fe();
  yield Oa(), yield Qo({ [a]: De.table }), yield Jc({
    id: a,
    ui: {
      ...i,
      zIndex: wa(r, s)
    }
  }), yield Ea({ tableId: a });
}, wp = (e) => function* ({ doc: { relationshipIds: t, indexIds: n }, editor: { selectedMap: o }, collections: r }) {
  const s = y(r).collection("relationshipEntities").selectByIds(t), i = y(r).collection("indexEntities").selectByIds(n);
  if (e) {
    const d = s.filter(({ start: h, end: p }) => h.tableId === e || p.tableId === e), f = i.filter(({ tableId: h }) => h === e);
    for (const { id: h } of f)
      yield es({ id: h });
    for (const { id: h } of d)
      yield _o({ id: h });
    yield Yi({ id: e });
    return;
  }
  const a = Object.entries(o).filter(([, d]) => d === De.table).map(([d]) => d), l = $(a), c = s.filter(({ start: d, end: f }) => l(d.tableId) || l(f.tableId)), u = i.filter(({ tableId: d }) => l(d));
  for (const { id: d } of u)
    yield es({ id: d });
  for (const { id: d } of c)
    yield _o({ id: d });
  for (const d of a)
    yield Yi({ id: d });
}, D$ = (e, t) => function* ({ doc: { tableIds: n, memoIds: o }, collections: r, editor: { drawRelationship: s } }) {
  const i = y(r).collection("tableEntities"), a = i.selectByIds(n), l = y(r).collection("memoEntities").selectByIds(o);
  if (t || (yield Oa()), yield Qo({ [e]: De.table }), yield bp({ id: e, zIndex: wa(a, l) }), yield Ea({ tableId: e }), !!s)
    if (s.start) {
      const c = i.selectById(s.start.tableId), u = i.selectById(e);
      if (!c || !u)
        return;
      const d = y(r).collection("tableColumnEntities").selectByIds(c.columnIds).filter(({ options: h }) => M(h, U.primaryKey));
      if (!d.length)
        return;
      const f = d.map(() => fe());
      for (let h = 0; h < d.length; h++) {
        const p = d[h], v = {
          id: f[h],
          tableId: u.id
        };
        yield [
          At(v),
          Ut({
            ...v,
            value: !0
          }),
          Pt({
            ...v,
            value: p.name
          }),
          Bt({
            ...v,
            value: p.dataType
          }),
          Wt({
            ...v,
            value: p.default
          }),
          _t({
            ...v,
            value: p.comment
          })
        ];
      }
      yield bs({
        id: fe(),
        relationshipType: s.relationshipType,
        start: {
          tableId: c.id,
          columnIds: d.map(({ id: h }) => h)
        },
        end: {
          tableId: u.id,
          columnIds: f
        }
      }), yield Pc();
    } else
      yield _p(e);
}, R$ = (e) => function* ({ editor: { selectedMap: t, focusTable: n }, settings: { show: o, columnOrder: r }, collections: s }) {
  const i = n && !Wn(n.focusType), a = Object.entries(t).filter(([, p]) => p === De.table).map(([p]) => p).filter((p) => i ? p !== (n == null ? void 0 : n.tableId) : !0);
  for (const p of a)
    for (const g of e) {
      const T = {
        id: fe(),
        tableId: p
      };
      yield [
        At(T),
        Pt({
          ...T,
          value: g.name
        }),
        Bt({
          ...T,
          value: g.dataType
        }),
        Wt({
          ...T,
          value: g.default
        }),
        _t({
          ...T,
          value: g.comment
        }),
        Ut({
          ...T,
          value: M(g.options, U.notNull)
        }),
        Dn({
          ...T,
          value: M(g.options, U.unique)
        }),
        kn({
          ...T,
          value: M(g.options, U.autoIncrement)
        })
      ];
    }
  if (!n || !i)
    return;
  const l = y(s).collection("tableEntities").selectById(n.tableId);
  if (!l)
    return;
  const c = l.columnIds.filter($(n.selectColumnIds));
  if (c.length === 0)
    return;
  const u = l.columnIds.slice(l.columnIds.indexOf(c[c.length - 1]) + 1), d = [...c, ...u], f = $$(o, r);
  yield Lc();
  for (let p = 0; p < d.length; p++) {
    const g = d[p], v = e[p];
    if (!v)
      break;
    const T = {
      id: g,
      tableId: l.id
    };
    yield [
      ...f.map((O) => {
        switch (O) {
          case P.columnName:
            return Pt({
              ...T,
              value: v.name
            });
          case P.columnDataType:
            return Bt({
              ...T,
              value: v.dataType
            });
          case P.columnDefault:
            return Wt({
              ...T,
              value: v.default
            });
          case P.columnComment:
            return _t({
              ...T,
              value: v.comment
            });
          case P.columnAutoIncrement:
            return kn({
              ...T,
              value: M(v.options, U.autoIncrement)
            });
          case P.columnUnique:
            return Dn({
              ...T,
              value: M(v.options, U.unique)
            });
          case P.columnNotNull:
            return Ut({
              ...T,
              value: M(v.options, U.notNull)
            });
          default:
            return null;
        }
      }).filter(Boolean),
      ln({
        tableId: l.id,
        columnId: g,
        focusType: n.focusType,
        $mod: !0,
        shiftKey: !1
      })
    ];
  }
  const h = e.slice(d.length);
  for (const p of h) {
    const g = fe(), v = {
      id: g,
      tableId: l.id
    };
    yield [
      At(v),
      ...f.map((T) => {
        switch (T) {
          case P.columnName:
            return Pt({
              ...v,
              value: p.name
            });
          case P.columnDataType:
            return Bt({
              ...v,
              value: p.dataType
            });
          case P.columnDefault:
            return Wt({
              ...v,
              value: p.default
            });
          case P.columnComment:
            return _t({
              ...v,
              value: p.comment
            });
          case P.columnAutoIncrement:
            return kn({
              ...v,
              value: M(p.options, U.autoIncrement)
            });
          case P.columnUnique:
            return Dn({
              ...v,
              value: M(p.options, U.unique)
            });
          case P.columnNotNull:
            return Ut({
              ...v,
              value: M(p.options, U.notNull)
            });
          default:
            return null;
        }
      }).filter(Boolean),
      ln({
        tableId: l.id,
        columnId: g,
        focusType: n.focusType,
        $mod: !0,
        shiftKey: !1
      })
    ];
  }
}, L$ = {
  addTableAction$: k$,
  removeTableAction$: wp,
  selectTableAction$: D$,
  pasteTableAction$: R$
};
function Cp(e, t, n, o = []) {
  const { doc: { relationshipIds: r }, collections: s } = t, i = e.pop();
  return i && (o.some(({ id: a }) => a === i.id) || (o.push(i), y(s).collection("relationshipEntities").selectByIds(r).forEach(({ start: a, end: l }) => {
    const c = a.columnIds.indexOf(i.id);
    if (c !== -1) {
      const u = l.columnIds[c];
      e.push({
        id: u,
        tableId: l.tableId,
        value: n.value
      });
    } else {
      const u = l.columnIds.indexOf(i.id);
      if (u !== -1) {
        const d = a.columnIds[u];
        e.push({
          id: d,
          tableId: a.tableId,
          value: n.value
        });
      }
    }
  })), Cp(e, t, n, o)), o;
}
const P$ = $([
  W.columnNotNull,
  W.columnUnique,
  W.columnAutoIncrement
]), _$ = $([
  W.columnName,
  W.columnDataType,
  W.columnDefault,
  W.columnComment
]), Op = (e) => function* ({ editor: { selectedMap: t } }) {
  if (e) {
    const r = fe();
    yield At({
      id: r,
      tableId: e
    }), yield ln({
      tableId: e,
      columnId: r,
      focusType: W.columnName,
      $mod: !1,
      shiftKey: !1
    });
    return;
  }
  const n = Object.entries(t).filter(([, r]) => r === De.table).map(([r]) => ({ tableId: r, id: fe() }));
  for (const r of n)
    yield At(r);
  const o = Fo(n);
  o && (yield ln({
    tableId: o.tableId,
    columnId: o.id,
    focusType: W.columnName,
    $mod: !1,
    shiftKey: !1
  }));
}, Ep = (e, t) => function* (n) {
  const { doc: { relationshipIds: o, indexIds: r }, editor: { focusTable: s }, collections: i } = n;
  if (s != null && s.columnId) {
    const u = Wx(n, t);
    u ? yield ln({
      tableId: s.tableId,
      columnId: u,
      focusType: s.focusType,
      $mod: !1,
      shiftKey: !1
    }) : yield Ea({
      tableId: s.tableId,
      focusType: W.tableName
    });
  }
  const a = $(t), l = y(i).collection("relationshipEntities").selectByIds(o).filter(({ start: u, end: d }) => u.tableId === e && u.columnIds.some(a) || d.tableId === e && d.columnIds.some(a)), c = y(i).collection("indexEntities").selectByIds(r).filter((u) => u.tableId === e && y(i).collection("indexColumnEntities").selectByIds(u.indexColumnIds).map(({ columnId: d }) => d).some(a));
  for (const { id: u } of c)
    yield es({ id: u });
  for (const { id: u } of l)
    yield _o({ id: u });
  for (const u of t)
    yield Eo({
      id: u,
      tableId: e
    });
}, B$ = (e, t, n) => function* ({ collections: o }) {
  if (!P$(e))
    return;
  const r = y(o).collection("tableColumnEntities").selectById(n);
  if (r)
    switch (e) {
      case W.columnNotNull:
        yield Ut({
          id: n,
          tableId: t,
          value: !M(r.options, U.notNull)
        });
        break;
      case W.columnUnique:
        yield Dn({
          id: n,
          tableId: t,
          value: !M(r.options, U.unique)
        });
        break;
      case W.columnAutoIncrement:
        yield kn({
          id: n,
          tableId: t,
          value: !M(r.options, U.autoIncrement)
        });
        break;
    }
}, Sp = (e) => function* (t) {
  const { settings: { relationshipDataTypeSync: n } } = t;
  let o = [e];
  n && (o = Cp([e], t, e)), yield o.map(Bt);
}, W$ = (e, t, n, o) => function* ({ collections: r }) {
  if (!_$(e) || !y(r).collection("tableColumnEntities").selectById(n))
    return;
  const i = {
    id: n,
    tableId: t,
    value: o
  };
  switch (e) {
    case W.columnName:
      yield Pt(i);
      break;
    case W.columnDataType:
      yield Sp(i);
      break;
    case W.columnDefault:
      yield Wt(i);
      break;
    case W.columnComment:
      yield _t(i);
      break;
  }
}, U$ = (e, t) => function* ({ collections: n }) {
  const o = y(n).collection("tableColumnEntities").selectById(t);
  if (!o)
    return;
  const r = M(o.options, U.primaryKey);
  yield Yn({
    tableId: e,
    id: t,
    value: !r
  });
}, j$ = {
  addColumnAction$: Op,
  removeColumnAction$: Ep,
  toggleColumnValueAction$: B$,
  changeColumnDataTypeAction$: Sp,
  changeColumnValueAction$: W$,
  changeColumnPrimaryKeyAction$: U$
};
function F$(e) {
  return Zi + Vi + e.ui.width + Vi + Zi;
}
function q$(e) {
  return Zi + Vi + zh + e.ui.height + Vi + Zi;
}
/*!
 * @dineug/schema-sql-parser
 * @version 0.1.0 | Sat Nov 09 2024
 * @author SeungHwan-Lee <dineug2@gmail.com>
 * @license MIT
 */
const H$ = [
  "BIGINT",
  "BINARY",
  "BIT",
  "BLOB",
  "BOOL",
  "BOOLEAN",
  "CHAR",
  "DATE",
  "DATETIME",
  "DEC",
  "DECIMAL",
  "DOUBLE PRECISION",
  "DOUBLE",
  "ENUM",
  "FIXED",
  "FLOAT",
  "GEOMETRY",
  "GEOMETRYCOLLECTION",
  "INT",
  "INTEGER",
  "JSON",
  "LINESTRING",
  "LONGBLOB",
  "LONGTEXT",
  "MEDIUMBLOB",
  "MEDIUMINT",
  "MEDIUMTEXT",
  "MULTILINESTRING",
  "MULTIPOINT",
  "MULTIPOLYGON",
  "NUMERIC",
  "POINT",
  "POLYGON",
  "REAL",
  "SET",
  "SMALLINT",
  "TEXT",
  "TIME",
  "TIMESTAMP",
  "TINYBLOB",
  "TINYINT",
  "TINYTEXT",
  "VARBINARY",
  "VARCHAR",
  "YEAR"
], Z$ = [
  "BIGINT",
  "BINARY",
  "BIT",
  "CHAR",
  "DATE",
  "DATETIME",
  "DATETIME2",
  "DATETIMEOFFSET",
  "DECIMAL",
  "FLOAT",
  "GEOGRAPHY",
  "GEOMETRY",
  "IMAGE",
  "INT",
  "MONEY",
  "NCHAR",
  "NTEXT",
  "NUMERIC",
  "NVARCHAR",
  "REAL",
  "SMALLDATETIME",
  "SMALLINT",
  "SMALLMONEY",
  "SQL_VARIANT",
  "TEXT",
  "TIME",
  "TINYINT",
  "UNIQUEIDENTIFIER",
  "VARBINARY",
  "VARCHAR",
  "XML"
], V$ = [
  "BIGINT",
  "BINARY",
  "BIT",
  "BLOB",
  "BOOL",
  "BOOLEAN",
  "CHAR",
  "DATE",
  "DATETIME",
  "DEC",
  "DECIMAL",
  "DOUBLE PRECISION",
  "DOUBLE",
  "ENUM",
  "FLOAT",
  "GEOMETRY",
  "GEOMETRYCOLLECTION",
  "INT",
  "INTEGER",
  "JSON",
  "LINESTRING",
  "LONGBLOB",
  "LONGTEXT",
  "MEDIUMBLOB",
  "MEDIUMINT",
  "MEDIUMTEXT",
  "MULTILINESTRING",
  "MULTIPOINT",
  "MULTIPOLYGON",
  "NUMERIC",
  "POINT",
  "POLYGON",
  "SET",
  "SMALLINT",
  "TEXT",
  "TIME",
  "TIMESTAMP",
  "TINYBLOB",
  "TINYINT",
  "TINYTEXT",
  "VARBINARY",
  "VARCHAR",
  "YEAR"
], z$ = [
  "BFILE",
  "BINARY_DOUBLE",
  "BINARY_FLOAT",
  "BLOB",
  "CHAR",
  "CLOB",
  "DATE",
  "DATETIME",
  "LONG RAW",
  "LONG",
  "NCHAR",
  "NCLOB",
  "NUMBER",
  "NVARCHAR2",
  "RAW",
  "TIMESTAMP WITH LOCAL TIME ZONE",
  "TIMESTAMP WITH TIME ZONE",
  "TIMESTAMP",
  "UriType",
  "VARCHAR",
  "VARCHAR2",
  "XMLType"
], G$ = [
  "BIGINT",
  "BIGSERIAL",
  "BIT VARYING",
  "BIT",
  "BOOL",
  "BOOLEAN",
  "BOX",
  "BYTEA",
  "CHAR",
  "CHARACTER VARYING",
  "CHARACTER",
  "CIDR",
  "CIRCLE",
  "DATE",
  "DECIMAL",
  "DOUBLE PRECISION",
  "FLOAT4",
  "FLOAT8",
  "INET",
  "INT",
  "INT2",
  "INT4",
  "INT8",
  "INTEGER",
  "INTERVAL",
  "JSON",
  "JSONB",
  "LINE",
  "LSEG",
  "MACADDR",
  "MACADDR8",
  "MONEY",
  "NUMERIC",
  "PATH",
  "PG_LSN",
  "POINT",
  "POLYGON",
  "REAL",
  "SERIAL",
  "SERIAL2",
  "SERIAL4",
  "SERIAL8",
  "SMALLINT",
  "SMALLSERIAL",
  "TEXT",
  "TIME WITH",
  "TIME",
  "TIMESTAMP WITH",
  "TIMESTAMP",
  "TIMESTAMPTZ",
  "TIMETZ",
  "TSQUERY",
  "TSVECTOR",
  "TXID_SNAPSHOT",
  "UUID",
  "VARBIT",
  "VARCHAR",
  "XML"
], Y$ = [
  "BLOB",
  "INTEGER",
  "NUMERIC",
  "REAL",
  "TEXT"
], be = {
  string: "string",
  leftParent: "leftParent",
  rightParent: "rightParent",
  leftBracket: "leftBracket",
  rightBracket: "rightBracket",
  comma: "comma",
  period: "period",
  equal: "equal",
  semicolon: "semicolon"
}, Se = {
  doubleQuote: '"',
  singleQuote: "'",
  backtick: "`",
  whiteSpace: /\s/,
  string: /\S/,
  breakString: /;|,|\(|\)|\[|\./,
  equal: "=",
  period: ".",
  comma: ",",
  semicolon: ";",
  leftParent: "(",
  rightParent: ")",
  leftBracket: "[",
  rightBracket: "]"
}, st = (e) => (t) => e === t, Ya = (e) => (t) => e.test(t), me = {
  doubleQuote: st(Se.doubleQuote),
  singleQuote: st(Se.singleQuote),
  backtick: st(Se.backtick),
  whiteSpace: Ya(Se.whiteSpace),
  string: Ya(Se.string),
  breakString: Ya(Se.breakString),
  equal: st(Se.equal),
  period: st(Se.period),
  comma: st(Se.comma),
  semicolon: st(Se.semicolon),
  leftParent: st(Se.leftParent),
  rightParent: st(Se.rightParent),
  leftBracket: st(Se.leftBracket),
  rightBracket: st(Se.rightBracket)
};
function K$(e) {
  const t = [];
  let n = 0;
  const o = () => n < e.length;
  for (; o(); ) {
    let r = e[n];
    if (me.whiteSpace(r)) {
      for (; o() && me.whiteSpace(r); )
        r = e[++n];
      continue;
    }
    if (me.leftParent(r)) {
      t.push({ type: be.leftParent, value: r }), n++;
      continue;
    }
    if (me.rightParent(r)) {
      t.push({ type: be.rightParent, value: r }), n++;
      continue;
    }
    if (me.leftBracket(r)) {
      t.push({ type: be.leftBracket, value: r }), n++;
      continue;
    }
    if (me.rightBracket(r)) {
      t.push({ type: be.rightBracket, value: r }), n++;
      continue;
    }
    if (me.comma(r)) {
      t.push({ type: be.comma, value: r }), n++;
      continue;
    }
    if (me.period(r)) {
      t.push({ type: be.period, value: r }), n++;
      continue;
    }
    if (me.equal(r)) {
      t.push({ type: be.equal, value: r }), n++;
      continue;
    }
    if (me.semicolon(r)) {
      t.push({ type: be.semicolon, value: r }), n++;
      continue;
    }
    if (me.doubleQuote(r)) {
      let s = "";
      for (r = e[++n]; o() && !me.doubleQuote(r); )
        s += r, r = e[++n];
      t.push({ type: be.string, value: s }), n++;
      continue;
    }
    if (me.singleQuote(r)) {
      let s = "";
      for (r = e[++n]; o() && !me.singleQuote(r); )
        s += r, r = e[++n];
      t.push({ type: be.string, value: s }), n++;
      continue;
    }
    if (me.backtick(r)) {
      let s = "";
      for (r = e[++n]; o() && !me.backtick(r); )
        s += r, r = e[++n];
      t.push({ type: be.string, value: s }), n++;
      continue;
    }
    if (me.string(r)) {
      let s = "";
      for (; o() && me.string(r) && !me.breakString(r); )
        s += r, r = e[++n];
      t.push({ type: be.string, value: s });
      continue;
    }
    n++;
  }
  return t;
}
const Is = (e) => (t) => (n) => t[n] ? t[n].type === e : !1, Y = (e) => {
  const t = e.toUpperCase();
  return (n) => (o) => n[o] ? n[o].value.toUpperCase() === t : !1;
}, mn = Is(be.string), Ts = Is(be.period), xp = Is(be.comma), Xo = Is(be.leftParent), ws = Is(be.rightParent), Cs = Y("CREATE"), Sa = Y("ALTER"), Q$ = Y("DROP"), J$ = Y("USE"), X$ = Y("RENAME"), ek = Y("DELETE"), tk = Y("SELECT"), hn = Y("TABLE"), xa = Y("INDEX"), Os = Y("UNIQUE"), ou = Y("ADD"), ru = Y("PRIMARY"), Es = Y("KEY"), Kn = Y("CONSTRAINT"), su = Y("FOREIGN"), Ap = Y("NOT"), nk = Y("NULL"), ok = Y("DEFAULT"), Mp = Y("COMMENT"), rk = Y("REFERENCES"), Np = Y("ASC"), $p = Y("DESC"), sk = Y("ON"), ik = Y("AUTO_INCREMENT"), ak = Y("AUTOINCREMENT"), lk = Y("IF"), ck = Y("EXISTS"), uk = (e) => {
  const t = ik(e), n = ak(e);
  return (o) => t(o) || n(o);
}, Ss = (e) => {
  const t = Cs(e), n = Sa(e), o = Q$(e), r = J$(e), s = X$(e), i = ek(e), a = tk(e);
  return (l) => t(l) || n(l) || o(l) || r(l) || s(l) || i(l) || a(l);
}, dk = (e) => {
  const t = Cs(e), n = hn(e), o = lk(e), r = Ap(e), s = ck(e);
  return (i) => t(i) && n(i + 1) && o(i + 2) && r(i + 3) && s(i + 4);
}, fk = (e) => {
  const t = Cs(e), n = hn(e);
  return (o) => t(o) && n(o + 1);
}, kp = (e) => {
  const t = Cs(e), n = xa(e), o = Os(e);
  return (r) => t(r) && o(r + 1) && n(r + 2);
}, mk = (e) => {
  const t = Cs(e), n = xa(e), o = kp(e);
  return (r) => t(r) && n(r + 1) || o(r);
}, hk = (e) => {
  const t = Sa(e), n = hn(e), o = ou(e), r = ru(e), s = Es(e), i = Kn(e);
  return (a) => t(a) && n(a + 1) && o(a + 3) && r(a + 4) && s(a + 5) || t(a) && n(a + 1) && o(a + 3) && i(a + 4) && r(a + 6) && s(a + 7) || t(a) && n(a + 1) && o(a + 5) && r(a + 6) && s(a + 7) || t(a) && n(a + 1) && o(a + 5) && i(a + 6) && r(a + 8) && s(a + 9);
}, pk = (e) => {
  const t = Sa(e), n = hn(e), o = ou(e), r = su(e), s = Es(e), i = Kn(e);
  return (a) => t(a) && n(a + 1) && o(a + 3) && r(a + 4) && s(a + 5) || t(a) && n(a + 1) && o(a + 3) && i(a + 4) && r(a + 6) && s(a + 7) || t(a) && n(a + 1) && o(a + 5) && r(a + 6) && s(a + 7) || t(a) && n(a + 1) && o(a + 5) && i(a + 6) && r(a + 8) && s(a + 9);
}, yk = (e) => {
  const t = Sa(e), n = hn(e), o = ou(e), r = Os(e), s = Kn(e);
  return (i) => t(i) && n(i + 1) && o(i + 3) && r(i + 4) || t(i) && n(i + 1) && o(i + 3) && s(i + 4) && r(i + 6) || t(i) && n(i + 1) && o(i + 5) && r(i + 6) || t(i) && n(i + 1) && o(i + 5) && s(i + 6) && r(i + 8);
}, gk = Array.from(new Set([
  ...H$,
  ...Z$,
  ...V$,
  ...z$,
  ...G$,
  ...Y$
].map((e) => e.toUpperCase()))), vk = (e) => {
  const t = mn(e);
  return (n) => {
    const o = e[n];
    return o ? t(n) && gk.includes(o.value.toUpperCase()) : !1;
  };
}, wt = {
  createTable: "create.table",
  createIndex: "create.index",
  alterTableAddUnique: "alter.table.add.unique",
  alterTableAddPrimaryKey: "alter.table.add.primaryKey",
  alterTableAddForeignKey: "alter.table.add.foreignKey"
}, Rn = {
  asc: "ASC",
  desc: "DESC"
};
function bk(e, t) {
  const n = Ss(e), o = mn(e), r = Xo(e), s = Ts(e), i = Mp(e), a = dk(e), l = () => t.value < e.length, c = {
    type: wt.createTable,
    name: "",
    comment: "",
    columns: [],
    indexes: [],
    foreignKeys: []
  };
  for (t.value += a(t.value) ? 5 : 2; l() && !n(t.value); ) {
    let u = e[t.value];
    if (r(t.value)) {
      t.value++;
      const { columns: d, indexes: f, foreignKeys: h } = Ik(e, t);
      c.columns = d, c.indexes = f, c.foreignKeys = h;
      continue;
    }
    if (o(t.value) && !c.name) {
      c.name = u.value, u = e[++t.value], s(t.value) && (u = e[++t.value], o(t.value) && (c.name = u.value, t.value++));
      continue;
    }
    if (i(t.value)) {
      u = e[++t.value], o(t.value) && (c.comment = u.value, t.value++);
      continue;
    }
    t.value++;
  }
  return c;
}
function Ik(e, t) {
  const n = mn(e), o = Xo(e), r = ws(e), s = xp(e), i = Kn(e), a = xa(e), l = ru(e), c = su(e), u = uk(e), d = Os(e), f = nk(e), h = Ap(e), p = ok(e), g = Mp(e), v = $p(e), T = Np(e), O = Es(e), k = vk(e), R = () => t.value < e.length, ae = [], vt = [], bt = [], nt = [], We = [];
  let z = {
    name: "",
    dataType: "",
    default: "",
    comment: "",
    primaryKey: !1,
    autoIncrement: !1,
    unique: !1,
    nullable: !0
  };
  for (; R(); ) {
    let _ = e[t.value];
    if (n(t.value) && !z.name && !i(t.value) && !l(t.value) && !c(t.value) && !d(t.value) && !a(t.value) && !O(t.value)) {
      z.name = _.value, t.value++;
      continue;
    }
    if (o(t.value)) {
      for (_ = e[++t.value]; R() && !r(t.value); )
        _ = e[++t.value];
      t.value++;
      continue;
    }
    if (i(t.value)) {
      _ = e[++t.value], n(t.value) && t.value++;
      continue;
    }
    if (l(t.value)) {
      if (_ = e[++t.value], O(t.value))
        if (_ = e[++t.value], o(t.value)) {
          for (_ = e[++t.value]; R() && !r(t.value); )
            n(t.value) && nt.push(_.value.toUpperCase()), _ = e[++t.value];
          t.value++;
        } else
          z.primaryKey = !0;
      continue;
    }
    if (c(t.value)) {
      const ot = Dp(e, t);
      ot && bt.push(ot);
      continue;
    }
    if (a(t.value) || O(t.value)) {
      if (_ = e[++t.value], n(t.value)) {
        const ot = _.value, Zt = [];
        if (_ = e[++t.value], o(t.value)) {
          _ = e[++t.value];
          let Nt = {
            name: "",
            sort: Rn.asc
          };
          for (; R() && !r(t.value); )
            n(t.value) && !v(t.value) && !T(t.value) && (Nt.name = _.value), v(t.value) && (Nt.sort = Rn.desc), s(t.value) && (Zt.push(Nt), Nt = {
              name: "",
              sort: Rn.asc
            }), _ = e[++t.value];
          !Zt.includes(Nt) && Nt.name !== "" && Zt.push(Nt), Zt.length && vt.push({
            name: ot,
            unique: !1,
            columns: Zt
          }), t.value++;
        }
      }
      continue;
    }
    if (d(t.value)) {
      if (_ = e[++t.value], O(t.value) && (_ = e[++t.value]), n(t.value) && (_ = e[++t.value]), o(t.value)) {
        for (_ = e[++t.value]; R() && !r(t.value); )
          n(t.value) && We.push(_.value.toUpperCase()), _ = e[++t.value];
        t.value++;
      } else
        z.unique = !0;
      continue;
    }
    if (h(t.value)) {
      _ = e[++t.value], f(t.value) && (z.nullable = !1, t.value++);
      continue;
    }
    if (p(t.value)) {
      _ = e[++t.value], n(t.value) && (z.default = _.value, t.value++);
      continue;
    }
    if (g(t.value)) {
      _ = e[++t.value], n(t.value) && (z.comment = _.value, t.value++);
      continue;
    }
    if (u(t.value)) {
      z.autoIncrement = !0, t.value++;
      continue;
    }
    if (k(t.value)) {
      let ot = _.value;
      if (_ = e[++t.value], o(t.value)) {
        for (ot += "(", _ = e[++t.value]; R() && !r(t.value); )
          ot += _.value, _ = e[++t.value];
        ot += ")", t.value++;
      }
      z.dataType = ot;
      continue;
    }
    if (s(t.value)) {
      (z.name || z.dataType) && ae.push(z), z = {
        name: "",
        dataType: "",
        default: "",
        comment: "",
        primaryKey: !1,
        autoIncrement: !1,
        unique: !1,
        nullable: !0
      }, t.value++;
      continue;
    }
    if (r(t.value)) {
      t.value++;
      break;
    }
    t.value++;
  }
  return !ae.includes(z) && (z.name || z.dataType) && ae.push(z), ae.forEach((_) => {
    nt.includes(_.name.toUpperCase()) && (_.primaryKey = !0), We.includes(_.name.toUpperCase()) && (_.unique = !0);
  }), {
    columns: ae,
    indexes: vt,
    foreignKeys: bt
  };
}
function Dp(e, t) {
  const n = mn(e), o = Xo(e), r = ws(e), s = rk(e), i = Ts(e), a = Es(e), l = () => t.value < e.length, c = {
    columnNames: [],
    refTableName: "",
    refColumnNames: []
  };
  let u = e[++t.value];
  if (a(t.value)) {
    if (u = e[++t.value], o(t.value)) {
      for (u = e[++t.value]; l() && !r(t.value); )
        n(t.value) && c.columnNames.push(u.value), u = e[++t.value];
      u = e[++t.value];
    }
    if (s(t.value) && (u = e[++t.value], n(t.value) && (c.refTableName = u.value, u = e[++t.value], i(t.value) && (u = e[++t.value], n(t.value) && (c.refTableName = u.value, u = e[++t.value])), o(t.value)))) {
      for (u = e[++t.value]; l() && !r(t.value); )
        n(t.value) && c.refColumnNames.push(u.value), u = e[++t.value];
      u = e[++t.value];
    }
    if (c.columnNames.length && c.columnNames.length === c.refColumnNames.length)
      return c;
  }
  return null;
}
function Tk(e, t) {
  const n = Ss(e), o = mn(e), r = Kn(e), s = Ts(e), i = hn(e), a = su(e), l = () => t.value < e.length, c = {
    type: wt.alterTableAddForeignKey,
    name: "",
    columnNames: [],
    refTableName: "",
    refColumnNames: []
  };
  for (t.value++; l() && !n(t.value); ) {
    let u = e[t.value];
    if (i(t.value)) {
      u = e[++t.value], o(t.value) && (c.name = u.value, u = e[++t.value], s(t.value) && (u = e[++t.value], o(t.value) && (c.name = u.value, t.value++)));
      continue;
    }
    if (r(t.value)) {
      u = e[++t.value], o(t.value) && t.value++;
      continue;
    }
    if (a(t.value)) {
      const d = Dp(e, t);
      d && (c.columnNames = d.columnNames, c.refTableName = d.refTableName, c.refColumnNames = d.refColumnNames);
      continue;
    }
    t.value++;
  }
  return c;
}
function wk(e, t) {
  const n = Ss(e), o = mn(e), r = Xo(e), s = ws(e), i = Kn(e), a = ru(e), l = Ts(e), c = Es(e), u = hn(e), d = () => t.value < e.length, f = {
    type: wt.alterTableAddPrimaryKey,
    name: "",
    columnNames: []
  };
  for (t.value++; d() && !n(t.value); ) {
    let h = e[t.value];
    if (u(t.value)) {
      h = e[++t.value], o(t.value) && (f.name = h.value, h = e[++t.value], l(t.value) && (h = e[++t.value], o(t.value) && (f.name = h.value, t.value++)));
      continue;
    }
    if (i(t.value)) {
      h = e[++t.value], o(t.value) && t.value++;
      continue;
    }
    if (a(t.value)) {
      if (h = e[++t.value], c(t.value) && (h = e[++t.value], r(t.value))) {
        for (h = e[++t.value]; d() && !s(t.value); )
          o(t.value) && f.columnNames.push(h.value), h = e[++t.value];
        h = e[++t.value];
      }
      continue;
    }
    t.value++;
  }
  return f;
}
function Ck(e, t) {
  const n = Ss(e), o = mn(e), r = Kn(e), s = Ts(e), i = hn(e), a = Os(e), l = Xo(e), c = ws(e), u = () => t.value < e.length, d = {
    type: wt.alterTableAddUnique,
    name: "",
    columnNames: []
  };
  for (t.value++; u() && !n(t.value); ) {
    let f = e[t.value];
    if (i(t.value)) {
      f = e[++t.value], o(t.value) && (d.name = f.value, f = e[++t.value], s(t.value) && (f = e[++t.value], o(t.value) && (d.name = f.value, t.value++)));
      continue;
    }
    if (r(t.value)) {
      f = e[++t.value], o(t.value) && t.value++;
      continue;
    }
    if (a(t.value)) {
      if (f = e[++t.value], l(t.value)) {
        for (f = e[++t.value]; u() && !c(t.value); )
          o(t.value) && d.columnNames.push(f.value), f = e[++t.value];
        t.value++;
      }
      continue;
    }
    t.value++;
  }
  return d;
}
function Ok(e, t) {
  const n = Ss(e), o = Os(e), r = mn(e), s = Xo(e), i = ws(e), a = xp(e), l = xa(e), c = sk(e), u = $p(e), d = Np(e), f = kp(e), h = () => t.value < e.length, p = {
    type: wt.createIndex,
    name: "",
    unique: o(t.value + 1),
    tableName: "",
    columns: []
  };
  for (t.value += f(t.value) ? 2 : 1; h() && !n(t.value); ) {
    let g = e[t.value];
    if (l(t.value)) {
      g = e[++t.value], r(t.value) && (p.name = g.value);
      continue;
    }
    if (c(t.value)) {
      if (g = e[++t.value], r(t.value) && (p.tableName = g.value, g = e[++t.value], s(t.value))) {
        g = e[++t.value];
        let v = {
          name: "",
          sort: Rn.asc
        };
        for (; h() && !i(t.value); )
          r(t.value) && !u(t.value) && !d(t.value) && (v.name = g.value), u(t.value) && (v.sort = Rn.desc), a(t.value) && (p.columns.push(v), v = {
            name: "",
            sort: Rn.asc
          }), g = e[++t.value];
        !p.columns.includes(v) && v.name !== "" && p.columns.push(v), t.value++;
      }
      continue;
    }
    t.value++;
  }
  return p;
}
function Ek(e) {
  const t = [], n = { value: 0 }, o = () => n.value < e.length, r = fk(e), s = mk(e), i = hk(e), a = pk(e), l = yk(e);
  for (; o(); ) {
    if (r(n.value)) {
      t.push(bk(e, n));
      continue;
    }
    if (s(n.value)) {
      t.push(Ok(e, n));
      continue;
    }
    if (i(n.value)) {
      t.push(wk(e, n));
      continue;
    }
    if (a(n.value)) {
      t.push(Tk(e, n));
      continue;
    }
    if (l(n.value)) {
      t.push(Ck(e, n));
      continue;
    }
    n.value++;
  }
  return t;
}
const Sk = (e) => Ek(K$(e));
function qe(e, t) {
  for (const n of e)
    if (n.name.toUpperCase() === t.toUpperCase())
      return n;
  return null;
}
function xk(e, t, n) {
  const o = qn({}), r = Sk(e), s = Ak(r), i = Mk(s), a = Nl(i.length * 100);
  return o.settings.width = a, o.settings.height = a, i.forEach((l) => Nk(o, l, t)), $k(o, i), kk(o, i), hc(n ? n(o) : o);
}
function Ak(e) {
  const t = {
    tables: [],
    indexes: [],
    primaryKeys: [],
    foreignKeys: [],
    uniques: []
  };
  for (const n of e)
    switch (n.type) {
      case wt.createTable:
        n.name && t.tables.push(n);
        break;
      case wt.createIndex:
        n.tableName && n.columns.length && t.indexes.push(n);
        break;
      case wt.alterTableAddPrimaryKey:
        n.name && n.columnNames.length && t.primaryKeys.push(n);
        break;
      case wt.alterTableAddForeignKey:
        n.name && n.columnNames.length && n.refTableName && n.refColumnNames.length && n.columnNames.length === n.refColumnNames.length && t.foreignKeys.push(n);
        break;
      case wt.alterTableAddUnique:
        n.name && n.columnNames.length && t.uniques.push(n);
        break;
    }
  return t;
}
function Mk({ tables: e, indexes: t, primaryKeys: n, foreignKeys: o, uniques: r }) {
  return t.forEach((s) => {
    const i = qe(e, s.tableName);
    i && i.indexes.push({
      name: s.name,
      unique: s.unique,
      columns: s.columns
    });
  }), n.forEach((s) => {
    const i = qe(e, s.name);
    i && s.columnNames.forEach((a) => {
      const l = qe(i.columns, a);
      l && (l.primaryKey = !0);
    });
  }), r.forEach((s) => {
    const i = qe(e, s.name);
    i && s.columnNames.forEach((a) => {
      const l = qe(i.columns, a);
      l && (l.unique = !0);
    });
  }), o.forEach((s) => {
    const i = qe(e, s.name);
    i && i.foreignKeys.push({
      columnNames: s.columnNames,
      refTableName: s.refTableName,
      refColumnNames: s.refColumnNames
    });
  }), e;
}
function Nk({ doc: e, collections: t }, n, { toWidth: o }) {
  const r = gt({
    name: n.name,
    comment: n.comment,
    ui: {
      widthName: ge(o(n.name)),
      widthComment: ge(o(n.comment))
    }
  });
  n.columns.forEach((s) => {
    const i = Mt({
      tableId: r.id,
      name: s.name,
      comment: s.comment,
      dataType: s.dataType,
      default: s.default,
      options: (s.autoIncrement ? U.autoIncrement : 0) | (s.primaryKey ? U.primaryKey : 0) | (s.unique ? U.unique : 0) | (s.nullable ? 0 : U.notNull),
      ui: {
        widthName: ge(o(s.name)),
        widthComment: ge(o(s.comment)),
        widthDataType: ge(o(s.dataType)),
        widthDefault: ge(o(s.default)),
        keys: s.primaryKey ? Pe.primaryKey : 0
      }
    });
    r.columnIds.push(i.id), r.seqColumnIds.push(i.id), y(t).collection("tableColumnEntities").setOne(i);
  }), e.tableIds.push(r.id), y(t).collection("tableEntities").setOne(r);
}
function $k({ doc: e, collections: t }, n) {
  const o = y(t).collection("tableEntities").selectByIds(e.tableIds), r = y(t).collection("tableColumnEntities");
  n.forEach((s) => {
    if (!s.foreignKeys.length)
      return;
    const i = qe(o, s.name);
    if (!i)
      return;
    const a = r.selectByIds(i.columnIds);
    s.foreignKeys.forEach((l) => {
      const c = qe(o, l.refTableName);
      if (!c)
        return;
      const u = r.selectByIds(c.columnIds), d = [], f = [];
      l.refColumnNames.forEach((p) => {
        const g = qe(u, p);
        g && d.push(g);
      }), l.columnNames.forEach((p) => {
        const g = qe(a, p);
        g && (f.push(g), M(g.ui.keys, Pe.primaryKey) ? g.ui.keys |= Pe.foreignKey : g.ui.keys = Pe.foreignKey);
      });
      const h = pp({
        identification: !f.some((p) => !(M(p.ui.keys, Pe.primaryKey) && M(p.ui.keys, Pe.foreignKey))),
        relationshipType: _h.ZeroN,
        start: {
          tableId: c.id,
          columnIds: d.map((p) => p.id)
        },
        end: {
          tableId: i.id,
          columnIds: f.map((p) => p.id)
        }
      });
      e.relationshipIds.push(h.id), y(t).collection("relationshipEntities").setOne(h);
    });
  });
}
function kk({ doc: e, collections: t }, n) {
  const o = y(t).collection("tableEntities").selectByIds(e.tableIds);
  n.forEach((r) => {
    r.indexes.forEach((s) => {
      const i = qe(o, r.name);
      if (!i)
        return;
      const a = y(t).collection("tableColumnEntities").selectByIds(i.columnIds), l = [], c = zn({
        name: s.name,
        tableId: i.id,
        unique: s.unique
      });
      s.columns.forEach((u) => {
        const d = qe(a, u.name);
        if (!d)
          return;
        const f = Fc({
          indexId: c.id,
          columnId: d.id,
          orderType: u.sort === Rn.asc ? wo.ASC : wo.DESC
        });
        l.push(f);
      }), l.length !== 0 && (l.forEach((u) => {
        c.indexColumnIds.push(u.id), c.seqIndexColumnIds.push(u.id), y(t).collection("indexColumnEntities").setOne(u);
      }), e.indexIds.push(c.id), y(t).collection("indexEntities").setOne(c));
    });
  });
}
function Rp(e, t, n = []) {
  const { doc: { relationshipIds: o }, collections: r } = t, s = e.pop();
  return s && (n.some(({ columnId: i }) => i === s.columnId) || (n.push(s), y(r).collection("relationshipEntities").selectByIds(o).forEach(({ id: i, start: a, end: l }) => {
    const c = a.columnIds.indexOf(s.columnId);
    if (c !== -1) {
      const u = l.columnIds[c];
      e.push({
        columnId: u,
        relationshipIds: [i]
      });
    } else {
      const u = l.columnIds.indexOf(s.columnId);
      if (u !== -1) {
        const d = a.columnIds[u];
        e.push({
          columnId: d,
          relationshipIds: [i]
        });
      }
    }
  })), Rp(e, t, n)), n;
}
const zt = 15;
function Lp(e) {
  return Object.entries(e).reduce((t, [n, o]) => (o === De.table ? t.tableIds.push(n) : o === De.memo && t.memoIds.push(n), t), { tableIds: [], memoIds: [] });
}
const Pp = (e) => function* () {
  yield Rc(), yield an({ value: e });
}, Dk = (e) => function* () {
  yield rp(), yield Lo({ value: e });
}, Rk = (e, t) => function* ({ editor: { selectedMap: n }, settings: { zoomLevel: o } }) {
  const { tableIds: r, memoIds: s } = Lp(n), i = e / o, a = t / o;
  r.length && (yield Oo({
    ids: r,
    movementX: i,
    movementY: a
  })), s.length && (yield Co({
    ids: s,
    movementX: i,
    movementY: a
  }));
}, Lk = () => function* () {
  yield wp(), yield Tp();
}, Pk = (e) => function* (t) {
  const { doc: { tableIds: n, memoIds: o }, collections: r } = t, s = {
    ...y(r).collection("tableEntities").selectByIds(n).reduce((i, a) => {
      const l = Kc(a, t).width, c = Qc(a), u = a.ui.x + l / 2 - zt, d = a.ui.y + c / 2 - zt;
      return wd(e, {
        x: u,
        y: d,
        w: zt,
        h: zt
      }) && (i[a.id] = De.table), i;
    }, {}),
    ...y(r).collection("memoEntities").selectByIds(o).reduce((i, a) => {
      const l = F$(a), c = q$(a), u = a.ui.x + l / 2 - zt, d = a.ui.y + c / 2 - zt;
      return wd(e, {
        x: u,
        y: d,
        w: zt,
        h: zt
      }) && (i[a.id] = De.memo), i;
    }, {})
  };
  yield Aa(), dm(s) || (yield Qo(s));
}, Aa = () => function* () {
  yield Oa(), yield Lc();
}, _k = (e, t) => function* (n) {
  const { editor: { focusTable: o } } = n;
  o && (e === ct.Tab && !t && (Wn(o.focusType) && Dc(n) && !vs(n) || !Wn(o.focusType) && $c(n) && kc(n)) ? yield Op(o.tableId) : yield sp({ moveKey: e, shiftKey: t }));
}, Bk = (e) => function* ({ editor: t }) {
  var n;
  ((n = t.drawRelationship) == null ? void 0 : n.relationshipType) === e ? yield Pc() : yield ip({ relationshipType: e });
}, _p = (e) => function* ({ collections: t }) {
  const n = y(t).collection("tableEntities").selectById(e);
  if (!n)
    return;
  if (!y(t).collection("tableColumnEntities").selectByIds(n.columnIds).some((r) => M(r.options, U.primaryKey))) {
    const r = fe();
    yield At({
      tableId: e,
      id: r
    }), yield Yn({
      tableId: e,
      id: r,
      value: !0
    }), yield ln({
      tableId: e,
      columnId: r,
      focusType: W.columnName,
      $mod: !1,
      shiftKey: !1
    });
  }
  yield ap({ tableId: e });
}, Wk = (e) => function* ({ editor: { selectedMap: t }, collections: n }) {
  const { tableIds: o, memoIds: r } = Lp(t), s = y(n).collection("tableEntities").selectByIds(o), i = y(n).collection("memoEntities").selectByIds(r);
  yield s.map((a) => Hr({ id: a.id, color: e, prevColor: a.ui.color })), yield i.map((a) => jr({ id: a.id, color: e, prevColor: a.ui.color }));
}, Uk = (e) => function* ({ settings: t }, n) {
  yield Pp(xk(e, n, (o) => (o.settings = {
    ...o.settings,
    ...zw(da(t), [
      "width",
      "height",
      "scrollTop",
      "scrollLeft",
      "zoomLevel"
    ])
  }, o))), yield nu();
}, jk = (e) => function* ({ editor: { focusTable: t } }) {
  !t || !t.columnId || (yield Wc({
    tableId: t.tableId,
    columnIds: e ? [...t.selectColumnIds] : [t.columnId]
  }));
}, Fk = (e, t) => function* ({ editor: { draggableColumn: n }, collections: o }) {
  if (!n || n.columnIds.length === 0)
    return;
  const { tableId: r, columnIds: s } = n, i = y(o).collection("tableEntities"), a = i.selectById(r);
  if (!a)
    return;
  if (t === r) {
    const d = a.columnIds.indexOf(s[0]);
    if (d === -1)
      return;
    const f = a.columnIds.indexOf(e);
    if (f === -1)
      return;
    const h = s.map((p) => Ki({ tableId: r, id: p, targetId: e }));
    d < f && h.reverse(), yield h;
    return;
  }
  if (!i.selectById(t))
    return;
  const c = y(o).collection("tableColumnEntities").selectByIds(s);
  if (c.length === 0)
    return;
  yield Ep(r, s);
  const u = c.map(() => fe());
  for (let d = 0; d < c.length; d++) {
    const f = c[d], h = u[d], p = {
      id: h,
      tableId: t
    };
    yield [
      At(p),
      Pt({
        ...p,
        value: f.name
      }),
      Bt({
        ...p,
        value: f.dataType
      }),
      Wt({
        ...p,
        value: f.default
      }),
      _t({
        ...p,
        value: f.comment
      }),
      Yn({
        ...p,
        value: M(f.options, U.primaryKey)
      }),
      Ut({
        ...p,
        value: M(f.options, U.notNull)
      }),
      Dn({
        ...p,
        value: M(f.options, U.unique)
      }),
      kn({
        ...p,
        value: M(f.options, U.autoIncrement)
      }),
      Ki({
        ...p,
        targetId: e
      }),
      ln({
        tableId: t,
        columnId: h,
        focusType: W.columnName,
        $mod: !0,
        shiftKey: !1
      })
    ];
  }
  yield Wc({
    tableId: t,
    columnIds: u
  });
}, qk = (e) => function* (t) {
  const { columnIds: n, relationshipIds: o } = Rp([{ columnId: e, relationshipIds: [] }], t).reduce((r, { columnId: s, relationshipIds: i }) => (r.columnIds.push(s), r.relationshipIds.push(...i), r), { columnIds: [], relationshipIds: [] });
  yield _c({ columnIds: Di(n) }), yield Bc({
    relationshipIds: Di(o)
  });
}, Hk = () => function* () {
  yield _c({ columnIds: [] }), yield Bc({ relationshipIds: [] });
}, Zk = {
  loadJsonAction$: Pp,
  initialLoadJsonAction$: Dk,
  moveAllAction$: Rk,
  removeSelectedAction$: Lk,
  dragSelectAction$: Pk,
  unselectAllAction$: Aa,
  focusMoveTableAction$: _k,
  drawStartRelationshipAction$: Bk,
  drawStartAddRelationshipAction$: _p,
  changeColorAllAction$: Wk,
  loadSchemaSQLAction$: Uk,
  dragstartColumnAction$: jk,
  dragoverColumnAction$: Fk,
  columnKeyHoverStartAction$: qk,
  columnKeyHoverEndAction$: Hk
}, Vk = (e) => function* () {
  yield Uc({
    id: fe(),
    tableId: e
  });
}, zk = (e) => function* ({ collections: t }) {
  const n = y(t).collection("indexEntities").selectById(e);
  n && (yield jc({
    id: e,
    tableId: n.tableId,
    value: !n.unique
  }));
}, Gk = {
  addIndexAction$: Vk,
  changeIndexUniqueAction$: zk
}, Yk = (e, t) => function* ({ collections: n }) {
  const o = y(n).collection("indexEntities").selectById(e);
  if (!o)
    return;
  const r = y(n).collection("indexColumnEntities").selectByIds(o.seqIndexColumnIds).find((s) => s.columnId === t);
  r ? yield zi({
    id: r.id,
    indexId: e,
    tableId: o.tableId,
    columnId: t
  }) : yield zi({
    id: fe(),
    indexId: e,
    tableId: o.tableId,
    columnId: t
  });
}, Kk = (e, t) => function* ({ collections: n }) {
  const o = y(n).collection("indexEntities").selectById(e);
  if (!o)
    return;
  const r = y(n).collection("indexColumnEntities").selectByIds(o.indexColumnIds).filter((s) => s.columnId === t);
  for (const s of r)
    yield qc({
      id: s.id,
      indexId: e,
      tableId: o.tableId
    });
}, Qk = (e) => function* ({ collections: t }) {
  const n = y(t).collection("indexColumnEntities").selectById(e);
  n && (yield Zc({
    id: e,
    indexId: n.indexId,
    columnId: n.columnId,
    value: n.orderType === wo.ASC ? wo.DESC : wo.ASC
  }));
}, Jk = (e, t) => function* ({ collections: n }) {
  if (e === t)
    return;
  const o = y(n).collection("indexColumnEntities").selectById(e);
  if (!o)
    return;
  const r = y(n).collection("indexEntities").selectById(o.indexId);
  r && (yield Hc({
    id: e,
    indexId: o.indexId,
    tableId: r.tableId,
    targetId: t
  }));
}, Xk = {
  addIndexColumnAction$: Yk,
  removeIndexColumnAction$: Kk,
  changeIndexColumnOrderTypeAction$: Qk,
  moveIndexColumnAction$: Jk
}, e2 = {};
function Bp({ editor: { viewport: e }, settings: { scrollLeft: t, scrollTop: n, zoomLevel: o, width: r, height: s } }, i) {
  const a = Ml(r, s, o), l = Ml(r, s, i), c = (a.w - l.w) / 2, u = (a.h - l.h) / 2, d = r / 2, f = s / 2, h = t * -1 + e.width / 2, p = n * -1 + e.height / 2, g = (d - h) / d, v = (f - p) / f, T = ft(-1 * c * g, 4), O = ft(-1 * u * v, 4);
  return { movementX: T, movementY: O };
}
const t2 = (e) => function* (t) {
  const { settings: { scrollLeft: n, scrollTop: o } } = t, r = Ta(e), { movementX: s, movementY: i } = Bp(t, r);
  yield zc({ value: e }), yield Gc({
    scrollLeft: n + s,
    scrollTop: o + i
  });
}, n2 = (e) => function* (t) {
  const { settings: { zoomLevel: n } } = t, o = Ta(n + e), { movementX: r, movementY: s } = Bp(t, o);
  yield Fr({ value: e }), yield qr({
    movementX: r,
    movementY: s
  });
}, o2 = {
  changeZoomLevelAction$: t2,
  streamZoomLevelAction$: n2
}, PL = Object.freeze({
  ...xA,
  ...Zk,
  ...QA,
  ...Gk,
  ...lM,
  ...Xk,
  ...CM,
  ...M$,
  ...BM,
  ...e2,
  ...bN,
  ...o2,
  ...qN,
  ...L$,
  ...m$,
  ...j$
}), Wp = [
  // table
  "table.add",
  "table.move",
  "table.moveTo",
  "table.remove",
  "table.changeName",
  "table.changeComment",
  "table.changeColor",
  "table.sort",
  // column
  "column.add",
  "column.remove",
  "column.changeName",
  "column.changeComment",
  "column.changeDataType",
  "column.changeDefault",
  "column.changeAutoIncrement",
  "column.changePrimaryKey",
  "column.changeUnique",
  "column.changeNotNull",
  "column.move",
  // relationship
  "relationship.add",
  "relationship.remove",
  "relationship.changeType",
  // index
  "index.add",
  "index.remove",
  "index.changeName",
  "index.changeUnique",
  // indexColumn
  "indexColumn.add",
  "indexColumn.remove",
  "indexColumn.move",
  "indexColumn.changeOrderType",
  // memo
  "memo.add",
  "memo.move",
  "memo.moveTo",
  "memo.remove",
  "memo.changeValue",
  "memo.changeColor",
  "memo.resize",
  // settings
  "settings.changeDatabaseName",
  "settings.resize",
  "settings.changeZoomLevel",
  "settings.streamZoomLevel",
  "settings.scrollTo",
  "settings.streamScrollTo",
  "settings.changeShow",
  "settings.changeDatabase",
  "settings.changeCanvasType",
  "settings.changeLanguage",
  "settings.changeTableNameCase",
  "settings.changeColumnNameCase",
  "settings.changeBracketType",
  "settings.changeRelationshipDataTypeSync",
  "settings.changeRelationshipOptimization",
  "settings.changeColumnOrder",
  "settings.changeMaxWidthComment",
  "settings.changeIgnoreSaveSettings",
  // editor
  "editor.loadJson",
  "editor.clear"
], r2 = $([
  "settings.changeZoomLevel",
  "settings.streamZoomLevel",
  "settings.scrollTo",
  "settings.streamScrollTo",
  "settings.changeDatabase",
  "settings.changeCanvasType",
  "settings.changeLanguage",
  "settings.changeTableNameCase",
  "settings.changeColumnNameCase",
  "settings.changeBracketType"
]), _L = [
  ...Wp.filter((e) => !r2(e))
], iu = [
  "editor.sharedMouseTracker"
], BL = [
  ...Wp,
  ...iu
], WL = [
  "settings.changeZoomLevel",
  "settings.streamZoomLevel",
  "settings.scrollTo",
  "settings.streamScrollTo",
  "settings.changeCanvasType"
], s2 = [
  "table.move",
  "memo.move"
], i2 = [
  "table.changeColor",
  "memo.changeColor"
], a2 = [
  "settings.streamZoomLevel",
  "settings.streamScrollTo"
], au = [
  ...Object.keys(ts)
], UL = [
  ...Object.keys(Ip),
  ...au
], Up = HS((e) => !!e.length), jL = (e) => {
  const t = $(e);
  return (n) => new tt((o) => n.subscribe({
    next: (r) => o.next(r.filter((s) => t(s.type))),
    error: (r) => o.error(r),
    complete: () => o.complete()
  })).pipe(Up);
}, jp = "@@none-stream", l2 = (e, t) => (n) => {
  const o = t.find(([, r]) => r(n));
  return o ? o[0] : e(n) ? n : jp;
}, Dd = (e, t = [], n = VS(200)) => {
  const o = $(e), r = t.map(([i, a]) => [
    i,
    $(a)
  ]), s = l2(o, r);
  return (i) => new tt((a) => i.subscribe({
    next: (l) => {
      const c = l.reduce((u, d) => {
        const f = s(d.type);
        return u[f] || (u[f] = []), u[f].push(d), u;
      }, {});
      Object.values(c).forEach((u) => a.next(u));
    },
    error: (l) => a.error(l),
    complete: () => a.complete()
  })).pipe(Up, zS((a) => s(a[0].type)), Lh((a) => a.key === jp ? a : a.pipe(ZS(a.pipe(n)), Fi((l) => l.flat()))));
}, c2 = $(au), u2 = $(iu), FL = (e) => e.pipe(Dd(iu, [], YS(100)), Fi((t) => {
  var n;
  return u2((n = t[0]) == null ? void 0 : n.type) ? [Fo(t)] : t;
}), Dd(au, [
  ["@@move", s2],
  ["@@scroll", a2],
  ["@@color", i2]
]), Fi((t) => {
  var o;
  if (!c2((o = t[0]) == null ? void 0 : o.type))
    return t;
  const n = [];
  for (const r of Object.keys(ts))
    ts[r]([], n, t);
  return n.length ? n : t;
}));
function qL(e, t) {
  return nS({
    context: e,
    state: {
      ...qn({}),
      editor: $x()
    },
    reducers: {
      ...SA,
      ...FN,
      ...f$,
      ...wM,
      ..._M,
      ...vN,
      ...KA,
      ...aM
    },
    enableObservable: t ?? !0
  });
}
/*!
 * @dineug/go
 * @version 0.1.8 | Sun Dec 24 2023
 * @author SeungHwan-Lee <dineug2@gmail.com>
 * @license MIT
 */
function Z(e, t, n, o) {
  if (n === "a" && !o)
    throw new TypeError("Private accessor was defined without a getter");
  if (typeof t == "function" ? e !== t || !o : !t.has(e))
    throw new TypeError("Cannot read private member from an object whose class did not declare it");
  return n === "m" ? o : n === "a" ? o.call(e) : o ? o.value : t.get(e);
}
function Zr(e, t, n, o, r) {
  if (o === "m")
    throw new TypeError("Private method is not writable");
  if (o === "a" && !r)
    throw new TypeError("Private accessor was defined without a setter");
  if (typeof t == "function" ? e !== t || !r : !t.has(e))
    throw new TypeError("Cannot write private member to an object whose class did not declare it");
  return o === "a" ? r.call(e, n) : r ? r.value = n : t.set(e, n), n;
}
var Dl, Ae, En, Fp;
const d2 = {
  limit: -1,
  leading: !1,
  trailing: !1
};
class f2 {
  constructor(t) {
    Dl.add(this), Ae.set(this, []), En.set(this, void 0), Zr(this, En, Object.assign({}, d2, {
      ...t
    }), "f");
  }
  isEmpty() {
    return !Z(this, Ae, "f").length;
  }
  put(t) {
    Z(this, Ae, "f").push(t), Z(this, Dl, "m", Fp).call(this);
  }
  take() {
    return Z(this, Ae, "f").shift();
  }
  flush() {
    const t = Z(this, Ae, "f");
    return Zr(this, Ae, [], "f"), t;
  }
  drop(t) {
    const n = Z(this, Ae, "f").findIndex(t);
    if (n === -1)
      return !1;
    const o = Z(this, Ae, "f").slice();
    return o.splice(n, 1), Zr(this, Ae, o, "f"), !0;
  }
}
Ae = /* @__PURE__ */ new WeakMap(), En = /* @__PURE__ */ new WeakMap(), Dl = /* @__PURE__ */ new WeakSet(), Fp = function() {
  Z(this, En, "f").limit !== -1 && Z(this, En, "f").limit < Z(this, Ae, "f").length && (Z(this, En, "f").trailing ? Z(this, Ae, "f").pop() : Z(this, En, "f").leading ? Z(this, Ae, "f").shift() : Z(this, Ae, "f").pop());
};
const m2 = (e) => new f2(e), Rd = {
  limitBuffer: m2
};
var Ai, xn, An, On, Rl;
const Ka = Symbol.for("https://github.com/dineug/go.git#closed");
class h2 {
  get closed() {
    return Z(this, On, "f");
  }
  constructor(t = Rd.limitBuffer()) {
    Ai.add(this), xn.set(this, void 0), An.set(this, Rd.limitBuffer()), On.set(this, !1), Zr(this, xn, t, "f");
  }
  put(t) {
    Z(this, On, "f") || (Z(this, xn, "f").put(t), Z(this, Ai, "m", Rl).call(this));
  }
  take(t, n) {
    return Z(this, On, "f") ? (n == null || n(Ka), () => !1) : (Z(this, An, "f").put([t, n]), Z(this, Ai, "m", Rl).call(this), () => Z(this, An, "f").drop(([o]) => o === t));
  }
  flush(t, n) {
    if (Z(this, On, "f")) {
      n == null || n(Ka);
      return;
    }
    t(Z(this, xn, "f").flush());
  }
  close() {
    Zr(this, On, !0, "f"), Z(this, An, "f").flush().forEach(([t, n]) => n == null ? void 0 : n(Ka));
  }
}
xn = /* @__PURE__ */ new WeakMap(), An = /* @__PURE__ */ new WeakMap(), On = /* @__PURE__ */ new WeakMap(), Ai = /* @__PURE__ */ new WeakSet(), Rl = function() {
  if (Z(this, An, "f").isEmpty() || Z(this, xn, "f").isEmpty())
    return;
  const [e] = Z(this, An, "f").take(), t = Z(this, xn, "f").take();
  e == null || e(t);
};
const p2 = (e) => new h2(e), qp = (e) => (t) => typeof t === e, y2 = qp("object"), So = qp("function"), g2 = (e) => e === null, { isArray: Qi } = Array, Ma = (e) => y2(e) && !g2(e) && !Qi(e), Hp = (e) => Ma(e) && So(e.then), Zp = (e) => Ma(e) && So(e.next), v2 = (e) => Ma(e) && So(e.next) && So(e.throw) && So(e.return), lu = Symbol.for("https://github.com/dineug/go.git#cancel"), Vp = Symbol.for("https://github.com/dineug/go.git#attachCancel"), zp = (e, t) => (Reflect.set(e, Vp, t), e), b2 = (e) => e === lu, Gp = (e) => {
  if (Ma(e)) {
    const t = Reflect.get(e, Vp);
    t == null || t();
  }
  return Ke(() => new Promise((t, n) => n(lu)));
}, Yp = (e) => Ke(function* () {
  let t = () => !1;
  const n = new Promise((o, r) => {
    t = e.take(o, r);
  });
  return zp(n, () => {
    t();
  }), yield n;
}), HL = (e) => Ke(() => new Promise((t) => setTimeout(t, e))), I2 = Symbol.for("https://github.com/dineug/go.git#kill"), T2 = (e) => e === I2, w2 = (e, t) => {
  e.put(t);
}, Na = (e, t) => Ke(function* () {
  for (; ; ) {
    const n = yield Yp(e);
    Ke(t, n);
  }
}), C2 = {
  leading: !0,
  trailing: !1
}, $a = (e, t, n, o) => Ke(function* () {
  const r = Object.assign({}, C2, {
    ...o
  });
  let s = -1, i, a;
  for (; ; ) {
    const l = yield Yp(e);
    a = l, s === -1 && (r.leading && (i = l, Ke(t, l)), s = setTimeout(() => {
      r.trailing && (!r.leading || i !== a) && Ke(t, a), s = -1;
    }, n));
  }
});
function Ke(e, ...t) {
  let n = !1, o = null;
  const r = new Promise(async (s, i) => {
    let a = null;
    o = () => {
      i(lu), a == null || a.forEach(Gp), a = null;
    };
    try {
      const l = e(...t);
      if (Hp(l))
        return a = [l], s(await l);
      if (!Zp(l))
        return s(l);
      let c = await l.next(), u;
      for (; !n && !c.done; ) {
        try {
          const d = O2(c.value);
          a = Qi(d) ? d : [d], u = await (Qi(d) ? Promise.all(d) : d), c = await l.next(u);
        } catch (d) {
          if (T2(d))
            throw d;
          v2(l) && (c = await l.throw(d));
        }
        u = void 0, a = null;
      }
      s(c.value);
    } catch (l) {
      b2(l) && (n = !0), i(l);
    }
  });
  return r.catch(() => {
  }), zp(r, () => {
    n = !0, o == null || o();
  }), r;
}
function O2(e) {
  return Hp(e) ? e : Zp(e) ? Ke(() => e) : So(e) ? Ke(e) : Qi(e) ? e.map((t) => Ke(() => t)) : Promise.resolve();
}
const re = {
  left: "left",
  right: "right",
  top: "top",
  bottom: "bottom"
}, Ll = Object.values(re);
$([
  re.top,
  re.bottom,
  re.left,
  re.right
]);
const E2 = 30, ZL = E2 + 20, VL = 35, zL = 10, GL = 16, YL = 26;
function S2(e, t) {
  const n = Kc(t, e).width, o = Qc(t), { x: r, y: s } = t.ui;
  return {
    width: n,
    height: o,
    top: {
      x: r + n / 2,
      y: s
    },
    bottom: {
      x: r + n / 2,
      y: s + o
    },
    left: {
      x: r,
      y: s + o / 2
    },
    right: {
      x: r + n,
      y: s + o / 2
    },
    lt: {
      x: r,
      y: s
    },
    rt: {
      x: r + n,
      y: s
    },
    lb: {
      x: r,
      y: s + o
    },
    rb: {
      x: r + n,
      y: s + o
    }
  };
}
function Ld(e, t) {
  return Math.sqrt(Math.pow(e.x - t.x, 2) + Math.pow(e.y - t.y, 2));
}
const Pd = {
  [re.top]: Ct.top,
  [re.bottom]: Ct.bottom,
  [re.left]: Ct.left,
  [re.right]: Ct.right
};
function Kp(e) {
  const { doc: { tableIds: t, relationshipIds: n }, collections: o } = e, r = $(t), s = y(o).collection("tableEntities"), i = y(o).collection("relationshipEntities").selectByIds(n).filter(({ start: c, end: u }) => r(c.tableId) && r(u.tableId)), a = /* @__PURE__ */ new Map(), l = /* @__PURE__ */ new Map();
  for (const c of i) {
    const u = x2(c), { start: d, end: f } = u, h = s.selectById(d.tableId), p = s.selectById(f.tableId);
    if (!(!h || !p))
      if (l.set(c, u), d.tableId === f.tableId) {
        d.direction = Ct.top, f.direction = Ct.right;
        const g = Qa(e, a, h);
        d.x = g.objectPoint.rt.x - 20, d.y = g.objectPoint.rt.y, f.x = g.objectPoint.rt.x, f.y = g.objectPoint.rt.y + 20, g.top.set(u.id, u), g.right.set(u.id, u);
      } else {
        const g = Qa(e, a, h), v = Qa(e, a, p), [T, O] = A2(g.objectPoint, v.objectPoint, u);
        g[T].set(u.id, u), v[O].set(u.id, u);
      }
  }
  for (const c of a.values())
    for (const u of Ll) {
      const d = u;
      c[d].size < 2 || M2(d, c);
    }
  for (const [c, u] of l.entries())
    c.start.direction = u.start.direction, c.start.x = u.start.x, c.start.y = u.start.y, c.end.direction = u.end.direction, c.end.x = u.end.x, c.end.y = u.end.y;
}
function Qa(e, t, n) {
  let o = t.get(n.id);
  return o || (o = {
    tableId: n.id,
    objectPoint: S2(e, n),
    top: /* @__PURE__ */ new Map(),
    bottom: /* @__PURE__ */ new Map(),
    left: /* @__PURE__ */ new Map(),
    right: /* @__PURE__ */ new Map()
  }, t.set(n.id, o)), o;
}
function x2(e) {
  return {
    id: e.id,
    start: {
      tableId: e.start.tableId,
      x: e.start.x,
      y: e.start.y,
      direction: e.start.direction
    },
    end: {
      tableId: e.end.tableId,
      x: e.end.x,
      y: e.end.y,
      direction: e.end.direction
    }
  };
}
function A2(e, t, n) {
  const o = [
    re.bottom,
    re.bottom
  ];
  let r = Ld(e.bottom, t.bottom);
  n.start.x = e.bottom.x, n.start.y = e.bottom.y, n.end.x = t.bottom.x, n.end.y = t.bottom.y, n.start.direction = Ct.bottom, n.end.direction = Ct.bottom;
  for (const s of Ll)
    for (const i of Ll) {
      const a = s, l = i, c = Ld(e[a], t[l]);
      r <= c || (r = c, o[0] = a, o[1] = l, n.start.x = e[a].x, n.start.y = e[a].y, n.start.direction = Pd[a], n.end.x = t[l].x, n.end.y = t[l].y, n.end.direction = Pd[l]);
    }
  return o;
}
function M2(e, t) {
  const n = N2(e, t), o = $2(e, t);
  e === re.left || e === re.right ? n.yArray.forEach((r, s) => {
    o[s].start.y = r;
  }) : (e === re.top || e === re.bottom) && n.xArray.forEach((r, s) => {
    o[s].start.x = r;
  });
}
function N2(e, t) {
  const n = t[e].size, o = {
    x: t.objectPoint.width / n,
    y: t.objectPoint.height / n
  }, r = {
    x: o.x / 2,
    y: o.y / 2
  }, s = [], i = [];
  if (e === re.left || e === re.right) {
    let a = t.objectPoint.lt.y - r.y;
    for (let l = 0; l < n; l++)
      a += o.y, i.push(a);
  } else if (e === re.top || e === re.bottom) {
    let a = t.objectPoint.lt.x - r.x;
    for (let l = 0; l < n; l++)
      a += o.x, s.push(a);
  }
  return {
    xArray: s,
    yArray: i
  };
}
function $2(e, t) {
  const n = [], o = [], r = [], s = e === re.top || e === re.bottom;
  for (const i of t[e].values()) {
    const { start: a, end: l } = i;
    a.tableId === l.tableId ? e === re.top ? (n.push(i.start), o.push(i.end)) : e === re.right && (n.push(i.end), o.push(i.start)) : i.start.tableId === t.tableId ? (n.push(i.start), o.push(i.end)) : (n.push(i.end), o.push(i.start));
  }
  return o.forEach((i, a) => {
    r.push({
      start: n[a],
      end: o[a],
      distance: s ? i.x : i.y
    });
  }), r.sort((i, a) => i.distance - a.distance);
}
const k2 = function* (e, t) {
  yield $a(e, function* () {
    const { doc: n, collections: o } = t, s = y(o).collection("relationshipEntities").selectByIds(n.relationshipIds);
    for (const i of s) {
      const { end: a, identification: l } = i, c = y(o).collection("tableEntities").selectById(a.tableId);
      if (!c)
        continue;
      const u = $(c.columnIds), d = y(o).collection("tableColumnEntities").selectByIds(a.columnIds).filter((h) => u(h.id));
      if (!d.length)
        continue;
      const f = d.every((h) => M(h.options, U.primaryKey));
      f !== l && (i.identification = f);
    }
  }, 10, { leading: !1, trailing: !0 });
}, D2 = function* (e, t) {
  yield $a(e, function* () {
    const { doc: n, collections: o } = t, s = y(o).collection("relationshipEntities").selectByIds(n.relationshipIds);
    for (const i of s) {
      const { end: a, startRelationshipType: l } = i, c = y(o).collection("tableEntities").selectById(a.tableId);
      if (!c)
        continue;
      const u = $(c.columnIds), d = y(o).collection("tableColumnEntities").selectByIds(a.columnIds).filter((h) => u(h.id));
      if (!d.length)
        continue;
      const f = d.every((h) => M(h.options, U.notNull)) ? Al.dash : Al.ring;
      f !== l && (i.startRelationshipType = f);
    }
  }, 10, { leading: !1, trailing: !0 });
}, R2 = function* (e, t) {
  yield $a(e, function* () {
    Kp(t);
  }, 5, { leading: !1, trailing: !0 });
}, L2 = [
  [
    [
      Eo,
      Yn,
      an,
      Lo
    ],
    k2
  ],
  [
    [
      Eo,
      Ut,
      an,
      Lo
    ],
    D2
  ],
  [
    [
      Yc,
      vp,
      bs,
      Co,
      Oo,
      Xc,
      eu,
      tu,
      At,
      Eo,
      Pt,
      _t,
      Bt,
      Wt,
      nu
    ],
    R2
  ]
], P2 = function* (e, t, n) {
  yield $a(e, function* () {
    kN(t, n), Kp(t);
  }, 5, { leading: !1, trailing: !0 });
}, _2 = [
  [[an, Lo], P2]
], B2 = function* (e, t) {
  yield Na(e, function* ({ payload: { id: n } }) {
    const { collections: o } = t, s = y(o).collection("tableColumnEntities").selectById(n);
    !s || !M(s.options, U.primaryKey) || M(s.options, U.notNull) || (s.options = s.options | U.notNull);
  });
}, W2 = function* (e, t) {
  yield Na(e, function* ({ payload: { id: n, end: o } }) {
    const { doc: { relationshipIds: r }, collections: s } = t;
    if (!r.includes(n))
      return;
    const i = y(s).collection("tableColumnEntities").selectByIds(o.columnIds);
    for (const a of i)
      a.ui.keys = a.ui.keys | Pe.foreignKey;
  });
}, U2 = function* (e, t) {
  yield Na(e, function* ({ payload: { id: n } }) {
    const { doc: { relationshipIds: o }, collections: r } = t;
    if (o.includes(n))
      return;
    const s = y(r).collection("relationshipEntities").selectById(n);
    if (!s)
      return;
    const i = y(r).collection("tableColumnEntities").selectByIds(s.end.columnIds);
    for (const a of i)
      a.ui.keys = a.ui.keys & ~Pe.foreignKey;
  });
}, j2 = function* (e, t) {
  yield Na(e, function* () {
    const { doc: n, collections: o } = t, r = y(o).collection("relationshipEntities").selectByIds(n.relationshipIds), s = y(o).collection("tableEntities").selectByIds(n.tableIds), i = /* @__PURE__ */ new Set(), a = y(o).collection("tableColumnEntities");
    for (const { end: l } of r) {
      const c = a.selectByIds(l.columnIds);
      for (const u of c)
        u.ui.keys = u.ui.keys | Pe.foreignKey, i.add(u.id);
    }
    for (const l of s) {
      const c = a.selectByIds(l.columnIds);
      for (const u of c)
        M(u.ui.keys, Pe.foreignKey) && !i.has(u.id) && (u.ui.keys = u.ui.keys & ~Pe.foreignKey);
    }
  });
}, F2 = [
  [[Yn], B2],
  [[bs], W2],
  [[_o], U2],
  [[an, Lo], j2]
], q2 = [..._2, ...F2, ...L2];
function KL(e) {
  const t = q2.map(([r, s]) => {
    const i = p2();
    return {
      pattern: $(r.map(String)),
      channel: i,
      proc: Ke(s, i, e.state, e.context)
    };
  }), n = e.subscribe((r) => {
    for (const s of r)
      for (const i of t)
        i.pattern(s.type) && w2(i.channel, s);
  });
  return { destroy: () => {
    t.forEach(({ proc: r }) => Gp(r)), t.splice(0, t.length), n();
  } };
}
class Qn extends Error {
}
class H2 extends Qn {
  constructor(t) {
    super(`Invalid DateTime: ${t.toMessage()}`);
  }
}
class Z2 extends Qn {
  constructor(t) {
    super(`Invalid Interval: ${t.toMessage()}`);
  }
}
class V2 extends Qn {
  constructor(t) {
    super(`Invalid Duration: ${t.toMessage()}`);
  }
}
class yo extends Qn {
}
class Qp extends Qn {
  constructor(t) {
    super(`Invalid unit ${t}`);
  }
}
class Me extends Qn {
}
class Gt extends Qn {
  constructor() {
    super("Zone is an abstract class");
  }
}
const C = "numeric", pt = "short", _e = "long", Ji = {
  year: C,
  month: C,
  day: C
}, Jp = {
  year: C,
  month: pt,
  day: C
}, z2 = {
  year: C,
  month: pt,
  day: C,
  weekday: pt
}, Xp = {
  year: C,
  month: _e,
  day: C
}, ey = {
  year: C,
  month: _e,
  day: C,
  weekday: _e
}, ty = {
  hour: C,
  minute: C
}, ny = {
  hour: C,
  minute: C,
  second: C
}, oy = {
  hour: C,
  minute: C,
  second: C,
  timeZoneName: pt
}, ry = {
  hour: C,
  minute: C,
  second: C,
  timeZoneName: _e
}, sy = {
  hour: C,
  minute: C,
  hourCycle: "h23"
}, iy = {
  hour: C,
  minute: C,
  second: C,
  hourCycle: "h23"
}, ay = {
  hour: C,
  minute: C,
  second: C,
  hourCycle: "h23",
  timeZoneName: pt
}, ly = {
  hour: C,
  minute: C,
  second: C,
  hourCycle: "h23",
  timeZoneName: _e
}, cy = {
  year: C,
  month: C,
  day: C,
  hour: C,
  minute: C
}, uy = {
  year: C,
  month: C,
  day: C,
  hour: C,
  minute: C,
  second: C
}, dy = {
  year: C,
  month: pt,
  day: C,
  hour: C,
  minute: C
}, fy = {
  year: C,
  month: pt,
  day: C,
  hour: C,
  minute: C,
  second: C
}, G2 = {
  year: C,
  month: pt,
  day: C,
  weekday: pt,
  hour: C,
  minute: C
}, my = {
  year: C,
  month: _e,
  day: C,
  hour: C,
  minute: C,
  timeZoneName: pt
}, hy = {
  year: C,
  month: _e,
  day: C,
  hour: C,
  minute: C,
  second: C,
  timeZoneName: pt
}, py = {
  year: C,
  month: _e,
  day: C,
  weekday: _e,
  hour: C,
  minute: C,
  timeZoneName: _e
}, yy = {
  year: C,
  month: _e,
  day: C,
  weekday: _e,
  hour: C,
  minute: C,
  second: C,
  timeZoneName: _e
};
class xs {
  /**
   * The type of zone
   * @abstract
   * @type {string}
   */
  get type() {
    throw new Gt();
  }
  /**
   * The name of this zone.
   * @abstract
   * @type {string}
   */
  get name() {
    throw new Gt();
  }
  get ianaName() {
    return this.name;
  }
  /**
   * Returns whether the offset is known to be fixed for the whole year.
   * @abstract
   * @type {boolean}
   */
  get isUniversal() {
    throw new Gt();
  }
  /**
   * Returns the offset's common name (such as EST) at the specified timestamp
   * @abstract
   * @param {number} ts - Epoch milliseconds for which to get the name
   * @param {Object} opts - Options to affect the format
   * @param {string} opts.format - What style of offset to return. Accepts 'long' or 'short'.
   * @param {string} opts.locale - What locale to return the offset name in.
   * @return {string}
   */
  offsetName(t, n) {
    throw new Gt();
  }
  /**
   * Returns the offset's value as a string
   * @abstract
   * @param {number} ts - Epoch milliseconds for which to get the offset
   * @param {string} format - What style of offset to return.
   *                          Accepts 'narrow', 'short', or 'techie'. Returning '+6', '+06:00', or '+0600' respectively
   * @return {string}
   */
  formatOffset(t, n) {
    throw new Gt();
  }
  /**
   * Return the offset in minutes for this zone at the specified timestamp.
   * @abstract
   * @param {number} ts - Epoch milliseconds for which to compute the offset
   * @return {number}
   */
  offset(t) {
    throw new Gt();
  }
  /**
   * Return whether this Zone is equal to another zone
   * @abstract
   * @param {Zone} otherZone - the zone to compare
   * @return {boolean}
   */
  equals(t) {
    throw new Gt();
  }
  /**
   * Return whether this Zone is valid.
   * @abstract
   * @type {boolean}
   */
  get isValid() {
    throw new Gt();
  }
}
let Ja = null;
class ka extends xs {
  /**
   * Get a singleton instance of the local zone
   * @return {SystemZone}
   */
  static get instance() {
    return Ja === null && (Ja = new ka()), Ja;
  }
  /** @override **/
  get type() {
    return "system";
  }
  /** @override **/
  get name() {
    return new Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  /** @override **/
  get isUniversal() {
    return !1;
  }
  /** @override **/
  offsetName(t, { format: n, locale: o }) {
    return Ey(t, n, o);
  }
  /** @override **/
  formatOffset(t, n) {
    return Vr(this.offset(t), n);
  }
  /** @override **/
  offset(t) {
    return -new Date(t).getTimezoneOffset();
  }
  /** @override **/
  equals(t) {
    return t.type === "system";
  }
  /** @override **/
  get isValid() {
    return !0;
  }
}
let Mi = {};
function Y2(e) {
  return Mi[e] || (Mi[e] = new Intl.DateTimeFormat("en-US", {
    hour12: !1,
    timeZone: e,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    era: "short"
  })), Mi[e];
}
const K2 = {
  year: 0,
  month: 1,
  day: 2,
  era: 3,
  hour: 4,
  minute: 5,
  second: 6
};
function Q2(e, t) {
  const n = e.format(t).replace(/\u200E/g, ""), o = /(\d+)\/(\d+)\/(\d+) (AD|BC),? (\d+):(\d+):(\d+)/.exec(n), [, r, s, i, a, l, c, u] = o;
  return [i, r, s, a, l, c, u];
}
function J2(e, t) {
  const n = e.formatToParts(t), o = [];
  for (let r = 0; r < n.length; r++) {
    const { type: s, value: i } = n[r], a = K2[s];
    s === "era" ? o[a] = i : N(a) || (o[a] = parseInt(i, 10));
  }
  return o;
}
let Bs = {};
class Ft extends xs {
  /**
   * @param {string} name - Zone name
   * @return {IANAZone}
   */
  static create(t) {
    return Bs[t] || (Bs[t] = new Ft(t)), Bs[t];
  }
  /**
   * Reset local caches. Should only be necessary in testing scenarios.
   * @return {void}
   */
  static resetCache() {
    Bs = {}, Mi = {};
  }
  /**
   * Returns whether the provided string is a valid specifier. This only checks the string's format, not that the specifier identifies a known zone; see isValidZone for that.
   * @param {string} s - The string to check validity on
   * @example IANAZone.isValidSpecifier("America/New_York") //=> true
   * @example IANAZone.isValidSpecifier("Sport~~blorp") //=> false
   * @deprecated This method returns false for some valid IANA names. Use isValidZone instead.
   * @return {boolean}
   */
  static isValidSpecifier(t) {
    return this.isValidZone(t);
  }
  /**
   * Returns whether the provided string identifies a real zone
   * @param {string} zone - The string to check
   * @example IANAZone.isValidZone("America/New_York") //=> true
   * @example IANAZone.isValidZone("Fantasia/Castle") //=> false
   * @example IANAZone.isValidZone("Sport~~blorp") //=> false
   * @return {boolean}
   */
  static isValidZone(t) {
    if (!t)
      return !1;
    try {
      return new Intl.DateTimeFormat("en-US", { timeZone: t }).format(), !0;
    } catch {
      return !1;
    }
  }
  constructor(t) {
    super(), this.zoneName = t, this.valid = Ft.isValidZone(t);
  }
  /** @override **/
  get type() {
    return "iana";
  }
  /** @override **/
  get name() {
    return this.zoneName;
  }
  /** @override **/
  get isUniversal() {
    return !1;
  }
  /** @override **/
  offsetName(t, { format: n, locale: o }) {
    return Ey(t, n, o, this.name);
  }
  /** @override **/
  formatOffset(t, n) {
    return Vr(this.offset(t), n);
  }
  /** @override **/
  offset(t) {
    const n = new Date(t);
    if (isNaN(n))
      return NaN;
    const o = Y2(this.name);
    let [r, s, i, a, l, c, u] = o.formatToParts ? J2(o, n) : Q2(o, n);
    a === "BC" && (r = -Math.abs(r) + 1);
    const f = Ra({
      year: r,
      month: s,
      day: i,
      hour: l === 24 ? 0 : l,
      minute: c,
      second: u,
      millisecond: 0
    });
    let h = +n;
    const p = h % 1e3;
    return h -= p >= 0 ? p : 1e3 + p, (f - h) / (60 * 1e3);
  }
  /** @override **/
  equals(t) {
    return t.type === "iana" && t.name === this.name;
  }
  /** @override **/
  get isValid() {
    return this.valid;
  }
}
let _d = {};
function X2(e, t = {}) {
  const n = JSON.stringify([e, t]);
  let o = _d[n];
  return o || (o = new Intl.ListFormat(e, t), _d[n] = o), o;
}
let Pl = {};
function _l(e, t = {}) {
  const n = JSON.stringify([e, t]);
  let o = Pl[n];
  return o || (o = new Intl.DateTimeFormat(e, t), Pl[n] = o), o;
}
let Bl = {};
function eD(e, t = {}) {
  const n = JSON.stringify([e, t]);
  let o = Bl[n];
  return o || (o = new Intl.NumberFormat(e, t), Bl[n] = o), o;
}
let Wl = {};
function tD(e, t = {}) {
  const { base: n, ...o } = t, r = JSON.stringify([e, o]);
  let s = Wl[r];
  return s || (s = new Intl.RelativeTimeFormat(e, t), Wl[r] = s), s;
}
let Br = null;
function nD() {
  return Br || (Br = new Intl.DateTimeFormat().resolvedOptions().locale, Br);
}
let Bd = {};
function oD(e) {
  let t = Bd[e];
  if (!t) {
    const n = new Intl.Locale(e);
    t = "getWeekInfo" in n ? n.getWeekInfo() : n.weekInfo, Bd[e] = t;
  }
  return t;
}
function rD(e) {
  const t = e.indexOf("-x-");
  t !== -1 && (e = e.substring(0, t));
  const n = e.indexOf("-u-");
  if (n === -1)
    return [e];
  {
    let o, r;
    try {
      o = _l(e).resolvedOptions(), r = e;
    } catch {
      const l = e.substring(0, n);
      o = _l(l).resolvedOptions(), r = l;
    }
    const { numberingSystem: s, calendar: i } = o;
    return [r, s, i];
  }
}
function sD(e, t, n) {
  return (n || t) && (e.includes("-u-") || (e += "-u"), n && (e += `-ca-${n}`), t && (e += `-nu-${t}`)), e;
}
function iD(e) {
  const t = [];
  for (let n = 1; n <= 12; n++) {
    const o = D.utc(2009, n, 1);
    t.push(e(o));
  }
  return t;
}
function aD(e) {
  const t = [];
  for (let n = 1; n <= 7; n++) {
    const o = D.utc(2016, 11, 13 + n);
    t.push(e(o));
  }
  return t;
}
function Ws(e, t, n, o) {
  const r = e.listingMode();
  return r === "error" ? null : r === "en" ? n(t) : o(t);
}
function lD(e) {
  return e.numberingSystem && e.numberingSystem !== "latn" ? !1 : e.numberingSystem === "latn" || !e.locale || e.locale.startsWith("en") || new Intl.DateTimeFormat(e.intl).resolvedOptions().numberingSystem === "latn";
}
class cD {
  constructor(t, n, o) {
    this.padTo = o.padTo || 0, this.floor = o.floor || !1;
    const { padTo: r, floor: s, ...i } = o;
    if (!n || Object.keys(i).length > 0) {
      const a = { useGrouping: !1, ...o };
      o.padTo > 0 && (a.minimumIntegerDigits = o.padTo), this.inf = eD(t, a);
    }
  }
  format(t) {
    if (this.inf) {
      const n = this.floor ? Math.floor(t) : t;
      return this.inf.format(n);
    } else {
      const n = this.floor ? Math.floor(t) : fu(t, 3);
      return de(n, this.padTo);
    }
  }
}
class uD {
  constructor(t, n, o) {
    this.opts = o, this.originalZone = void 0;
    let r;
    if (this.opts.timeZone)
      this.dt = t;
    else if (t.zone.type === "fixed") {
      const i = -1 * (t.offset / 60), a = i >= 0 ? `Etc/GMT+${i}` : `Etc/GMT${i}`;
      t.offset !== 0 && Ft.create(a).valid ? (r = a, this.dt = t) : (r = "UTC", this.dt = t.offset === 0 ? t : t.setZone("UTC").plus({ minutes: t.offset }), this.originalZone = t.zone);
    } else
      t.zone.type === "system" ? this.dt = t : t.zone.type === "iana" ? (this.dt = t, r = t.zone.name) : (r = "UTC", this.dt = t.setZone("UTC").plus({ minutes: t.offset }), this.originalZone = t.zone);
    const s = { ...this.opts };
    s.timeZone = s.timeZone || r, this.dtf = _l(n, s);
  }
  format() {
    return this.originalZone ? this.formatToParts().map(({ value: t }) => t).join("") : this.dtf.format(this.dt.toJSDate());
  }
  formatToParts() {
    const t = this.dtf.formatToParts(this.dt.toJSDate());
    return this.originalZone ? t.map((n) => {
      if (n.type === "timeZoneName") {
        const o = this.originalZone.offsetName(this.dt.ts, {
          locale: this.dt.locale,
          format: this.opts.timeZoneName
        });
        return {
          ...n,
          value: o
        };
      } else
        return n;
    }) : t;
  }
  resolvedOptions() {
    return this.dtf.resolvedOptions();
  }
}
class dD {
  constructor(t, n, o) {
    this.opts = { style: "long", ...o }, !n && Cy() && (this.rtf = tD(t, o));
  }
  format(t, n) {
    return this.rtf ? this.rtf.format(t, n) : $D(n, t, this.opts.numeric, this.opts.style !== "long");
  }
  formatToParts(t, n) {
    return this.rtf ? this.rtf.formatToParts(t, n) : [];
  }
}
const fD = {
  firstDay: 1,
  minimalDays: 4,
  weekend: [6, 7]
};
class V {
  static fromOpts(t) {
    return V.create(
      t.locale,
      t.numberingSystem,
      t.outputCalendar,
      t.weekSettings,
      t.defaultToEN
    );
  }
  static create(t, n, o, r, s = !1) {
    const i = t || le.defaultLocale, a = i || (s ? "en-US" : nD()), l = n || le.defaultNumberingSystem, c = o || le.defaultOutputCalendar, u = Ul(r) || le.defaultWeekSettings;
    return new V(a, l, c, u, i);
  }
  static resetCache() {
    Br = null, Pl = {}, Bl = {}, Wl = {};
  }
  static fromObject({ locale: t, numberingSystem: n, outputCalendar: o, weekSettings: r } = {}) {
    return V.create(t, n, o, r);
  }
  constructor(t, n, o, r, s) {
    const [i, a, l] = rD(t);
    this.locale = i, this.numberingSystem = n || a || null, this.outputCalendar = o || l || null, this.weekSettings = r, this.intl = sD(this.locale, this.numberingSystem, this.outputCalendar), this.weekdaysCache = { format: {}, standalone: {} }, this.monthsCache = { format: {}, standalone: {} }, this.meridiemCache = null, this.eraCache = {}, this.specifiedLocale = s, this.fastNumbersCached = null;
  }
  get fastNumbers() {
    return this.fastNumbersCached == null && (this.fastNumbersCached = lD(this)), this.fastNumbersCached;
  }
  listingMode() {
    const t = this.isEnglish(), n = (this.numberingSystem === null || this.numberingSystem === "latn") && (this.outputCalendar === null || this.outputCalendar === "gregory");
    return t && n ? "en" : "intl";
  }
  clone(t) {
    return !t || Object.getOwnPropertyNames(t).length === 0 ? this : V.create(
      t.locale || this.specifiedLocale,
      t.numberingSystem || this.numberingSystem,
      t.outputCalendar || this.outputCalendar,
      Ul(t.weekSettings) || this.weekSettings,
      t.defaultToEN || !1
    );
  }
  redefaultToEN(t = {}) {
    return this.clone({ ...t, defaultToEN: !0 });
  }
  redefaultToSystem(t = {}) {
    return this.clone({ ...t, defaultToEN: !1 });
  }
  months(t, n = !1) {
    return Ws(this, t, Ay, () => {
      const o = n ? { month: t, day: "numeric" } : { month: t }, r = n ? "format" : "standalone";
      return this.monthsCache[r][t] || (this.monthsCache[r][t] = iD((s) => this.extract(s, o, "month"))), this.monthsCache[r][t];
    });
  }
  weekdays(t, n = !1) {
    return Ws(this, t, $y, () => {
      const o = n ? { weekday: t, year: "numeric", month: "long", day: "numeric" } : { weekday: t }, r = n ? "format" : "standalone";
      return this.weekdaysCache[r][t] || (this.weekdaysCache[r][t] = aD(
        (s) => this.extract(s, o, "weekday")
      )), this.weekdaysCache[r][t];
    });
  }
  meridiems() {
    return Ws(
      this,
      void 0,
      () => ky,
      () => {
        if (!this.meridiemCache) {
          const t = { hour: "numeric", hourCycle: "h12" };
          this.meridiemCache = [D.utc(2016, 11, 13, 9), D.utc(2016, 11, 13, 19)].map(
            (n) => this.extract(n, t, "dayperiod")
          );
        }
        return this.meridiemCache;
      }
    );
  }
  eras(t) {
    return Ws(this, t, Dy, () => {
      const n = { era: t };
      return this.eraCache[t] || (this.eraCache[t] = [D.utc(-40, 1, 1), D.utc(2017, 1, 1)].map(
        (o) => this.extract(o, n, "era")
      )), this.eraCache[t];
    });
  }
  extract(t, n, o) {
    const r = this.dtFormatter(t, n), s = r.formatToParts(), i = s.find((a) => a.type.toLowerCase() === o);
    return i ? i.value : null;
  }
  numberFormatter(t = {}) {
    return new cD(this.intl, t.forceSimple || this.fastNumbers, t);
  }
  dtFormatter(t, n = {}) {
    return new uD(t, this.intl, n);
  }
  relFormatter(t = {}) {
    return new dD(this.intl, this.isEnglish(), t);
  }
  listFormatter(t = {}) {
    return X2(this.intl, t);
  }
  isEnglish() {
    return this.locale === "en" || this.locale.toLowerCase() === "en-us" || new Intl.DateTimeFormat(this.intl).resolvedOptions().locale.startsWith("en-us");
  }
  getWeekSettings() {
    return this.weekSettings ? this.weekSettings : Oy() ? oD(this.locale) : fD;
  }
  getStartOfWeek() {
    return this.getWeekSettings().firstDay;
  }
  getMinDaysInFirstWeek() {
    return this.getWeekSettings().minimalDays;
  }
  getWeekendDays() {
    return this.getWeekSettings().weekend;
  }
  equals(t) {
    return this.locale === t.locale && this.numberingSystem === t.numberingSystem && this.outputCalendar === t.outputCalendar;
  }
}
let Xa = null;
class Ee extends xs {
  /**
   * Get a singleton instance of UTC
   * @return {FixedOffsetZone}
   */
  static get utcInstance() {
    return Xa === null && (Xa = new Ee(0)), Xa;
  }
  /**
   * Get an instance with a specified offset
   * @param {number} offset - The offset in minutes
   * @return {FixedOffsetZone}
   */
  static instance(t) {
    return t === 0 ? Ee.utcInstance : new Ee(t);
  }
  /**
   * Get an instance of FixedOffsetZone from a UTC offset string, like "UTC+6"
   * @param {string} s - The offset string to parse
   * @example FixedOffsetZone.parseSpecifier("UTC+6")
   * @example FixedOffsetZone.parseSpecifier("UTC+06")
   * @example FixedOffsetZone.parseSpecifier("UTC-6:00")
   * @return {FixedOffsetZone}
   */
  static parseSpecifier(t) {
    if (t) {
      const n = t.match(/^utc(?:([+-]\d{1,2})(?::(\d{2}))?)?$/i);
      if (n)
        return new Ee(La(n[1], n[2]));
    }
    return null;
  }
  constructor(t) {
    super(), this.fixed = t;
  }
  /** @override **/
  get type() {
    return "fixed";
  }
  /** @override **/
  get name() {
    return this.fixed === 0 ? "UTC" : `UTC${Vr(this.fixed, "narrow")}`;
  }
  get ianaName() {
    return this.fixed === 0 ? "Etc/UTC" : `Etc/GMT${Vr(-this.fixed, "narrow")}`;
  }
  /** @override **/
  offsetName() {
    return this.name;
  }
  /** @override **/
  formatOffset(t, n) {
    return Vr(this.fixed, n);
  }
  /** @override **/
  get isUniversal() {
    return !0;
  }
  /** @override **/
  offset() {
    return this.fixed;
  }
  /** @override **/
  equals(t) {
    return t.type === "fixed" && t.fixed === this.fixed;
  }
  /** @override **/
  get isValid() {
    return !0;
  }
}
class mD extends xs {
  constructor(t) {
    super(), this.zoneName = t;
  }
  /** @override **/
  get type() {
    return "invalid";
  }
  /** @override **/
  get name() {
    return this.zoneName;
  }
  /** @override **/
  get isUniversal() {
    return !1;
  }
  /** @override **/
  offsetName() {
    return null;
  }
  /** @override **/
  formatOffset() {
    return "";
  }
  /** @override **/
  offset() {
    return NaN;
  }
  /** @override **/
  equals() {
    return !1;
  }
  /** @override **/
  get isValid() {
    return !1;
  }
}
function on(e, t) {
  if (N(e) || e === null)
    return t;
  if (e instanceof xs)
    return e;
  if (yD(e)) {
    const n = e.toLowerCase();
    return n === "default" ? t : n === "local" || n === "system" ? ka.instance : n === "utc" || n === "gmt" ? Ee.utcInstance : Ee.parseSpecifier(n) || Ft.create(e);
  } else
    return Ln(e) ? Ee.instance(e) : typeof e == "object" && "offset" in e && typeof e.offset == "function" ? e : new mD(e);
}
let Wd = () => Date.now(), Ud = "system", jd = null, Fd = null, qd = null, Hd = 60, Zd, Vd = null;
class le {
  /**
   * Get the callback for returning the current timestamp.
   * @type {function}
   */
  static get now() {
    return Wd;
  }
  /**
   * Set the callback for returning the current timestamp.
   * The function should return a number, which will be interpreted as an Epoch millisecond count
   * @type {function}
   * @example Settings.now = () => Date.now() + 3000 // pretend it is 3 seconds in the future
   * @example Settings.now = () => 0 // always pretend it's Jan 1, 1970 at midnight in UTC time
   */
  static set now(t) {
    Wd = t;
  }
  /**
   * Set the default time zone to create DateTimes in. Does not affect existing instances.
   * Use the value "system" to reset this value to the system's time zone.
   * @type {string}
   */
  static set defaultZone(t) {
    Ud = t;
  }
  /**
   * Get the default time zone object currently used to create DateTimes. Does not affect existing instances.
   * The default value is the system's time zone (the one set on the machine that runs this code).
   * @type {Zone}
   */
  static get defaultZone() {
    return on(Ud, ka.instance);
  }
  /**
   * Get the default locale to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static get defaultLocale() {
    return jd;
  }
  /**
   * Set the default locale to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static set defaultLocale(t) {
    jd = t;
  }
  /**
   * Get the default numbering system to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static get defaultNumberingSystem() {
    return Fd;
  }
  /**
   * Set the default numbering system to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static set defaultNumberingSystem(t) {
    Fd = t;
  }
  /**
   * Get the default output calendar to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static get defaultOutputCalendar() {
    return qd;
  }
  /**
   * Set the default output calendar to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static set defaultOutputCalendar(t) {
    qd = t;
  }
  /**
   * @typedef {Object} WeekSettings
   * @property {number} firstDay
   * @property {number} minimalDays
   * @property {number[]} weekend
   */
  /**
   * @return {WeekSettings|null}
   */
  static get defaultWeekSettings() {
    return Vd;
  }
  /**
   * Allows overriding the default locale week settings, i.e. the start of the week, the weekend and
   * how many days are required in the first week of a year.
   * Does not affect existing instances.
   *
   * @param {WeekSettings|null} weekSettings
   */
  static set defaultWeekSettings(t) {
    Vd = Ul(t);
  }
  /**
   * Get the cutoff year after which a string encoding a year as two digits is interpreted to occur in the current century.
   * @type {number}
   */
  static get twoDigitCutoffYear() {
    return Hd;
  }
  /**
   * Set the cutoff year after which a string encoding a year as two digits is interpreted to occur in the current century.
   * @type {number}
   * @example Settings.twoDigitCutoffYear = 0 // cut-off year is 0, so all 'yy' are interpreted as current century
   * @example Settings.twoDigitCutoffYear = 50 // '49' -> 1949; '50' -> 2050
   * @example Settings.twoDigitCutoffYear = 1950 // interpreted as 50
   * @example Settings.twoDigitCutoffYear = 2050 // ALSO interpreted as 50
   */
  static set twoDigitCutoffYear(t) {
    Hd = t % 100;
  }
  /**
   * Get whether Luxon will throw when it encounters invalid DateTimes, Durations, or Intervals
   * @type {boolean}
   */
  static get throwOnInvalid() {
    return Zd;
  }
  /**
   * Set whether Luxon will throw when it encounters invalid DateTimes, Durations, or Intervals
   * @type {boolean}
   */
  static set throwOnInvalid(t) {
    Zd = t;
  }
  /**
   * Reset Luxon's global caches. Should only be necessary in testing scenarios.
   * @return {void}
   */
  static resetCaches() {
    V.resetCache(), Ft.resetCache();
  }
}
class ut {
  constructor(t, n) {
    this.reason = t, this.explanation = n;
  }
  toMessage() {
    return this.explanation ? `${this.reason}: ${this.explanation}` : this.reason;
  }
}
const gy = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334], vy = [0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335];
function Ge(e, t) {
  return new ut(
    "unit out of range",
    `you specified ${t} (of type ${typeof t}) as a ${e}, which is invalid`
  );
}
function cu(e, t, n) {
  const o = new Date(Date.UTC(e, t - 1, n));
  e < 100 && e >= 0 && o.setUTCFullYear(o.getUTCFullYear() - 1900);
  const r = o.getUTCDay();
  return r === 0 ? 7 : r;
}
function by(e, t, n) {
  return n + (As(e) ? vy : gy)[t - 1];
}
function Iy(e, t) {
  const n = As(e) ? vy : gy, o = n.findIndex((s) => s < t), r = t - n[o];
  return { month: o + 1, day: r };
}
function uu(e, t) {
  return (e - t + 7) % 7 + 1;
}
function Xi(e, t = 4, n = 1) {
  const { year: o, month: r, day: s } = e, i = by(o, r, s), a = uu(cu(o, r, s), n);
  let l = Math.floor((i - a + 14 - t) / 7), c;
  return l < 1 ? (c = o - 1, l = ns(c, t, n)) : l > ns(o, t, n) ? (c = o + 1, l = 1) : c = o, { weekYear: c, weekNumber: l, weekday: a, ...Pa(e) };
}
function zd(e, t = 4, n = 1) {
  const { weekYear: o, weekNumber: r, weekday: s } = e, i = uu(cu(o, 1, t), n), a = xo(o);
  let l = r * 7 + s - i - 7 + t, c;
  l < 1 ? (c = o - 1, l += xo(c)) : l > a ? (c = o + 1, l -= xo(o)) : c = o;
  const { month: u, day: d } = Iy(c, l);
  return { year: c, month: u, day: d, ...Pa(e) };
}
function el(e) {
  const { year: t, month: n, day: o } = e, r = by(t, n, o);
  return { year: t, ordinal: r, ...Pa(e) };
}
function Gd(e) {
  const { year: t, ordinal: n } = e, { month: o, day: r } = Iy(t, n);
  return { year: t, month: o, day: r, ...Pa(e) };
}
function Yd(e, t) {
  if (!N(e.localWeekday) || !N(e.localWeekNumber) || !N(e.localWeekYear)) {
    if (!N(e.weekday) || !N(e.weekNumber) || !N(e.weekYear))
      throw new yo(
        "Cannot mix locale-based week fields with ISO-based week fields"
      );
    return N(e.localWeekday) || (e.weekday = e.localWeekday), N(e.localWeekNumber) || (e.weekNumber = e.localWeekNumber), N(e.localWeekYear) || (e.weekYear = e.localWeekYear), delete e.localWeekday, delete e.localWeekNumber, delete e.localWeekYear, {
      minDaysInFirstWeek: t.getMinDaysInFirstWeek(),
      startOfWeek: t.getStartOfWeek()
    };
  } else
    return { minDaysInFirstWeek: 4, startOfWeek: 1 };
}
function hD(e, t = 4, n = 1) {
  const o = Da(e.weekYear), r = Ye(
    e.weekNumber,
    1,
    ns(e.weekYear, t, n)
  ), s = Ye(e.weekday, 1, 7);
  return o ? r ? s ? !1 : Ge("weekday", e.weekday) : Ge("week", e.weekNumber) : Ge("weekYear", e.weekYear);
}
function pD(e) {
  const t = Da(e.year), n = Ye(e.ordinal, 1, xo(e.year));
  return t ? n ? !1 : Ge("ordinal", e.ordinal) : Ge("year", e.year);
}
function Ty(e) {
  const t = Da(e.year), n = Ye(e.month, 1, 12), o = Ye(e.day, 1, ea(e.year, e.month));
  return t ? n ? o ? !1 : Ge("day", e.day) : Ge("month", e.month) : Ge("year", e.year);
}
function wy(e) {
  const { hour: t, minute: n, second: o, millisecond: r } = e, s = Ye(t, 0, 23) || t === 24 && n === 0 && o === 0 && r === 0, i = Ye(n, 0, 59), a = Ye(o, 0, 59), l = Ye(r, 0, 999);
  return s ? i ? a ? l ? !1 : Ge("millisecond", r) : Ge("second", o) : Ge("minute", n) : Ge("hour", t);
}
function N(e) {
  return typeof e > "u";
}
function Ln(e) {
  return typeof e == "number";
}
function Da(e) {
  return typeof e == "number" && e % 1 === 0;
}
function yD(e) {
  return typeof e == "string";
}
function gD(e) {
  return Object.prototype.toString.call(e) === "[object Date]";
}
function Cy() {
  try {
    return typeof Intl < "u" && !!Intl.RelativeTimeFormat;
  } catch {
    return !1;
  }
}
function Oy() {
  try {
    return typeof Intl < "u" && !!Intl.Locale && ("weekInfo" in Intl.Locale.prototype || "getWeekInfo" in Intl.Locale.prototype);
  } catch {
    return !1;
  }
}
function vD(e) {
  return Array.isArray(e) ? e : [e];
}
function Kd(e, t, n) {
  if (e.length !== 0)
    return e.reduce((o, r) => {
      const s = [t(r), r];
      return o && n(o[0], s[0]) === o[0] ? o : s;
    }, null)[1];
}
function bD(e, t) {
  return t.reduce((n, o) => (n[o] = e[o], n), {});
}
function Bo(e, t) {
  return Object.prototype.hasOwnProperty.call(e, t);
}
function Ul(e) {
  if (e == null)
    return null;
  if (typeof e != "object")
    throw new Me("Week settings must be an object");
  if (!Ye(e.firstDay, 1, 7) || !Ye(e.minimalDays, 1, 7) || !Array.isArray(e.weekend) || e.weekend.some((t) => !Ye(t, 1, 7)))
    throw new Me("Invalid week settings");
  return {
    firstDay: e.firstDay,
    minimalDays: e.minimalDays,
    weekend: Array.from(e.weekend)
  };
}
function Ye(e, t, n) {
  return Da(e) && e >= t && e <= n;
}
function ID(e, t) {
  return e - t * Math.floor(e / t);
}
function de(e, t = 2) {
  const n = e < 0;
  let o;
  return n ? o = "-" + ("" + -e).padStart(t, "0") : o = ("" + e).padStart(t, "0"), o;
}
function nn(e) {
  if (!(N(e) || e === null || e === ""))
    return parseInt(e, 10);
}
function yn(e) {
  if (!(N(e) || e === null || e === ""))
    return parseFloat(e);
}
function du(e) {
  if (!(N(e) || e === null || e === "")) {
    const t = parseFloat("0." + e) * 1e3;
    return Math.floor(t);
  }
}
function fu(e, t, n = !1) {
  const o = 10 ** t;
  return (n ? Math.trunc : Math.round)(e * o) / o;
}
function As(e) {
  return e % 4 === 0 && (e % 100 !== 0 || e % 400 === 0);
}
function xo(e) {
  return As(e) ? 366 : 365;
}
function ea(e, t) {
  const n = ID(t - 1, 12) + 1, o = e + (t - n) / 12;
  return n === 2 ? As(o) ? 29 : 28 : [31, null, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][n - 1];
}
function Ra(e) {
  let t = Date.UTC(
    e.year,
    e.month - 1,
    e.day,
    e.hour,
    e.minute,
    e.second,
    e.millisecond
  );
  return e.year < 100 && e.year >= 0 && (t = new Date(t), t.setUTCFullYear(e.year, e.month - 1, e.day)), +t;
}
function Qd(e, t, n) {
  return -uu(cu(e, 1, t), n) + t - 1;
}
function ns(e, t = 4, n = 1) {
  const o = Qd(e, t, n), r = Qd(e + 1, t, n);
  return (xo(e) - o + r) / 7;
}
function jl(e) {
  return e > 99 ? e : e > le.twoDigitCutoffYear ? 1900 + e : 2e3 + e;
}
function Ey(e, t, n, o = null) {
  const r = new Date(e), s = {
    hourCycle: "h23",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  };
  o && (s.timeZone = o);
  const i = { timeZoneName: t, ...s }, a = new Intl.DateTimeFormat(n, i).formatToParts(r).find((l) => l.type.toLowerCase() === "timezonename");
  return a ? a.value : null;
}
function La(e, t) {
  let n = parseInt(e, 10);
  Number.isNaN(n) && (n = 0);
  const o = parseInt(t, 10) || 0, r = n < 0 || Object.is(n, -0) ? -o : o;
  return n * 60 + r;
}
function Sy(e) {
  const t = Number(e);
  if (typeof e == "boolean" || e === "" || Number.isNaN(t))
    throw new Me(`Invalid unit value ${e}`);
  return t;
}
function ta(e, t) {
  const n = {};
  for (const o in e)
    if (Bo(e, o)) {
      const r = e[o];
      if (r == null)
        continue;
      n[t(o)] = Sy(r);
    }
  return n;
}
function Vr(e, t) {
  const n = Math.trunc(Math.abs(e / 60)), o = Math.trunc(Math.abs(e % 60)), r = e >= 0 ? "+" : "-";
  switch (t) {
    case "short":
      return `${r}${de(n, 2)}:${de(o, 2)}`;
    case "narrow":
      return `${r}${n}${o > 0 ? `:${o}` : ""}`;
    case "techie":
      return `${r}${de(n, 2)}${de(o, 2)}`;
    default:
      throw new RangeError(`Value format ${t} is out of range for property format`);
  }
}
function Pa(e) {
  return bD(e, ["hour", "minute", "second", "millisecond"]);
}
const TD = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
], xy = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
], wD = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];
function Ay(e) {
  switch (e) {
    case "narrow":
      return [...wD];
    case "short":
      return [...xy];
    case "long":
      return [...TD];
    case "numeric":
      return ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
    case "2-digit":
      return ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    default:
      return null;
  }
}
const My = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
], Ny = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"], CD = ["M", "T", "W", "T", "F", "S", "S"];
function $y(e) {
  switch (e) {
    case "narrow":
      return [...CD];
    case "short":
      return [...Ny];
    case "long":
      return [...My];
    case "numeric":
      return ["1", "2", "3", "4", "5", "6", "7"];
    default:
      return null;
  }
}
const ky = ["AM", "PM"], OD = ["Before Christ", "Anno Domini"], ED = ["BC", "AD"], SD = ["B", "A"];
function Dy(e) {
  switch (e) {
    case "narrow":
      return [...SD];
    case "short":
      return [...ED];
    case "long":
      return [...OD];
    default:
      return null;
  }
}
function xD(e) {
  return ky[e.hour < 12 ? 0 : 1];
}
function AD(e, t) {
  return $y(t)[e.weekday - 1];
}
function MD(e, t) {
  return Ay(t)[e.month - 1];
}
function ND(e, t) {
  return Dy(t)[e.year < 0 ? 0 : 1];
}
function $D(e, t, n = "always", o = !1) {
  const r = {
    years: ["year", "yr."],
    quarters: ["quarter", "qtr."],
    months: ["month", "mo."],
    weeks: ["week", "wk."],
    days: ["day", "day", "days"],
    hours: ["hour", "hr."],
    minutes: ["minute", "min."],
    seconds: ["second", "sec."]
  }, s = ["hours", "minutes", "seconds"].indexOf(e) === -1;
  if (n === "auto" && s) {
    const d = e === "days";
    switch (t) {
      case 1:
        return d ? "tomorrow" : `next ${r[e][0]}`;
      case -1:
        return d ? "yesterday" : `last ${r[e][0]}`;
      case 0:
        return d ? "today" : `this ${r[e][0]}`;
    }
  }
  const i = Object.is(t, -0) || t < 0, a = Math.abs(t), l = a === 1, c = r[e], u = o ? l ? c[1] : c[2] || c[1] : l ? r[e][0] : e;
  return i ? `${a} ${u} ago` : `in ${a} ${u}`;
}
function Jd(e, t) {
  let n = "";
  for (const o of e)
    o.literal ? n += o.val : n += t(o.val);
  return n;
}
const kD = {
  D: Ji,
  DD: Jp,
  DDD: Xp,
  DDDD: ey,
  t: ty,
  tt: ny,
  ttt: oy,
  tttt: ry,
  T: sy,
  TT: iy,
  TTT: ay,
  TTTT: ly,
  f: cy,
  ff: dy,
  fff: my,
  ffff: py,
  F: uy,
  FF: fy,
  FFF: hy,
  FFFF: yy
};
class Te {
  static create(t, n = {}) {
    return new Te(t, n);
  }
  static parseFormat(t) {
    let n = null, o = "", r = !1;
    const s = [];
    for (let i = 0; i < t.length; i++) {
      const a = t.charAt(i);
      a === "'" ? (o.length > 0 && s.push({ literal: r || /^\s+$/.test(o), val: o }), n = null, o = "", r = !r) : r || a === n ? o += a : (o.length > 0 && s.push({ literal: /^\s+$/.test(o), val: o }), o = a, n = a);
    }
    return o.length > 0 && s.push({ literal: r || /^\s+$/.test(o), val: o }), s;
  }
  static macroTokenToFormatOpts(t) {
    return kD[t];
  }
  constructor(t, n) {
    this.opts = n, this.loc = t, this.systemLoc = null;
  }
  formatWithSystemDefault(t, n) {
    return this.systemLoc === null && (this.systemLoc = this.loc.redefaultToSystem()), this.systemLoc.dtFormatter(t, { ...this.opts, ...n }).format();
  }
  dtFormatter(t, n = {}) {
    return this.loc.dtFormatter(t, { ...this.opts, ...n });
  }
  formatDateTime(t, n) {
    return this.dtFormatter(t, n).format();
  }
  formatDateTimeParts(t, n) {
    return this.dtFormatter(t, n).formatToParts();
  }
  formatInterval(t, n) {
    return this.dtFormatter(t.start, n).dtf.formatRange(t.start.toJSDate(), t.end.toJSDate());
  }
  resolvedOptions(t, n) {
    return this.dtFormatter(t, n).resolvedOptions();
  }
  num(t, n = 0) {
    if (this.opts.forceSimple)
      return de(t, n);
    const o = { ...this.opts };
    return n > 0 && (o.padTo = n), this.loc.numberFormatter(o).format(t);
  }
  formatDateTimeFromString(t, n) {
    const o = this.loc.listingMode() === "en", r = this.loc.outputCalendar && this.loc.outputCalendar !== "gregory", s = (h, p) => this.loc.extract(t, h, p), i = (h) => t.isOffsetFixed && t.offset === 0 && h.allowZ ? "Z" : t.isValid ? t.zone.formatOffset(t.ts, h.format) : "", a = () => o ? xD(t) : s({ hour: "numeric", hourCycle: "h12" }, "dayperiod"), l = (h, p) => o ? MD(t, h) : s(p ? { month: h } : { month: h, day: "numeric" }, "month"), c = (h, p) => o ? AD(t, h) : s(
      p ? { weekday: h } : { weekday: h, month: "long", day: "numeric" },
      "weekday"
    ), u = (h) => {
      const p = Te.macroTokenToFormatOpts(h);
      return p ? this.formatWithSystemDefault(t, p) : h;
    }, d = (h) => o ? ND(t, h) : s({ era: h }, "era"), f = (h) => {
      switch (h) {
        case "S":
          return this.num(t.millisecond);
        case "u":
        case "SSS":
          return this.num(t.millisecond, 3);
        case "s":
          return this.num(t.second);
        case "ss":
          return this.num(t.second, 2);
        case "uu":
          return this.num(Math.floor(t.millisecond / 10), 2);
        case "uuu":
          return this.num(Math.floor(t.millisecond / 100));
        case "m":
          return this.num(t.minute);
        case "mm":
          return this.num(t.minute, 2);
        case "h":
          return this.num(t.hour % 12 === 0 ? 12 : t.hour % 12);
        case "hh":
          return this.num(t.hour % 12 === 0 ? 12 : t.hour % 12, 2);
        case "H":
          return this.num(t.hour);
        case "HH":
          return this.num(t.hour, 2);
        case "Z":
          return i({ format: "narrow", allowZ: this.opts.allowZ });
        case "ZZ":
          return i({ format: "short", allowZ: this.opts.allowZ });
        case "ZZZ":
          return i({ format: "techie", allowZ: this.opts.allowZ });
        case "ZZZZ":
          return t.zone.offsetName(t.ts, { format: "short", locale: this.loc.locale });
        case "ZZZZZ":
          return t.zone.offsetName(t.ts, { format: "long", locale: this.loc.locale });
        case "z":
          return t.zoneName;
        case "a":
          return a();
        case "d":
          return r ? s({ day: "numeric" }, "day") : this.num(t.day);
        case "dd":
          return r ? s({ day: "2-digit" }, "day") : this.num(t.day, 2);
        case "c":
          return this.num(t.weekday);
        case "ccc":
          return c("short", !0);
        case "cccc":
          return c("long", !0);
        case "ccccc":
          return c("narrow", !0);
        case "E":
          return this.num(t.weekday);
        case "EEE":
          return c("short", !1);
        case "EEEE":
          return c("long", !1);
        case "EEEEE":
          return c("narrow", !1);
        case "L":
          return r ? s({ month: "numeric", day: "numeric" }, "month") : this.num(t.month);
        case "LL":
          return r ? s({ month: "2-digit", day: "numeric" }, "month") : this.num(t.month, 2);
        case "LLL":
          return l("short", !0);
        case "LLLL":
          return l("long", !0);
        case "LLLLL":
          return l("narrow", !0);
        case "M":
          return r ? s({ month: "numeric" }, "month") : this.num(t.month);
        case "MM":
          return r ? s({ month: "2-digit" }, "month") : this.num(t.month, 2);
        case "MMM":
          return l("short", !1);
        case "MMMM":
          return l("long", !1);
        case "MMMMM":
          return l("narrow", !1);
        case "y":
          return r ? s({ year: "numeric" }, "year") : this.num(t.year);
        case "yy":
          return r ? s({ year: "2-digit" }, "year") : this.num(t.year.toString().slice(-2), 2);
        case "yyyy":
          return r ? s({ year: "numeric" }, "year") : this.num(t.year, 4);
        case "yyyyyy":
          return r ? s({ year: "numeric" }, "year") : this.num(t.year, 6);
        case "G":
          return d("short");
        case "GG":
          return d("long");
        case "GGGGG":
          return d("narrow");
        case "kk":
          return this.num(t.weekYear.toString().slice(-2), 2);
        case "kkkk":
          return this.num(t.weekYear, 4);
        case "W":
          return this.num(t.weekNumber);
        case "WW":
          return this.num(t.weekNumber, 2);
        case "n":
          return this.num(t.localWeekNumber);
        case "nn":
          return this.num(t.localWeekNumber, 2);
        case "ii":
          return this.num(t.localWeekYear.toString().slice(-2), 2);
        case "iiii":
          return this.num(t.localWeekYear, 4);
        case "o":
          return this.num(t.ordinal);
        case "ooo":
          return this.num(t.ordinal, 3);
        case "q":
          return this.num(t.quarter);
        case "qq":
          return this.num(t.quarter, 2);
        case "X":
          return this.num(Math.floor(t.ts / 1e3));
        case "x":
          return this.num(t.ts);
        default:
          return u(h);
      }
    };
    return Jd(Te.parseFormat(n), f);
  }
  formatDurationFromString(t, n) {
    const o = (l) => {
      switch (l[0]) {
        case "S":
          return "millisecond";
        case "s":
          return "second";
        case "m":
          return "minute";
        case "h":
          return "hour";
        case "d":
          return "day";
        case "w":
          return "week";
        case "M":
          return "month";
        case "y":
          return "year";
        default:
          return null;
      }
    }, r = (l) => (c) => {
      const u = o(c);
      return u ? this.num(l.get(u), c.length) : c;
    }, s = Te.parseFormat(n), i = s.reduce(
      (l, { literal: c, val: u }) => c ? l : l.concat(u),
      []
    ), a = t.shiftTo(...i.map(o).filter((l) => l));
    return Jd(s, r(a));
  }
}
const Ry = /[A-Za-z_+-]{1,256}(?::?\/[A-Za-z0-9_+-]{1,256}(?:\/[A-Za-z0-9_+-]{1,256})?)?/;
function er(...e) {
  const t = e.reduce((n, o) => n + o.source, "");
  return RegExp(`^${t}$`);
}
function tr(...e) {
  return (t) => e.reduce(
    ([n, o, r], s) => {
      const [i, a, l] = s(t, r);
      return [{ ...n, ...i }, a || o, l];
    },
    [{}, null, 1]
  ).slice(0, 2);
}
function nr(e, ...t) {
  if (e == null)
    return [null, null];
  for (const [n, o] of t) {
    const r = n.exec(e);
    if (r)
      return o(r);
  }
  return [null, null];
}
function Ly(...e) {
  return (t, n) => {
    const o = {};
    let r;
    for (r = 0; r < e.length; r++)
      o[e[r]] = nn(t[n + r]);
    return [o, null, n + r];
  };
}
const Py = /(?:(Z)|([+-]\d\d)(?::?(\d\d))?)/, DD = `(?:${Py.source}?(?:\\[(${Ry.source})\\])?)?`, mu = /(\d\d)(?::?(\d\d)(?::?(\d\d)(?:[.,](\d{1,30}))?)?)?/, _y = RegExp(`${mu.source}${DD}`), hu = RegExp(`(?:T${_y.source})?`), RD = /([+-]\d{6}|\d{4})(?:-?(\d\d)(?:-?(\d\d))?)?/, LD = /(\d{4})-?W(\d\d)(?:-?(\d))?/, PD = /(\d{4})-?(\d{3})/, _D = Ly("weekYear", "weekNumber", "weekDay"), BD = Ly("year", "ordinal"), WD = /(\d{4})-(\d\d)-(\d\d)/, By = RegExp(
  `${mu.source} ?(?:${Py.source}|(${Ry.source}))?`
), UD = RegExp(`(?: ${By.source})?`);
function Ao(e, t, n) {
  const o = e[t];
  return N(o) ? n : nn(o);
}
function jD(e, t) {
  return [{
    year: Ao(e, t),
    month: Ao(e, t + 1, 1),
    day: Ao(e, t + 2, 1)
  }, null, t + 3];
}
function or(e, t) {
  return [{
    hours: Ao(e, t, 0),
    minutes: Ao(e, t + 1, 0),
    seconds: Ao(e, t + 2, 0),
    milliseconds: du(e[t + 3])
  }, null, t + 4];
}
function Ms(e, t) {
  const n = !e[t] && !e[t + 1], o = La(e[t + 1], e[t + 2]), r = n ? null : Ee.instance(o);
  return [{}, r, t + 3];
}
function Ns(e, t) {
  const n = e[t] ? Ft.create(e[t]) : null;
  return [{}, n, t + 1];
}
const FD = RegExp(`^T?${mu.source}$`), qD = /^-?P(?:(?:(-?\d{1,20}(?:\.\d{1,20})?)Y)?(?:(-?\d{1,20}(?:\.\d{1,20})?)M)?(?:(-?\d{1,20}(?:\.\d{1,20})?)W)?(?:(-?\d{1,20}(?:\.\d{1,20})?)D)?(?:T(?:(-?\d{1,20}(?:\.\d{1,20})?)H)?(?:(-?\d{1,20}(?:\.\d{1,20})?)M)?(?:(-?\d{1,20})(?:[.,](-?\d{1,20}))?S)?)?)$/;
function HD(e) {
  const [t, n, o, r, s, i, a, l, c] = e, u = t[0] === "-", d = l && l[0] === "-", f = (h, p = !1) => h !== void 0 && (p || h && u) ? -h : h;
  return [
    {
      years: f(yn(n)),
      months: f(yn(o)),
      weeks: f(yn(r)),
      days: f(yn(s)),
      hours: f(yn(i)),
      minutes: f(yn(a)),
      seconds: f(yn(l), l === "-0"),
      milliseconds: f(du(c), d)
    }
  ];
}
const ZD = {
  GMT: 0,
  EDT: -4 * 60,
  EST: -5 * 60,
  CDT: -5 * 60,
  CST: -6 * 60,
  MDT: -6 * 60,
  MST: -7 * 60,
  PDT: -7 * 60,
  PST: -8 * 60
};
function pu(e, t, n, o, r, s, i) {
  const a = {
    year: t.length === 2 ? jl(nn(t)) : nn(t),
    month: xy.indexOf(n) + 1,
    day: nn(o),
    hour: nn(r),
    minute: nn(s)
  };
  return i && (a.second = nn(i)), e && (a.weekday = e.length > 3 ? My.indexOf(e) + 1 : Ny.indexOf(e) + 1), a;
}
const VD = /^(?:(Mon|Tue|Wed|Thu|Fri|Sat|Sun),\s)?(\d{1,2})\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s(\d{2,4})\s(\d\d):(\d\d)(?::(\d\d))?\s(?:(UT|GMT|[ECMP][SD]T)|([Zz])|(?:([+-]\d\d)(\d\d)))$/;
function zD(e) {
  const [
    ,
    t,
    n,
    o,
    r,
    s,
    i,
    a,
    l,
    c,
    u,
    d
  ] = e, f = pu(t, r, o, n, s, i, a);
  let h;
  return l ? h = ZD[l] : c ? h = 0 : h = La(u, d), [f, new Ee(h)];
}
function GD(e) {
  return e.replace(/\([^()]*\)|[\n\t]/g, " ").replace(/(\s\s+)/g, " ").trim();
}
const YD = /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun), (\d\d) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) (\d{4}) (\d\d):(\d\d):(\d\d) GMT$/, KD = /^(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday), (\d\d)-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-(\d\d) (\d\d):(\d\d):(\d\d) GMT$/, QD = /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) ( \d|\d\d) (\d\d):(\d\d):(\d\d) (\d{4})$/;
function Xd(e) {
  const [, t, n, o, r, s, i, a] = e;
  return [pu(t, r, o, n, s, i, a), Ee.utcInstance];
}
function JD(e) {
  const [, t, n, o, r, s, i, a] = e;
  return [pu(t, a, n, o, r, s, i), Ee.utcInstance];
}
const XD = er(RD, hu), eR = er(LD, hu), tR = er(PD, hu), nR = er(_y), Wy = tr(
  jD,
  or,
  Ms,
  Ns
), oR = tr(
  _D,
  or,
  Ms,
  Ns
), rR = tr(
  BD,
  or,
  Ms,
  Ns
), sR = tr(
  or,
  Ms,
  Ns
);
function iR(e) {
  return nr(
    e,
    [XD, Wy],
    [eR, oR],
    [tR, rR],
    [nR, sR]
  );
}
function aR(e) {
  return nr(GD(e), [VD, zD]);
}
function lR(e) {
  return nr(
    e,
    [YD, Xd],
    [KD, Xd],
    [QD, JD]
  );
}
function cR(e) {
  return nr(e, [qD, HD]);
}
const uR = tr(or);
function dR(e) {
  return nr(e, [FD, uR]);
}
const fR = er(WD, UD), mR = er(By), hR = tr(
  or,
  Ms,
  Ns
);
function pR(e) {
  return nr(
    e,
    [fR, Wy],
    [mR, hR]
  );
}
const ef = "Invalid Duration", Uy = {
  weeks: {
    days: 7,
    hours: 7 * 24,
    minutes: 7 * 24 * 60,
    seconds: 7 * 24 * 60 * 60,
    milliseconds: 7 * 24 * 60 * 60 * 1e3
  },
  days: {
    hours: 24,
    minutes: 24 * 60,
    seconds: 24 * 60 * 60,
    milliseconds: 24 * 60 * 60 * 1e3
  },
  hours: { minutes: 60, seconds: 60 * 60, milliseconds: 60 * 60 * 1e3 },
  minutes: { seconds: 60, milliseconds: 60 * 1e3 },
  seconds: { milliseconds: 1e3 }
}, yR = {
  years: {
    quarters: 4,
    months: 12,
    weeks: 52,
    days: 365,
    hours: 365 * 24,
    minutes: 365 * 24 * 60,
    seconds: 365 * 24 * 60 * 60,
    milliseconds: 365 * 24 * 60 * 60 * 1e3
  },
  quarters: {
    months: 3,
    weeks: 13,
    days: 91,
    hours: 91 * 24,
    minutes: 91 * 24 * 60,
    seconds: 91 * 24 * 60 * 60,
    milliseconds: 91 * 24 * 60 * 60 * 1e3
  },
  months: {
    weeks: 4,
    days: 30,
    hours: 30 * 24,
    minutes: 30 * 24 * 60,
    seconds: 30 * 24 * 60 * 60,
    milliseconds: 30 * 24 * 60 * 60 * 1e3
  },
  ...Uy
}, Ue = 146097 / 400, Xn = 146097 / 4800, gR = {
  years: {
    quarters: 4,
    months: 12,
    weeks: Ue / 7,
    days: Ue,
    hours: Ue * 24,
    minutes: Ue * 24 * 60,
    seconds: Ue * 24 * 60 * 60,
    milliseconds: Ue * 24 * 60 * 60 * 1e3
  },
  quarters: {
    months: 3,
    weeks: Ue / 28,
    days: Ue / 4,
    hours: Ue * 24 / 4,
    minutes: Ue * 24 * 60 / 4,
    seconds: Ue * 24 * 60 * 60 / 4,
    milliseconds: Ue * 24 * 60 * 60 * 1e3 / 4
  },
  months: {
    weeks: Xn / 7,
    days: Xn,
    hours: Xn * 24,
    minutes: Xn * 24 * 60,
    seconds: Xn * 24 * 60 * 60,
    milliseconds: Xn * 24 * 60 * 60 * 1e3
  },
  ...Uy
}, Mn = [
  "years",
  "quarters",
  "months",
  "weeks",
  "days",
  "hours",
  "minutes",
  "seconds",
  "milliseconds"
], vR = Mn.slice(0).reverse();
function Yt(e, t, n = !1) {
  const o = {
    values: n ? t.values : { ...e.values, ...t.values || {} },
    loc: e.loc.clone(t.loc),
    conversionAccuracy: t.conversionAccuracy || e.conversionAccuracy,
    matrix: t.matrix || e.matrix
  };
  return new j(o);
}
function jy(e, t) {
  let n = t.milliseconds ?? 0;
  for (const o of vR.slice(1))
    t[o] && (n += t[o] * e[o].milliseconds);
  return n;
}
function tf(e, t) {
  const n = jy(e, t) < 0 ? -1 : 1;
  Mn.reduceRight((o, r) => {
    if (N(t[r]))
      return o;
    if (o) {
      const s = t[o] * n, i = e[r][o], a = Math.floor(s / i);
      t[r] += a * n, t[o] -= a * i * n;
    }
    return r;
  }, null), Mn.reduce((o, r) => {
    if (N(t[r]))
      return o;
    if (o) {
      const s = t[o] % 1;
      t[o] -= s, t[r] += s * e[o][r];
    }
    return r;
  }, null);
}
function bR(e) {
  const t = {};
  for (const [n, o] of Object.entries(e))
    o !== 0 && (t[n] = o);
  return t;
}
class j {
  /**
   * @private
   */
  constructor(t) {
    const n = t.conversionAccuracy === "longterm" || !1;
    let o = n ? gR : yR;
    t.matrix && (o = t.matrix), this.values = t.values, this.loc = t.loc || V.create(), this.conversionAccuracy = n ? "longterm" : "casual", this.invalid = t.invalid || null, this.matrix = o, this.isLuxonDuration = !0;
  }
  /**
   * Create Duration from a number of milliseconds.
   * @param {number} count of milliseconds
   * @param {Object} opts - options for parsing
   * @param {string} [opts.locale='en-US'] - the locale to use
   * @param {string} opts.numberingSystem - the numbering system to use
   * @param {string} [opts.conversionAccuracy='casual'] - the conversion system to use
   * @return {Duration}
   */
  static fromMillis(t, n) {
    return j.fromObject({ milliseconds: t }, n);
  }
  /**
   * Create a Duration from a JavaScript object with keys like 'years' and 'hours'.
   * If this object is empty then a zero milliseconds duration is returned.
   * @param {Object} obj - the object to create the DateTime from
   * @param {number} obj.years
   * @param {number} obj.quarters
   * @param {number} obj.months
   * @param {number} obj.weeks
   * @param {number} obj.days
   * @param {number} obj.hours
   * @param {number} obj.minutes
   * @param {number} obj.seconds
   * @param {number} obj.milliseconds
   * @param {Object} [opts=[]] - options for creating this Duration
   * @param {string} [opts.locale='en-US'] - the locale to use
   * @param {string} opts.numberingSystem - the numbering system to use
   * @param {string} [opts.conversionAccuracy='casual'] - the preset conversion system to use
   * @param {string} [opts.matrix=Object] - the custom conversion system to use
   * @return {Duration}
   */
  static fromObject(t, n = {}) {
    if (t == null || typeof t != "object")
      throw new Me(
        `Duration.fromObject: argument expected to be an object, got ${t === null ? "null" : typeof t}`
      );
    return new j({
      values: ta(t, j.normalizeUnit),
      loc: V.fromObject(n),
      conversionAccuracy: n.conversionAccuracy,
      matrix: n.matrix
    });
  }
  /**
   * Create a Duration from DurationLike.
   *
   * @param {Object | number | Duration} durationLike
   * One of:
   * - object with keys like 'years' and 'hours'.
   * - number representing milliseconds
   * - Duration instance
   * @return {Duration}
   */
  static fromDurationLike(t) {
    if (Ln(t))
      return j.fromMillis(t);
    if (j.isDuration(t))
      return t;
    if (typeof t == "object")
      return j.fromObject(t);
    throw new Me(
      `Unknown duration argument ${t} of type ${typeof t}`
    );
  }
  /**
   * Create a Duration from an ISO 8601 duration string.
   * @param {string} text - text to parse
   * @param {Object} opts - options for parsing
   * @param {string} [opts.locale='en-US'] - the locale to use
   * @param {string} opts.numberingSystem - the numbering system to use
   * @param {string} [opts.conversionAccuracy='casual'] - the preset conversion system to use
   * @param {string} [opts.matrix=Object] - the preset conversion system to use
   * @see https://en.wikipedia.org/wiki/ISO_8601#Durations
   * @example Duration.fromISO('P3Y6M1W4DT12H30M5S').toObject() //=> { years: 3, months: 6, weeks: 1, days: 4, hours: 12, minutes: 30, seconds: 5 }
   * @example Duration.fromISO('PT23H').toObject() //=> { hours: 23 }
   * @example Duration.fromISO('P5Y3M').toObject() //=> { years: 5, months: 3 }
   * @return {Duration}
   */
  static fromISO(t, n) {
    const [o] = cR(t);
    return o ? j.fromObject(o, n) : j.invalid("unparsable", `the input "${t}" can't be parsed as ISO 8601`);
  }
  /**
   * Create a Duration from an ISO 8601 time string.
   * @param {string} text - text to parse
   * @param {Object} opts - options for parsing
   * @param {string} [opts.locale='en-US'] - the locale to use
   * @param {string} opts.numberingSystem - the numbering system to use
   * @param {string} [opts.conversionAccuracy='casual'] - the preset conversion system to use
   * @param {string} [opts.matrix=Object] - the conversion system to use
   * @see https://en.wikipedia.org/wiki/ISO_8601#Times
   * @example Duration.fromISOTime('11:22:33.444').toObject() //=> { hours: 11, minutes: 22, seconds: 33, milliseconds: 444 }
   * @example Duration.fromISOTime('11:00').toObject() //=> { hours: 11, minutes: 0, seconds: 0 }
   * @example Duration.fromISOTime('T11:00').toObject() //=> { hours: 11, minutes: 0, seconds: 0 }
   * @example Duration.fromISOTime('1100').toObject() //=> { hours: 11, minutes: 0, seconds: 0 }
   * @example Duration.fromISOTime('T1100').toObject() //=> { hours: 11, minutes: 0, seconds: 0 }
   * @return {Duration}
   */
  static fromISOTime(t, n) {
    const [o] = dR(t);
    return o ? j.fromObject(o, n) : j.invalid("unparsable", `the input "${t}" can't be parsed as ISO 8601`);
  }
  /**
   * Create an invalid Duration.
   * @param {string} reason - simple string of why this datetime is invalid. Should not contain parameters or anything else data-dependent
   * @param {string} [explanation=null] - longer explanation, may include parameters and other useful debugging information
   * @return {Duration}
   */
  static invalid(t, n = null) {
    if (!t)
      throw new Me("need to specify a reason the Duration is invalid");
    const o = t instanceof ut ? t : new ut(t, n);
    if (le.throwOnInvalid)
      throw new V2(o);
    return new j({ invalid: o });
  }
  /**
   * @private
   */
  static normalizeUnit(t) {
    const n = {
      year: "years",
      years: "years",
      quarter: "quarters",
      quarters: "quarters",
      month: "months",
      months: "months",
      week: "weeks",
      weeks: "weeks",
      day: "days",
      days: "days",
      hour: "hours",
      hours: "hours",
      minute: "minutes",
      minutes: "minutes",
      second: "seconds",
      seconds: "seconds",
      millisecond: "milliseconds",
      milliseconds: "milliseconds"
    }[t && t.toLowerCase()];
    if (!n)
      throw new Qp(t);
    return n;
  }
  /**
   * Check if an object is a Duration. Works across context boundaries
   * @param {object} o
   * @return {boolean}
   */
  static isDuration(t) {
    return t && t.isLuxonDuration || !1;
  }
  /**
   * Get  the locale of a Duration, such 'en-GB'
   * @type {string}
   */
  get locale() {
    return this.isValid ? this.loc.locale : null;
  }
  /**
   * Get the numbering system of a Duration, such 'beng'. The numbering system is used when formatting the Duration
   *
   * @type {string}
   */
  get numberingSystem() {
    return this.isValid ? this.loc.numberingSystem : null;
  }
  /**
   * Returns a string representation of this Duration formatted according to the specified format string. You may use these tokens:
   * * `S` for milliseconds
   * * `s` for seconds
   * * `m` for minutes
   * * `h` for hours
   * * `d` for days
   * * `w` for weeks
   * * `M` for months
   * * `y` for years
   * Notes:
   * * Add padding by repeating the token, e.g. "yy" pads the years to two digits, "hhhh" pads the hours out to four digits
   * * Tokens can be escaped by wrapping with single quotes.
   * * The duration will be converted to the set of units in the format string using {@link Duration#shiftTo} and the Durations's conversion accuracy setting.
   * @param {string} fmt - the format string
   * @param {Object} opts - options
   * @param {boolean} [opts.floor=true] - floor numerical values
   * @example Duration.fromObject({ years: 1, days: 6, seconds: 2 }).toFormat("y d s") //=> "1 6 2"
   * @example Duration.fromObject({ years: 1, days: 6, seconds: 2 }).toFormat("yy dd sss") //=> "01 06 002"
   * @example Duration.fromObject({ years: 1, days: 6, seconds: 2 }).toFormat("M S") //=> "12 518402000"
   * @return {string}
   */
  toFormat(t, n = {}) {
    const o = {
      ...n,
      floor: n.round !== !1 && n.floor !== !1
    };
    return this.isValid ? Te.create(this.loc, o).formatDurationFromString(this, t) : ef;
  }
  /**
   * Returns a string representation of a Duration with all units included.
   * To modify its behavior, use `listStyle` and any Intl.NumberFormat option, though `unitDisplay` is especially relevant.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options
   * @param {Object} opts - Formatting options. Accepts the same keys as the options parameter of the native `Intl.NumberFormat` constructor, as well as `listStyle`.
   * @param {string} [opts.listStyle='narrow'] - How to format the merged list. Corresponds to the `style` property of the options parameter of the native `Intl.ListFormat` constructor.
   * @example
   * ```js
   * var dur = Duration.fromObject({ days: 1, hours: 5, minutes: 6 })
   * dur.toHuman() //=> '1 day, 5 hours, 6 minutes'
   * dur.toHuman({ listStyle: "long" }) //=> '1 day, 5 hours, and 6 minutes'
   * dur.toHuman({ unitDisplay: "short" }) //=> '1 day, 5 hr, 6 min'
   * ```
   */
  toHuman(t = {}) {
    if (!this.isValid)
      return ef;
    const n = Mn.map((o) => {
      const r = this.values[o];
      return N(r) ? null : this.loc.numberFormatter({ style: "unit", unitDisplay: "long", ...t, unit: o.slice(0, -1) }).format(r);
    }).filter((o) => o);
    return this.loc.listFormatter({ type: "conjunction", style: t.listStyle || "narrow", ...t }).format(n);
  }
  /**
   * Returns a JavaScript object with this Duration's values.
   * @example Duration.fromObject({ years: 1, days: 6, seconds: 2 }).toObject() //=> { years: 1, days: 6, seconds: 2 }
   * @return {Object}
   */
  toObject() {
    return this.isValid ? { ...this.values } : {};
  }
  /**
   * Returns an ISO 8601-compliant string representation of this Duration.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Durations
   * @example Duration.fromObject({ years: 3, seconds: 45 }).toISO() //=> 'P3YT45S'
   * @example Duration.fromObject({ months: 4, seconds: 45 }).toISO() //=> 'P4MT45S'
   * @example Duration.fromObject({ months: 5 }).toISO() //=> 'P5M'
   * @example Duration.fromObject({ minutes: 5 }).toISO() //=> 'PT5M'
   * @example Duration.fromObject({ milliseconds: 6 }).toISO() //=> 'PT0.006S'
   * @return {string}
   */
  toISO() {
    if (!this.isValid)
      return null;
    let t = "P";
    return this.years !== 0 && (t += this.years + "Y"), (this.months !== 0 || this.quarters !== 0) && (t += this.months + this.quarters * 3 + "M"), this.weeks !== 0 && (t += this.weeks + "W"), this.days !== 0 && (t += this.days + "D"), (this.hours !== 0 || this.minutes !== 0 || this.seconds !== 0 || this.milliseconds !== 0) && (t += "T"), this.hours !== 0 && (t += this.hours + "H"), this.minutes !== 0 && (t += this.minutes + "M"), (this.seconds !== 0 || this.milliseconds !== 0) && (t += fu(this.seconds + this.milliseconds / 1e3, 3) + "S"), t === "P" && (t += "T0S"), t;
  }
  /**
   * Returns an ISO 8601-compliant string representation of this Duration, formatted as a time of day.
   * Note that this will return null if the duration is invalid, negative, or equal to or greater than 24 hours.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Times
   * @param {Object} opts - options
   * @param {boolean} [opts.suppressMilliseconds=false] - exclude milliseconds from the format if they're 0
   * @param {boolean} [opts.suppressSeconds=false] - exclude seconds from the format if they're 0
   * @param {boolean} [opts.includePrefix=false] - include the `T` prefix
   * @param {string} [opts.format='extended'] - choose between the basic and extended format
   * @example Duration.fromObject({ hours: 11 }).toISOTime() //=> '11:00:00.000'
   * @example Duration.fromObject({ hours: 11 }).toISOTime({ suppressMilliseconds: true }) //=> '11:00:00'
   * @example Duration.fromObject({ hours: 11 }).toISOTime({ suppressSeconds: true }) //=> '11:00'
   * @example Duration.fromObject({ hours: 11 }).toISOTime({ includePrefix: true }) //=> 'T11:00:00.000'
   * @example Duration.fromObject({ hours: 11 }).toISOTime({ format: 'basic' }) //=> '110000.000'
   * @return {string}
   */
  toISOTime(t = {}) {
    if (!this.isValid)
      return null;
    const n = this.toMillis();
    return n < 0 || n >= 864e5 ? null : (t = {
      suppressMilliseconds: !1,
      suppressSeconds: !1,
      includePrefix: !1,
      format: "extended",
      ...t,
      includeOffset: !1
    }, D.fromMillis(n, { zone: "UTC" }).toISOTime(t));
  }
  /**
   * Returns an ISO 8601 representation of this Duration appropriate for use in JSON.
   * @return {string}
   */
  toJSON() {
    return this.toISO();
  }
  /**
   * Returns an ISO 8601 representation of this Duration appropriate for use in debugging.
   * @return {string}
   */
  toString() {
    return this.toISO();
  }
  /**
   * Returns a string representation of this Duration appropriate for the REPL.
   * @return {string}
   */
  [Symbol.for("nodejs.util.inspect.custom")]() {
    return this.isValid ? `Duration { values: ${JSON.stringify(this.values)} }` : `Duration { Invalid, reason: ${this.invalidReason} }`;
  }
  /**
   * Returns an milliseconds value of this Duration.
   * @return {number}
   */
  toMillis() {
    return this.isValid ? jy(this.matrix, this.values) : NaN;
  }
  /**
   * Returns an milliseconds value of this Duration. Alias of {@link toMillis}
   * @return {number}
   */
  valueOf() {
    return this.toMillis();
  }
  /**
   * Make this Duration longer by the specified amount. Return a newly-constructed Duration.
   * @param {Duration|Object|number} duration - The amount to add. Either a Luxon Duration, a number of milliseconds, the object argument to Duration.fromObject()
   * @return {Duration}
   */
  plus(t) {
    if (!this.isValid)
      return this;
    const n = j.fromDurationLike(t), o = {};
    for (const r of Mn)
      (Bo(n.values, r) || Bo(this.values, r)) && (o[r] = n.get(r) + this.get(r));
    return Yt(this, { values: o }, !0);
  }
  /**
   * Make this Duration shorter by the specified amount. Return a newly-constructed Duration.
   * @param {Duration|Object|number} duration - The amount to subtract. Either a Luxon Duration, a number of milliseconds, the object argument to Duration.fromObject()
   * @return {Duration}
   */
  minus(t) {
    if (!this.isValid)
      return this;
    const n = j.fromDurationLike(t);
    return this.plus(n.negate());
  }
  /**
   * Scale this Duration by the specified amount. Return a newly-constructed Duration.
   * @param {function} fn - The function to apply to each unit. Arity is 1 or 2: the value of the unit and, optionally, the unit name. Must return a number.
   * @example Duration.fromObject({ hours: 1, minutes: 30 }).mapUnits(x => x * 2) //=> { hours: 2, minutes: 60 }
   * @example Duration.fromObject({ hours: 1, minutes: 30 }).mapUnits((x, u) => u === "hours" ? x * 2 : x) //=> { hours: 2, minutes: 30 }
   * @return {Duration}
   */
  mapUnits(t) {
    if (!this.isValid)
      return this;
    const n = {};
    for (const o of Object.keys(this.values))
      n[o] = Sy(t(this.values[o], o));
    return Yt(this, { values: n }, !0);
  }
  /**
   * Get the value of unit.
   * @param {string} unit - a unit such as 'minute' or 'day'
   * @example Duration.fromObject({years: 2, days: 3}).get('years') //=> 2
   * @example Duration.fromObject({years: 2, days: 3}).get('months') //=> 0
   * @example Duration.fromObject({years: 2, days: 3}).get('days') //=> 3
   * @return {number}
   */
  get(t) {
    return this[j.normalizeUnit(t)];
  }
  /**
   * "Set" the values of specified units. Return a newly-constructed Duration.
   * @param {Object} values - a mapping of units to numbers
   * @example dur.set({ years: 2017 })
   * @example dur.set({ hours: 8, minutes: 30 })
   * @return {Duration}
   */
  set(t) {
    if (!this.isValid)
      return this;
    const n = { ...this.values, ...ta(t, j.normalizeUnit) };
    return Yt(this, { values: n });
  }
  /**
   * "Set" the locale and/or numberingSystem.  Returns a newly-constructed Duration.
   * @example dur.reconfigure({ locale: 'en-GB' })
   * @return {Duration}
   */
  reconfigure({ locale: t, numberingSystem: n, conversionAccuracy: o, matrix: r } = {}) {
    const i = { loc: this.loc.clone({ locale: t, numberingSystem: n }), matrix: r, conversionAccuracy: o };
    return Yt(this, i);
  }
  /**
   * Return the length of the duration in the specified unit.
   * @param {string} unit - a unit such as 'minutes' or 'days'
   * @example Duration.fromObject({years: 1}).as('days') //=> 365
   * @example Duration.fromObject({years: 1}).as('months') //=> 12
   * @example Duration.fromObject({hours: 60}).as('days') //=> 2.5
   * @return {number}
   */
  as(t) {
    return this.isValid ? this.shiftTo(t).get(t) : NaN;
  }
  /**
   * Reduce this Duration to its canonical representation in its current units.
   * Assuming the overall value of the Duration is positive, this means:
   * - excessive values for lower-order units are converted to higher-order units (if possible, see first and second example)
   * - negative lower-order units are converted to higher order units (there must be such a higher order unit, otherwise
   *   the overall value would be negative, see third example)
   * - fractional values for higher-order units are converted to lower-order units (if possible, see fourth example)
   *
   * If the overall value is negative, the result of this method is equivalent to `this.negate().normalize().negate()`.
   * @example Duration.fromObject({ years: 2, days: 5000 }).normalize().toObject() //=> { years: 15, days: 255 }
   * @example Duration.fromObject({ days: 5000 }).normalize().toObject() //=> { days: 5000 }
   * @example Duration.fromObject({ hours: 12, minutes: -45 }).normalize().toObject() //=> { hours: 11, minutes: 15 }
   * @example Duration.fromObject({ years: 2.5, days: 0, hours: 0 }).normalize().toObject() //=> { years: 2, days: 182, hours: 12 }
   * @return {Duration}
   */
  normalize() {
    if (!this.isValid)
      return this;
    const t = this.toObject();
    return tf(this.matrix, t), Yt(this, { values: t }, !0);
  }
  /**
   * Rescale units to its largest representation
   * @example Duration.fromObject({ milliseconds: 90000 }).rescale().toObject() //=> { minutes: 1, seconds: 30 }
   * @return {Duration}
   */
  rescale() {
    if (!this.isValid)
      return this;
    const t = bR(this.normalize().shiftToAll().toObject());
    return Yt(this, { values: t }, !0);
  }
  /**
   * Convert this Duration into its representation in a different set of units.
   * @example Duration.fromObject({ hours: 1, seconds: 30 }).shiftTo('minutes', 'milliseconds').toObject() //=> { minutes: 60, milliseconds: 30000 }
   * @return {Duration}
   */
  shiftTo(...t) {
    if (!this.isValid)
      return this;
    if (t.length === 0)
      return this;
    t = t.map((i) => j.normalizeUnit(i));
    const n = {}, o = {}, r = this.toObject();
    let s;
    for (const i of Mn)
      if (t.indexOf(i) >= 0) {
        s = i;
        let a = 0;
        for (const c in o)
          a += this.matrix[c][i] * o[c], o[c] = 0;
        Ln(r[i]) && (a += r[i]);
        const l = Math.trunc(a);
        n[i] = l, o[i] = (a * 1e3 - l * 1e3) / 1e3;
      } else
        Ln(r[i]) && (o[i] = r[i]);
    for (const i in o)
      o[i] !== 0 && (n[s] += i === s ? o[i] : o[i] / this.matrix[s][i]);
    return tf(this.matrix, n), Yt(this, { values: n }, !0);
  }
  /**
   * Shift this Duration to all available units.
   * Same as shiftTo("years", "months", "weeks", "days", "hours", "minutes", "seconds", "milliseconds")
   * @return {Duration}
   */
  shiftToAll() {
    return this.isValid ? this.shiftTo(
      "years",
      "months",
      "weeks",
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    ) : this;
  }
  /**
   * Return the negative of this Duration.
   * @example Duration.fromObject({ hours: 1, seconds: 30 }).negate().toObject() //=> { hours: -1, seconds: -30 }
   * @return {Duration}
   */
  negate() {
    if (!this.isValid)
      return this;
    const t = {};
    for (const n of Object.keys(this.values))
      t[n] = this.values[n] === 0 ? 0 : -this.values[n];
    return Yt(this, { values: t }, !0);
  }
  /**
   * Get the years.
   * @type {number}
   */
  get years() {
    return this.isValid ? this.values.years || 0 : NaN;
  }
  /**
   * Get the quarters.
   * @type {number}
   */
  get quarters() {
    return this.isValid ? this.values.quarters || 0 : NaN;
  }
  /**
   * Get the months.
   * @type {number}
   */
  get months() {
    return this.isValid ? this.values.months || 0 : NaN;
  }
  /**
   * Get the weeks
   * @type {number}
   */
  get weeks() {
    return this.isValid ? this.values.weeks || 0 : NaN;
  }
  /**
   * Get the days.
   * @type {number}
   */
  get days() {
    return this.isValid ? this.values.days || 0 : NaN;
  }
  /**
   * Get the hours.
   * @type {number}
   */
  get hours() {
    return this.isValid ? this.values.hours || 0 : NaN;
  }
  /**
   * Get the minutes.
   * @type {number}
   */
  get minutes() {
    return this.isValid ? this.values.minutes || 0 : NaN;
  }
  /**
   * Get the seconds.
   * @return {number}
   */
  get seconds() {
    return this.isValid ? this.values.seconds || 0 : NaN;
  }
  /**
   * Get the milliseconds.
   * @return {number}
   */
  get milliseconds() {
    return this.isValid ? this.values.milliseconds || 0 : NaN;
  }
  /**
   * Returns whether the Duration is invalid. Invalid durations are returned by diff operations
   * on invalid DateTimes or Intervals.
   * @return {boolean}
   */
  get isValid() {
    return this.invalid === null;
  }
  /**
   * Returns an error code if this Duration became invalid, or null if the Duration is valid
   * @return {string}
   */
  get invalidReason() {
    return this.invalid ? this.invalid.reason : null;
  }
  /**
   * Returns an explanation of why this Duration became invalid, or null if the Duration is valid
   * @type {string}
   */
  get invalidExplanation() {
    return this.invalid ? this.invalid.explanation : null;
  }
  /**
   * Equality check
   * Two Durations are equal iff they have the same units and the same values for each unit.
   * @param {Duration} other
   * @return {boolean}
   */
  equals(t) {
    if (!this.isValid || !t.isValid || !this.loc.equals(t.loc))
      return !1;
    function n(o, r) {
      return o === void 0 || o === 0 ? r === void 0 || r === 0 : o === r;
    }
    for (const o of Mn)
      if (!n(this.values[o], t.values[o]))
        return !1;
    return !0;
  }
}
const eo = "Invalid Interval";
function IR(e, t) {
  return !e || !e.isValid ? ie.invalid("missing or invalid start") : !t || !t.isValid ? ie.invalid("missing or invalid end") : t < e ? ie.invalid(
    "end before start",
    `The end of an interval must be after its start, but you had start=${e.toISO()} and end=${t.toISO()}`
  ) : null;
}
class ie {
  /**
   * @private
   */
  constructor(t) {
    this.s = t.start, this.e = t.end, this.invalid = t.invalid || null, this.isLuxonInterval = !0;
  }
  /**
   * Create an invalid Interval.
   * @param {string} reason - simple string of why this Interval is invalid. Should not contain parameters or anything else data-dependent
   * @param {string} [explanation=null] - longer explanation, may include parameters and other useful debugging information
   * @return {Interval}
   */
  static invalid(t, n = null) {
    if (!t)
      throw new Me("need to specify a reason the Interval is invalid");
    const o = t instanceof ut ? t : new ut(t, n);
    if (le.throwOnInvalid)
      throw new Z2(o);
    return new ie({ invalid: o });
  }
  /**
   * Create an Interval from a start DateTime and an end DateTime. Inclusive of the start but not the end.
   * @param {DateTime|Date|Object} start
   * @param {DateTime|Date|Object} end
   * @return {Interval}
   */
  static fromDateTimes(t, n) {
    const o = mr(t), r = mr(n), s = IR(o, r);
    return s ?? new ie({
      start: o,
      end: r
    });
  }
  /**
   * Create an Interval from a start DateTime and a Duration to extend to.
   * @param {DateTime|Date|Object} start
   * @param {Duration|Object|number} duration - the length of the Interval.
   * @return {Interval}
   */
  static after(t, n) {
    const o = j.fromDurationLike(n), r = mr(t);
    return ie.fromDateTimes(r, r.plus(o));
  }
  /**
   * Create an Interval from an end DateTime and a Duration to extend backwards to.
   * @param {DateTime|Date|Object} end
   * @param {Duration|Object|number} duration - the length of the Interval.
   * @return {Interval}
   */
  static before(t, n) {
    const o = j.fromDurationLike(n), r = mr(t);
    return ie.fromDateTimes(r.minus(o), r);
  }
  /**
   * Create an Interval from an ISO 8601 string.
   * Accepts `<start>/<end>`, `<start>/<duration>`, and `<duration>/<end>` formats.
   * @param {string} text - the ISO string to parse
   * @param {Object} [opts] - options to pass {@link DateTime#fromISO} and optionally {@link Duration#fromISO}
   * @see https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
   * @return {Interval}
   */
  static fromISO(t, n) {
    const [o, r] = (t || "").split("/", 2);
    if (o && r) {
      let s, i;
      try {
        s = D.fromISO(o, n), i = s.isValid;
      } catch {
        i = !1;
      }
      let a, l;
      try {
        a = D.fromISO(r, n), l = a.isValid;
      } catch {
        l = !1;
      }
      if (i && l)
        return ie.fromDateTimes(s, a);
      if (i) {
        const c = j.fromISO(r, n);
        if (c.isValid)
          return ie.after(s, c);
      } else if (l) {
        const c = j.fromISO(o, n);
        if (c.isValid)
          return ie.before(a, c);
      }
    }
    return ie.invalid("unparsable", `the input "${t}" can't be parsed as ISO 8601`);
  }
  /**
   * Check if an object is an Interval. Works across context boundaries
   * @param {object} o
   * @return {boolean}
   */
  static isInterval(t) {
    return t && t.isLuxonInterval || !1;
  }
  /**
   * Returns the start of the Interval
   * @type {DateTime}
   */
  get start() {
    return this.isValid ? this.s : null;
  }
  /**
   * Returns the end of the Interval
   * @type {DateTime}
   */
  get end() {
    return this.isValid ? this.e : null;
  }
  /**
   * Returns whether this Interval's end is at least its start, meaning that the Interval isn't 'backwards'.
   * @type {boolean}
   */
  get isValid() {
    return this.invalidReason === null;
  }
  /**
   * Returns an error code if this Interval is invalid, or null if the Interval is valid
   * @type {string}
   */
  get invalidReason() {
    return this.invalid ? this.invalid.reason : null;
  }
  /**
   * Returns an explanation of why this Interval became invalid, or null if the Interval is valid
   * @type {string}
   */
  get invalidExplanation() {
    return this.invalid ? this.invalid.explanation : null;
  }
  /**
   * Returns the length of the Interval in the specified unit.
   * @param {string} unit - the unit (such as 'hours' or 'days') to return the length in.
   * @return {number}
   */
  length(t = "milliseconds") {
    return this.isValid ? this.toDuration(t).get(t) : NaN;
  }
  /**
   * Returns the count of minutes, hours, days, months, or years included in the Interval, even in part.
   * Unlike {@link Interval#length} this counts sections of the calendar, not periods of time, e.g. specifying 'day'
   * asks 'what dates are included in this interval?', not 'how many days long is this interval?'
   * @param {string} [unit='milliseconds'] - the unit of time to count.
   * @param {Object} opts - options
   * @param {boolean} [opts.useLocaleWeeks=false] - If true, use weeks based on the locale, i.e. use the locale-dependent start of the week; this operation will always use the locale of the start DateTime
   * @return {number}
   */
  count(t = "milliseconds", n) {
    if (!this.isValid)
      return NaN;
    const o = this.start.startOf(t, n);
    let r;
    return n != null && n.useLocaleWeeks ? r = this.end.reconfigure({ locale: o.locale }) : r = this.end, r = r.startOf(t, n), Math.floor(r.diff(o, t).get(t)) + (r.valueOf() !== this.end.valueOf());
  }
  /**
   * Returns whether this Interval's start and end are both in the same unit of time
   * @param {string} unit - the unit of time to check sameness on
   * @return {boolean}
   */
  hasSame(t) {
    return this.isValid ? this.isEmpty() || this.e.minus(1).hasSame(this.s, t) : !1;
  }
  /**
   * Return whether this Interval has the same start and end DateTimes.
   * @return {boolean}
   */
  isEmpty() {
    return this.s.valueOf() === this.e.valueOf();
  }
  /**
   * Return whether this Interval's start is after the specified DateTime.
   * @param {DateTime} dateTime
   * @return {boolean}
   */
  isAfter(t) {
    return this.isValid ? this.s > t : !1;
  }
  /**
   * Return whether this Interval's end is before the specified DateTime.
   * @param {DateTime} dateTime
   * @return {boolean}
   */
  isBefore(t) {
    return this.isValid ? this.e <= t : !1;
  }
  /**
   * Return whether this Interval contains the specified DateTime.
   * @param {DateTime} dateTime
   * @return {boolean}
   */
  contains(t) {
    return this.isValid ? this.s <= t && this.e > t : !1;
  }
  /**
   * "Sets" the start and/or end dates. Returns a newly-constructed Interval.
   * @param {Object} values - the values to set
   * @param {DateTime} values.start - the starting DateTime
   * @param {DateTime} values.end - the ending DateTime
   * @return {Interval}
   */
  set({ start: t, end: n } = {}) {
    return this.isValid ? ie.fromDateTimes(t || this.s, n || this.e) : this;
  }
  /**
   * Split this Interval at each of the specified DateTimes
   * @param {...DateTime} dateTimes - the unit of time to count.
   * @return {Array}
   */
  splitAt(...t) {
    if (!this.isValid)
      return [];
    const n = t.map(mr).filter((i) => this.contains(i)).sort((i, a) => i.toMillis() - a.toMillis()), o = [];
    let { s: r } = this, s = 0;
    for (; r < this.e; ) {
      const i = n[s] || this.e, a = +i > +this.e ? this.e : i;
      o.push(ie.fromDateTimes(r, a)), r = a, s += 1;
    }
    return o;
  }
  /**
   * Split this Interval into smaller Intervals, each of the specified length.
   * Left over time is grouped into a smaller interval
   * @param {Duration|Object|number} duration - The length of each resulting interval.
   * @return {Array}
   */
  splitBy(t) {
    const n = j.fromDurationLike(t);
    if (!this.isValid || !n.isValid || n.as("milliseconds") === 0)
      return [];
    let { s: o } = this, r = 1, s;
    const i = [];
    for (; o < this.e; ) {
      const a = this.start.plus(n.mapUnits((l) => l * r));
      s = +a > +this.e ? this.e : a, i.push(ie.fromDateTimes(o, s)), o = s, r += 1;
    }
    return i;
  }
  /**
   * Split this Interval into the specified number of smaller intervals.
   * @param {number} numberOfParts - The number of Intervals to divide the Interval into.
   * @return {Array}
   */
  divideEqually(t) {
    return this.isValid ? this.splitBy(this.length() / t).slice(0, t) : [];
  }
  /**
   * Return whether this Interval overlaps with the specified Interval
   * @param {Interval} other
   * @return {boolean}
   */
  overlaps(t) {
    return this.e > t.s && this.s < t.e;
  }
  /**
   * Return whether this Interval's end is adjacent to the specified Interval's start.
   * @param {Interval} other
   * @return {boolean}
   */
  abutsStart(t) {
    return this.isValid ? +this.e == +t.s : !1;
  }
  /**
   * Return whether this Interval's start is adjacent to the specified Interval's end.
   * @param {Interval} other
   * @return {boolean}
   */
  abutsEnd(t) {
    return this.isValid ? +t.e == +this.s : !1;
  }
  /**
   * Return whether this Interval engulfs the start and end of the specified Interval.
   * @param {Interval} other
   * @return {boolean}
   */
  engulfs(t) {
    return this.isValid ? this.s <= t.s && this.e >= t.e : !1;
  }
  /**
   * Return whether this Interval has the same start and end as the specified Interval.
   * @param {Interval} other
   * @return {boolean}
   */
  equals(t) {
    return !this.isValid || !t.isValid ? !1 : this.s.equals(t.s) && this.e.equals(t.e);
  }
  /**
   * Return an Interval representing the intersection of this Interval and the specified Interval.
   * Specifically, the resulting Interval has the maximum start time and the minimum end time of the two Intervals.
   * Returns null if the intersection is empty, meaning, the intervals don't intersect.
   * @param {Interval} other
   * @return {Interval}
   */
  intersection(t) {
    if (!this.isValid)
      return this;
    const n = this.s > t.s ? this.s : t.s, o = this.e < t.e ? this.e : t.e;
    return n >= o ? null : ie.fromDateTimes(n, o);
  }
  /**
   * Return an Interval representing the union of this Interval and the specified Interval.
   * Specifically, the resulting Interval has the minimum start time and the maximum end time of the two Intervals.
   * @param {Interval} other
   * @return {Interval}
   */
  union(t) {
    if (!this.isValid)
      return this;
    const n = this.s < t.s ? this.s : t.s, o = this.e > t.e ? this.e : t.e;
    return ie.fromDateTimes(n, o);
  }
  /**
   * Merge an array of Intervals into a equivalent minimal set of Intervals.
   * Combines overlapping and adjacent Intervals.
   * @param {Array} intervals
   * @return {Array}
   */
  static merge(t) {
    const [n, o] = t.sort((r, s) => r.s - s.s).reduce(
      ([r, s], i) => s ? s.overlaps(i) || s.abutsStart(i) ? [r, s.union(i)] : [r.concat([s]), i] : [r, i],
      [[], null]
    );
    return o && n.push(o), n;
  }
  /**
   * Return an array of Intervals representing the spans of time that only appear in one of the specified Intervals.
   * @param {Array} intervals
   * @return {Array}
   */
  static xor(t) {
    let n = null, o = 0;
    const r = [], s = t.map((l) => [
      { time: l.s, type: "s" },
      { time: l.e, type: "e" }
    ]), i = Array.prototype.concat(...s), a = i.sort((l, c) => l.time - c.time);
    for (const l of a)
      o += l.type === "s" ? 1 : -1, o === 1 ? n = l.time : (n && +n != +l.time && r.push(ie.fromDateTimes(n, l.time)), n = null);
    return ie.merge(r);
  }
  /**
   * Return an Interval representing the span of time in this Interval that doesn't overlap with any of the specified Intervals.
   * @param {...Interval} intervals
   * @return {Array}
   */
  difference(...t) {
    return ie.xor([this].concat(t)).map((n) => this.intersection(n)).filter((n) => n && !n.isEmpty());
  }
  /**
   * Returns a string representation of this Interval appropriate for debugging.
   * @return {string}
   */
  toString() {
    return this.isValid ? `[${this.s.toISO()} – ${this.e.toISO()})` : eo;
  }
  /**
   * Returns a string representation of this Interval appropriate for the REPL.
   * @return {string}
   */
  [Symbol.for("nodejs.util.inspect.custom")]() {
    return this.isValid ? `Interval { start: ${this.s.toISO()}, end: ${this.e.toISO()} }` : `Interval { Invalid, reason: ${this.invalidReason} }`;
  }
  /**
   * Returns a localized string representing this Interval. Accepts the same options as the
   * Intl.DateTimeFormat constructor and any presets defined by Luxon, such as
   * {@link DateTime.DATE_FULL} or {@link DateTime.TIME_SIMPLE}. The exact behavior of this method
   * is browser-specific, but in general it will return an appropriate representation of the
   * Interval in the assigned locale. Defaults to the system's locale if no locale has been
   * specified.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
   * @param {Object} [formatOpts=DateTime.DATE_SHORT] - Either a DateTime preset or
   * Intl.DateTimeFormat constructor options.
   * @param {Object} opts - Options to override the configuration of the start DateTime.
   * @example Interval.fromISO('2022-11-07T09:00Z/2022-11-08T09:00Z').toLocaleString(); //=> 11/7/2022 – 11/8/2022
   * @example Interval.fromISO('2022-11-07T09:00Z/2022-11-08T09:00Z').toLocaleString(DateTime.DATE_FULL); //=> November 7 – 8, 2022
   * @example Interval.fromISO('2022-11-07T09:00Z/2022-11-08T09:00Z').toLocaleString(DateTime.DATE_FULL, { locale: 'fr-FR' }); //=> 7–8 novembre 2022
   * @example Interval.fromISO('2022-11-07T17:00Z/2022-11-07T19:00Z').toLocaleString(DateTime.TIME_SIMPLE); //=> 6:00 – 8:00 PM
   * @example Interval.fromISO('2022-11-07T17:00Z/2022-11-07T19:00Z').toLocaleString({ weekday: 'short', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' }); //=> Mon, Nov 07, 6:00 – 8:00 p
   * @return {string}
   */
  toLocaleString(t = Ji, n = {}) {
    return this.isValid ? Te.create(this.s.loc.clone(n), t).formatInterval(this) : eo;
  }
  /**
   * Returns an ISO 8601-compliant string representation of this Interval.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
   * @param {Object} opts - The same options as {@link DateTime#toISO}
   * @return {string}
   */
  toISO(t) {
    return this.isValid ? `${this.s.toISO(t)}/${this.e.toISO(t)}` : eo;
  }
  /**
   * Returns an ISO 8601-compliant string representation of date of this Interval.
   * The time components are ignored.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
   * @return {string}
   */
  toISODate() {
    return this.isValid ? `${this.s.toISODate()}/${this.e.toISODate()}` : eo;
  }
  /**
   * Returns an ISO 8601-compliant string representation of time of this Interval.
   * The date components are ignored.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
   * @param {Object} opts - The same options as {@link DateTime#toISO}
   * @return {string}
   */
  toISOTime(t) {
    return this.isValid ? `${this.s.toISOTime(t)}/${this.e.toISOTime(t)}` : eo;
  }
  /**
   * Returns a string representation of this Interval formatted according to the specified format
   * string. **You may not want this.** See {@link Interval#toLocaleString} for a more flexible
   * formatting tool.
   * @param {string} dateFormat - The format string. This string formats the start and end time.
   * See {@link DateTime#toFormat} for details.
   * @param {Object} opts - Options.
   * @param {string} [opts.separator =  ' – '] - A separator to place between the start and end
   * representations.
   * @return {string}
   */
  toFormat(t, { separator: n = " – " } = {}) {
    return this.isValid ? `${this.s.toFormat(t)}${n}${this.e.toFormat(t)}` : eo;
  }
  /**
   * Return a Duration representing the time spanned by this interval.
   * @param {string|string[]} [unit=['milliseconds']] - the unit or units (such as 'hours' or 'days') to include in the duration.
   * @param {Object} opts - options that affect the creation of the Duration
   * @param {string} [opts.conversionAccuracy='casual'] - the conversion system to use
   * @example Interval.fromDateTimes(dt1, dt2).toDuration().toObject() //=> { milliseconds: 88489257 }
   * @example Interval.fromDateTimes(dt1, dt2).toDuration('days').toObject() //=> { days: 1.0241812152777778 }
   * @example Interval.fromDateTimes(dt1, dt2).toDuration(['hours', 'minutes']).toObject() //=> { hours: 24, minutes: 34.82095 }
   * @example Interval.fromDateTimes(dt1, dt2).toDuration(['hours', 'minutes', 'seconds']).toObject() //=> { hours: 24, minutes: 34, seconds: 49.257 }
   * @example Interval.fromDateTimes(dt1, dt2).toDuration('seconds').toObject() //=> { seconds: 88489.257 }
   * @return {Duration}
   */
  toDuration(t, n) {
    return this.isValid ? this.e.diff(this.s, t, n) : j.invalid(this.invalidReason);
  }
  /**
   * Run mapFn on the interval start and end, returning a new Interval from the resulting DateTimes
   * @param {function} mapFn
   * @return {Interval}
   * @example Interval.fromDateTimes(dt1, dt2).mapEndpoints(endpoint => endpoint.toUTC())
   * @example Interval.fromDateTimes(dt1, dt2).mapEndpoints(endpoint => endpoint.plus({ hours: 2 }))
   */
  mapEndpoints(t) {
    return ie.fromDateTimes(t(this.s), t(this.e));
  }
}
class Us {
  /**
   * Return whether the specified zone contains a DST.
   * @param {string|Zone} [zone='local'] - Zone to check. Defaults to the environment's local zone.
   * @return {boolean}
   */
  static hasDST(t = le.defaultZone) {
    const n = D.now().setZone(t).set({ month: 12 });
    return !t.isUniversal && n.offset !== n.set({ month: 6 }).offset;
  }
  /**
   * Return whether the specified zone is a valid IANA specifier.
   * @param {string} zone - Zone to check
   * @return {boolean}
   */
  static isValidIANAZone(t) {
    return Ft.isValidZone(t);
  }
  /**
   * Converts the input into a {@link Zone} instance.
   *
   * * If `input` is already a Zone instance, it is returned unchanged.
   * * If `input` is a string containing a valid time zone name, a Zone instance
   *   with that name is returned.
   * * If `input` is a string that doesn't refer to a known time zone, a Zone
   *   instance with {@link Zone#isValid} == false is returned.
   * * If `input is a number, a Zone instance with the specified fixed offset
   *   in minutes is returned.
   * * If `input` is `null` or `undefined`, the default zone is returned.
   * @param {string|Zone|number} [input] - the value to be converted
   * @return {Zone}
   */
  static normalizeZone(t) {
    return on(t, le.defaultZone);
  }
  /**
   * Get the weekday on which the week starts according to the given locale.
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @returns {number} the start of the week, 1 for Monday through 7 for Sunday
   */
  static getStartOfWeek({ locale: t = null, locObj: n = null } = {}) {
    return (n || V.create(t)).getStartOfWeek();
  }
  /**
   * Get the minimum number of days necessary in a week before it is considered part of the next year according
   * to the given locale.
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @returns {number}
   */
  static getMinimumDaysInFirstWeek({ locale: t = null, locObj: n = null } = {}) {
    return (n || V.create(t)).getMinDaysInFirstWeek();
  }
  /**
   * Get the weekdays, which are considered the weekend according to the given locale
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @returns {number[]} an array of weekdays, 1 for Monday through 7 for Sunday
   */
  static getWeekendWeekdays({ locale: t = null, locObj: n = null } = {}) {
    return (n || V.create(t)).getWeekendDays().slice();
  }
  /**
   * Return an array of standalone month names.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
   * @param {string} [length='long'] - the length of the month representation, such as "numeric", "2-digit", "narrow", "short", "long"
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.numberingSystem=null] - the numbering system
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @param {string} [opts.outputCalendar='gregory'] - the calendar
   * @example Info.months()[0] //=> 'January'
   * @example Info.months('short')[0] //=> 'Jan'
   * @example Info.months('numeric')[0] //=> '1'
   * @example Info.months('short', { locale: 'fr-CA' } )[0] //=> 'janv.'
   * @example Info.months('numeric', { locale: 'ar' })[0] //=> '١'
   * @example Info.months('long', { outputCalendar: 'islamic' })[0] //=> 'Rabiʻ I'
   * @return {Array}
   */
  static months(t = "long", { locale: n = null, numberingSystem: o = null, locObj: r = null, outputCalendar: s = "gregory" } = {}) {
    return (r || V.create(n, o, s)).months(t);
  }
  /**
   * Return an array of format month names.
   * Format months differ from standalone months in that they're meant to appear next to the day of the month. In some languages, that
   * changes the string.
   * See {@link Info#months}
   * @param {string} [length='long'] - the length of the month representation, such as "numeric", "2-digit", "narrow", "short", "long"
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.numberingSystem=null] - the numbering system
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @param {string} [opts.outputCalendar='gregory'] - the calendar
   * @return {Array}
   */
  static monthsFormat(t = "long", { locale: n = null, numberingSystem: o = null, locObj: r = null, outputCalendar: s = "gregory" } = {}) {
    return (r || V.create(n, o, s)).months(t, !0);
  }
  /**
   * Return an array of standalone week names.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
   * @param {string} [length='long'] - the length of the weekday representation, such as "narrow", "short", "long".
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.numberingSystem=null] - the numbering system
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @example Info.weekdays()[0] //=> 'Monday'
   * @example Info.weekdays('short')[0] //=> 'Mon'
   * @example Info.weekdays('short', { locale: 'fr-CA' })[0] //=> 'lun.'
   * @example Info.weekdays('short', { locale: 'ar' })[0] //=> 'الاثنين'
   * @return {Array}
   */
  static weekdays(t = "long", { locale: n = null, numberingSystem: o = null, locObj: r = null } = {}) {
    return (r || V.create(n, o, null)).weekdays(t);
  }
  /**
   * Return an array of format week names.
   * Format weekdays differ from standalone weekdays in that they're meant to appear next to more date information. In some languages, that
   * changes the string.
   * See {@link Info#weekdays}
   * @param {string} [length='long'] - the length of the month representation, such as "narrow", "short", "long".
   * @param {Object} opts - options
   * @param {string} [opts.locale=null] - the locale code
   * @param {string} [opts.numberingSystem=null] - the numbering system
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @return {Array}
   */
  static weekdaysFormat(t = "long", { locale: n = null, numberingSystem: o = null, locObj: r = null } = {}) {
    return (r || V.create(n, o, null)).weekdays(t, !0);
  }
  /**
   * Return an array of meridiems.
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @example Info.meridiems() //=> [ 'AM', 'PM' ]
   * @example Info.meridiems({ locale: 'my' }) //=> [ 'နံနက်', 'ညနေ' ]
   * @return {Array}
   */
  static meridiems({ locale: t = null } = {}) {
    return V.create(t).meridiems();
  }
  /**
   * Return an array of eras, such as ['BC', 'AD']. The locale can be specified, but the calendar system is always Gregorian.
   * @param {string} [length='short'] - the length of the era representation, such as "short" or "long".
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @example Info.eras() //=> [ 'BC', 'AD' ]
   * @example Info.eras('long') //=> [ 'Before Christ', 'Anno Domini' ]
   * @example Info.eras('long', { locale: 'fr' }) //=> [ 'avant Jésus-Christ', 'après Jésus-Christ' ]
   * @return {Array}
   */
  static eras(t = "short", { locale: n = null } = {}) {
    return V.create(n, null, "gregory").eras(t);
  }
  /**
   * Return the set of available features in this environment.
   * Some features of Luxon are not available in all environments. For example, on older browsers, relative time formatting support is not available. Use this function to figure out if that's the case.
   * Keys:
   * * `relative`: whether this environment supports relative time formatting
   * * `localeWeek`: whether this environment supports different weekdays for the start of the week based on the locale
   * @example Info.features() //=> { relative: false, localeWeek: true }
   * @return {Object}
   */
  static features() {
    return { relative: Cy(), localeWeek: Oy() };
  }
}
function nf(e, t) {
  const n = (r) => r.toUTC(0, { keepLocalTime: !0 }).startOf("day").valueOf(), o = n(t) - n(e);
  return Math.floor(j.fromMillis(o).as("days"));
}
function TR(e, t, n) {
  const o = [
    ["years", (l, c) => c.year - l.year],
    ["quarters", (l, c) => c.quarter - l.quarter + (c.year - l.year) * 4],
    ["months", (l, c) => c.month - l.month + (c.year - l.year) * 12],
    [
      "weeks",
      (l, c) => {
        const u = nf(l, c);
        return (u - u % 7) / 7;
      }
    ],
    ["days", nf]
  ], r = {}, s = e;
  let i, a;
  for (const [l, c] of o)
    n.indexOf(l) >= 0 && (i = l, r[l] = c(e, t), a = s.plus(r), a > t ? (r[l]--, e = s.plus(r), e > t && (a = e, r[l]--, e = s.plus(r))) : e = a);
  return [e, r, a, i];
}
function wR(e, t, n, o) {
  let [r, s, i, a] = TR(e, t, n);
  const l = t - r, c = n.filter(
    (d) => ["hours", "minutes", "seconds", "milliseconds"].indexOf(d) >= 0
  );
  c.length === 0 && (i < t && (i = r.plus({ [a]: 1 })), i !== r && (s[a] = (s[a] || 0) + l / (i - r)));
  const u = j.fromObject(s, o);
  return c.length > 0 ? j.fromMillis(l, o).shiftTo(...c).plus(u) : u;
}
const yu = {
  arab: "[٠-٩]",
  arabext: "[۰-۹]",
  bali: "[᭐-᭙]",
  beng: "[০-৯]",
  deva: "[०-९]",
  fullwide: "[０-９]",
  gujr: "[૦-૯]",
  hanidec: "[〇|一|二|三|四|五|六|七|八|九]",
  khmr: "[០-៩]",
  knda: "[೦-೯]",
  laoo: "[໐-໙]",
  limb: "[᥆-᥏]",
  mlym: "[൦-൯]",
  mong: "[᠐-᠙]",
  mymr: "[၀-၉]",
  orya: "[୦-୯]",
  tamldec: "[௦-௯]",
  telu: "[౦-౯]",
  thai: "[๐-๙]",
  tibt: "[༠-༩]",
  latn: "\\d"
}, of = {
  arab: [1632, 1641],
  arabext: [1776, 1785],
  bali: [6992, 7001],
  beng: [2534, 2543],
  deva: [2406, 2415],
  fullwide: [65296, 65303],
  gujr: [2790, 2799],
  khmr: [6112, 6121],
  knda: [3302, 3311],
  laoo: [3792, 3801],
  limb: [6470, 6479],
  mlym: [3430, 3439],
  mong: [6160, 6169],
  mymr: [4160, 4169],
  orya: [2918, 2927],
  tamldec: [3046, 3055],
  telu: [3174, 3183],
  thai: [3664, 3673],
  tibt: [3872, 3881]
}, CR = yu.hanidec.replace(/[\[|\]]/g, "").split("");
function OR(e) {
  let t = parseInt(e, 10);
  if (isNaN(t)) {
    t = "";
    for (let n = 0; n < e.length; n++) {
      const o = e.charCodeAt(n);
      if (e[n].search(yu.hanidec) !== -1)
        t += CR.indexOf(e[n]);
      else
        for (const r in of) {
          const [s, i] = of[r];
          o >= s && o <= i && (t += o - s);
        }
    }
    return parseInt(t, 10);
  } else
    return t;
}
function it({ numberingSystem: e }, t = "") {
  return new RegExp(`${yu[e || "latn"]}${t}`);
}
const ER = "missing Intl.DateTimeFormat.formatToParts support";
function H(e, t = (n) => n) {
  return { regex: e, deser: ([n]) => t(OR(n)) };
}
const SR = " ", Fy = `[ ${SR}]`, qy = new RegExp(Fy, "g");
function xR(e) {
  return e.replace(/\./g, "\\.?").replace(qy, Fy);
}
function rf(e) {
  return e.replace(/\./g, "").replace(qy, " ").toLowerCase();
}
function at(e, t) {
  return e === null ? null : {
    regex: RegExp(e.map(xR).join("|")),
    deser: ([n]) => e.findIndex((o) => rf(n) === rf(o)) + t
  };
}
function sf(e, t) {
  return { regex: e, deser: ([, n, o]) => La(n, o), groups: t };
}
function js(e) {
  return { regex: e, deser: ([t]) => t };
}
function AR(e) {
  return e.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&");
}
function MR(e, t) {
  const n = it(t), o = it(t, "{2}"), r = it(t, "{3}"), s = it(t, "{4}"), i = it(t, "{6}"), a = it(t, "{1,2}"), l = it(t, "{1,3}"), c = it(t, "{1,6}"), u = it(t, "{1,9}"), d = it(t, "{2,4}"), f = it(t, "{4,6}"), h = (v) => ({ regex: RegExp(AR(v.val)), deser: ([T]) => T, literal: !0 }), g = ((v) => {
    if (e.literal)
      return h(v);
    switch (v.val) {
      case "G":
        return at(t.eras("short"), 0);
      case "GG":
        return at(t.eras("long"), 0);
      case "y":
        return H(c);
      case "yy":
        return H(d, jl);
      case "yyyy":
        return H(s);
      case "yyyyy":
        return H(f);
      case "yyyyyy":
        return H(i);
      case "M":
        return H(a);
      case "MM":
        return H(o);
      case "MMM":
        return at(t.months("short", !0), 1);
      case "MMMM":
        return at(t.months("long", !0), 1);
      case "L":
        return H(a);
      case "LL":
        return H(o);
      case "LLL":
        return at(t.months("short", !1), 1);
      case "LLLL":
        return at(t.months("long", !1), 1);
      case "d":
        return H(a);
      case "dd":
        return H(o);
      case "o":
        return H(l);
      case "ooo":
        return H(r);
      case "HH":
        return H(o);
      case "H":
        return H(a);
      case "hh":
        return H(o);
      case "h":
        return H(a);
      case "mm":
        return H(o);
      case "m":
        return H(a);
      case "q":
        return H(a);
      case "qq":
        return H(o);
      case "s":
        return H(a);
      case "ss":
        return H(o);
      case "S":
        return H(l);
      case "SSS":
        return H(r);
      case "u":
        return js(u);
      case "uu":
        return js(a);
      case "uuu":
        return H(n);
      case "a":
        return at(t.meridiems(), 0);
      case "kkkk":
        return H(s);
      case "kk":
        return H(d, jl);
      case "W":
        return H(a);
      case "WW":
        return H(o);
      case "E":
      case "c":
        return H(n);
      case "EEE":
        return at(t.weekdays("short", !1), 1);
      case "EEEE":
        return at(t.weekdays("long", !1), 1);
      case "ccc":
        return at(t.weekdays("short", !0), 1);
      case "cccc":
        return at(t.weekdays("long", !0), 1);
      case "Z":
      case "ZZ":
        return sf(new RegExp(`([+-]${a.source})(?::(${o.source}))?`), 2);
      case "ZZZ":
        return sf(new RegExp(`([+-]${a.source})(${o.source})?`), 2);
      case "z":
        return js(/[a-z_+-/]{1,256}?/i);
      case " ":
        return js(/[^\S\n\r]/);
      default:
        return h(v);
    }
  })(e) || {
    invalidReason: ER
  };
  return g.token = e, g;
}
const NR = {
  year: {
    "2-digit": "yy",
    numeric: "yyyyy"
  },
  month: {
    numeric: "M",
    "2-digit": "MM",
    short: "MMM",
    long: "MMMM"
  },
  day: {
    numeric: "d",
    "2-digit": "dd"
  },
  weekday: {
    short: "EEE",
    long: "EEEE"
  },
  dayperiod: "a",
  dayPeriod: "a",
  hour12: {
    numeric: "h",
    "2-digit": "hh"
  },
  hour24: {
    numeric: "H",
    "2-digit": "HH"
  },
  minute: {
    numeric: "m",
    "2-digit": "mm"
  },
  second: {
    numeric: "s",
    "2-digit": "ss"
  },
  timeZoneName: {
    long: "ZZZZZ",
    short: "ZZZ"
  }
};
function $R(e, t, n) {
  const { type: o, value: r } = e;
  if (o === "literal") {
    const l = /^\s+$/.test(r);
    return {
      literal: !l,
      val: l ? " " : r
    };
  }
  const s = t[o];
  let i = o;
  o === "hour" && (t.hour12 != null ? i = t.hour12 ? "hour12" : "hour24" : t.hourCycle != null ? t.hourCycle === "h11" || t.hourCycle === "h12" ? i = "hour12" : i = "hour24" : i = n.hour12 ? "hour12" : "hour24");
  let a = NR[i];
  if (typeof a == "object" && (a = a[s]), a)
    return {
      literal: !1,
      val: a
    };
}
function kR(e) {
  return [`^${e.map((n) => n.regex).reduce((n, o) => `${n}(${o.source})`, "")}$`, e];
}
function DR(e, t, n) {
  const o = e.match(t);
  if (o) {
    const r = {};
    let s = 1;
    for (const i in n)
      if (Bo(n, i)) {
        const a = n[i], l = a.groups ? a.groups + 1 : 1;
        !a.literal && a.token && (r[a.token.val[0]] = a.deser(o.slice(s, s + l))), s += l;
      }
    return [o, r];
  } else
    return [o, {}];
}
function RR(e) {
  const t = (s) => {
    switch (s) {
      case "S":
        return "millisecond";
      case "s":
        return "second";
      case "m":
        return "minute";
      case "h":
      case "H":
        return "hour";
      case "d":
        return "day";
      case "o":
        return "ordinal";
      case "L":
      case "M":
        return "month";
      case "y":
        return "year";
      case "E":
      case "c":
        return "weekday";
      case "W":
        return "weekNumber";
      case "k":
        return "weekYear";
      case "q":
        return "quarter";
      default:
        return null;
    }
  };
  let n = null, o;
  return N(e.z) || (n = Ft.create(e.z)), N(e.Z) || (n || (n = new Ee(e.Z)), o = e.Z), N(e.q) || (e.M = (e.q - 1) * 3 + 1), N(e.h) || (e.h < 12 && e.a === 1 ? e.h += 12 : e.h === 12 && e.a === 0 && (e.h = 0)), e.G === 0 && e.y && (e.y = -e.y), N(e.u) || (e.S = du(e.u)), [Object.keys(e).reduce((s, i) => {
    const a = t(i);
    return a && (s[a] = e[i]), s;
  }, {}), n, o];
}
let tl = null;
function LR() {
  return tl || (tl = D.fromMillis(1555555555555)), tl;
}
function PR(e, t) {
  if (e.literal)
    return e;
  const n = Te.macroTokenToFormatOpts(e.val), o = Vy(n, t);
  return o == null || o.includes(void 0) ? e : o;
}
function Hy(e, t) {
  return Array.prototype.concat(...e.map((n) => PR(n, t)));
}
function Zy(e, t, n) {
  const o = Hy(Te.parseFormat(n), e), r = o.map((i) => MR(i, e)), s = r.find((i) => i.invalidReason);
  if (s)
    return { input: t, tokens: o, invalidReason: s.invalidReason };
  {
    const [i, a] = kR(r), l = RegExp(i, "i"), [c, u] = DR(t, l, a), [d, f, h] = u ? RR(u) : [null, null, void 0];
    if (Bo(u, "a") && Bo(u, "H"))
      throw new yo(
        "Can't include meridiem when specifying 24-hour format"
      );
    return { input: t, tokens: o, regex: l, rawMatches: c, matches: u, result: d, zone: f, specificOffset: h };
  }
}
function _R(e, t, n) {
  const { result: o, zone: r, specificOffset: s, invalidReason: i } = Zy(e, t, n);
  return [o, r, s, i];
}
function Vy(e, t) {
  if (!e)
    return null;
  const o = Te.create(t, e).dtFormatter(LR()), r = o.formatToParts(), s = o.resolvedOptions();
  return r.map((i) => $R(i, e, s));
}
const nl = "Invalid DateTime", af = 864e13;
function Fs(e) {
  return new ut("unsupported zone", `the zone "${e.name}" is not supported`);
}
function ol(e) {
  return e.weekData === null && (e.weekData = Xi(e.c)), e.weekData;
}
function rl(e) {
  return e.localWeekData === null && (e.localWeekData = Xi(
    e.c,
    e.loc.getMinDaysInFirstWeek(),
    e.loc.getStartOfWeek()
  )), e.localWeekData;
}
function gn(e, t) {
  const n = {
    ts: e.ts,
    zone: e.zone,
    c: e.c,
    o: e.o,
    loc: e.loc,
    invalid: e.invalid
  };
  return new D({ ...n, ...t, old: n });
}
function zy(e, t, n) {
  let o = e - t * 60 * 1e3;
  const r = n.offset(o);
  if (t === r)
    return [o, t];
  o -= (r - t) * 60 * 1e3;
  const s = n.offset(o);
  return r === s ? [o, r] : [e - Math.min(r, s) * 60 * 1e3, Math.max(r, s)];
}
function qs(e, t) {
  e += t * 60 * 1e3;
  const n = new Date(e);
  return {
    year: n.getUTCFullYear(),
    month: n.getUTCMonth() + 1,
    day: n.getUTCDate(),
    hour: n.getUTCHours(),
    minute: n.getUTCMinutes(),
    second: n.getUTCSeconds(),
    millisecond: n.getUTCMilliseconds()
  };
}
function Ni(e, t, n) {
  return zy(Ra(e), t, n);
}
function lf(e, t) {
  const n = e.o, o = e.c.year + Math.trunc(t.years), r = e.c.month + Math.trunc(t.months) + Math.trunc(t.quarters) * 3, s = {
    ...e.c,
    year: o,
    month: r,
    day: Math.min(e.c.day, ea(o, r)) + Math.trunc(t.days) + Math.trunc(t.weeks) * 7
  }, i = j.fromObject({
    years: t.years - Math.trunc(t.years),
    quarters: t.quarters - Math.trunc(t.quarters),
    months: t.months - Math.trunc(t.months),
    weeks: t.weeks - Math.trunc(t.weeks),
    days: t.days - Math.trunc(t.days),
    hours: t.hours,
    minutes: t.minutes,
    seconds: t.seconds,
    milliseconds: t.milliseconds
  }).as("milliseconds"), a = Ra(s);
  let [l, c] = zy(a, n, e.zone);
  return i !== 0 && (l += i, c = e.zone.offset(l)), { ts: l, o: c };
}
function fr(e, t, n, o, r, s) {
  const { setZone: i, zone: a } = n;
  if (e && Object.keys(e).length !== 0 || t) {
    const l = t || a, c = D.fromObject(e, {
      ...n,
      zone: l,
      specificOffset: s
    });
    return i ? c : c.setZone(a);
  } else
    return D.invalid(
      new ut("unparsable", `the input "${r}" can't be parsed as ${o}`)
    );
}
function Hs(e, t, n = !0) {
  return e.isValid ? Te.create(V.create("en-US"), {
    allowZ: n,
    forceSimple: !0
  }).formatDateTimeFromString(e, t) : null;
}
function sl(e, t) {
  const n = e.c.year > 9999 || e.c.year < 0;
  let o = "";
  return n && e.c.year >= 0 && (o += "+"), o += de(e.c.year, n ? 6 : 4), t ? (o += "-", o += de(e.c.month), o += "-", o += de(e.c.day)) : (o += de(e.c.month), o += de(e.c.day)), o;
}
function cf(e, t, n, o, r, s) {
  let i = de(e.c.hour);
  return t ? (i += ":", i += de(e.c.minute), (e.c.millisecond !== 0 || e.c.second !== 0 || !n) && (i += ":")) : i += de(e.c.minute), (e.c.millisecond !== 0 || e.c.second !== 0 || !n) && (i += de(e.c.second), (e.c.millisecond !== 0 || !o) && (i += ".", i += de(e.c.millisecond, 3))), r && (e.isOffsetFixed && e.offset === 0 && !s ? i += "Z" : e.o < 0 ? (i += "-", i += de(Math.trunc(-e.o / 60)), i += ":", i += de(Math.trunc(-e.o % 60))) : (i += "+", i += de(Math.trunc(e.o / 60)), i += ":", i += de(Math.trunc(e.o % 60)))), s && (i += "[" + e.zone.ianaName + "]"), i;
}
const Gy = {
  month: 1,
  day: 1,
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0
}, BR = {
  weekNumber: 1,
  weekday: 1,
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0
}, WR = {
  ordinal: 1,
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0
}, Yy = ["year", "month", "day", "hour", "minute", "second", "millisecond"], UR = [
  "weekYear",
  "weekNumber",
  "weekday",
  "hour",
  "minute",
  "second",
  "millisecond"
], jR = ["year", "ordinal", "hour", "minute", "second", "millisecond"];
function FR(e) {
  const t = {
    year: "year",
    years: "year",
    month: "month",
    months: "month",
    day: "day",
    days: "day",
    hour: "hour",
    hours: "hour",
    minute: "minute",
    minutes: "minute",
    quarter: "quarter",
    quarters: "quarter",
    second: "second",
    seconds: "second",
    millisecond: "millisecond",
    milliseconds: "millisecond",
    weekday: "weekday",
    weekdays: "weekday",
    weeknumber: "weekNumber",
    weeksnumber: "weekNumber",
    weeknumbers: "weekNumber",
    weekyear: "weekYear",
    weekyears: "weekYear",
    ordinal: "ordinal"
  }[e.toLowerCase()];
  if (!t)
    throw new Qp(e);
  return t;
}
function uf(e) {
  switch (e.toLowerCase()) {
    case "localweekday":
    case "localweekdays":
      return "localWeekday";
    case "localweeknumber":
    case "localweeknumbers":
      return "localWeekNumber";
    case "localweekyear":
    case "localweekyears":
      return "localWeekYear";
    default:
      return FR(e);
  }
}
function df(e, t) {
  const n = on(t.zone, le.defaultZone), o = V.fromObject(t), r = le.now();
  let s, i;
  if (N(e.year))
    s = r;
  else {
    for (const c of Yy)
      N(e[c]) && (e[c] = Gy[c]);
    const a = Ty(e) || wy(e);
    if (a)
      return D.invalid(a);
    const l = n.offset(r);
    [s, i] = Ni(e, l, n);
  }
  return new D({ ts: s, zone: n, loc: o, o: i });
}
function ff(e, t, n) {
  const o = N(n.round) ? !0 : n.round, r = (i, a) => (i = fu(i, o || n.calendary ? 0 : 2, !0), t.loc.clone(n).relFormatter(n).format(i, a)), s = (i) => n.calendary ? t.hasSame(e, i) ? 0 : t.startOf(i).diff(e.startOf(i), i).get(i) : t.diff(e, i).get(i);
  if (n.unit)
    return r(s(n.unit), n.unit);
  for (const i of n.units) {
    const a = s(i);
    if (Math.abs(a) >= 1)
      return r(a, i);
  }
  return r(e > t ? -0 : 0, n.units[n.units.length - 1]);
}
function mf(e) {
  let t = {}, n;
  return e.length > 0 && typeof e[e.length - 1] == "object" ? (t = e[e.length - 1], n = Array.from(e).slice(0, e.length - 1)) : n = Array.from(e), [t, n];
}
class D {
  /**
   * @access private
   */
  constructor(t) {
    const n = t.zone || le.defaultZone;
    let o = t.invalid || (Number.isNaN(t.ts) ? new ut("invalid input") : null) || (n.isValid ? null : Fs(n));
    this.ts = N(t.ts) ? le.now() : t.ts;
    let r = null, s = null;
    if (!o)
      if (t.old && t.old.ts === this.ts && t.old.zone.equals(n))
        [r, s] = [t.old.c, t.old.o];
      else {
        const a = n.offset(this.ts);
        r = qs(this.ts, a), o = Number.isNaN(r.year) ? new ut("invalid input") : null, r = o ? null : r, s = o ? null : a;
      }
    this._zone = n, this.loc = t.loc || V.create(), this.invalid = o, this.weekData = null, this.localWeekData = null, this.c = r, this.o = s, this.isLuxonDateTime = !0;
  }
  // CONSTRUCT
  /**
   * Create a DateTime for the current instant, in the system's time zone.
   *
   * Use Settings to override these default values if needed.
   * @example DateTime.now().toISO() //~> now in the ISO format
   * @return {DateTime}
   */
  static now() {
    return new D({});
  }
  /**
   * Create a local DateTime
   * @param {number} [year] - The calendar year. If omitted (as in, call `local()` with no arguments), the current time will be used
   * @param {number} [month=1] - The month, 1-indexed
   * @param {number} [day=1] - The day of the month, 1-indexed
   * @param {number} [hour=0] - The hour of the day, in 24-hour time
   * @param {number} [minute=0] - The minute of the hour, meaning a number between 0 and 59
   * @param {number} [second=0] - The second of the minute, meaning a number between 0 and 59
   * @param {number} [millisecond=0] - The millisecond of the second, meaning a number between 0 and 999
   * @example DateTime.local()                                  //~> now
   * @example DateTime.local({ zone: "America/New_York" })      //~> now, in US east coast time
   * @example DateTime.local(2017)                              //~> 2017-01-01T00:00:00
   * @example DateTime.local(2017, 3)                           //~> 2017-03-01T00:00:00
   * @example DateTime.local(2017, 3, 12, { locale: "fr" })     //~> 2017-03-12T00:00:00, with a French locale
   * @example DateTime.local(2017, 3, 12, 5)                    //~> 2017-03-12T05:00:00
   * @example DateTime.local(2017, 3, 12, 5, { zone: "utc" })   //~> 2017-03-12T05:00:00, in UTC
   * @example DateTime.local(2017, 3, 12, 5, 45)                //~> 2017-03-12T05:45:00
   * @example DateTime.local(2017, 3, 12, 5, 45, 10)            //~> 2017-03-12T05:45:10
   * @example DateTime.local(2017, 3, 12, 5, 45, 10, 765)       //~> 2017-03-12T05:45:10.765
   * @return {DateTime}
   */
  static local() {
    const [t, n] = mf(arguments), [o, r, s, i, a, l, c] = n;
    return df({ year: o, month: r, day: s, hour: i, minute: a, second: l, millisecond: c }, t);
  }
  /**
   * Create a DateTime in UTC
   * @param {number} [year] - The calendar year. If omitted (as in, call `utc()` with no arguments), the current time will be used
   * @param {number} [month=1] - The month, 1-indexed
   * @param {number} [day=1] - The day of the month
   * @param {number} [hour=0] - The hour of the day, in 24-hour time
   * @param {number} [minute=0] - The minute of the hour, meaning a number between 0 and 59
   * @param {number} [second=0] - The second of the minute, meaning a number between 0 and 59
   * @param {number} [millisecond=0] - The millisecond of the second, meaning a number between 0 and 999
   * @param {Object} options - configuration options for the DateTime
   * @param {string} [options.locale] - a locale to set on the resulting DateTime instance
   * @param {string} [options.outputCalendar] - the output calendar to set on the resulting DateTime instance
   * @param {string} [options.numberingSystem] - the numbering system to set on the resulting DateTime instance
   * @example DateTime.utc()                                              //~> now
   * @example DateTime.utc(2017)                                          //~> 2017-01-01T00:00:00Z
   * @example DateTime.utc(2017, 3)                                       //~> 2017-03-01T00:00:00Z
   * @example DateTime.utc(2017, 3, 12)                                   //~> 2017-03-12T00:00:00Z
   * @example DateTime.utc(2017, 3, 12, 5)                                //~> 2017-03-12T05:00:00Z
   * @example DateTime.utc(2017, 3, 12, 5, 45)                            //~> 2017-03-12T05:45:00Z
   * @example DateTime.utc(2017, 3, 12, 5, 45, { locale: "fr" })          //~> 2017-03-12T05:45:00Z with a French locale
   * @example DateTime.utc(2017, 3, 12, 5, 45, 10)                        //~> 2017-03-12T05:45:10Z
   * @example DateTime.utc(2017, 3, 12, 5, 45, 10, 765, { locale: "fr" }) //~> 2017-03-12T05:45:10.765Z with a French locale
   * @return {DateTime}
   */
  static utc() {
    const [t, n] = mf(arguments), [o, r, s, i, a, l, c] = n;
    return t.zone = Ee.utcInstance, df({ year: o, month: r, day: s, hour: i, minute: a, second: l, millisecond: c }, t);
  }
  /**
   * Create a DateTime from a JavaScript Date object. Uses the default zone.
   * @param {Date} date - a JavaScript Date object
   * @param {Object} options - configuration options for the DateTime
   * @param {string|Zone} [options.zone='local'] - the zone to place the DateTime into
   * @return {DateTime}
   */
  static fromJSDate(t, n = {}) {
    const o = gD(t) ? t.valueOf() : NaN;
    if (Number.isNaN(o))
      return D.invalid("invalid input");
    const r = on(n.zone, le.defaultZone);
    return r.isValid ? new D({
      ts: o,
      zone: r,
      loc: V.fromObject(n)
    }) : D.invalid(Fs(r));
  }
  /**
   * Create a DateTime from a number of milliseconds since the epoch (meaning since 1 January 1970 00:00:00 UTC). Uses the default zone.
   * @param {number} milliseconds - a number of milliseconds since 1970 UTC
   * @param {Object} options - configuration options for the DateTime
   * @param {string|Zone} [options.zone='local'] - the zone to place the DateTime into
   * @param {string} [options.locale] - a locale to set on the resulting DateTime instance
   * @param {string} options.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @param {string} options.numberingSystem - the numbering system to set on the resulting DateTime instance
   * @return {DateTime}
   */
  static fromMillis(t, n = {}) {
    if (Ln(t))
      return t < -af || t > af ? D.invalid("Timestamp out of range") : new D({
        ts: t,
        zone: on(n.zone, le.defaultZone),
        loc: V.fromObject(n)
      });
    throw new Me(
      `fromMillis requires a numerical input, but received a ${typeof t} with value ${t}`
    );
  }
  /**
   * Create a DateTime from a number of seconds since the epoch (meaning since 1 January 1970 00:00:00 UTC). Uses the default zone.
   * @param {number} seconds - a number of seconds since 1970 UTC
   * @param {Object} options - configuration options for the DateTime
   * @param {string|Zone} [options.zone='local'] - the zone to place the DateTime into
   * @param {string} [options.locale] - a locale to set on the resulting DateTime instance
   * @param {string} options.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @param {string} options.numberingSystem - the numbering system to set on the resulting DateTime instance
   * @return {DateTime}
   */
  static fromSeconds(t, n = {}) {
    if (Ln(t))
      return new D({
        ts: t * 1e3,
        zone: on(n.zone, le.defaultZone),
        loc: V.fromObject(n)
      });
    throw new Me("fromSeconds requires a numerical input");
  }
  /**
   * Create a DateTime from a JavaScript object with keys like 'year' and 'hour' with reasonable defaults.
   * @param {Object} obj - the object to create the DateTime from
   * @param {number} obj.year - a year, such as 1987
   * @param {number} obj.month - a month, 1-12
   * @param {number} obj.day - a day of the month, 1-31, depending on the month
   * @param {number} obj.ordinal - day of the year, 1-365 or 366
   * @param {number} obj.weekYear - an ISO week year
   * @param {number} obj.weekNumber - an ISO week number, between 1 and 52 or 53, depending on the year
   * @param {number} obj.weekday - an ISO weekday, 1-7, where 1 is Monday and 7 is Sunday
   * @param {number} obj.localWeekYear - a week year, according to the locale
   * @param {number} obj.localWeekNumber - a week number, between 1 and 52 or 53, depending on the year, according to the locale
   * @param {number} obj.localWeekday - a weekday, 1-7, where 1 is the first and 7 is the last day of the week, according to the locale
   * @param {number} obj.hour - hour of the day, 0-23
   * @param {number} obj.minute - minute of the hour, 0-59
   * @param {number} obj.second - second of the minute, 0-59
   * @param {number} obj.millisecond - millisecond of the second, 0-999
   * @param {Object} opts - options for creating this DateTime
   * @param {string|Zone} [opts.zone='local'] - interpret the numbers in the context of a particular zone. Can take any value taken as the first argument to setZone()
   * @param {string} [opts.locale='system\'s locale'] - a locale to set on the resulting DateTime instance
   * @param {string} opts.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @param {string} opts.numberingSystem - the numbering system to set on the resulting DateTime instance
   * @example DateTime.fromObject({ year: 1982, month: 5, day: 25}).toISODate() //=> '1982-05-25'
   * @example DateTime.fromObject({ year: 1982 }).toISODate() //=> '1982-01-01'
   * @example DateTime.fromObject({ hour: 10, minute: 26, second: 6 }) //~> today at 10:26:06
   * @example DateTime.fromObject({ hour: 10, minute: 26, second: 6 }, { zone: 'utc' }),
   * @example DateTime.fromObject({ hour: 10, minute: 26, second: 6 }, { zone: 'local' })
   * @example DateTime.fromObject({ hour: 10, minute: 26, second: 6 }, { zone: 'America/New_York' })
   * @example DateTime.fromObject({ weekYear: 2016, weekNumber: 2, weekday: 3 }).toISODate() //=> '2016-01-13'
   * @example DateTime.fromObject({ localWeekYear: 2022, localWeekNumber: 1, localWeekday: 1 }, { locale: "en-US" }).toISODate() //=> '2021-12-26'
   * @return {DateTime}
   */
  static fromObject(t, n = {}) {
    t = t || {};
    const o = on(n.zone, le.defaultZone);
    if (!o.isValid)
      return D.invalid(Fs(o));
    const r = V.fromObject(n), s = ta(t, uf), { minDaysInFirstWeek: i, startOfWeek: a } = Yd(s, r), l = le.now(), c = N(n.specificOffset) ? o.offset(l) : n.specificOffset, u = !N(s.ordinal), d = !N(s.year), f = !N(s.month) || !N(s.day), h = d || f, p = s.weekYear || s.weekNumber;
    if ((h || u) && p)
      throw new yo(
        "Can't mix weekYear/weekNumber units with year/month/day or ordinals"
      );
    if (f && u)
      throw new yo("Can't mix ordinal dates with month/day");
    const g = p || s.weekday && !h;
    let v, T, O = qs(l, c);
    g ? (v = UR, T = BR, O = Xi(O, i, a)) : u ? (v = jR, T = WR, O = el(O)) : (v = Yy, T = Gy);
    let k = !1;
    for (const z of v) {
      const _ = s[z];
      N(_) ? k ? s[z] = T[z] : s[z] = O[z] : k = !0;
    }
    const R = g ? hD(s, i, a) : u ? pD(s) : Ty(s), ae = R || wy(s);
    if (ae)
      return D.invalid(ae);
    const vt = g ? zd(s, i, a) : u ? Gd(s) : s, [bt, nt] = Ni(vt, c, o), We = new D({
      ts: bt,
      zone: o,
      o: nt,
      loc: r
    });
    return s.weekday && h && t.weekday !== We.weekday ? D.invalid(
      "mismatched weekday",
      `you can't specify both a weekday of ${s.weekday} and a date of ${We.toISO()}`
    ) : We;
  }
  /**
   * Create a DateTime from an ISO 8601 string
   * @param {string} text - the ISO string
   * @param {Object} opts - options to affect the creation
   * @param {string|Zone} [opts.zone='local'] - use this zone if no offset is specified in the input string itself. Will also convert the time to this zone
   * @param {boolean} [opts.setZone=false] - override the zone with a fixed-offset zone specified in the string itself, if it specifies one
   * @param {string} [opts.locale='system's locale'] - a locale to set on the resulting DateTime instance
   * @param {string} [opts.outputCalendar] - the output calendar to set on the resulting DateTime instance
   * @param {string} [opts.numberingSystem] - the numbering system to set on the resulting DateTime instance
   * @example DateTime.fromISO('2016-05-25T09:08:34.123')
   * @example DateTime.fromISO('2016-05-25T09:08:34.123+06:00')
   * @example DateTime.fromISO('2016-05-25T09:08:34.123+06:00', {setZone: true})
   * @example DateTime.fromISO('2016-05-25T09:08:34.123', {zone: 'utc'})
   * @example DateTime.fromISO('2016-W05-4')
   * @return {DateTime}
   */
  static fromISO(t, n = {}) {
    const [o, r] = iR(t);
    return fr(o, r, n, "ISO 8601", t);
  }
  /**
   * Create a DateTime from an RFC 2822 string
   * @param {string} text - the RFC 2822 string
   * @param {Object} opts - options to affect the creation
   * @param {string|Zone} [opts.zone='local'] - convert the time to this zone. Since the offset is always specified in the string itself, this has no effect on the interpretation of string, merely the zone the resulting DateTime is expressed in.
   * @param {boolean} [opts.setZone=false] - override the zone with a fixed-offset zone specified in the string itself, if it specifies one
   * @param {string} [opts.locale='system's locale'] - a locale to set on the resulting DateTime instance
   * @param {string} opts.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @param {string} opts.numberingSystem - the numbering system to set on the resulting DateTime instance
   * @example DateTime.fromRFC2822('25 Nov 2016 13:23:12 GMT')
   * @example DateTime.fromRFC2822('Fri, 25 Nov 2016 13:23:12 +0600')
   * @example DateTime.fromRFC2822('25 Nov 2016 13:23 Z')
   * @return {DateTime}
   */
  static fromRFC2822(t, n = {}) {
    const [o, r] = aR(t);
    return fr(o, r, n, "RFC 2822", t);
  }
  /**
   * Create a DateTime from an HTTP header date
   * @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec3.html#sec3.3.1
   * @param {string} text - the HTTP header date
   * @param {Object} opts - options to affect the creation
   * @param {string|Zone} [opts.zone='local'] - convert the time to this zone. Since HTTP dates are always in UTC, this has no effect on the interpretation of string, merely the zone the resulting DateTime is expressed in.
   * @param {boolean} [opts.setZone=false] - override the zone with the fixed-offset zone specified in the string. For HTTP dates, this is always UTC, so this option is equivalent to setting the `zone` option to 'utc', but this option is included for consistency with similar methods.
   * @param {string} [opts.locale='system's locale'] - a locale to set on the resulting DateTime instance
   * @param {string} opts.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @param {string} opts.numberingSystem - the numbering system to set on the resulting DateTime instance
   * @example DateTime.fromHTTP('Sun, 06 Nov 1994 08:49:37 GMT')
   * @example DateTime.fromHTTP('Sunday, 06-Nov-94 08:49:37 GMT')
   * @example DateTime.fromHTTP('Sun Nov  6 08:49:37 1994')
   * @return {DateTime}
   */
  static fromHTTP(t, n = {}) {
    const [o, r] = lR(t);
    return fr(o, r, n, "HTTP", n);
  }
  /**
   * Create a DateTime from an input string and format string.
   * Defaults to en-US if no locale has been specified, regardless of the system's locale. For a table of tokens and their interpretations, see [here](https://moment.github.io/luxon/#/parsing?id=table-of-tokens).
   * @param {string} text - the string to parse
   * @param {string} fmt - the format the string is expected to be in (see the link below for the formats)
   * @param {Object} opts - options to affect the creation
   * @param {string|Zone} [opts.zone='local'] - use this zone if no offset is specified in the input string itself. Will also convert the DateTime to this zone
   * @param {boolean} [opts.setZone=false] - override the zone with a zone specified in the string itself, if it specifies one
   * @param {string} [opts.locale='en-US'] - a locale string to use when parsing. Will also set the DateTime to this locale
   * @param {string} opts.numberingSystem - the numbering system to use when parsing. Will also set the resulting DateTime to this numbering system
   * @param {string} opts.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @return {DateTime}
   */
  static fromFormat(t, n, o = {}) {
    if (N(t) || N(n))
      throw new Me("fromFormat requires an input string and a format");
    const { locale: r = null, numberingSystem: s = null } = o, i = V.fromOpts({
      locale: r,
      numberingSystem: s,
      defaultToEN: !0
    }), [a, l, c, u] = _R(i, t, n);
    return u ? D.invalid(u) : fr(a, l, o, `format ${n}`, t, c);
  }
  /**
   * @deprecated use fromFormat instead
   */
  static fromString(t, n, o = {}) {
    return D.fromFormat(t, n, o);
  }
  /**
   * Create a DateTime from a SQL date, time, or datetime
   * Defaults to en-US if no locale has been specified, regardless of the system's locale
   * @param {string} text - the string to parse
   * @param {Object} opts - options to affect the creation
   * @param {string|Zone} [opts.zone='local'] - use this zone if no offset is specified in the input string itself. Will also convert the DateTime to this zone
   * @param {boolean} [opts.setZone=false] - override the zone with a zone specified in the string itself, if it specifies one
   * @param {string} [opts.locale='en-US'] - a locale string to use when parsing. Will also set the DateTime to this locale
   * @param {string} opts.numberingSystem - the numbering system to use when parsing. Will also set the resulting DateTime to this numbering system
   * @param {string} opts.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @example DateTime.fromSQL('2017-05-15')
   * @example DateTime.fromSQL('2017-05-15 09:12:34')
   * @example DateTime.fromSQL('2017-05-15 09:12:34.342')
   * @example DateTime.fromSQL('2017-05-15 09:12:34.342+06:00')
   * @example DateTime.fromSQL('2017-05-15 09:12:34.342 America/Los_Angeles')
   * @example DateTime.fromSQL('2017-05-15 09:12:34.342 America/Los_Angeles', { setZone: true })
   * @example DateTime.fromSQL('2017-05-15 09:12:34.342', { zone: 'America/Los_Angeles' })
   * @example DateTime.fromSQL('09:12:34.342')
   * @return {DateTime}
   */
  static fromSQL(t, n = {}) {
    const [o, r] = pR(t);
    return fr(o, r, n, "SQL", t);
  }
  /**
   * Create an invalid DateTime.
   * @param {string} reason - simple string of why this DateTime is invalid. Should not contain parameters or anything else data-dependent.
   * @param {string} [explanation=null] - longer explanation, may include parameters and other useful debugging information
   * @return {DateTime}
   */
  static invalid(t, n = null) {
    if (!t)
      throw new Me("need to specify a reason the DateTime is invalid");
    const o = t instanceof ut ? t : new ut(t, n);
    if (le.throwOnInvalid)
      throw new H2(o);
    return new D({ invalid: o });
  }
  /**
   * Check if an object is an instance of DateTime. Works across context boundaries
   * @param {object} o
   * @return {boolean}
   */
  static isDateTime(t) {
    return t && t.isLuxonDateTime || !1;
  }
  /**
   * Produce the format string for a set of options
   * @param formatOpts
   * @param localeOpts
   * @returns {string}
   */
  static parseFormatForOpts(t, n = {}) {
    const o = Vy(t, V.fromObject(n));
    return o ? o.map((r) => r ? r.val : null).join("") : null;
  }
  /**
   * Produce the the fully expanded format token for the locale
   * Does NOT quote characters, so quoted tokens will not round trip correctly
   * @param fmt
   * @param localeOpts
   * @returns {string}
   */
  static expandFormat(t, n = {}) {
    return Hy(Te.parseFormat(t), V.fromObject(n)).map((r) => r.val).join("");
  }
  // INFO
  /**
   * Get the value of unit.
   * @param {string} unit - a unit such as 'minute' or 'day'
   * @example DateTime.local(2017, 7, 4).get('month'); //=> 7
   * @example DateTime.local(2017, 7, 4).get('day'); //=> 4
   * @return {number}
   */
  get(t) {
    return this[t];
  }
  /**
   * Returns whether the DateTime is valid. Invalid DateTimes occur when:
   * * The DateTime was created from invalid calendar information, such as the 13th month or February 30
   * * The DateTime was created by an operation on another invalid date
   * @type {boolean}
   */
  get isValid() {
    return this.invalid === null;
  }
  /**
   * Returns an error code if this DateTime is invalid, or null if the DateTime is valid
   * @type {string}
   */
  get invalidReason() {
    return this.invalid ? this.invalid.reason : null;
  }
  /**
   * Returns an explanation of why this DateTime became invalid, or null if the DateTime is valid
   * @type {string}
   */
  get invalidExplanation() {
    return this.invalid ? this.invalid.explanation : null;
  }
  /**
   * Get the locale of a DateTime, such 'en-GB'. The locale is used when formatting the DateTime
   *
   * @type {string}
   */
  get locale() {
    return this.isValid ? this.loc.locale : null;
  }
  /**
   * Get the numbering system of a DateTime, such 'beng'. The numbering system is used when formatting the DateTime
   *
   * @type {string}
   */
  get numberingSystem() {
    return this.isValid ? this.loc.numberingSystem : null;
  }
  /**
   * Get the output calendar of a DateTime, such 'islamic'. The output calendar is used when formatting the DateTime
   *
   * @type {string}
   */
  get outputCalendar() {
    return this.isValid ? this.loc.outputCalendar : null;
  }
  /**
   * Get the time zone associated with this DateTime.
   * @type {Zone}
   */
  get zone() {
    return this._zone;
  }
  /**
   * Get the name of the time zone.
   * @type {string}
   */
  get zoneName() {
    return this.isValid ? this.zone.name : null;
  }
  /**
   * Get the year
   * @example DateTime.local(2017, 5, 25).year //=> 2017
   * @type {number}
   */
  get year() {
    return this.isValid ? this.c.year : NaN;
  }
  /**
   * Get the quarter
   * @example DateTime.local(2017, 5, 25).quarter //=> 2
   * @type {number}
   */
  get quarter() {
    return this.isValid ? Math.ceil(this.c.month / 3) : NaN;
  }
  /**
   * Get the month (1-12).
   * @example DateTime.local(2017, 5, 25).month //=> 5
   * @type {number}
   */
  get month() {
    return this.isValid ? this.c.month : NaN;
  }
  /**
   * Get the day of the month (1-30ish).
   * @example DateTime.local(2017, 5, 25).day //=> 25
   * @type {number}
   */
  get day() {
    return this.isValid ? this.c.day : NaN;
  }
  /**
   * Get the hour of the day (0-23).
   * @example DateTime.local(2017, 5, 25, 9).hour //=> 9
   * @type {number}
   */
  get hour() {
    return this.isValid ? this.c.hour : NaN;
  }
  /**
   * Get the minute of the hour (0-59).
   * @example DateTime.local(2017, 5, 25, 9, 30).minute //=> 30
   * @type {number}
   */
  get minute() {
    return this.isValid ? this.c.minute : NaN;
  }
  /**
   * Get the second of the minute (0-59).
   * @example DateTime.local(2017, 5, 25, 9, 30, 52).second //=> 52
   * @type {number}
   */
  get second() {
    return this.isValid ? this.c.second : NaN;
  }
  /**
   * Get the millisecond of the second (0-999).
   * @example DateTime.local(2017, 5, 25, 9, 30, 52, 654).millisecond //=> 654
   * @type {number}
   */
  get millisecond() {
    return this.isValid ? this.c.millisecond : NaN;
  }
  /**
   * Get the week year
   * @see https://en.wikipedia.org/wiki/ISO_week_date
   * @example DateTime.local(2014, 12, 31).weekYear //=> 2015
   * @type {number}
   */
  get weekYear() {
    return this.isValid ? ol(this).weekYear : NaN;
  }
  /**
   * Get the week number of the week year (1-52ish).
   * @see https://en.wikipedia.org/wiki/ISO_week_date
   * @example DateTime.local(2017, 5, 25).weekNumber //=> 21
   * @type {number}
   */
  get weekNumber() {
    return this.isValid ? ol(this).weekNumber : NaN;
  }
  /**
   * Get the day of the week.
   * 1 is Monday and 7 is Sunday
   * @see https://en.wikipedia.org/wiki/ISO_week_date
   * @example DateTime.local(2014, 11, 31).weekday //=> 4
   * @type {number}
   */
  get weekday() {
    return this.isValid ? ol(this).weekday : NaN;
  }
  /**
   * Returns true if this date is on a weekend according to the locale, false otherwise
   * @returns {boolean}
   */
  get isWeekend() {
    return this.isValid && this.loc.getWeekendDays().includes(this.weekday);
  }
  /**
   * Get the day of the week according to the locale.
   * 1 is the first day of the week and 7 is the last day of the week.
   * If the locale assigns Sunday as the first day of the week, then a date which is a Sunday will return 1,
   * @returns {number}
   */
  get localWeekday() {
    return this.isValid ? rl(this).weekday : NaN;
  }
  /**
   * Get the week number of the week year according to the locale. Different locales assign week numbers differently,
   * because the week can start on different days of the week (see localWeekday) and because a different number of days
   * is required for a week to count as the first week of a year.
   * @returns {number}
   */
  get localWeekNumber() {
    return this.isValid ? rl(this).weekNumber : NaN;
  }
  /**
   * Get the week year according to the locale. Different locales assign week numbers (and therefor week years)
   * differently, see localWeekNumber.
   * @returns {number}
   */
  get localWeekYear() {
    return this.isValid ? rl(this).weekYear : NaN;
  }
  /**
   * Get the ordinal (meaning the day of the year)
   * @example DateTime.local(2017, 5, 25).ordinal //=> 145
   * @type {number|DateTime}
   */
  get ordinal() {
    return this.isValid ? el(this.c).ordinal : NaN;
  }
  /**
   * Get the human readable short month name, such as 'Oct'.
   * Defaults to the system's locale if no locale has been specified
   * @example DateTime.local(2017, 10, 30).monthShort //=> Oct
   * @type {string}
   */
  get monthShort() {
    return this.isValid ? Us.months("short", { locObj: this.loc })[this.month - 1] : null;
  }
  /**
   * Get the human readable long month name, such as 'October'.
   * Defaults to the system's locale if no locale has been specified
   * @example DateTime.local(2017, 10, 30).monthLong //=> October
   * @type {string}
   */
  get monthLong() {
    return this.isValid ? Us.months("long", { locObj: this.loc })[this.month - 1] : null;
  }
  /**
   * Get the human readable short weekday, such as 'Mon'.
   * Defaults to the system's locale if no locale has been specified
   * @example DateTime.local(2017, 10, 30).weekdayShort //=> Mon
   * @type {string}
   */
  get weekdayShort() {
    return this.isValid ? Us.weekdays("short", { locObj: this.loc })[this.weekday - 1] : null;
  }
  /**
   * Get the human readable long weekday, such as 'Monday'.
   * Defaults to the system's locale if no locale has been specified
   * @example DateTime.local(2017, 10, 30).weekdayLong //=> Monday
   * @type {string}
   */
  get weekdayLong() {
    return this.isValid ? Us.weekdays("long", { locObj: this.loc })[this.weekday - 1] : null;
  }
  /**
   * Get the UTC offset of this DateTime in minutes
   * @example DateTime.now().offset //=> -240
   * @example DateTime.utc().offset //=> 0
   * @type {number}
   */
  get offset() {
    return this.isValid ? +this.o : NaN;
  }
  /**
   * Get the short human name for the zone's current offset, for example "EST" or "EDT".
   * Defaults to the system's locale if no locale has been specified
   * @type {string}
   */
  get offsetNameShort() {
    return this.isValid ? this.zone.offsetName(this.ts, {
      format: "short",
      locale: this.locale
    }) : null;
  }
  /**
   * Get the long human name for the zone's current offset, for example "Eastern Standard Time" or "Eastern Daylight Time".
   * Defaults to the system's locale if no locale has been specified
   * @type {string}
   */
  get offsetNameLong() {
    return this.isValid ? this.zone.offsetName(this.ts, {
      format: "long",
      locale: this.locale
    }) : null;
  }
  /**
   * Get whether this zone's offset ever changes, as in a DST.
   * @type {boolean}
   */
  get isOffsetFixed() {
    return this.isValid ? this.zone.isUniversal : null;
  }
  /**
   * Get whether the DateTime is in a DST.
   * @type {boolean}
   */
  get isInDST() {
    return this.isOffsetFixed ? !1 : this.offset > this.set({ month: 1, day: 1 }).offset || this.offset > this.set({ month: 5 }).offset;
  }
  /**
   * Get those DateTimes which have the same local time as this DateTime, but a different offset from UTC
   * in this DateTime's zone. During DST changes local time can be ambiguous, for example
   * `2023-10-29T02:30:00` in `Europe/Berlin` can have offset `+01:00` or `+02:00`.
   * This method will return both possible DateTimes if this DateTime's local time is ambiguous.
   * @returns {DateTime[]}
   */
  getPossibleOffsets() {
    if (!this.isValid || this.isOffsetFixed)
      return [this];
    const t = 864e5, n = 6e4, o = Ra(this.c), r = this.zone.offset(o - t), s = this.zone.offset(o + t), i = this.zone.offset(o - r * n), a = this.zone.offset(o - s * n);
    if (i === a)
      return [this];
    const l = o - i * n, c = o - a * n, u = qs(l, i), d = qs(c, a);
    return u.hour === d.hour && u.minute === d.minute && u.second === d.second && u.millisecond === d.millisecond ? [gn(this, { ts: l }), gn(this, { ts: c })] : [this];
  }
  /**
   * Returns true if this DateTime is in a leap year, false otherwise
   * @example DateTime.local(2016).isInLeapYear //=> true
   * @example DateTime.local(2013).isInLeapYear //=> false
   * @type {boolean}
   */
  get isInLeapYear() {
    return As(this.year);
  }
  /**
   * Returns the number of days in this DateTime's month
   * @example DateTime.local(2016, 2).daysInMonth //=> 29
   * @example DateTime.local(2016, 3).daysInMonth //=> 31
   * @type {number}
   */
  get daysInMonth() {
    return ea(this.year, this.month);
  }
  /**
   * Returns the number of days in this DateTime's year
   * @example DateTime.local(2016).daysInYear //=> 366
   * @example DateTime.local(2013).daysInYear //=> 365
   * @type {number}
   */
  get daysInYear() {
    return this.isValid ? xo(this.year) : NaN;
  }
  /**
   * Returns the number of weeks in this DateTime's year
   * @see https://en.wikipedia.org/wiki/ISO_week_date
   * @example DateTime.local(2004).weeksInWeekYear //=> 53
   * @example DateTime.local(2013).weeksInWeekYear //=> 52
   * @type {number}
   */
  get weeksInWeekYear() {
    return this.isValid ? ns(this.weekYear) : NaN;
  }
  /**
   * Returns the number of weeks in this DateTime's local week year
   * @example DateTime.local(2020, 6, {locale: 'en-US'}).weeksInLocalWeekYear //=> 52
   * @example DateTime.local(2020, 6, {locale: 'de-DE'}).weeksInLocalWeekYear //=> 53
   * @type {number}
   */
  get weeksInLocalWeekYear() {
    return this.isValid ? ns(
      this.localWeekYear,
      this.loc.getMinDaysInFirstWeek(),
      this.loc.getStartOfWeek()
    ) : NaN;
  }
  /**
   * Returns the resolved Intl options for this DateTime.
   * This is useful in understanding the behavior of formatting methods
   * @param {Object} opts - the same options as toLocaleString
   * @return {Object}
   */
  resolvedLocaleOptions(t = {}) {
    const { locale: n, numberingSystem: o, calendar: r } = Te.create(
      this.loc.clone(t),
      t
    ).resolvedOptions(this);
    return { locale: n, numberingSystem: o, outputCalendar: r };
  }
  // TRANSFORM
  /**
   * "Set" the DateTime's zone to UTC. Returns a newly-constructed DateTime.
   *
   * Equivalent to {@link DateTime#setZone}('utc')
   * @param {number} [offset=0] - optionally, an offset from UTC in minutes
   * @param {Object} [opts={}] - options to pass to `setZone()`
   * @return {DateTime}
   */
  toUTC(t = 0, n = {}) {
    return this.setZone(Ee.instance(t), n);
  }
  /**
   * "Set" the DateTime's zone to the host's local zone. Returns a newly-constructed DateTime.
   *
   * Equivalent to `setZone('local')`
   * @return {DateTime}
   */
  toLocal() {
    return this.setZone(le.defaultZone);
  }
  /**
   * "Set" the DateTime's zone to specified zone. Returns a newly-constructed DateTime.
   *
   * By default, the setter keeps the underlying time the same (as in, the same timestamp), but the new instance will report different local times and consider DSTs when making computations, as with {@link DateTime#plus}. You may wish to use {@link DateTime#toLocal} and {@link DateTime#toUTC} which provide simple convenience wrappers for commonly used zones.
   * @param {string|Zone} [zone='local'] - a zone identifier. As a string, that can be any IANA zone supported by the host environment, or a fixed-offset name of the form 'UTC+3', or the strings 'local' or 'utc'. You may also supply an instance of a {@link DateTime#Zone} class.
   * @param {Object} opts - options
   * @param {boolean} [opts.keepLocalTime=false] - If true, adjust the underlying time so that the local time stays the same, but in the target zone. You should rarely need this.
   * @return {DateTime}
   */
  setZone(t, { keepLocalTime: n = !1, keepCalendarTime: o = !1 } = {}) {
    if (t = on(t, le.defaultZone), t.equals(this.zone))
      return this;
    if (t.isValid) {
      let r = this.ts;
      if (n || o) {
        const s = t.offset(this.ts), i = this.toObject();
        [r] = Ni(i, s, t);
      }
      return gn(this, { ts: r, zone: t });
    } else
      return D.invalid(Fs(t));
  }
  /**
   * "Set" the locale, numberingSystem, or outputCalendar. Returns a newly-constructed DateTime.
   * @param {Object} properties - the properties to set
   * @example DateTime.local(2017, 5, 25).reconfigure({ locale: 'en-GB' })
   * @return {DateTime}
   */
  reconfigure({ locale: t, numberingSystem: n, outputCalendar: o } = {}) {
    const r = this.loc.clone({ locale: t, numberingSystem: n, outputCalendar: o });
    return gn(this, { loc: r });
  }
  /**
   * "Set" the locale. Returns a newly-constructed DateTime.
   * Just a convenient alias for reconfigure({ locale })
   * @example DateTime.local(2017, 5, 25).setLocale('en-GB')
   * @return {DateTime}
   */
  setLocale(t) {
    return this.reconfigure({ locale: t });
  }
  /**
   * "Set" the values of specified units. Returns a newly-constructed DateTime.
   * You can only set units with this method; for "setting" metadata, see {@link DateTime#reconfigure} and {@link DateTime#setZone}.
   *
   * This method also supports setting locale-based week units, i.e. `localWeekday`, `localWeekNumber` and `localWeekYear`.
   * They cannot be mixed with ISO-week units like `weekday`.
   * @param {Object} values - a mapping of units to numbers
   * @example dt.set({ year: 2017 })
   * @example dt.set({ hour: 8, minute: 30 })
   * @example dt.set({ weekday: 5 })
   * @example dt.set({ year: 2005, ordinal: 234 })
   * @return {DateTime}
   */
  set(t) {
    if (!this.isValid)
      return this;
    const n = ta(t, uf), { minDaysInFirstWeek: o, startOfWeek: r } = Yd(n, this.loc), s = !N(n.weekYear) || !N(n.weekNumber) || !N(n.weekday), i = !N(n.ordinal), a = !N(n.year), l = !N(n.month) || !N(n.day), c = a || l, u = n.weekYear || n.weekNumber;
    if ((c || i) && u)
      throw new yo(
        "Can't mix weekYear/weekNumber units with year/month/day or ordinals"
      );
    if (l && i)
      throw new yo("Can't mix ordinal dates with month/day");
    let d;
    s ? d = zd(
      { ...Xi(this.c, o, r), ...n },
      o,
      r
    ) : N(n.ordinal) ? (d = { ...this.toObject(), ...n }, N(n.day) && (d.day = Math.min(ea(d.year, d.month), d.day))) : d = Gd({ ...el(this.c), ...n });
    const [f, h] = Ni(d, this.o, this.zone);
    return gn(this, { ts: f, o: h });
  }
  /**
   * Add a period of time to this DateTime and return the resulting DateTime
   *
   * Adding hours, minutes, seconds, or milliseconds increases the timestamp by the right number of milliseconds. Adding days, months, or years shifts the calendar, accounting for DSTs and leap years along the way. Thus, `dt.plus({ hours: 24 })` may result in a different time than `dt.plus({ days: 1 })` if there's a DST shift in between.
   * @param {Duration|Object|number} duration - The amount to add. Either a Luxon Duration, a number of milliseconds, the object argument to Duration.fromObject()
   * @example DateTime.now().plus(123) //~> in 123 milliseconds
   * @example DateTime.now().plus({ minutes: 15 }) //~> in 15 minutes
   * @example DateTime.now().plus({ days: 1 }) //~> this time tomorrow
   * @example DateTime.now().plus({ days: -1 }) //~> this time yesterday
   * @example DateTime.now().plus({ hours: 3, minutes: 13 }) //~> in 3 hr, 13 min
   * @example DateTime.now().plus(Duration.fromObject({ hours: 3, minutes: 13 })) //~> in 3 hr, 13 min
   * @return {DateTime}
   */
  plus(t) {
    if (!this.isValid)
      return this;
    const n = j.fromDurationLike(t);
    return gn(this, lf(this, n));
  }
  /**
   * Subtract a period of time to this DateTime and return the resulting DateTime
   * See {@link DateTime#plus}
   * @param {Duration|Object|number} duration - The amount to subtract. Either a Luxon Duration, a number of milliseconds, the object argument to Duration.fromObject()
   @return {DateTime}
   */
  minus(t) {
    if (!this.isValid)
      return this;
    const n = j.fromDurationLike(t).negate();
    return gn(this, lf(this, n));
  }
  /**
   * "Set" this DateTime to the beginning of a unit of time.
   * @param {string} unit - The unit to go to the beginning of. Can be 'year', 'quarter', 'month', 'week', 'day', 'hour', 'minute', 'second', or 'millisecond'.
   * @param {Object} opts - options
   * @param {boolean} [opts.useLocaleWeeks=false] - If true, use weeks based on the locale, i.e. use the locale-dependent start of the week
   * @example DateTime.local(2014, 3, 3).startOf('month').toISODate(); //=> '2014-03-01'
   * @example DateTime.local(2014, 3, 3).startOf('year').toISODate(); //=> '2014-01-01'
   * @example DateTime.local(2014, 3, 3).startOf('week').toISODate(); //=> '2014-03-03', weeks always start on Mondays
   * @example DateTime.local(2014, 3, 3, 5, 30).startOf('day').toISOTime(); //=> '00:00.000-05:00'
   * @example DateTime.local(2014, 3, 3, 5, 30).startOf('hour').toISOTime(); //=> '05:00:00.000-05:00'
   * @return {DateTime}
   */
  startOf(t, { useLocaleWeeks: n = !1 } = {}) {
    if (!this.isValid)
      return this;
    const o = {}, r = j.normalizeUnit(t);
    switch (r) {
      case "years":
        o.month = 1;
      case "quarters":
      case "months":
        o.day = 1;
      case "weeks":
      case "days":
        o.hour = 0;
      case "hours":
        o.minute = 0;
      case "minutes":
        o.second = 0;
      case "seconds":
        o.millisecond = 0;
        break;
    }
    if (r === "weeks")
      if (n) {
        const s = this.loc.getStartOfWeek(), { weekday: i } = this;
        i < s && (o.weekNumber = this.weekNumber - 1), o.weekday = s;
      } else
        o.weekday = 1;
    if (r === "quarters") {
      const s = Math.ceil(this.month / 3);
      o.month = (s - 1) * 3 + 1;
    }
    return this.set(o);
  }
  /**
   * "Set" this DateTime to the end (meaning the last millisecond) of a unit of time
   * @param {string} unit - The unit to go to the end of. Can be 'year', 'quarter', 'month', 'week', 'day', 'hour', 'minute', 'second', or 'millisecond'.
   * @param {Object} opts - options
   * @param {boolean} [opts.useLocaleWeeks=false] - If true, use weeks based on the locale, i.e. use the locale-dependent start of the week
   * @example DateTime.local(2014, 3, 3).endOf('month').toISO(); //=> '2014-03-31T23:59:59.999-05:00'
   * @example DateTime.local(2014, 3, 3).endOf('year').toISO(); //=> '2014-12-31T23:59:59.999-05:00'
   * @example DateTime.local(2014, 3, 3).endOf('week').toISO(); // => '2014-03-09T23:59:59.999-05:00', weeks start on Mondays
   * @example DateTime.local(2014, 3, 3, 5, 30).endOf('day').toISO(); //=> '2014-03-03T23:59:59.999-05:00'
   * @example DateTime.local(2014, 3, 3, 5, 30).endOf('hour').toISO(); //=> '2014-03-03T05:59:59.999-05:00'
   * @return {DateTime}
   */
  endOf(t, n) {
    return this.isValid ? this.plus({ [t]: 1 }).startOf(t, n).minus(1) : this;
  }
  // OUTPUT
  /**
   * Returns a string representation of this DateTime formatted according to the specified format string.
   * **You may not want this.** See {@link DateTime#toLocaleString} for a more flexible formatting tool. For a table of tokens and their interpretations, see [here](https://moment.github.io/luxon/#/formatting?id=table-of-tokens).
   * Defaults to en-US if no locale has been specified, regardless of the system's locale.
   * @param {string} fmt - the format string
   * @param {Object} opts - opts to override the configuration options on this DateTime
   * @example DateTime.now().toFormat('yyyy LLL dd') //=> '2017 Apr 22'
   * @example DateTime.now().setLocale('fr').toFormat('yyyy LLL dd') //=> '2017 avr. 22'
   * @example DateTime.now().toFormat('yyyy LLL dd', { locale: "fr" }) //=> '2017 avr. 22'
   * @example DateTime.now().toFormat("HH 'hours and' mm 'minutes'") //=> '20 hours and 55 minutes'
   * @return {string}
   */
  toFormat(t, n = {}) {
    return this.isValid ? Te.create(this.loc.redefaultToEN(n)).formatDateTimeFromString(this, t) : nl;
  }
  /**
   * Returns a localized string representing this date. Accepts the same options as the Intl.DateTimeFormat constructor and any presets defined by Luxon, such as `DateTime.DATE_FULL` or `DateTime.TIME_SIMPLE`.
   * The exact behavior of this method is browser-specific, but in general it will return an appropriate representation
   * of the DateTime in the assigned locale.
   * Defaults to the system's locale if no locale has been specified
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
   * @param formatOpts {Object} - Intl.DateTimeFormat constructor options and configuration options
   * @param {Object} opts - opts to override the configuration options on this DateTime
   * @example DateTime.now().toLocaleString(); //=> 4/20/2017
   * @example DateTime.now().setLocale('en-gb').toLocaleString(); //=> '20/04/2017'
   * @example DateTime.now().toLocaleString(DateTime.DATE_FULL); //=> 'April 20, 2017'
   * @example DateTime.now().toLocaleString(DateTime.DATE_FULL, { locale: 'fr' }); //=> '28 août 2022'
   * @example DateTime.now().toLocaleString(DateTime.TIME_SIMPLE); //=> '11:32 AM'
   * @example DateTime.now().toLocaleString(DateTime.DATETIME_SHORT); //=> '4/20/2017, 11:32 AM'
   * @example DateTime.now().toLocaleString({ weekday: 'long', month: 'long', day: '2-digit' }); //=> 'Thursday, April 20'
   * @example DateTime.now().toLocaleString({ weekday: 'short', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' }); //=> 'Thu, Apr 20, 11:27 AM'
   * @example DateTime.now().toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h23' }); //=> '11:32'
   * @return {string}
   */
  toLocaleString(t = Ji, n = {}) {
    return this.isValid ? Te.create(this.loc.clone(n), t).formatDateTime(this) : nl;
  }
  /**
   * Returns an array of format "parts", meaning individual tokens along with metadata. This is allows callers to post-process individual sections of the formatted output.
   * Defaults to the system's locale if no locale has been specified
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat/formatToParts
   * @param opts {Object} - Intl.DateTimeFormat constructor options, same as `toLocaleString`.
   * @example DateTime.now().toLocaleParts(); //=> [
   *                                   //=>   { type: 'day', value: '25' },
   *                                   //=>   { type: 'literal', value: '/' },
   *                                   //=>   { type: 'month', value: '05' },
   *                                   //=>   { type: 'literal', value: '/' },
   *                                   //=>   { type: 'year', value: '1982' }
   *                                   //=> ]
   */
  toLocaleParts(t = {}) {
    return this.isValid ? Te.create(this.loc.clone(t), t).formatDateTimeParts(this) : [];
  }
  /**
   * Returns an ISO 8601-compliant string representation of this DateTime
   * @param {Object} opts - options
   * @param {boolean} [opts.suppressMilliseconds=false] - exclude milliseconds from the format if they're 0
   * @param {boolean} [opts.suppressSeconds=false] - exclude seconds from the format if they're 0
   * @param {boolean} [opts.includeOffset=true] - include the offset, such as 'Z' or '-04:00'
   * @param {boolean} [opts.extendedZone=false] - add the time zone format extension
   * @param {string} [opts.format='extended'] - choose between the basic and extended format
   * @example DateTime.utc(1983, 5, 25).toISO() //=> '1982-05-25T00:00:00.000Z'
   * @example DateTime.now().toISO() //=> '2017-04-22T20:47:05.335-04:00'
   * @example DateTime.now().toISO({ includeOffset: false }) //=> '2017-04-22T20:47:05.335'
   * @example DateTime.now().toISO({ format: 'basic' }) //=> '20170422T204705.335-0400'
   * @return {string}
   */
  toISO({
    format: t = "extended",
    suppressSeconds: n = !1,
    suppressMilliseconds: o = !1,
    includeOffset: r = !0,
    extendedZone: s = !1
  } = {}) {
    if (!this.isValid)
      return null;
    const i = t === "extended";
    let a = sl(this, i);
    return a += "T", a += cf(this, i, n, o, r, s), a;
  }
  /**
   * Returns an ISO 8601-compliant string representation of this DateTime's date component
   * @param {Object} opts - options
   * @param {string} [opts.format='extended'] - choose between the basic and extended format
   * @example DateTime.utc(1982, 5, 25).toISODate() //=> '1982-05-25'
   * @example DateTime.utc(1982, 5, 25).toISODate({ format: 'basic' }) //=> '19820525'
   * @return {string}
   */
  toISODate({ format: t = "extended" } = {}) {
    return this.isValid ? sl(this, t === "extended") : null;
  }
  /**
   * Returns an ISO 8601-compliant string representation of this DateTime's week date
   * @example DateTime.utc(1982, 5, 25).toISOWeekDate() //=> '1982-W21-2'
   * @return {string}
   */
  toISOWeekDate() {
    return Hs(this, "kkkk-'W'WW-c");
  }
  /**
   * Returns an ISO 8601-compliant string representation of this DateTime's time component
   * @param {Object} opts - options
   * @param {boolean} [opts.suppressMilliseconds=false] - exclude milliseconds from the format if they're 0
   * @param {boolean} [opts.suppressSeconds=false] - exclude seconds from the format if they're 0
   * @param {boolean} [opts.includeOffset=true] - include the offset, such as 'Z' or '-04:00'
   * @param {boolean} [opts.extendedZone=true] - add the time zone format extension
   * @param {boolean} [opts.includePrefix=false] - include the `T` prefix
   * @param {string} [opts.format='extended'] - choose between the basic and extended format
   * @example DateTime.utc().set({ hour: 7, minute: 34 }).toISOTime() //=> '07:34:19.361Z'
   * @example DateTime.utc().set({ hour: 7, minute: 34, seconds: 0, milliseconds: 0 }).toISOTime({ suppressSeconds: true }) //=> '07:34Z'
   * @example DateTime.utc().set({ hour: 7, minute: 34 }).toISOTime({ format: 'basic' }) //=> '073419.361Z'
   * @example DateTime.utc().set({ hour: 7, minute: 34 }).toISOTime({ includePrefix: true }) //=> 'T07:34:19.361Z'
   * @return {string}
   */
  toISOTime({
    suppressMilliseconds: t = !1,
    suppressSeconds: n = !1,
    includeOffset: o = !0,
    includePrefix: r = !1,
    extendedZone: s = !1,
    format: i = "extended"
  } = {}) {
    return this.isValid ? (r ? "T" : "") + cf(
      this,
      i === "extended",
      n,
      t,
      o,
      s
    ) : null;
  }
  /**
   * Returns an RFC 2822-compatible string representation of this DateTime
   * @example DateTime.utc(2014, 7, 13).toRFC2822() //=> 'Sun, 13 Jul 2014 00:00:00 +0000'
   * @example DateTime.local(2014, 7, 13).toRFC2822() //=> 'Sun, 13 Jul 2014 00:00:00 -0400'
   * @return {string}
   */
  toRFC2822() {
    return Hs(this, "EEE, dd LLL yyyy HH:mm:ss ZZZ", !1);
  }
  /**
   * Returns a string representation of this DateTime appropriate for use in HTTP headers. The output is always expressed in GMT.
   * Specifically, the string conforms to RFC 1123.
   * @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec3.html#sec3.3.1
   * @example DateTime.utc(2014, 7, 13).toHTTP() //=> 'Sun, 13 Jul 2014 00:00:00 GMT'
   * @example DateTime.utc(2014, 7, 13, 19).toHTTP() //=> 'Sun, 13 Jul 2014 19:00:00 GMT'
   * @return {string}
   */
  toHTTP() {
    return Hs(this.toUTC(), "EEE, dd LLL yyyy HH:mm:ss 'GMT'");
  }
  /**
   * Returns a string representation of this DateTime appropriate for use in SQL Date
   * @example DateTime.utc(2014, 7, 13).toSQLDate() //=> '2014-07-13'
   * @return {string}
   */
  toSQLDate() {
    return this.isValid ? sl(this, !0) : null;
  }
  /**
   * Returns a string representation of this DateTime appropriate for use in SQL Time
   * @param {Object} opts - options
   * @param {boolean} [opts.includeZone=false] - include the zone, such as 'America/New_York'. Overrides includeOffset.
   * @param {boolean} [opts.includeOffset=true] - include the offset, such as 'Z' or '-04:00'
   * @param {boolean} [opts.includeOffsetSpace=true] - include the space between the time and the offset, such as '05:15:16.345 -04:00'
   * @example DateTime.utc().toSQL() //=> '05:15:16.345'
   * @example DateTime.now().toSQL() //=> '05:15:16.345 -04:00'
   * @example DateTime.now().toSQL({ includeOffset: false }) //=> '05:15:16.345'
   * @example DateTime.now().toSQL({ includeZone: false }) //=> '05:15:16.345 America/New_York'
   * @return {string}
   */
  toSQLTime({ includeOffset: t = !0, includeZone: n = !1, includeOffsetSpace: o = !0 } = {}) {
    let r = "HH:mm:ss.SSS";
    return (n || t) && (o && (r += " "), n ? r += "z" : t && (r += "ZZ")), Hs(this, r, !0);
  }
  /**
   * Returns a string representation of this DateTime appropriate for use in SQL DateTime
   * @param {Object} opts - options
   * @param {boolean} [opts.includeZone=false] - include the zone, such as 'America/New_York'. Overrides includeOffset.
   * @param {boolean} [opts.includeOffset=true] - include the offset, such as 'Z' or '-04:00'
   * @param {boolean} [opts.includeOffsetSpace=true] - include the space between the time and the offset, such as '05:15:16.345 -04:00'
   * @example DateTime.utc(2014, 7, 13).toSQL() //=> '2014-07-13 00:00:00.000 Z'
   * @example DateTime.local(2014, 7, 13).toSQL() //=> '2014-07-13 00:00:00.000 -04:00'
   * @example DateTime.local(2014, 7, 13).toSQL({ includeOffset: false }) //=> '2014-07-13 00:00:00.000'
   * @example DateTime.local(2014, 7, 13).toSQL({ includeZone: true }) //=> '2014-07-13 00:00:00.000 America/New_York'
   * @return {string}
   */
  toSQL(t = {}) {
    return this.isValid ? `${this.toSQLDate()} ${this.toSQLTime(t)}` : null;
  }
  /**
   * Returns a string representation of this DateTime appropriate for debugging
   * @return {string}
   */
  toString() {
    return this.isValid ? this.toISO() : nl;
  }
  /**
   * Returns a string representation of this DateTime appropriate for the REPL.
   * @return {string}
   */
  [Symbol.for("nodejs.util.inspect.custom")]() {
    return this.isValid ? `DateTime { ts: ${this.toISO()}, zone: ${this.zone.name}, locale: ${this.locale} }` : `DateTime { Invalid, reason: ${this.invalidReason} }`;
  }
  /**
   * Returns the epoch milliseconds of this DateTime. Alias of {@link DateTime#toMillis}
   * @return {number}
   */
  valueOf() {
    return this.toMillis();
  }
  /**
   * Returns the epoch milliseconds of this DateTime.
   * @return {number}
   */
  toMillis() {
    return this.isValid ? this.ts : NaN;
  }
  /**
   * Returns the epoch seconds of this DateTime.
   * @return {number}
   */
  toSeconds() {
    return this.isValid ? this.ts / 1e3 : NaN;
  }
  /**
   * Returns the epoch seconds (as a whole number) of this DateTime.
   * @return {number}
   */
  toUnixInteger() {
    return this.isValid ? Math.floor(this.ts / 1e3) : NaN;
  }
  /**
   * Returns an ISO 8601 representation of this DateTime appropriate for use in JSON.
   * @return {string}
   */
  toJSON() {
    return this.toISO();
  }
  /**
   * Returns a BSON serializable equivalent to this DateTime.
   * @return {Date}
   */
  toBSON() {
    return this.toJSDate();
  }
  /**
   * Returns a JavaScript object with this DateTime's year, month, day, and so on.
   * @param opts - options for generating the object
   * @param {boolean} [opts.includeConfig=false] - include configuration attributes in the output
   * @example DateTime.now().toObject() //=> { year: 2017, month: 4, day: 22, hour: 20, minute: 49, second: 42, millisecond: 268 }
   * @return {Object}
   */
  toObject(t = {}) {
    if (!this.isValid)
      return {};
    const n = { ...this.c };
    return t.includeConfig && (n.outputCalendar = this.outputCalendar, n.numberingSystem = this.loc.numberingSystem, n.locale = this.loc.locale), n;
  }
  /**
   * Returns a JavaScript Date equivalent to this DateTime.
   * @return {Date}
   */
  toJSDate() {
    return new Date(this.isValid ? this.ts : NaN);
  }
  // COMPARE
  /**
   * Return the difference between two DateTimes as a Duration.
   * @param {DateTime} otherDateTime - the DateTime to compare this one to
   * @param {string|string[]} [unit=['milliseconds']] - the unit or array of units (such as 'hours' or 'days') to include in the duration.
   * @param {Object} opts - options that affect the creation of the Duration
   * @param {string} [opts.conversionAccuracy='casual'] - the conversion system to use
   * @example
   * var i1 = DateTime.fromISO('1982-05-25T09:45'),
   *     i2 = DateTime.fromISO('1983-10-14T10:30');
   * i2.diff(i1).toObject() //=> { milliseconds: 43807500000 }
   * i2.diff(i1, 'hours').toObject() //=> { hours: 12168.75 }
   * i2.diff(i1, ['months', 'days']).toObject() //=> { months: 16, days: 19.03125 }
   * i2.diff(i1, ['months', 'days', 'hours']).toObject() //=> { months: 16, days: 19, hours: 0.75 }
   * @return {Duration}
   */
  diff(t, n = "milliseconds", o = {}) {
    if (!this.isValid || !t.isValid)
      return j.invalid("created by diffing an invalid DateTime");
    const r = { locale: this.locale, numberingSystem: this.numberingSystem, ...o }, s = vD(n).map(j.normalizeUnit), i = t.valueOf() > this.valueOf(), a = i ? this : t, l = i ? t : this, c = wR(a, l, s, r);
    return i ? c.negate() : c;
  }
  /**
   * Return the difference between this DateTime and right now.
   * See {@link DateTime#diff}
   * @param {string|string[]} [unit=['milliseconds']] - the unit or units units (such as 'hours' or 'days') to include in the duration
   * @param {Object} opts - options that affect the creation of the Duration
   * @param {string} [opts.conversionAccuracy='casual'] - the conversion system to use
   * @return {Duration}
   */
  diffNow(t = "milliseconds", n = {}) {
    return this.diff(D.now(), t, n);
  }
  /**
   * Return an Interval spanning between this DateTime and another DateTime
   * @param {DateTime} otherDateTime - the other end point of the Interval
   * @return {Interval}
   */
  until(t) {
    return this.isValid ? ie.fromDateTimes(this, t) : this;
  }
  /**
   * Return whether this DateTime is in the same unit of time as another DateTime.
   * Higher-order units must also be identical for this function to return `true`.
   * Note that time zones are **ignored** in this comparison, which compares the **local** calendar time. Use {@link DateTime#setZone} to convert one of the dates if needed.
   * @param {DateTime} otherDateTime - the other DateTime
   * @param {string} unit - the unit of time to check sameness on
   * @param {Object} opts - options
   * @param {boolean} [opts.useLocaleWeeks=false] - If true, use weeks based on the locale, i.e. use the locale-dependent start of the week; only the locale of this DateTime is used
   * @example DateTime.now().hasSame(otherDT, 'day'); //~> true if otherDT is in the same current calendar day
   * @return {boolean}
   */
  hasSame(t, n, o) {
    if (!this.isValid)
      return !1;
    const r = t.valueOf(), s = this.setZone(t.zone, { keepLocalTime: !0 });
    return s.startOf(n, o) <= r && r <= s.endOf(n, o);
  }
  /**
   * Equality check
   * Two DateTimes are equal if and only if they represent the same millisecond, have the same zone and location, and are both valid.
   * To compare just the millisecond values, use `+dt1 === +dt2`.
   * @param {DateTime} other - the other DateTime
   * @return {boolean}
   */
  equals(t) {
    return this.isValid && t.isValid && this.valueOf() === t.valueOf() && this.zone.equals(t.zone) && this.loc.equals(t.loc);
  }
  /**
   * Returns a string representation of a this time relative to now, such as "in two days". Can only internationalize if your
   * platform supports Intl.RelativeTimeFormat. Rounds down by default.
   * @param {Object} options - options that affect the output
   * @param {DateTime} [options.base=DateTime.now()] - the DateTime to use as the basis to which this time is compared. Defaults to now.
   * @param {string} [options.style="long"] - the style of units, must be "long", "short", or "narrow"
   * @param {string|string[]} options.unit - use a specific unit or array of units; if omitted, or an array, the method will pick the best unit. Use an array or one of "years", "quarters", "months", "weeks", "days", "hours", "minutes", or "seconds"
   * @param {boolean} [options.round=true] - whether to round the numbers in the output.
   * @param {number} [options.padding=0] - padding in milliseconds. This allows you to round up the result if it fits inside the threshold. Don't use in combination with {round: false} because the decimal output will include the padding.
   * @param {string} options.locale - override the locale of this DateTime
   * @param {string} options.numberingSystem - override the numberingSystem of this DateTime. The Intl system may choose not to honor this
   * @example DateTime.now().plus({ days: 1 }).toRelative() //=> "in 1 day"
   * @example DateTime.now().setLocale("es").toRelative({ days: 1 }) //=> "dentro de 1 día"
   * @example DateTime.now().plus({ days: 1 }).toRelative({ locale: "fr" }) //=> "dans 23 heures"
   * @example DateTime.now().minus({ days: 2 }).toRelative() //=> "2 days ago"
   * @example DateTime.now().minus({ days: 2 }).toRelative({ unit: "hours" }) //=> "48 hours ago"
   * @example DateTime.now().minus({ hours: 36 }).toRelative({ round: false }) //=> "1.5 days ago"
   */
  toRelative(t = {}) {
    if (!this.isValid)
      return null;
    const n = t.base || D.fromObject({}, { zone: this.zone }), o = t.padding ? this < n ? -t.padding : t.padding : 0;
    let r = ["years", "months", "days", "hours", "minutes", "seconds"], s = t.unit;
    return Array.isArray(t.unit) && (r = t.unit, s = void 0), ff(n, this.plus(o), {
      ...t,
      numeric: "always",
      units: r,
      unit: s
    });
  }
  /**
   * Returns a string representation of this date relative to today, such as "yesterday" or "next month".
   * Only internationalizes on platforms that supports Intl.RelativeTimeFormat.
   * @param {Object} options - options that affect the output
   * @param {DateTime} [options.base=DateTime.now()] - the DateTime to use as the basis to which this time is compared. Defaults to now.
   * @param {string} options.locale - override the locale of this DateTime
   * @param {string} options.unit - use a specific unit; if omitted, the method will pick the unit. Use one of "years", "quarters", "months", "weeks", or "days"
   * @param {string} options.numberingSystem - override the numberingSystem of this DateTime. The Intl system may choose not to honor this
   * @example DateTime.now().plus({ days: 1 }).toRelativeCalendar() //=> "tomorrow"
   * @example DateTime.now().setLocale("es").plus({ days: 1 }).toRelative() //=> ""mañana"
   * @example DateTime.now().plus({ days: 1 }).toRelativeCalendar({ locale: "fr" }) //=> "demain"
   * @example DateTime.now().minus({ days: 2 }).toRelativeCalendar() //=> "2 days ago"
   */
  toRelativeCalendar(t = {}) {
    return this.isValid ? ff(t.base || D.fromObject({}, { zone: this.zone }), this, {
      ...t,
      numeric: "auto",
      units: ["years", "months", "days"],
      calendary: !0
    }) : null;
  }
  /**
   * Return the min of several date times
   * @param {...DateTime} dateTimes - the DateTimes from which to choose the minimum
   * @return {DateTime} the min DateTime, or undefined if called with no argument
   */
  static min(...t) {
    if (!t.every(D.isDateTime))
      throw new Me("min requires all arguments be DateTimes");
    return Kd(t, (n) => n.valueOf(), Math.min);
  }
  /**
   * Return the max of several date times
   * @param {...DateTime} dateTimes - the DateTimes from which to choose the maximum
   * @return {DateTime} the max DateTime, or undefined if called with no argument
   */
  static max(...t) {
    if (!t.every(D.isDateTime))
      throw new Me("max requires all arguments be DateTimes");
    return Kd(t, (n) => n.valueOf(), Math.max);
  }
  // MISC
  /**
   * Explain how a string would be parsed by fromFormat()
   * @param {string} text - the string to parse
   * @param {string} fmt - the format the string is expected to be in (see description)
   * @param {Object} options - options taken by fromFormat()
   * @return {Object}
   */
  static fromFormatExplain(t, n, o = {}) {
    const { locale: r = null, numberingSystem: s = null } = o, i = V.fromOpts({
      locale: r,
      numberingSystem: s,
      defaultToEN: !0
    });
    return Zy(i, t, n);
  }
  /**
   * @deprecated use fromFormatExplain instead
   */
  static fromStringExplain(t, n, o = {}) {
    return D.fromFormatExplain(t, n, o);
  }
  // FORMAT PRESETS
  /**
   * {@link DateTime#toLocaleString} format like 10/14/1983
   * @type {Object}
   */
  static get DATE_SHORT() {
    return Ji;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Oct 14, 1983'
   * @type {Object}
   */
  static get DATE_MED() {
    return Jp;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Fri, Oct 14, 1983'
   * @type {Object}
   */
  static get DATE_MED_WITH_WEEKDAY() {
    return z2;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'October 14, 1983'
   * @type {Object}
   */
  static get DATE_FULL() {
    return Xp;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Tuesday, October 14, 1983'
   * @type {Object}
   */
  static get DATE_HUGE() {
    return ey;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get TIME_SIMPLE() {
    return ty;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get TIME_WITH_SECONDS() {
    return ny;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23 AM EDT'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get TIME_WITH_SHORT_OFFSET() {
    return oy;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23 AM Eastern Daylight Time'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get TIME_WITH_LONG_OFFSET() {
    return ry;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30', always 24-hour.
   * @type {Object}
   */
  static get TIME_24_SIMPLE() {
    return sy;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23', always 24-hour.
   * @type {Object}
   */
  static get TIME_24_WITH_SECONDS() {
    return iy;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23 EDT', always 24-hour.
   * @type {Object}
   */
  static get TIME_24_WITH_SHORT_OFFSET() {
    return ay;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23 Eastern Daylight Time', always 24-hour.
   * @type {Object}
   */
  static get TIME_24_WITH_LONG_OFFSET() {
    return ly;
  }
  /**
   * {@link DateTime#toLocaleString} format like '10/14/1983, 9:30 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_SHORT() {
    return cy;
  }
  /**
   * {@link DateTime#toLocaleString} format like '10/14/1983, 9:30:33 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_SHORT_WITH_SECONDS() {
    return uy;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Oct 14, 1983, 9:30 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_MED() {
    return dy;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Oct 14, 1983, 9:30:33 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_MED_WITH_SECONDS() {
    return fy;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Fri, 14 Oct 1983, 9:30 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_MED_WITH_WEEKDAY() {
    return G2;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'October 14, 1983, 9:30 AM EDT'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_FULL() {
    return my;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'October 14, 1983, 9:30:33 AM EDT'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_FULL_WITH_SECONDS() {
    return hy;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Friday, October 14, 1983, 9:30 AM Eastern Daylight Time'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_HUGE() {
    return py;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Friday, October 14, 1983, 9:30:33 AM Eastern Daylight Time'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_HUGE_WITH_SECONDS() {
    return yy;
  }
}
function mr(e) {
  if (D.isDateTime(e))
    return e;
  if (e && e.valueOf && Ln(e.valueOf()))
    return D.fromJSDate(e);
  if (e && typeof e == "object")
    return D.fromObject(e);
  throw new Me(
    `Unknown datetime argument: ${e}, of type ${typeof e}`
  );
}
function qR({ collections: e, lww: t }, { tableIds: n, tableColumnIds: o, relationshipIds: r, indexIds: s, indexColumnIds: i, memoIds: a }) {
  y(e).collection("tableEntities").removeMany(n), y(e).collection("tableColumnEntities").removeMany(o), y(e).collection("relationshipEntities").removeMany(r), y(e).collection("indexEntities").removeMany(s), y(e).collection("indexColumnEntities").removeMany(i), y(e).collection("memoEntities").removeMany(a), [
    ...n,
    ...o,
    ...r,
    ...s,
    ...i,
    ...a
  ].forEach((l) => Reflect.deleteProperty(t, l));
}
const HR = 3, ZR = $([
  "tableEntities",
  "tableColumnEntities",
  "relationshipEntities",
  "indexEntities",
  "indexColumnEntities",
  "memoEntities"
]);
class QL {
  async run(t) {
    const n = JSON.parse(t), o = qn(n), { doc: { tableIds: r, memoIds: s, indexIds: i, relationshipIds: a }, collections: l, lww: c } = o, u = $(r), d = $(s), f = $(i), h = $(a), p = VR(D.now()), g = y(l).collection("tableEntities"), v = y(l).collection("tableColumnEntities"), T = y(l).collection("indexEntities"), O = y(l).collection("indexColumnEntities"), k = y(l).collection("relationshipEntities"), R = y(l).collection("memoEntities"), ae = new Set(g.selectAll().filter(p(u)).map(({ id: S }) => S)), vt = /* @__PURE__ */ new Set(), bt = new Set(k.selectAll().filter(p(h)).map(({ id: S }) => S)), nt = new Set(T.selectAll().filter(p(f)).map(({ id: S }) => S)), We = /* @__PURE__ */ new Set(), z = new Set(R.selectAll().filter(p(d)).map(({ id: S }) => S));
    v.selectAll().filter(({ tableId: S }) => ae.has(S)).forEach(({ id: S }) => vt.add(S)), k.selectAll().filter(({ id: S, start: It, end: rr }) => !bt.has(S) && (ae.has(It.tableId) || ae.has(rr.tableId))).forEach(({ id: S }) => bt.add(S)), T.selectAll().filter(({ id: S, tableId: It }) => !nt.has(S) && ae.has(It)).forEach(({ id: S }) => nt.add(S)), O.selectAll().filter(({ indexId: S }) => nt.has(S)).forEach(({ id: S }) => We.add(S)), qR(o, {
      tableIds: [...ae],
      tableColumnIds: [...vt],
      relationshipIds: [...bt],
      indexIds: [...nt],
      indexColumnIds: [...We],
      memoIds: [...z]
    });
    const _ = $(g.selectAll().map(({ id: S }) => S)), ot = $(v.selectAll().map(({ id: S }) => S)), Zt = $(T.selectAll().map(({ id: S }) => S)), Nt = $(O.selectAll().map(({ id: S }) => S)), Ky = $(k.selectAll().map(({ id: S }) => S)), Qy = $(R.selectAll().map(({ id: S }) => S));
    return v.selectAll().filter(({ tableId: S, id: It, meta: rr }) => !_(S) && p(() => !1)({ id: It, meta: rr })).forEach(({ id: S }) => vt.add(S)), O.selectAll().filter(({ indexId: S, id: It, meta: rr }) => !Zt(S) && p(() => !1)({ id: It, meta: rr })).forEach(({ id: S }) => We.add(S)), Object.entries(c).forEach(([S, [It]]) => {
      if (ZR(It))
        switch (It) {
          case "tableEntities":
            !_(S) && ae.add(S);
            break;
          case "tableColumnEntities":
            !ot(S) && vt.add(S);
            break;
          case "relationshipEntities":
            !Ky(S) && bt.add(S);
            break;
          case "indexEntities":
            !Zt(S) && nt.add(S);
            break;
          case "indexColumnEntities":
            !Nt(S) && We.add(S);
            break;
          case "memoEntities":
            !Qy(S) && z.add(S);
            break;
        }
    }), {
      tableIds: [...ae],
      tableColumnIds: [...vt],
      relationshipIds: [...bt],
      indexIds: [...nt],
      indexColumnIds: [...We],
      memoIds: [...z]
    };
  }
}
const VR = (e) => (t) => ({ id: n, meta: o }) => {
  if (t(n))
    return !1;
  const r = Math.floor(e.diff(D.fromMillis(o.updateAt), "days").toObject().days ?? 0);
  return HR < r;
};
export {
  PL as $,
  M as A,
  $ as B,
  _L as C,
  qL as D,
  KL as E,
  Fx as F,
  jL as G,
  UL as H,
  Dd as I,
  Wp as J,
  VS as K,
  RL as L,
  s2 as M,
  a2 as N,
  tt as O,
  i2 as P,
  au as Q,
  pe as R,
  ps as S,
  Vn as T,
  Oh as U,
  zR as V,
  I as W,
  mL as X,
  s1 as Y,
  cs as Z,
  Ui as _,
  Wi as a,
  qk as a$,
  nL as a0,
  rp as a1,
  QR as a2,
  Ct as a3,
  ZL as a4,
  VL as a5,
  GL as a6,
  zL as a7,
  YL as a8,
  _h as a9,
  Qc as aA,
  dm as aB,
  YE as aC,
  mp as aD,
  wx as aE,
  Cx as aF,
  Vi as aG,
  px as aH,
  F$ as aI,
  q$ as aJ,
  A$ as aK,
  Tp as aL,
  fp as aM,
  tL as aN,
  W as aO,
  YC as aP,
  sL as aQ,
  rL as aR,
  Jn as aS,
  Uh as aT,
  Sp as aU,
  Pe as aV,
  Hh as aW,
  U as aX,
  Tx as aY,
  Ix as aZ,
  Ep as a_,
  uL as aa,
  Al as ab,
  _c as ac,
  fL as ad,
  y as ae,
  lL as af,
  KC as ag,
  GR as ah,
  S2 as ai,
  Ld as aj,
  Ll as ak,
  re as al,
  JR as am,
  mA as an,
  Hi as ao,
  jh as ap,
  gx as aq,
  IL as ar,
  vx as as,
  yx as at,
  $n as au,
  Gh as av,
  HS as aw,
  D$ as ax,
  Rk as ay,
  Kc as az,
  Gw as b,
  dp as b$,
  Hk as b0,
  P as b1,
  G as b2,
  Vh as b3,
  Zh as b4,
  ln as b5,
  P$ as b6,
  B$ as b7,
  rA as b8,
  iA as b9,
  sx as bA,
  n2 as bB,
  Aa as bC,
  wo as bD,
  gL as bE,
  OL as bF,
  cx as bG,
  ux as bH,
  dx as bI,
  Pk as bJ,
  Bh as bK,
  RC as bL,
  fe as bM,
  q as bN,
  Pp as bO,
  Uk as bP,
  QM as bQ,
  Bk as bR,
  D as bS,
  yp as bT,
  Yc as bU,
  k$ as bV,
  x$ as bW,
  _o as bX,
  U$ as bY,
  wd as bZ,
  Xc as b_,
  W$ as ba,
  YS as bb,
  eL as bc,
  Op as bd,
  wp as be,
  Ea as bf,
  tu as bg,
  eu as bh,
  jk as bi,
  IA as bj,
  Fk as bk,
  iL as bl,
  NL as bm,
  qr as bn,
  TL as bo,
  wL as bp,
  Gc as bq,
  Kp as br,
  oL as bs,
  Gx as bt,
  Dk as bu,
  hc as bv,
  zc as bw,
  ft as bx,
  gA as by,
  Je as bz,
  e0 as c,
  FL as c$,
  bL as c0,
  es as c1,
  zk as c2,
  cp as c3,
  Yk as c4,
  Kk as c5,
  Qk as c6,
  Jk as c7,
  Vk as c8,
  sN as c9,
  R$ as cA,
  Wk as cB,
  wA as cC,
  XM as cD,
  SL as cE,
  vL as cF,
  cN as cG,
  kN as cH,
  vp as cI,
  Ze as cJ,
  Yh as cK,
  yN as cL,
  yL as cM,
  mN as cN,
  Mb as cO,
  ql as cP,
  XR as cQ,
  CL as cR,
  xL as cS,
  qM as cT,
  Nl as cU,
  gp as cV,
  Ta as cW,
  t2 as cX,
  QL as cY,
  WL as cZ,
  BL as c_,
  pL as ca,
  tN as cb,
  hL as cc,
  oN as cd,
  aL as ce,
  kL as cf,
  t0 as cg,
  HL as ch,
  aN as ci,
  _s as cj,
  DL as ck,
  $A as cl,
  $$ as cm,
  N$ as cn,
  Mt as co,
  LL as cp,
  Ph as cq,
  ct as cr,
  $L as cs,
  sp as ct,
  _k as cu,
  Hx as cv,
  Lk as cw,
  De as cx,
  lA as cy,
  Pc as cz,
  xt as d,
  ax as d0,
  ix as d1,
  da as d2,
  Rc as d3,
  ML as d4,
  we as d5,
  AL as d6,
  EL as d7,
  dL as d8,
  qR as d9,
  OA as da,
  zw as db,
  US as e,
  ys as f,
  ve as g,
  NS as h,
  CS as i,
  SS as j,
  OS as k,
  ES as l,
  xS as m,
  $S as n,
  Zn as o,
  DS as p,
  AS as q,
  kS as r,
  Fi as s,
  Lh as t,
  fS as u,
  Sl as v,
  Dh as w,
  El as x,
  ZS as y,
  Up as z
};
